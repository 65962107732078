<tds-spin [spinning]="isLoading">
  <tds-layout class="h-screen w-full overflow-hidden">
    <app-header 
      [(isLoading)]="isLoading"
      (roleListChange)="roleListChange($event)">
    </app-header>
    <tds-layout>
      <tds-layout-sider>
        <tds-menu [mode]="'light'" [data]="lstData" [showLogo]="true">
        </tds-menu>
      </tds-layout-sider>
      <tds-layout-content
        class="overflow-auto bg-neutral-3-200 dark:bg-d-neutral-3-50 relative border-t border-t-neutral-2-200 dark:border-t-d-neutral-2-100">
        <div class="absolute inset-0 overflow-y-auto overflow-x-visible tds-custom-scroll" cdkScrollable>
          <router-outlet></router-outlet>
        </div>
      </tds-layout-content>
    </tds-layout>
  </tds-layout>
</tds-spin>