import { Component, OnInit } from '@angular/core';
import { TDSButtonModule } from "tds-ui/button";
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSSelectModule } from 'tds-ui/select';
import { TDSInputModule } from 'tds-ui/tds-input';
import { CategoryService } from '../../service/category.service';
import { CategoryParam, ICategory, ICategoryList, ResponseCreateCategory } from '../../models/category.model';
import { TDSTableModule, TDSTableQueryParams } from 'tds-ui/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TDSMessageModule, TDSMessageService } from 'tds-ui/message';
import { Paging } from 'app/shared/models/paging.model';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from 'app/shared/components/avatar/avatar.component';
import { FormsModule } from '@angular/forms';
import { AuthService } from 'app/core/services/auth.service';
import { UserInfo } from 'app/core/models/user.model';
import { TDSHelperString, TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { StoreService } from 'app/features/stores/services/store.service';
import { Store } from 'app/features/stores/models/store.model';
import { EditCategoryComponent } from '../edit-category/edit-category.component';


@Component({
  selector: 'app-list-category',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TDSButtonModule,
    TDSSelectModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSTableModule,
    DragDropModule,
    TDSMessageModule,
    AvatarComponent,
    TDSModalModule
  ],
  templateUrl: './list-category.component.html',
  styleUrl: './list-category.component.scss'
})
export class ListCategoryComponent implements OnInit {
  isLoading = false;
  paging: Paging = {
    size: 20,
    index: 1,
    total: 0,
  };
  search: string = '';
  listOfCategory: ICategory[] = [];
  searchText: string = '';
  userInfo: UserInfo | null = null;
  listOfStore: Store[] = [];
  selectedStore!: string;
  storeId!:string | null;
  constructor(private categoryService: CategoryService,
    private authService: AuthService,
    private modalService: TDSModalService,
    private storeService: StoreService,
    private messageService: TDSMessageService) {

  }

  ngOnInit(): void {
    this.getCurrentStore();
    this.getUserInfo();
    this.loadCategories();
  }

  getCurrentStore() {
    this.storeId = this.authService.getCurrentStoreId();
  }

  getUserInfo() {
    this.userInfo = this.authService.getUserInfo();
    // if (this.userInfo?.isOwner) {
    //   this.getListStore();
    // }
  }

  // getListStore() {
  //   this.storeService.getStoreList$()
  //     .subscribe({
  //       next: (res: Store[]) => {
  //         this.listOfStore = res;
  //       }
  //       , error: () => this.messageService.error("Có lỗi khi lấy danh sách doanh nghiệp!")
  //     })
  // }

  loadCategories() {
    this._enableLoading();
    const model = this.prepareParam();
    this.categoryService.getCategories$(
      (model.skip - 1) * model.take,
      model.take,
      model.storeId,
      model.filter
    )
      .subscribe({
        next: (res: ICategoryList) => {
          this.paging.total = res.totalCount;
          this.listOfCategory = res.items;
        }
        , error: () => this.messageService.error("Không thể lấy được danh sách danh mục!")
        , complete: () => this._disableLoading()
      })
  }

  private _enableLoading() {
    this.isLoading = true;
  }

  private _disableLoading() {
    this.isLoading = false;
  }

  prepareParam() {
    const model: CategoryParam = {
      skip: this.paging.index,
      take: this.paging.size,
      filter: this.searchText,
      storeId: TDSHelperString.hasValueString(this.storeId) ? this.storeId : ''
    };
    
    return model;
  }

  onQueryParamsChange(event: TDSTableQueryParams) {
    this.paging.size = event.pageSize;
    this.paging.index = event.pageIndex;
    this.loadCategories();
  }

  onSearch(event: TDSSafeAny) {
    this.searchText = (event) ? event.value : undefined;
    this._resetPaging();
    this.loadCategories();
  }

  private _resetPaging() {
    this.paging.index = 1;
  }

  addCategory() {
    const modal = this.modalService.create({
      title: 'Thêm danh mục',
      size: "md",
      content: AddCategoryComponent,
    });

    modal.afterClose.subscribe(((res: ResponseCreateCategory) => {
      if (res) this.loadCategories();
    }))
  }

  onChangeStore(event: TDSSafeAny) {
    this.selectedStore = event;
    this._resetPaging();
    this.loadCategories();
  }

  editCategory(category: ICategory) {
    const modal = this.modalService.create({
      title: 'Chỉnh sửa danh mục',
      size: "md",
      content: EditCategoryComponent,
      componentParams: {
        category: category
      }
    });

    modal.afterClose.subscribe(((res: undefined | boolean) => {
      if (typeof res == 'boolean' && res) this.loadCategories();
    }))
  }

  removeCategory(category: ICategory) {
    this.modalService.error({
      title: 'Xóa danh mục',
      confirmIcon: 'tdsi-trash-fill',
      content: `Bạn có chắc chắn muốn xóa danh mục "<strong>${category.name}</strong>” ?`,
      onOk: () => this.callApiRemoveCategory(category),
      okText:"Xác nhận",
      cancelText:"Đóng"
    });
  }

  callApiRemoveCategory(category: ICategory){
    this.categoryService.removeCategory$(category.id)
      .subscribe({
        next:() => {
          this.messageService.success("Đã xóa thành công!");
          this._resetPaging();
          this.loadCategories();
        },error: () => this.messageService.error("Có lỗi khi xóa, vui lòng thử lại!")
      })
  }
}
