import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { ChannelType, ECommerceName } from 'app/shared/enums/ecommerce.enum';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';

@Component({
  selector: 'app-add-shop',
  standalone: true,
  imports: [
    CommonModule,
    TDSSpinnerModule,
    TDSButtonModule,
    TDSModalModule
  ],
  templateUrl: './add-shop.component.html',
})

export class AddShopComponent {
  @Input() branchId: string | undefined | null = '';
  isLoading: boolean = false;
  defaultChannel = [
    {
      image: '/assets/images/tiktok.png',
      name: ECommerceName.TiktokShop,
      value: ChannelType.TiktokShop,
      isEnabled: true,
    },
    {
      image: '/assets/images/shopee.png',
      name: ECommerceName.Shopee,
      value: ChannelType.Shopee,
      isEnabled: true,
    },
    {
      image: '/assets/images/lazada.png',
      name: ECommerceName.Lazada,
      value: ChannelType.Lazada,
      isEnabled: true,
    },
    {
      image: '/assets/images/tiki.png',
      name: ECommerceName.Tiki,
      value: ChannelType.Tiki,
      isEnabled: false,
    }
  ];
  messageEventListener: ((event: MessageEvent) => void) | undefined;
  urlDomain = environment.apiUrl;
  constructor(private message: TDSMessageService,
    private modalRef: TDSModalRef,
  ) { }

  connect(eChannel: ChannelType) {
    this.isLoading = true;
    this.getAuthenUrl(eChannel);
  }

  getAuthenUrl(channel: number) {
    let w = 1200, h = 800;
    let left = Number((screen.width / 2) - (w / 2));
    let tops = Number((screen.height / 2) - (h / 2));
    let windowPopup = window.open(`${this.urlDomain}/ecommerce/connect?channel=${channel}&app=omni&clientId=${this.branchId}`, ``, 'width=' + w + ', height=' + h + ', top=' + tops + ', left=' + left);
    // Xóa the new event listener nếu nó có tồn tại
    if (this.messageEventListener) {
      window.removeEventListener("message", this.messageEventListener);
    }

    // Kiểm tra sự kiện đóng của popup
    let popupTick = setInterval(() => {
      if (windowPopup?.closed) {
        clearInterval(popupTick);
        this.isLoading = false;
      }
    }, 1000);

    // Định nghĩa the new event listener
    this.messageEventListener = (event: MessageEvent) => {
      let body = event.data;
      if (body) {
        //Xử lý check xem gian hàng đã kết nối với cửa hàng khác chưa
        const parsedData = JSON.parse(body.data);
        const isSuccess = parsedData.IsSuccess;
        if (isSuccess) {
          this.modalRef.destroy(true);
          this.message.success("Kết nối thành công");
        } else {
          this.message.error(parsedData.Message);
        }
      }
    };
    // Thêm the new event listener
    window.addEventListener("message", this.messageEventListener);
  }

  onCancel() {
    this.modalRef.destroy();
  }
}
