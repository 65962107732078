<ng-container>
  <tds-spin [spinning]="isLoading">
    <form class="w-full h-full flex flex-col"
      [formGroup]="storeForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="flex items-center gap-4 w-full px-10 py-5 tds-color-background-neutral-minimalist">
        <tds-badge [count]="editIconTpl">
          <tds-spin [spinning]="isUploading">
            <tds-avatar [size]="64" [icon]="'tdsi-building-fill'"
              [tdsSrc]="storeForm.value?.avatar!"
              class="tds-color-background-neutral-minimal text-tds-color-neutral-300-light dark:text-tds-color-neutral-300-dark"
            ></tds-avatar>
          </tds-spin>
        </tds-badge>

        <ng-template #editIconTpl>
          <app-custom-upload-image
            (uploadSucceeded)="handleUploadSucceeded($event)"
            (loadingChange)="onLoadingChange($event)"
          ></app-custom-upload-image>
        </ng-template>

        <div class="w-full flex-1">
          <div class="flex items-center justify-between pb-2">
            <tds-label [tdsRequired]="true">Tên doanh nghiệp</tds-label>
            <p class="tds-caption-1 tds-color-text-tertiary">{{storeForm.value?.storeName?.length}} / 100</p>
          </div>
          <tds-form-field class="w-full">
            <input tdsInput
              formControlName="storeName"
              placeholder="Tối đa 100 ký tự"
              [maxlength]="100"
            />
            <tds-error>Vui lòng nhập tên doanh nghiệp</tds-error>
          </tds-form-field>
        </div>
      </div>

      <div class="w-full flex flex-col gap-3 p-4">
        <div class="flex items-center gap-2">
          <tds-label [tdsRequired]="true" class="w-1/3">Tên chủ doanh nghiệp</tds-label>
          <tds-form-field class="w-2/3 flex items-center gap-2">
            <input tdsInput
              formControlName="ownerName"
              placeholder="Nhập tên chủ doanh nghiệp"
              [maxlength]="100"
            />
            <tds-error>Vui lòng nhập tên chủ doanh nghiệp</tds-error>
          </tds-form-field>
        </div>

        <div class="flex items-center gap-2">
          <tds-label class="w-1/3">Ngành hàng kinh doanh</tds-label>
          <div class="w-2/3 flex items-center gap-2">
            <tds-form-field class="flex-1">
              <tds-select
                [data]="storeCategories"
                valueField="id"
                textField="name"
                formControlName="storeCategories"
                placeholder='Chọn ngành hàng'
                [allowClear]="true"
                [mode]="'multiple'"
                [valuePrimitive]="false"
                (selectChange)="onChangeStoreCategories($event)"
                (openChange)="onOpenChange($event)"
                [loading]="isLoadingCategories"
              >
                <ng-template
                  tds-option-tmp
                  let-item="item"
                  let-value='value'
                  let-selected="selected"
                >
                  <div class="flex items-center justify-between w-full">
                    <span>
                      {{item.name}}
                    </span>

                    <div class="flex items-center gap-2">
                      @if (selected) {
                        <i class="tdsi-success-fill tds-color-text-success"></i>
                      }

                      @if (!item.isDefault) {
                        <button tds-button-flat-icon
                          color="error"
                          size="xs"
                          (tdsBtnClick)="onDeleteCategory($event,item)"
                        >
                          <i class="tdsi-trash-line"></i>
                        </button>
                      }
                    </div>
                  </div>
                </ng-template>
              </tds-select>
            </tds-form-field>

            <app-quick-add-store-category
              [storeId]="store.id"
              (categoryAdded)="loadStoreCategories(store.id)"
            ></app-quick-add-store-category>
          </div>
        </div>

        <div class="flex items-center gap-2">
          <tds-label class="w-1/3">Email</tds-label>
          <tds-form-field class="w-2/3 flex items-center gap-2">
            <input tdsInput
              formControlName="email"
              placeholder="Nhập email"
            />
            @if (storeForm.get('email')?.errors?.['inValidEmail']) {
              <tds-error>Email không đúng định dạng</tds-error>
            }
          </tds-form-field>
        </div>

        <div class="flex items-start gap-2">
          <tds-label class="w-1/3">Số điện thoại</tds-label>
          <div class="w-2/3 flex flex-col gap-2">
            <tds-form-field class="w-full">
              <input tdsInput
                formControlName="phoneNumber"
                placeholder="Nhập số điện thoại"
                [maxlength]="10"
                numbersOnly
                (blur)="onPhoneBlur()"
                (focus)="onPhoneFocus()"
              />
              @if (storeForm.get('phoneNumber')?.errors?.['pattern'] && phoneIsTouched) {
                <tds-error>Số điện thoại không hợp lệ</tds-error>
              }
            </tds-form-field>
          </div>
        </div>

        <div class="flex items-start gap-2">
          <tds-label class="w-1/3" [tdsRequired]="true">Địa chỉ</tds-label>
          <div class="w-2/3 flex flex-col gap-2">
            <tds-form-field class="w-full">
              <input tdsInput
                formControlName="street"
                placeholder="Nhập số nhà, tên đường"
                [maxlength]="1000"
              />
              <tds-error>Vui lòng nhập địa chỉ</tds-error>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="provinces"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="city"
                placeholder="Tỉnh/thành phố"
                [allowClear]="true"
                (selectChange)="onChangeProvince($event)"
              >
              </tds-select>
              <tds-error>Vui lòng chọn tỉnh/thành phố</tds-error>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="districts"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="district"
                placeholder="Quận/huyện"
                [allowClear]="true"
                (selectChange)="onChangeDistrict($event)"
              >
              </tds-select>
              <tds-error>Vui lòng chọn quận/huyện</tds-error>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="wards"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="ward"
                placeholder="Phường/xã"
                [allowClear]="true"
                (selectChange)="onChangeWard($event)"
              >
              </tds-select>
              <tds-error>Vui lòng chọn phường/xã</tds-error>
            </tds-form-field>
          </div>
        </div>

        <div class="flex items-center gap-2">
          <tds-label class="w-1/3">Múi giờ</tds-label>
          <div class="w-2/3">UTC+7</div>
        </div>
      </div>
      <div
        *tdsModalFooter
        class="flex items-center justify-end gap-2 p-4"
      >
        <button tds-button-outline
          color="secondary"
          class="min-w-[100px]"
          type="button"
          (tdsBtnClick)="onCancel()"
        >
          Hủy
        </button>
        <button tds-button
          color="primary"
          class="min-w-[100px]"
          type="submit"
          (tdsBtnClick)="onSubmit()"
        >
          Lưu
        </button>
      </div>
    </form>
  </tds-spin>
</ng-container>
