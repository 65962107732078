import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ACTIVATED_SHOP_ENDPOINT, SHOP_LIST_ENDPOINT } from "app/core/constants/api-endpoint.const";
import { ApiService } from "app/core/services/api.service";
import { ChannelType } from "app/shared/enums/ecommerce.enum";
import { IBaseList } from "app/shared/interface/base-list.interface";
import { Observable } from "rxjs";
import { TDSHelperString, TDSSafeAny } from "tds-ui/shared/utility";
import { ShopInfo } from "../models/shop-info.model";

@Injectable({
    providedIn: 'root'
})
export class ShopService {
    messageEventListener: ((event: MessageEvent) => void) | undefined;

    constructor(
        private readonly apiService: ApiService
    ) {
    }

    /**
     * Get list shops
     * @param skip 
     * @param take 
     * @param filter 
     * @param storeId 
     * @returns 
     */
    getShops$(
        storeBranchId: string | null | undefined = null,
        skip: number = 0,
        take: number = 20,
        name: string = '',
        eChannel: number = ChannelType.All,
        isActive: boolean | null = null
      ): Observable<IBaseList<ShopInfo>> {
        const headers = new HttpHeaders().set('ClientId', TDSHelperString.hasValueString(storeBranchId) ? storeBranchId! : '');
        const nameParams = TDSHelperString.hasValueString(name) ? `&name=${name}` : '';
        const eChannelParams = eChannel != ChannelType.All ? `&e_channel=${eChannel}` : '';
        const isActiveParams = TDSHelperString.hasValueString(isActive) ? `&is_active=${isActive}` : '';
        return this.apiService.get<IBaseList<ShopInfo>>(`${SHOP_LIST_ENDPOINT}?skip=${skip}&take=${take}${nameParams}${isActiveParams}${eChannelParams}`, headers);
    }
   
    /**
     * Thay đổi trạng thái shop
     * @param channelId 
     * @returns 
     */
    updateLinkedShop(channel_token: string) {
        const headers = new HttpHeaders().set('ChannelAuthentication', TDSHelperString.hasValueString(channel_token) ? channel_token! : '');
        const url = `${ACTIVATED_SHOP_ENDPOINT}`;
        return this.apiService.put<TDSSafeAny>(url, null, headers);
    }
}