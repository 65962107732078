<tds-upload
  [listType]="'picture'"
  [accept]="fileType"
  [showUploadList]="false"
  [customRequest]="handleUpload"
>
  <div class="absolute top-10 -right-2 rounded-full bg-white p-0.5 flex items-center justify-center cursor-pointer">
    <div class="bg-primary-1 dark:bg-d-primary-1 flex items-center justify-center p-1 rounded-full">
      <span class="tdsi-edit-fill text-white text-base leading-4 flex items-center justify-center"></span>
    </div>
  </div>
</tds-upload>