<div class="flex items-center py-2 px-3 justify-between">
    <div class="font-semibold tds-heading-6">
        Thuộc tính
    </div>
    <div class="">
        <button 
            tds-button-outline-icon 
            color="secondary" 
            class="mr-2"
            tds-tooltip
            tooltipTitle="Sắp xếp theo ngày cập nhật"
            (tdsBtnClick)="changeSort()">
            <i [ngClass]="sortByName | sortDateClass"></i>
        </button>
        <button 
            tds-button 
            tds-popover
            popoverTrigger="click" 
            [popoverContent]="addAttributeTemplate"
            [popoverFooter]="footerTemplate"
            [(popoverVisible)]="isVisibleAddAttribute"
            popoverPlacement="bottomRight"
            color="primary" 
            [tdsPrefixIcon]="'tdsi-plus-circle-fill'">
            Thêm mới
        </button>
    </div>
</div>
<ng-template #addAttributeTemplate>
    <div class="w-72">
        <form [formGroup]="form" id="form">
            <tds-form-field class="w-full">
                <tds-label tdsRequired="true">Thuộc tính</tds-label>
                <input 
                    tdsInput 
                    autocomplete="off"  
                    placeholder="Tối đa 100 ký tự" 
                    formControlName="name"
                    [maxlength]="rule.name.maxLength"
                    (blur)="trimName()"
                    [required]='true' />
                @if (this.form.get('name')?.errors?.['required'] || this.form.get('name')?.errors?.['whitespace']) {
                    <tds-error>Vui lòng nhập tên thuộc tính</tds-error>
                }
                @if (this.form.get('name')?.errors?.['duplicate']) {
                    <tds-error>Thuộc tính đã tồn tại</tds-error>
                }
            </tds-form-field>
        </form>
    </div>
</ng-template>

<ng-template #footerTemplate>
    <div class="flex justify-end">
        <button tds-button color="secondary" class="mr-2" size="sm" (click)="close()">
            Đóng
        </button>
        <button tds-button size="sm" (click)="addAttribute()">
            Thêm thuộc tính
        </button>
    </div>
</ng-template>