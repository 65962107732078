import { Injectable } from '@angular/core';
import { USER_INFO_ENDPOINT } from 'app/core/constants/api-endpoint.const';
import { UserInfo } from 'app/core/models/user.model';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';
import { UpdateUserInfoReq } from '../models/update-user-info-req.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getUserInfo$(): Observable<UserInfo> {
    return this.apiService.get<UserInfo>(USER_INFO_ENDPOINT);
  }

  updateUserInfo$(updateInfo: UpdateUserInfoReq): Observable<UserInfo> {
    return this.apiService.put<UserInfo>(USER_INFO_ENDPOINT, updateInfo);
  }
}