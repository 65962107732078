import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { CreateStoreComponent } from '../create-store/create-store.component';
import { UserInfo } from 'app/core/models/user.model';
import { StoreService } from '../../services/store.service';
import { TDSCardModule } from 'tds-ui/card';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSTagModule } from 'tds-ui/tag';
import { Store } from '../../models/store.model';
import { CommonModule } from '@angular/common';
import { TDSMessageService } from 'tds-ui/message';
import { BranchListComponent } from 'app/features/branches/components/branch-list/branch-list.component';
import { TDSDestroyService } from 'tds-ui/core/services';
import { AuthService } from 'app/core/services/auth.service';
import { takeUntil } from 'rxjs';
import { ErrorResponse } from 'app/core/models/error-response.model';
import { EditStoreComponent } from '../edit-store/edit-store.component';
import { AddressPipe } from 'app/shared/pipes/address.pipe';
import { HeaderService } from 'app/shared/services/header.service';

@Component({
  selector: 'app-store-list',
  standalone: true,
  imports: [
    CommonModule,
    TDSModalModule,
    TDSCardModule,
    TDSAvatarModule,
    TDSButtonModule,
    TDSTagModule,
    BranchListComponent,
    AddressPipe
  ],
  providers: [
    TDSModalService,
    StoreService,
    TDSMessageService,
  ],
  templateUrl: './store-list.component.html',
  styleUrl: './store-list.component.scss'
})
export class StoreListComponent implements OnInit, OnChanges {
  @Input() userInfo!: UserInfo;
  @Output() onModalClose = new EventEmitter<any>();
  store!: Store;

  constructor(
    private readonly modalService: TDSModalService,
    private readonly storeService: StoreService,
    private readonly msgService: TDSMessageService,
    private readonly authService: AuthService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly headerService: HeaderService,
  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userInfo']?.currentValue) {
      this.userInfo = changes['userInfo'].currentValue;
      if (!this.userInfo.roleList.length && !this.userInfo.isOwner) {
        this.createStore(true);
      }
    }
  }

  ngOnInit(): void {
    this.loadStoreList();
  }
  /**
   * Get all enterprise (store) of user
   */
  loadStoreList(): void {
    this.storeService.getStoreList$()
      .pipe(
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          const currentStoreId = this.authService.getCurrentStoreId();
          this.store = res.find(store => store.id === currentStoreId) as Store;
        },
        error: (err: ErrorResponse) => {
          this.msgService.error(err.error.message || "Đã xảy ra lỗi, vui lòng thử lại sau");
        }
      });
  }
  /**
   * Create a new store
   * @param isFirst - True if it's the first store, false otherwise
   */
  createStore(isFirst: boolean): void {
    const modal = this.modalService.create({
      closable: !isFirst,
      title: isFirst ? 'Tạo cửa hàng' : 'Tạo doanh nghiệp mới',
      content: CreateStoreComponent,
      componentParams: {
        userInfo: this.userInfo,
        isFirst: isFirst
      },
      size: 'lg',
      bodyStyle: {
        'padding': '0px'
      },
      footer: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        this.headerService.triggerReloadHeader();
        this.loadStoreList();
      }
    });
  }

  onCreateStore(): void {
    this.createStore(false);
  }

  onReloadData(): void {
    this.loadStoreList();
  }

  onEditStore(store: Store): void {
    const modal = this.modalService.create({
      title: 'Chỉnh sửa doanh nghiệp',
      content: EditStoreComponent,
      componentParams: {
        store: store
      },
      size: 'lg',
      bodyStyle: {
        'padding': '0px'
      },
      footer: null
    });

    modal.afterClose.subscribe((result: any) => {
      if (result) {
        this.loadStoreList();
        this.headerService.triggerReloadHeader();
      }
    });
  }
}
