import { Component, EventEmitter, Output } from '@angular/core';
import { UploadImageResponse } from 'app/shared/models/upload-image.model';
import { UploadImageService } from 'app/shared/services/upload-image.service';
import { catchError, of, Subscription, switchMap, takeUntil } from 'rxjs';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSMessageService } from 'tds-ui/message';
import { TDSUploadModule, TDSUploadXHRArgs } from "tds-ui/upload";

@Component({
  selector: 'app-custom-upload-image',
  standalone: true,
  imports: [
    TDSUploadModule
  ],
  templateUrl: './custom-upload-image.component.html',
  styleUrl: './custom-upload-image.component.scss'
})
export class CustomUploadImageComponent {
  @Output() uploadSucceeded = new EventEmitter<UploadImageResponse>();
  @Output() loadingChange = new EventEmitter<boolean>();

  fileType = "image/jpeg, image/jpg, image/png, image/heic";

  constructor(
    private readonly uploadImageService: UploadImageService,
    private readonly msgService: TDSMessageService,
    private readonly tdsDestroyService: TDSDestroyService
  ) { }


  handleUpload = (item: TDSUploadXHRArgs): Subscription => {
    this.loadingChange.emit(true);//enable loading

    return this.uploadImageService.validateImageType(item.file as unknown as File)
      .pipe(
        switchMap((isValidType: boolean) => {
          if (!isValidType) {
            this.loadingChange.emit(false);//disable loading
            return of(false);
          }

          return this.uploadImageService.resizeImage(item.file as unknown as File)
            .pipe(
              switchMap((resizedFile: File) => {
                return this.uploadImageService.processImageSize(resizedFile);
              }),
              switchMap((processedFile: File) => {
                return this.uploadImageService.uploadImage(processedFile)
                  .pipe(
                    takeUntil(this.tdsDestroyService)
                  );
              })
            )
        }),
        catchError((error: any) => {
          throw error;
        })
      ).subscribe({
        next: (response: boolean | UploadImageResponse) => {
          if (typeof response !== 'boolean') {
            this.uploadSucceeded.emit(response);
          }
        },
        error: () => {
          this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
        },
        complete: () => {
          this.loadingChange.emit(false);//disable loading
        }
      })
  }
}
