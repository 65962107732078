import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { SignUpErrorResponse, SignUpReq, SignUpResponse, VerifiedOtp } from '../../../../core/models/sign-up.model';
import { WiAccountService } from '../../../../core/services/wi-account.service';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSModalRef } from 'tds-ui/modal';
import { TDSInputModule } from 'tds-ui/tds-input';
import { finalize, takeUntil } from 'rxjs/operators';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSMessageService } from 'tds-ui/message';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { WiECommerceDirtyErrorStateMatcher } from '../../../../core/utilities/error-state-matcher.util';
import { noWhitespaceOrVietnamese } from 'app/core/utilities/no-white-space-or-vietnamese.util';
import { emailValidator } from 'app/core/utilities/email-validator.util';

// Interface for the create password form
interface CreatePasswordForm {
  fullName: FormControl<string | null>;
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}

@Component({
  selector: 'app-create-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSButtonModule,
    CommonModule
  ],
  providers: [
    WiAccountService,
    TDSDestroyService,
    TDSMessageService,
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    },
  ],
  templateUrl: './create-password.component.html',
  styleUrl: './create-password.component.scss'
})
export class CreatePasswordComponent implements OnInit {
  @Input() verifiedOtp!: VerifiedOtp;
  form!: FormGroup<CreatePasswordForm>;

  // Password visibility toggles and input types
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;
  readonly passwordInputType = 'password';
  readonly textInputType = 'text';
  passwordFieldType = this.passwordInputType;
  confirmPasswordFieldType = this.passwordInputType;
  isLoading = false;

  showPasswordHint = false;

  constructor(
    private readonly modalRef: TDSModalRef,
    private readonly wiAccountService: WiAccountService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly messageService: TDSMessageService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  // Initialize the form with validators
  private initForm(): void {
    const passwordPattern = /^[^\s]{8,16}$/;

    this.form = new FormGroup<CreatePasswordForm>({
      fullName: new FormControl(null, Validators.required),
      email: new FormControl(null, emailValidator),
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(passwordPattern),
        noWhitespaceOrVietnamese
      ]),
      confirmPassword: new FormControl(null)
    });

    this.form.get('confirmPassword')?.setValidators([
      Validators.required,
      this.confirmPasswordValidator.bind(this)
    ]);
  }

  private confirmPasswordValidator(control: AbstractControl): ValidationErrors | null {
    const password = this.form.get('password')?.value;
    const confirmPassword = control.value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  // Getter for the fullName form control, providing access to the fullName field in the form
  get fullName() {
    return this.form.get('fullName') as FormControl;
  }

  // Getter for the email form control, providing access to the email field in the form
  get email() {
    return this.form.get('email') as FormControl;
  }

  // Getter for the password form control, providing access to the password field in the form
  get password() {
    return this.form.get('password') as FormControl;
  }

  // Getter for the confirmPassword form control, providing access to the confirmPassword field in the form
  get confirmPassword() {
    return this.form.get('confirmPassword') as FormControl;
  }

  // Prepares the sign-up request object using the form values and verified OTP data
  private prepareSignUpReq(): SignUpReq {
    return {
      name: this.form.value.fullName ?? '',
      email: this.form.value.email ?? null,
      password: this.form.value.password ?? '',
      phoneNumber: this.verifiedOtp.phone,
      smsOtpToken: this.verifiedOtp.smsOtpToken,
      otpCode: this.verifiedOtp.otpCode
    };
  }

  private markFormAsDirty(): void {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key)?.markAsDirty();
    });
  }

  // Handle form completion
  onComplete(): void {
    this.markFormAsDirty();
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;
    const signUpReq = this.prepareSignUpReq();
    this.wiAccountService.signUp(signUpReq)
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: SignUpResponse) => {
          this.modalRef.destroy(true);
        },
        error: (err: SignUpErrorResponse) => {
          this.messageService.error(err.error.message);
        }
      });
  }

  // Handle back navigation
  onBack(): void {
    this.modalRef.close();
  }

  // Toggle password visibility
  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.passwordFieldType = this.isPasswordVisible ? this.textInputType : this.passwordInputType;
  }

  // Toggle confirm password visibility
  toggleConfirmPasswordVisibility(): void {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    this.confirmPasswordFieldType = this.isConfirmPasswordVisible ? this.textInputType : this.passwordInputType;
  }

  onFocus(): void {
    this.showPasswordHint = true;
    this.password.markAsDirty();
  }

  onBlur(): void {
    const value = this.fullName.value?.trim();
    this.fullName.setValue(value);
  }
}
