<div class="w-full h-full flex flex-col">
    <div class="flex flex-col tds-page-header-color-background">
        <tds-page-header tdsTitle="Kênh kết nối">
            <tds-page-header-content>
                <div class="w-full flex items-center">
                    <div class="flex items-center">
                        <tds-tabs-filter 
                            [tdsData]="lstChannel" 
                            [(tdsValue)]="selectedChannel" 
                            (tdsOptionChange)="onChannelSelected($event)"
                            tdsType="pill">
                        </tds-tabs-filter>
                    </div>
                </div>
            </tds-page-header-content>
            <tds-page-header-extra>
                <button tds-button 
                    color="primary" 
                    [tdsPrefixIcon]="'tdsi-shop-fill'" 
                    (click)="addChannel()"> 
                    Kết nối gian hàng
                </button>
            </tds-page-header-extra>
        </tds-page-header>
    </div>
    <div class="h-full p-2">
        <div class="rounded-md bg-white flex flex-col w-full h-full">
            <div class="flex justify-between px-2 items-center">
                <tds-filter-status 
                    [(ngModel)]="selectedStatus" 
                    (selectChange)="onStatusSelected($event)">
                    <ng-template ngFor let-item [ngForOf]="statusItems" let-i="index">
                        <tds-filter-status-item 
                            [name]='item.text' 
                            [value]="item.status">
                        </tds-filter-status-item>
                    </ng-template>
                </tds-filter-status>
                <div class="flex gap-2 category-actions">
                    <tds-form-field class="w-[300px] input-category">
                        <input autocomplete="off" 
                            tdsInput placeholder="Nhập tên gian hàng, cửa hàng" 
                            [tdsInputDebounce]="750"
                            (inputKeyup)="onSearch($event)"
                            [(ngModel)]="searchText"/>
                        <span tdsPrefix><i class="tdsi-search-fill"></i></span>
                    </tds-form-field>
                </div>
            </div>
            <div class="h-full w-full">
                <tds-table #categoryTable 
                    showSizeChanger 
                    [loading]="isLoading" 
                    [total]="paging.total" 
                    [pageSize]="paging.size"
                    [pageIndex]="paging.index"
                    [frontPagination]="false"
                    [noResult]="noResult"
                    [scroll]="{'x':'auto','y': 'auto'}"
                    (clickRefresh)="loadShops()"
                    (queryParams)="onQueryParamsChange($event)"
                    [listData]="listOfShops">
                    <thead>
                        <tr>
                            <th [width]="'120px'">STT</th>
                            <th [width]="'400px'">Gian hàng</th>
                            <th [width]="'243px'">Ngày kết nối</th>
                            <th [width]="'243px'">Trạng thái kết nối</th>
                            <th [width]="'243px'">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for(shop of listOfShops; let i = $index; track shop.shop_id) {
                        <tr>
                            <td>
                                <div class="tds-body-2">
                                    {{ i + 1 }}
                                </div>
                            </td>
                            <td>
                                <div class="tds-body-2 flex items-center gap-2">
                                    <i class="text-xl leading-none" 
                                        [ngClass]="shop | tdsMapper: getIconStatus"></i>
                                    <span class="font-semibold tds-color-text-info cursor-pointer" 
                                        (click)="onDetail(shop)">{{ shop.shop_name }}</span>
                                </div>
                                <span class="text-orange-400 text-xs ml-6 "
                                    *ngIf="shop.message_error === DEAUTHORIZED_MESSAGE_1 || shop.message_error=== DEAUTHORIZED_MESSAGE_2">
                                    Gian hàng bị hủy ủy quyền
                                </span>
                                <span class="text-orange-400 text-xs ml-6 "
                                    *ngIf="(shop.e_Channel === channelEnum.Shopee && shop.message_error === CANCELED_BY_USER) || (shop.e_Channel === channelEnum.Lazada && shop.message_error == DEAUTHORIZED_MESSAGE_LAZADA)">
                                    Gian hàng bị hủy bởi người dùng
                                </span>
                                <span class="text-orange-400 text-xs ml-6 " *ngIf="!shop.can_enable">
                                    Gian hàng bị hủy kích hoạt
                                </span>
                            </td>
                            <td>
                                <span>{{shop.last_connected_time | date: 'dd/MM/yyyy'}}</span>
                            </td>
                            <td>
                                <div class="flex gap-2 items-center text-body-2">
                                    <tds-switch 
                                        class="flex items-center"
                                        [tdsSize]="'md'" 
                                        (ngModelChange)="onClickLinkShop(shop, i)"
                                        [tdsLoading]="indexLoadingSwitch === i" 
                                        [tdsDisabled]="!shop.can_enable"
                                        [(ngModel)]="shop.is_active">
                                    </tds-switch>
                                    <span>{{shop | tdsMapper: getNameStatus}}</span>
                                </div>
                            </td>
                            <td>
                                <button 
                                    tds-button-flat-icon
                                    color="info"
                                    size="sm"
                                    tds-tooltip
                                    tooltipTitle="Chi tiết"
                                    (click)="onDetail(shop)"
                                >
                                    <i class="tdsi-edit-line"></i>
                                </button>
                            </td>
                        </tr>
                        }
                    </tbody>
                </tds-table>
            </div>
        </div>
    </div>
</div>

<ng-template #noResult>
    <div class="flex justify-center items-center w-full h-full bg-white rounded-md">
        <div class="flex flex-col gap-4">
          <div class="w-full h-full px-[48px]">
            <img src="/assets/images/shop-no-result.svg"
              alt="no-result" class="w-full h-full">
          </div>
          <div class="flex flex-col gap-2 items-center justify-center">
            <div class="tds-heading-6 tds-color-text-primary font-semibold">Chưa có gian hàng kết nối</div>
            <div class="text-body-2 tds-color-text-secondary">Hãy kết nối gian hàng vào danh sách của bạn</div>
          </div>
          <div class="flex justify-center">
            <button tds-button 
                color="primary" 
                [tdsPrefixIcon]="'tdsi-shop-fill'" 
                (click)="addChannel()"> 
                Kết nối gian hàng
            </button>
          </div>
        </div>
      </div>
</ng-template>