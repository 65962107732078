<div class="flex flex-col gap-3 w-full">
    <div class="tds-heading-6 font-semibold">Thêm mới giá trị thuộc tính</div>
    <form [formGroup]="form" id="form">
        <div class="flex gap-2 w-full">
            <tds-form-field class="w-full">
                <tds-select 
                    valueField="id" 
                    textField="name" 
                    placeholder='Chọn thuộc tính' 
                    disabledField="isActive"
                    [data]="listOfAttribute"
                    formControlName="attributeId"
                    (selectChange)="changeAttributes($event)"
                    [required]='true' 
                    (blur)="trimName()"
                    [allowClear]="true">
                </tds-select>
                @if (this.form.get('attributeId')?.errors?.['required'] ) {
                    <tds-error>Vui lòng chọn thuộc tính</tds-error>
                }
            </tds-form-field>
            
            <tds-form-field class="w-full">
                <input tdsInput 
                    autocomplete="off" 
                    formControlName="name"
                    [maxlength]="rule.name.maxLength"
                    placeholder="Nhập giá trị thuộc tính" 
                    (blur)="trimName()"
                    [required]='true' />
                @if (this.form.get('name')?.errors?.['required'] || this.form.get('name')?.errors?.['whitespace']) {
                    <tds-error>Vui lòng nhập tên giá trị thuộc tính</tds-error>
                }
                @if (this.form.get('name')?.errors?.['duplicate']) {
                    <tds-error>Giá trị thuộc tính đã tồn tại</tds-error>
                }
            </tds-form-field>
            <button tds-button 
                color="success" 
                class="w-[100px]"
                [disabled]="isLoadingAdd"
                (click)="onSave()"
                >Thêm
            </button>
        </div>
    </form>
</div>