import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  
  if (authService.isAuthenticated()) {
    return true;
  }
  
  // If unauthenticated, redirect to sign-in page
  // return router.createUrlTree(['/sign-in'], { queryParams: { returnUrl: state.url }});
  router.navigate(['/sign-in']);
  return false;
};