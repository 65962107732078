import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserInfo } from 'app/core/models/user.model';
import { AuthService } from 'app/core/services/auth.service';
import { StoreBranch, UserStoreBranch } from 'app/features/branches/models/store-branch.model';
import { BranchService } from 'app/features/branches/services/branch.service';
import { StoreService } from 'app/features/stores/services/store.service';
import { UserService } from 'app/features/users/services/user.service';
import { HeaderService } from 'app/shared/services/header.service';
import { finalize, takeUntil } from 'rxjs';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSDropDownModule } from "tds-ui/dropdown";
import { TDSHeaderModule } from 'tds-ui/header';
import { TDSMessageService } from 'tds-ui/message';
import { TDSTagModule } from 'tds-ui/tag';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    TDSHeaderModule,
    TDSAvatarModule,
    TDSDropDownModule,
    TDSButtonModule,
    TDSTagModule,
    RouterModule
  ],
  providers: [
    TDSDestroyService,
    UserService,
    StoreService,
    AuthService,
    TDSMessageService,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  @Input() isLoading!: boolean;
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @Output() roleListChange = new EventEmitter<string[]>();

  userInfo!: UserInfo;
  storeBranches: StoreBranch[] = [];
  currentStore!: UserStoreBranch;
  currentBranch!: StoreBranch;
  userStoreBranches: UserStoreBranch[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly destroyService: TDSDestroyService,
    private readonly msgService: TDSMessageService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly branchService: BranchService,
    private readonly headerService: HeaderService,
  ) {
  }

  ngOnInit(): void {
    // Load header data lần đầu
    this.loadHeader();

    // Listen event reload header
    this.headerService.reloadHeader$
      .pipe(takeUntil(this.destroyService))
      .subscribe((res: boolean) => {
        if (res) {
          this.loadHeader();
          this.headerService.resetReloadHeader();
        }
      });
  }

  loadHeader(): void {
    this.loadUserInfo();
    this.loadStoreBranches();
  }

  loadUserInfo() {
    this.userService.getUserInfo$()
    .pipe(
      takeUntil(this.destroyService),
      finalize(() => this.isLoadingChange.emit(false))
    )
    .subscribe({
      next: (info) => {
        this.userInfo = info;
        this.roleListChange.emit(info.roleList);
      },
      error: (error) => {
        this.msgService.error(error.message ? `${error.message}` : 'Không lấy được thông tin người dùng');
      }
    });
  }

  signOut() {
    this.authService.signOut();
  }

  navigateToProfile() {
    this.router.navigate(['/user/profile']);
  }
  /**
   * Set default selected branch and store
   * @param branch 
   */
  private setDefaultBranch(branch: StoreBranch) {
    this.authService.setCurrentBranchId(branch.id);
    this.authService.setCurrentStoreId(branch.storeId);
    this.authService.setBranchTenantId(branch.tenantId);
  }

  loadStoreBranches(): void {
    this.branchService.getUserStoreBranches$()
      .pipe(
        takeUntil(this.destroyService),
      )
      .subscribe({
        next: (res: UserStoreBranch[]) => {
          this.userStoreBranches = res;
  
          if (this.userStoreBranches.length === 1 && this.userStoreBranches[0].storeBranchList.length === 1) {
            this.setDefaultBranch(this.userStoreBranches[0].storeBranchList[0]);
          }
          
          const currentBranchId = this.authService.getCurrentBranchId();
          const currentStoreId = this.authService.getCurrentStoreId();
          if (currentBranchId && currentStoreId) {
            this.currentStore = res.find(store => store.id === currentStoreId) as UserStoreBranch;
            this.currentBranch = this.currentStore.storeBranchList.find(branch => branch.id === currentBranchId) as StoreBranch;
          }
        },
        error: (err) => {
          this.msgService.error("Lỗi khi lấy danh sách cửa hàng, vui lòng thử lại sau");
        }
      });
  }

  changeBranch(branch: any) {
  }
}
