import { TDSMenuDTO } from 'tds-ui/menu';

export const DATA_MENU: Array<TDSMenuDTO> = [
  {
    name: "Tổng quan",
    icon: "tdsi-bar-chart-fill",
    link: "/dashboard"
  },
  {
    name: "Kết nối",
    icon: "tdsi-shop-fill",
    link: "/connection"
  },
  // {
  //   name: "Tin nhắn",
  //   icon: "tdsi-chat-message-fill",
  //   link: "/messages"
  // },
  {
    name: "Quản lý sản phẩm",
    icon: "tdsi-box-fill",
    listChild: [
      {
        name: "Danh sách sản phẩm",
        link: "/products/list",
      },
      // {
      //   name: "Xuất nhập kho",
      //   link: "/warehouses",
      // },
    ]
  },
  // {
  //   name: "Sàn TMĐT",
  //   icon: "tdsi-cart-fill",
  //   link: "/ecom-products"
  // },
  // {
  //   name: "Đơn hàng",
  //   icon: "tdsi-shopping-bag-check-fill",
  //   link: "/orders"
  // },
  // {
  //   name: "Trả hàng/ hoàn tiền",
  //   icon: "tdsi-cod-fill",
  //   link: "/return-refund"
  // },
  {
    name: "Nhà cung cấp",
    icon: "tdsi-storehouse-fill",
    link: "/suppliers",
  },
  {
    name: "Cài đặt",
    icon: "tdsi-gear-fill",
    listChild: [
      {
        name: "Cài đặt chung",
        link: "settings/categories/list",
      },
      {
        name: "Vai trò",
        link: "roles/list",
      },
      {
        name: "Nhân viên",
        link: "employees/list",
      },
    ]
  },
];
