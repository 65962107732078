<div class="flex items-start gap-2 w-full p-2">
  <div class="w-1/3 bg-white rounded-lg h-auto">
    <section class="flex items-center justify-between gap-2 px-4 py-2">
      <p class="tds-heading-5 font-semibold tds-color-text-primary">Thông tin cá nhân</p>
      <button
        tds-button
        color="primary"
        (click)="openEditProfile(userInfo)"
      >
        <span class="flex items-center">
          <i class="tdsi-edit-fill text-lg leading-none mr-2"></i>
          Chỉnh sửa
        </span>
      </button>
    </section>

    <tds-spin [spinning]="!userInfo" class="w-full h-96">
      @if (userInfo) {
        <div class="flex flex-col gap-6 p-4 w-full">
          <div class="flex flex-col justify-center items-center gap-4 w-full">
            <div class="relative w-[120px] h-[132px]">
              <tds-avatar
                [size]="112"
                [shape]="'circle'"
                [tdsSrc]="'https://randomuser.me/api/portraits/women/68.jpg'"
                class="border-4 tds-color-border-white"
              ></tds-avatar>
              <tds-tag
                [size]="'lg'"
                status="success"
                class="absolute right-1.5 top-[104px]"
              >
                Chủ cửa hàng
              </tds-tag>
            </div>
            <div class="text-heading-4 font-semibold">{{ userInfo.name }}</div>
          </div>
          
          <div class="flex flex-col w-full">
            <div class="flex flex-col p-3 gap-1 border-b tds-color-border-secondary">
              <span class="text-body-2 tds-color-text-secondary flex items-center gap-2">
                <i class="tdsi-call-fill text-xl leading-none"></i>Số điện thoại
              </span>
              <div class="text-body-1 font-semibold">{{ userInfo.phoneNumber }}</div>
            </div>

            <div class="flex flex-col p-3 gap-1 border-b tds-color-border-secondary">
              <span class="text-body-2 tds-color-text-secondary flex items-center gap-2">
                <i class="tdsi-email-fill text-xl leading-none tds-color-icon-secondary"></i>Email
              </span>
              <div class="text-body-1 font-semibold">{{ userInfo.email }}</div>
            </div>

            <div class="flex flex-col p-3 gap-1 border-b tds-color-border-secondary">
              <span class="text-body-2 tds-color-text-secondary flex items-center gap-2">
                <i class="tdsi-birthday-fill text-xl leading-none tds-color-icon-secondary"></i>Ngày sinh
              </span>
              <div class="text-body-1 font-semibold">{{(userInfo.birthday | date: 'dd/MM/yyyy') || '--'}}</div>
            </div>

            <div class="flex flex-col p-3 gap-1 border-b tds-color-border-secondary">
              <span class="text-body-2 tds-color-text-secondary flex items-center gap-2">
                <i class="tdsi-location-fill text-xl leading-none tds-color-icon-secondary"></i>Địa chỉ
              </span>
              <span class="text-body-1 font-semibold">{{userInfo | address}}</span>
            </div>

            <div class="flex flex-col p-3 gap-1 border-b tds-color-border-secondary">
              <span class="text-body-2 tds-color-text-secondary flex items-center gap-2">
                <i class="tdsi-lock-fill text-xl leading-none tds-color-icon-secondary"></i>Phân quyền
              </span>
              @if (userInfo.roleList.includes('Admin')) {
                <div class="text-body-1 font-semibold tds-color-text-info">Toàn quyền truy cập</div>
              } @else {
                <div class="text-body-1 font-semibold tds-color-text-secondary">
                  @for (role of userInfo.roleList; track role) {
                    {{role}}
                  }
                </div>
              }
            </div>
          </div>
        </div>
      }
    </tds-spin>
  </div>
  <div class="w-2/3">
    <app-store-list
      [userInfo]="userInfo"
    ></app-store-list>
  </div>
</div>