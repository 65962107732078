/** Coerces a data-bound value (typically a string) to a boolean. */
function coerceBooleanProperty(value) {
  return value != null && `${value}` !== 'false';
}

/**
 * Generated bundle index. Do not edit.
 */

export { coerceBooleanProperty };
