<tds-spin [spinning]="isLoading">
  <form [formGroup]="editForm"
    class="w-full"
    (ngSubmit)="onSubmit()"
  >
    <section class="flex justify-center gap-4 py-5 tds-color-background-neutral-minimalist">
      <tds-badge [count]="editAvatarTpl">
        <tds-avatar
          [size]="64"
          [tdsSrc]="userInfo.avatar!"
        ></tds-avatar>
      </tds-badge>

      <ng-template #editAvatarTpl>
        <div class="absolute top-10 -right-2 rounded-full bg-white p-0.5 flex items-center justify-center cursor-pointer"
          (click)="onEditAvatar()"
        >
          <div class="bg-primary-1 dark:bg-d-primary-1 flex items-center justify-center p-1 rounded-full">
            <span class="tdsi-edit-fill text-white text-base leading-4 flex items-center justify-center"></span>
          </div>
        </div>
      </ng-template>
      <div class="flex flex-col gap-2 w-80">
        <tds-label [tdsRequired]="true">Họ và tên</tds-label>
        <tds-form-field>
          <input tdsInput
            formControlName="name"
            placeholder="Nhập họ và tên"
            [maxlength]="100"
          >
          <tds-error>Vui lòng nhập họ và tên</tds-error>
        </tds-form-field>
      </div>
    </section>

    <section class="flex flex-col gap-3 p-4 w-full">
      <div class="flex items-center gap-2">
        <tds-label class="w-1/3">Vai trò</tds-label>
        @if (userInfo.roleList) {
          <div class="flex flex-1 flex-wrap gap-2">
            @for (role of userInfo.roleList; track role) {
            <tds-tag [size]="'xl'" [type]="'outline'" [status]="role !== 'Admin' ? 'info' : 'success'">
              {{role}}
            </tds-tag>
            }
          </div>
        }
      </div>

      <div class="flex items-start gap-2">
        <tds-label
          class="w-1/3 mt-1"
        >
          Số điện thoại
        </tds-label>
        <tds-form-field class="w-2/3">
          <input tdsInput
            [disabled]='true'
            [value]="userInfo.phoneNumber"
          >
        </tds-form-field>
      </div>
      
      <div class="flex items-start gap-2">
        <tds-label
          class="w-1/3 mt-1"
        >
          Email
        </tds-label>
        <tds-form-field class="w-2/3">
          <input tdsInput 
            formControlName="email"
            placeholder="Nhập email"
          > 
          @if (editForm.get('email')?.errors?.['inValidEmail']) {
            <tds-error>Email không đúng định dạng</tds-error>
          }
        </tds-form-field>
      </div>

      <div class="flex items-start gap-2">
        <tds-label
          class="w-1/3 mt-1"
        >
          Ngày sinh
        </tds-label>
        <tds-form-field class="w-2/3">
          <tds-date-picker
            [disabledDate]="disabledDate"
            [inputReadOnly]="true"
            placeholder="Chọn ngày sinh"
            formControlName="birthday"
          >
          </tds-date-picker>
        </tds-form-field>
      </div>

      <div class="flex items-start gap-2">
        <tds-label
          class="w-1/3 mt-1"
        >
          Địa chỉ
        </tds-label>
        <div class="flex flex-col gap-2 w-2/3">
          <tds-form-field class="w-full">
              <input tdsInput 
                formControlName="street"
                placeholder="Nhập số nhà, tên đường"
                [maxlength]="1000"
              />
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="provinces"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="city"
                placeholder="Tỉnh/thành phố"
                (selectChange)="onChangeProvince($event)"
              >
              </tds-select>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="districts"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="district"
                placeholder="Quận/huyện"
                (selectChange)="onChangeDistrict($event)"
              >
              </tds-select>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="wards"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="ward"
                placeholder="Phường/xã"
                (selectChange)="onChangeWard($event)"
              >
              </tds-select>
            </tds-form-field>
        </div>
      </div>

      <div *tdsModalFooter
          class="flex items-center justify-end gap-2 p-4"
        >
          <button tds-button
            color="secondary"
            class="min-w-[100px]"
            type="button"
            (tdsBtnClick)="onCancel()"
          >
            Hủy
          </button>
          <button tds-button
            color="primary"
            class="min-w-[100px]"
            type="submit"
            (tdsBtnClick)="onSubmit()"
          >
            Lưu
          </button>
        </div>
    </section>
  </form>
</tds-spin>