<ng-container>
  <section class="flex flex-col gap-3">
    <div class="flex items-center gap-4 w-full">
      <tds-avatar
        class="border"
        [size]="80"
        [tdsSrc]="employee.avatar"
      ></tds-avatar>
      <div class="flex-1 w-full">
        <div class="flex flex-col gap-2 w-full">
          <div class="flex"
            [ngClass]="{
              'flex-col gap-2 items-start' : employee.userStatus === status.Pending,
              'flex-row gap-4 items-center' : employee.userStatus !== status.Pending,
            }"
          >
            <div class="tds-heading-5 tds-color-text-primary font-semibold">{{employee.name}}</div>
            <app-employee-status
              [status]="employee.userStatus"
              [statusName]="employee.userStatusName!"
              [size]="'lg'"
            ></app-employee-status>
          </div>
          @if (!canNotEdit()) {
            <app-employee-actions
              [employee]="employee"
              [isModalAction]="true"
              [isEditOnly]="true"
              (onCompletedModalAction)="close()"
            ></app-employee-actions>
          }
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-3 w-full">
      <div class="flex items-center gap-4 w-full tds-body-2">
        <div class="w-[200px]">Số điện thoại</div>
        <div class="flex-1 font-semibold">{{employee.phoneNumber || '---'}}</div>  
      </div>
      <div class="flex items-center gap-4 w-full tds-body-2">
        <div class="w-[200px]">Email</div>
        <div class="flex-1 font-semibold">{{employee.email || '---'}}</div>  
      </div>
      <div class="flex items-center gap-4 w-full tds-body-2">
        <div class="w-[200px]">Ngày sinh</div>
        <div class="flex-1 font-semibold">{{(employee.birthday | date: 'dd/MM/yyyy') || '---'}}</div>  
      </div>
      <div class="flex items-center gap-4 w-full tds-body-2">
        <div class="w-[200px]">Địa chỉ</div>
        <div class="flex-1 font-semibold">{{employee | address}}</div>  
      </div>
      @if (!isOwner()) {
        <div class="flex items-center gap-4 w-full tds-body-2">
          <div class="w-[200px]">Vai trò</div>
          <tds-tag
            type="outline"
            size="md"
            status="default"
            class="tds-color-text-tertiary"
          >
            {{ employee.userInStoreBranches?.[0]?.roleName }}
          </tds-tag>
        </div>
      }
    </div>

    @if (isOwner()) {
      <div class="flex flex-col gap-3 w-full">
        <div class="tds-color-text-primary tds-body-2 font-semibold">Danh sách cửa hàng làm việc</div>
        <div class="w-full max-h-[272px] flex flex-col border rounded-md overflow-auto custom-scroll">
          @for (branch of employee.userInStoreBranches; track $index; let last = $last) {
            <div class="p-3 w-full">
              <div class="w-full">
                <div class="flex items-center p-3"
                >
                  <div class="w-1/2 tds-body-2 font-semibold tds-color-text-primary">{{branch.name}}</div>
                  <div class="w-1/2">
                    <span class="mr-2">Vai trò:</span>
                    <tds-tag
                      type="outline"
                      size="md"
                      status="default"
                      class="tds-color-text-tertiary"
                    >
                      {{ branch.roleName }}
                    </tds-tag>
                  </div>
                </div>
                @if(!last) {
                  <div class="pt-3 border-b"></div>
                }
              </div>
            </div>
          }

        </div>
      </div>
    }
  </section>

  <section *tdsModalFooter
    class="flex items-center justify-end gap-2 p-4"
  >
    <button
      tds-button-outline
      size="md"
      color="secondary"
      class="min-w-[100px]"
      (tdsBtnClick)="close()"
    >
      Đóng
    </button>

    @if (!shouldHiddenActions(employee)) {
      <app-employee-actions
        [status]="employee.userStatus"
        [employee]="employee"
        [isModalAction]="true"
        (onCompletedModalAction)="close()"
      ></app-employee-actions>
    }
  </section>
</ng-container>
