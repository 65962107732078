import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]',
  standalone: true
})
export class NumbersOnlyDirective {
  constructor(private readonly el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent) {
    const initialValue = this.el.nativeElement.value;
    const newValue = initialValue.replace(/\D/g, ''); // Only keep digits
    if (initialValue !== newValue) {
      this.el.nativeElement.value = newValue;
      event.stopPropagation();
    }
  }
  
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const pattern = /\d/;

    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput = event.clipboardData?.getData('text/plain').replace(/\D/g, '');
    const input = this.el.nativeElement as HTMLInputElement;
    const start = input.selectionStart ?? 0;
    const end = input.selectionEnd ?? 0;
    input.setRangeText(pastedInput ?? '', start, end, 'end');
  }
}
