<div class="w-full h-full flex flex-col gap-6 px-[70px] py-16" 
    (keydown.enter)="onContinue()"
>
    <section class="flex flex-col gap-4 justify-center items-center">
        <h3 class="tds-heading-3 tds-color-text-primary font-semibold">Nhập mã</h3>
        <div class="tds-body-1 tds-color-text-tertiary text-center px-1">
          Vui lòng nhập mã OTP được gửi đến <span class="font-semibold tds-color-text-primary">{{inputData.type === 'ZaLo' ? 'Zalo' : inputData.type}}</span> với số điện thoại đã đăng ký <span class="font-semibold tds-color-text-primary">{{inputData.phone}}</span>
        </div>
    </section>
    <section class="flex flex-col gap-4 justify-center items-center">
        @if(otpCountdown) {
          <div class="tds-body-2 tds-color-text-tertiary">
            Mã sẽ hết hiệu lực trong <span class="tds-color-text-brand-primary font-semibold">{{formattedOtpCountdown}}</span> giây
          </div>
        } @else {
          <div class="tds-body-2 tds-color-text-error">Mã OTP đã hết hiệu lực</div>
        }
        <tds-otp-input 
          [tdsAllowNumbersOnly]="true"
          [tdsDisableAutoFocus]="false"
          [tdsOtpLength]="6"
          [tdsError]="isInvalidOtp || isOtpEmpty"
          #inputOtp 
          (tdsInputChange)="onOtpChange($event)"
        ></tds-otp-input>
        @if(isOtpEmpty) {
          <div class="tds-body-2 text-center tds-color-text-error flex flex-col justify-center">
            <span>Mã OTP không được để trống.</span>
          </div>
        } @else if(isInvalidOtp) {
          <div class="tds-body-2 text-center tds-color-text-error flex flex-col justify-center">
            <span>Mã OTP không chính xác hoặc hết hiệu lực.</span>
            <span>Vui lòng nhập lại hoặc bấm gửi lại mã.</span>
          </div>
        }
        <div class="tds-body-2 tds-color-text-tertiary">
          <span class="mr-0.5">{{otpCountdown ? 'Không nhận được mã?' : 'Vui lòng bấm gửi lại mã.' }}</span>

          @if(isResendEnabled) {
            <span class="font-semibold tds-color-text-info cursor-pointer"
              (click)="onResendOtp()"
            >
              Gửi lại 
            </span>
          } @else {
            <span class="font-semibold tds-color-text-primary">Gửi lại ({{formattedResendCountdown}})</span>
          }
        </div>
    </section>
    <section class="flex flex-col gap-2">
        <button tds-button color="primary"
          size="xl"
          type="button"
          (tdsBtnClick)="onContinue()"
        >
            Tiếp tục
        </button>
        <button tds-button-outline color="secondary"
          size="xl"
          type="button"
          (tdsBtnClick)="onBack()"
        >
            Trở về
        </button>
    </section>
</div>

<ng-template #limitOtp>
  <div class="w-full flex flex-col justify-center text-center tds-body-2">
    <div>
      Bạn đã hết 3 lượt gửi mã.
    </div>
    <div>
      Vui lòng thử lại sau <span class="font-semibold ml-0.5">{{sixHoursFromLatestTimeSend | date: 'HH:mm'}}</span>
    </div>
  </div>
</ng-template>