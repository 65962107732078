import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChannelType, ECommerceIcon, ECommerceName } from 'app/shared/enums/ecommerce.enum';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSPageHeaderModule } from 'tds-ui/page-header';
import { TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSTableModule, TDSTableQueryParams } from 'tds-ui/table';
import { TDSTabsFilterOption, TDSTabsModule } from 'tds-ui/tabs';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSInputModule } from 'tds-ui/tds-input';

import { environment } from '@environments/environment';
import { AuthService } from 'app/core/services/auth.service';
import { Paging } from 'app/shared/models/paging.model';
import { finalize } from 'rxjs';
import { TDSMapperPipeModule } from 'tds-ui/cdk/pipes/mapper';
import { TDSFilterStatusModule } from "tds-ui/filter-status";
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalService } from 'tds-ui/modal';
import { TDSSwitchModule } from 'tds-ui/switch';
import { ShopInfo, ShopParams } from '../../models/shop-info.model';
import { ShopService } from '../../services/shop.service';
import { AddShopComponent } from '../add-shop/add-shop.component';
import { CANCELED_BY_USER, DEAUTHORIZED_MESSAGE_1, DEAUTHORIZED_MESSAGE_2, DEAUTHORIZED_MESSAGE_LAZADA } from '../../constants/message-error-shop.constants';
import { ShopDetailComponent } from '../shop-detail/shop-detail.component';


@Component({
  selector: 'app-list-of-shops',
  standalone: true,
  imports: [
    TDSTableModule,
    CommonModule,
    TDSPageHeaderModule,
    TDSTabsModule,
    TDSTagModule,
    TDSButtonModule,
    TDSFormFieldModule,
    TDSInputModule,
    FormsModule,
    TDSFilterStatusModule,
    TDSSwitchModule,
    TDSMapperPipeModule,
  ],
  templateUrl: './list-of-shops.component.html',
})
export class ListOfShopsComponent implements OnInit {
  lstChannel: TDSTabsFilterOption[] = [
    {
      icon: ECommerceIcon.All,
      label: ECommerceName.All,
      value: ChannelType.All,
    },
    {
      icon: ECommerceIcon.TiktokShop,
      label: ECommerceName.TiktokShop,
      value: ChannelType.TiktokShop
    },
    {
      icon: ECommerceIcon.Shopee,
      label: ECommerceName.Shopee,
      value: ChannelType.Shopee
    },
    {
      icon: ECommerceIcon.Lazada,
      label: ECommerceName.Lazada,
      value: ChannelType.Lazada
    },
  ];

  selectedChannel: number = ChannelType.All;
  searchText!: string;
  statusItems: TDSSafeAny[] = [
    { status: 0, text: 'Tất cả', count: 0 },
    { status: 1, text: 'Đang kết nối', count: 1 },
    { status: 2, text: 'Ngắt kết nối', count: 2 },
  ];
  selectedStatus: number = 0;
  isLoading: boolean = false;
  paging: Paging = {
    size: 20,
    index: 1,
    total: 0,
  };

  listOfShops: ShopInfo[] = [];
  branchId: string | null | undefined;
  indexLoadingSwitch: number = -1;
  DEAUTHORIZED_MESSAGE_1 = DEAUTHORIZED_MESSAGE_1;
  DEAUTHORIZED_MESSAGE_2 = DEAUTHORIZED_MESSAGE_2;
  DEAUTHORIZED_MESSAGE_LAZADA = DEAUTHORIZED_MESSAGE_LAZADA;
  CANCELED_BY_USER = CANCELED_BY_USER
  messageEventListener: ((event: MessageEvent) => void) | undefined;
  urlDomain = environment.apiUrl;
  channelEnum = ChannelType;
  isFirstLoad: boolean = true;
  constructor(private modal: TDSModalService,
    private authService: AuthService,
    private message: TDSMessageService,
    private shopService: ShopService
  ) { }

  ngOnInit(): void {
    this.getBranchId();
  }

  getBranchId() {
    this.branchId = this.authService.getCurrentBranchId();
  }

  onChannelSelected(channel: TDSTabsFilterOption) {
    this.selectedChannel = channel.value;
    this.selectedStatus = 0;
    this.paging.index = 1;
    this.loadShops();
  }

  addChannel() {
    const modal = this.modal.create({
      title: 'Kết nối gian hàng',
      content: AddShopComponent,
      size: 'xl',
      componentParams: {
        branchId: this.branchId
      },
    });
   modal.afterClose.subscribe(((res: undefined | boolean) => {
      if (typeof res == 'boolean' && res) this.loadShops();
   }));
  }

  onSearch(event: TDSSafeAny) {
    this.searchText = event.value;
    this.paging.index = 1;
    this.loadShops();
  }

  onStatusSelected(event: TDSSafeAny) {
    this.selectedStatus = event;
    this.loadShops();
  }

  loadShops() {
    this.isLoading = true;
    const model = this.prepareParam();
    this.shopService.getShops$(
      model.storeBranchId,
      (model.skip - 1) * model.take,
      model.take,
      model.name,
      model.eChannel,
      model.isActive)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe({
        next: (res) => {
          this.listOfShops = res.items;
          this.paging.total = res.totalCount;
          if(this.paging.total == 0 && this.isFirstLoad){
            this.isFirstLoad = false;
            this.addChannel();
          } 
        }
      })
  }

  prepareParam(): ShopParams {
    const model: ShopParams = {
      storeBranchId: this.branchId,
      skip: this.paging.index,
      take: this.paging.size,
      name: this.searchText,
      eChannel: this.selectedChannel,
      isActive: this.selectedStatus === 1 ? true : this.selectedStatus === 2 ? false : null
    }
    return model;
  }

  onQueryParamsChange(event: TDSTableQueryParams) {
    this.paging.size = event.pageSize;
    this.paging.index = event.pageIndex;
    this.loadShops();
  }

  onClickLinkShop(item: ShopInfo, index: number) {
    this.indexLoadingSwitch = index;
    if (item.is_active) {
      let channelName = '';
      switch (item.e_Channel) {
        case ChannelType.TiktokShop:
          channelName = 'TiktokShop'
          break;
        case ChannelType.Shopee:
          channelName = 'Shopee'
          break;
        case ChannelType.Lazada:
          channelName = 'Lazada'
          break;
      }
      this.modal.warning({
        title: 'Ngắt kết nối gian hàng',
        content: `<div>Khi ngắt kết nối gian hàng ${channelName}, bạn sẽ không quản lý được đơn hàng, tồn kho khi phát sinh đơn hàng từ ${channelName
          }.</div><div class="font-semibold">Bạn có chắc chắn muốn ngắt kết nối với gian hàng ?</div>`,
        onOk: () => this.updateLinkedShop(item),
        onCancel: () => {
          this.resetIndexLoadingSwitch();
          item.is_active = true;
        },
        okText: 'Xác nhận',
        cancelText: 'Hủy bỏ',
      });
    } else {
      this.modal.info({
        title: 'Kết nối lại gian hàng',
        content: 'Bạn có chắc chắn muốn nối lại gian hàng?',
        onOk: () => this.updateLinkedShop(item),
        onCancel: () => {
          this.resetIndexLoadingSwitch();
          item.is_active = false;
        },
        okText: 'Xác nhận',
        cancelText: 'Hủy bỏ',
      });
    }
  }

  updateLinkedShop(item: ShopInfo) {
    if (!item.require_reauthorize) {
      this.callApiUpdateLinkedShop(item);
    } else {
      this.getAuthenUrl(item.e_Channel);
    }
  }

  callApiUpdateLinkedShop(item: ShopInfo) {
    this.shopService.updateLinkedShop(item.channel_token)
      .pipe(finalize(() => {
        this.resetIndexLoadingSwitch();
        this.loadShops();
        }
      ))
      .subscribe({
        next: () => {
          this.message.success('Cập nhật thành công');
        },
        error: (err: TDSSafeAny) => {
          this.message.error(err?.error?.message);
        }
      });
  }

  getAuthenUrl(channel: number) {
    let w = 1200, h = 800;
    let left = Number((screen.width / 2) - (w / 2));
    let tops = Number((screen.height / 2) - (h / 2));
    let windowPopup = window.open(`${this.urlDomain}/ecommerce/connect?channel=${channel}&app=omni&clientId=${this.branchId}`, ``, 'width=' + w + ', height=' + h + ', top=' + tops + ', left=' + left);
    // Xóa the new event listener nếu nó có tồn tại
    if (this.messageEventListener) {
      window.removeEventListener("message", this.messageEventListener);
    }

    // Kiểm tra sự kiện đóng của popup
    let popupTick = setInterval(() => {
      if (windowPopup?.closed) {
        clearInterval(popupTick);
        this.isLoading = false;
      }
    }, 1000);

    // Định nghĩa the new event listener
    this.messageEventListener = (event: MessageEvent) => {
      let body = event.data;
      if (body) {
        //Xử lý check xem gian hàng đã kết nối với cửa hàng khác chưa
        const parsedData = JSON.parse(body.data);
        const isSuccess = parsedData.IsSuccess;
        if (!isSuccess) this.message.error(`${parsedData.Message}`);
      }
    };
    // Thêm the new event listener
    window.addEventListener("message", this.messageEventListener);
  }

  resetIndexLoadingSwitch() {
    this.indexLoadingSwitch = -1;
  }

  readonly getNameStatus = (data: ShopInfo) => {
    return (data.is_active) ? 'Ngắt kết nối' : 'Kết nối gian hàng';
  }

  readonly getIconStatus = (data: ShopInfo) => {
    let iconClass = '';
    switch (data.e_Channel) {
      case ChannelType.TiktokShop:
        iconClass = 'tdsi-tiktok-fill';
        break;
      case ChannelType.Shopee:
        iconClass = 'tdsi-shopee-fill text-[#FA6C1D]';
        break;
      case ChannelType.Lazada:
        iconClass = 'tdsi-lazada-fill text-[#BC7FEB]';
        break;
    }
    return iconClass;
  }

  onDetail(shop: ShopInfo) {
    const modal = this.modal.create({
      closable: false,
      size: 'lg',
      footer: null,
      content: ShopDetailComponent,
      componentParams: {
        shopInfo: shop,
        eChannel: shop.e_Channel
      }
    });

    modal.afterClose.subscribe(((res: undefined | boolean) => {
      if (typeof res == 'boolean' && res) this.loadShops();
    }))
  }
}

