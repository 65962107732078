import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSBadgeModule } from 'tds-ui/badges';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSInputNumberModule } from 'tds-ui/input-number';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { TDSSelectModule } from 'tds-ui/select';
import { TDSInputModule } from 'tds-ui/tds-input';
import { StoreService } from '../../services/store.service';
import { TDSDestroyService } from 'tds-ui/core/services';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { AddressService } from 'app/core/services/address.service';
import { TDSMessageService } from 'tds-ui/message';
import { StoreCategory } from '../../models/store-category.model';
import { DistrictResponse, ProvinceResponse, WardResponse } from 'app/core/models/address.model';
import { finalize, takeUntil, tap } from 'rxjs';
import { Store } from '../../models/store.model';
import { StoreCategoryService } from '../../services/store-category.service';
import { UpdateStoreReq } from '../../models/update-store.model';
import { NumbersOnlyDirective } from 'app/shared/directives/numbers-only.directive';
import { phoneValidatorTenDigits } from 'app/core/utilities/phone-validator.util';
import { emailValidator } from 'app/core/utilities/email-validator.util';

@Component({
  selector: 'app-edit-store',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSAvatarModule,
    TDSFormFieldModule,
    TDSButtonModule,
    TDSInputModule,
    TDSInputNumberModule,
    TDSSelectModule,
    TDSModalModule,
    TDSBadgeModule,
    TDSSpinnerModule,
    NumbersOnlyDirective
  ],
  providers: [
    StoreService,
    TDSDestroyService,
    {
      provide: ErrorStateMatcher, useClass: WiECommerceDirtyErrorStateMatcher
    },
    AddressService,
    TDSMessageService
  ],
  templateUrl: './edit-store.component.html',
  styleUrl: './edit-store.component.scss'
})
export class EditStoreComponent implements OnInit {
  @Input() store!: Store;
  storeForm!: FormGroup;
  isLoading = false;

  storeCategories: StoreCategory[] = [];
  provinces: ProvinceResponse[] = [];
  districts: DistrictResponse[] = [];
  wards: WardResponse[] = [];

  curProvinceCode!: string;
  curDistCode!: string;

  phoneIsTouched = false;

  constructor(
    private readonly storeService: StoreService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly addressService: AddressService,
    private readonly msgService: TDSMessageService,
    private readonly modalRef: TDSModalRef<EditStoreComponent>,
    private readonly storeCategoryService: StoreCategoryService
  ) {

  }

  ngOnInit(): void {
    this.loadStoreCategories();
    this.loadProvinces();
    this.initForm();
    if (this.store) {
      this.updateForm(this.store);
    }
  }

  initForm(): void {
    this.storeForm = new FormGroup({
      avatar: new FormControl(null),
      ownerName: new FormControl('', [Validators.required]),
      storeCategories: new FormControl(null),
      storeName: new FormControl('', [Validators.required]),
      email: new FormControl(null, emailValidator),
      phoneNumber: new FormControl(null, phoneValidatorTenDigits()),
      city: new FormControl(null),
      district: new FormControl(null),
      ward: new FormControl(null),
      street: new FormControl(null, Validators.maxLength(1000)),
    })
  };

  updateForm(store: Store): void {
    this.storeForm.patchValue({
      avatar: store.avatar,
      storeName: store.name,
      ownerName: store.ownerName,
      email: store.email,
      phoneNumber: store.phoneNumber,
      city: store.city?.length > 0 ? store.city : null,
      district: store.district?.length > 0 ? store.district : null,
      ward: store.ward?.length > 0 ? store.ward : null,
      street: store.street?.length > 0 ? store.street : null
    });

    if (store.storeCategoryList) {
      const lstId = store.storeCategoryList.map(item => item.id);
      this.storeForm.get('storeCategories')?.reset();
      this.storeForm.get('storeCategories')?.setValue(lstId);
    }
  }

  loadProvinces(): void {
    this.addressService.getProvinces$()
      .pipe(
        tap(res => {
          this.curProvinceCode = res.Data.find(city => city.Name === this.store.city)?.Code as string;
          if (this.curProvinceCode) {
            this.loadDistricts(this.curProvinceCode);
          }
        }),
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.provinces = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách tỉnh/thành phố thất bại');
        }
      })
  }

  loadDistricts(provinceCode: string): void {
    this.addressService.getDistricts$(provinceCode)
      .pipe(
        tap(res => {
          this.curDistCode = res.Data.find(district => district.Name === this.store.district)?.Code as string;
          if (this.curDistCode) {
            this.loadWards(this.curDistCode);
          }        
        }),
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.districts = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách quận/huyện thất bại');
        }
      })
  }

  loadWards(districtCode: string): void {
    this.addressService.getWards$(districtCode)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.wards = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách phường/xã thất bại');
        }
      })
  }

  loadStoreCategories(): void {
    this.isLoading = true;

    this.storeCategoryService.getStoreCategories$()
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res) => {
          this.storeCategories = res;
        },
        error: () => {
          this.msgService.error('Lấy danh sách danh mục thất bại');
        }
      })
  }

  onEditAvatar(): void {

  }

  onChangeStoreCategories(event: StoreCategory[]): void {
    if (!event) return;

    const lstId = event.map(item => item.id);
    this.storeForm.get('storeCategories')?.reset();
    this.storeForm.get('storeCategories')?.setValue(lstId);
  }

  onChangeProvince(event: ProvinceResponse): void {
    if (!event) return;

    this.storeForm.get('district')?.reset();
    this.storeForm.get('ward')?.reset();

    this.storeForm.get('city')?.setValue(event.Name);
    this.loadDistricts(event.Code);
  }
    
  onChangeDistrict(event: DistrictResponse): void {
    if (!event) return;

    this.storeForm.get('ward')?.reset();

    this.storeForm.get('district')?.setValue(event.Name);
    this.loadWards(event.Code);
  }

  onChangeWard(event: WardResponse): void {
    if (!event) return;

    this.storeForm.get('ward')?.setValue(event.Name);
  }

  private prepareModel(): UpdateStoreReq {
    const model: UpdateStoreReq = {
      Avatar: this.storeForm.value.avatar,
      Name: this.storeForm.value.storeName,
      OwnerName: this.storeForm.value.ownerName,
      StoreCategoryIdList: this.storeForm.value.storeCategories,
      Email: this.storeForm.value.email,
      PhoneNumber: this.storeForm.value.phoneNumber,
      Street: this.storeForm.value.street,
      Ward: this.storeForm.value.ward,
      District: this.storeForm.value.district,
      City: this.storeForm.value.city,
      TimeZone: this.store.timeZone
    }

    return model;
  }

  onSubmit(): void {
    if (this.storeForm.invalid) return;

    this.isLoading = true;
    
    const model = this.prepareModel();
    this.storeService.updateStore$(this.store.id, model)
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: Store) => {
          this.isLoading = false;
          this.msgService.success('Cập nhật thông tin thành công');
          this.modalRef.close(res);
        },
        error: () => {
          this.msgService.error('Cập nhật thông tin thất bại');
        }
      })
  }

  onCancel(): void {
    this.modalRef.close();
  }

  onPhoneBlur(): void {
    this.phoneIsTouched = true;
  }

  onPhoneFocus(): void {
    this.phoneIsTouched = false;
  }
}
