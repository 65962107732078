import { tdsIsHTMLElement } from 'tds-ui/cdk/utils/dom';
import { svgNodeFilter } from 'tds-ui/cdk/contants';

/**
 * Returns current active element, including shadow dom
 *
 * @return element or null
 */
function tdsGetNativeFocused(documentRef) {
  if (!documentRef.activeElement?.shadowRoot) {
    return documentRef.activeElement;
  }
  let element = documentRef.activeElement.shadowRoot.activeElement;
  while (element?.shadowRoot) {
    element = element.shadowRoot.activeElement;
  }
  return element;
}

/**
 * Finds and blurs current active element, including shadow DOM
 */
function tdsBlurNativeFocused(documentRef) {
  const activeElement = tdsGetNativeFocused(documentRef);
  if (tdsIsHTMLElement(activeElement)) {
    activeElement.blur();
  }
}

/**
 * Checks for signs that element can be focused with keyboard. tabIndex above 0 is ignored to
 * only target natural focus order. Not checking the possibility of an element to
 * be focused, for example element can have display: none applied to it or any other
 * circumstances could prevent actual focus.
 */
function tdsIsNativeKeyboardFocusable(element) {
  if (element.hasAttribute(`disabled`) || element.getAttribute(`tabIndex`) === `-1`) {
    return false;
  }
  if (tdsIsHTMLElement(element) && element.isContentEditable || element.getAttribute(`tabIndex`) === `0`) {
    return true;
  }
  switch (element.tagName) {
    case `BUTTON`:
    case `SELECT`:
    case `TEXTAREA`:
      return true;
    case `VIDEO`:
    case `AUDIO`:
      return element.hasAttribute(`controls`);
    case `INPUT`:
      return element.getAttribute(`type`) !== `hidden`;
    case `A`:
    case `LINK`:
      return element.hasAttribute(`href`);
    default:
      return false;
  }
}
function tdsIsNativeMouseFocusable(element) {
  return !element.hasAttribute(`disabled`) && (element.getAttribute(`tabIndex`) === `-1` || tdsIsNativeKeyboardFocusable(element));
}

/**
 * @description:
 * Finds the closest element that can be focused with a keyboard or mouse in theory
 */
function tdsGetClosestFocusable({
  initial,
  root,
  previous = false,
  keyboard = true
}) {
  if (!root.ownerDocument) {
    return null;
  }
  const check = keyboard ? tdsIsNativeKeyboardFocusable : tdsIsNativeMouseFocusable;
  const treeWalker = root.ownerDocument.createTreeWalker(root, NodeFilter.SHOW_ELEMENT, svgNodeFilter);
  treeWalker.currentNode = initial;
  while (previous ? treeWalker.previousNode() : treeWalker.nextNode()) {
    if (tdsIsHTMLElement(treeWalker.currentNode)) {
      initial = treeWalker.currentNode;
    }
    if (tdsIsHTMLElement(initial) && check(initial)) {
      return initial;
    }
  }
  return null;
}

/**
 * Checks if element is focused.
 *
 * Could return true even after blur since element remains focused if you switch away from a browser tab.
 *
 * @param node or null (as a common return value of DOM nodes walking)
 * @return true if focused
 */
function tdsIsNativeFocused(node) {
  return !!node?.ownerDocument && tdsGetNativeFocused(node.ownerDocument) === node;
}

/**
 * Checks if focused element is within given element.
 *
 * @param node
 * @return true if focused node is contained within element
 */
function tdsIsNativeFocusedIn(node) {
  // !node.contains - check for IE11
  if (!node.ownerDocument || !node.contains) {
    return false;
  }
  const nativeFocused = tdsGetNativeFocused(node.ownerDocument);
  return nativeFocused !== null && node.contains(nativeFocused);
}

/**
 * Utility method for moving focus in a list of elements
 *
 * @param currentIndex currently focused index
 * @param elements array of focusable elements
 * @param step a step to move focus by, typically -1 or 1
 */
function tdsMoveFocus(currentIndex, elements, step) {
  currentIndex += step;
  while (currentIndex >= 0 && currentIndex < elements.length) {
    elements[currentIndex].focus();
    if (tdsIsNativeFocused(elements[currentIndex])) {
      return;
    }
    currentIndex += step;
  }
}

/**
 * Focuses or blurs element with mouse action imitation (to spoof {@link TDSFocusVisibleService})
 *
 * @param element
 * @param focused desired focused state
 * @param preventScroll optionally prevent native browser scroll after focus
 */
function tdsSetNativeMouseFocused(element, focused = true, preventScroll = false) {
  if (!element.ownerDocument) {
    return;
  }
  if (typeof Event === `function`) {
    element.dispatchEvent(new Event(`mousedown`, {
      bubbles: true,
      cancelable: true
    }));
  } else {
    const event = element.ownerDocument.createEvent(`Event`);
    event.initEvent(`mousedown`, true, true);
    element.dispatchEvent(event);
  }
  if (focused) {
    element.focus({
      preventScroll
    });
  } else {
    element.blur();
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { tdsBlurNativeFocused, tdsGetClosestFocusable, tdsGetNativeFocused, tdsIsNativeFocused, tdsIsNativeFocusedIn, tdsIsNativeKeyboardFocusable, tdsIsNativeMouseFocusable, tdsMoveFocus, tdsSetNativeMouseFocused };
