import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { AuthService } from 'app/core/services/auth.service';
import { ChannelType } from 'app/shared/enums/ecommerce.enum';
import { finalize } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSMapperPipeModule } from 'tds-ui/cdk/pipes/mapper';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalRef, TDSModalService } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSTabsModule } from 'tds-ui/tabs';
import { CANCELED_BY_USER, DEAUTHORIZED_MESSAGE_1, DEAUTHORIZED_MESSAGE_2, DEAUTHORIZED_MESSAGE_LAZADA, OMNI_NOTIFICATION } from '../../constants/message-error-shop.constants';
import { ShopDetail, ShopInfo } from '../../models/shop-info.model';
import { ShopService } from '../../services/shop.service';

@Component({
    selector: 'app-shop-detail',
    standalone: true,
    templateUrl: './shop-detail.component.html',
    imports: [
        CommonModule,
        BrowserModule,
        TDSTabsModule,
        TDSSpinnerModule,
        TDSButtonModule,
        TDSMapperPipeModule
    ],
    providers: [
        TDSDestroyService
    ]
})
export class ShopDetailComponent implements OnInit {
    @Input() eChannel !: ChannelType
    @Input() shopInfo !: ShopInfo;

    shopDetail!: ShopDetail;
    isLoading: boolean = false;
    OMNI_NOTIFICATION = OMNI_NOTIFICATION;
    CANCELED_BY_USER = CANCELED_BY_USER;
    DEAUTHORIZED_MESSAGE_1 = DEAUTHORIZED_MESSAGE_1;
    DEAUTHORIZED_MESSAGE_2 = DEAUTHORIZED_MESSAGE_2;
    DEAUTHORIZED_MESSAGE_LAZADA = DEAUTHORIZED_MESSAGE_LAZADA;
    channelType = {
        tiktok: ChannelType.TiktokShop,
        shopee: ChannelType.Shopee,
        lazada: ChannelType.Lazada
    }
    messageEventListener: ((event: MessageEvent) => void) | undefined;
    urlDomain = environment.apiUrl;
    storeId: string | null | undefined;

    constructor(
        private shopService: ShopService,
        private authService: AuthService,
        private message: TDSMessageService,
        private modalRef: TDSModalRef,
        private modal: TDSModalService,
        
    ) {
    }

    ngOnInit(): void {
        this.shopDetail = this.shopInfo.detail;
        this.getStoreId();
    }

    getStoreId() {
        this.storeId = this.authService.getCurrentStoreId();
    }

    onClose() {
        this.modalRef.destroy(null);
    }

    onClickLinkShop(item: ShopInfo) {
        if (item.is_active) {
            let channelName = '';
            switch (item.e_Channel) {
                case ChannelType.TiktokShop:
                    channelName = 'TiktokShop'
                    break;
                case ChannelType.Shopee:
                    channelName = 'Shopee'
                    break;
                case ChannelType.Lazada:
                    channelName = 'Lazada'
                    break;
            }
            this.modal.warning({
                title: 'Ngắt kết nối gian hàng',
                content: `<div>Khi ngắt kết nối gian hàng ${channelName}, bạn sẽ không quản lý được đơn hàng, tồn kho khi phát sinh đơn hàng từ ${channelName
                    }.</div><div class="font-semibold">Bạn có chắc chắn muốn ngắt kết nối với gian hàng ?</div>`,
                onOk: () => this.updateLinkedShop(item),
                onCancel: () => {

                    item.is_active = true;
                },
                okText: 'Xác nhận',
                cancelText: 'Hủy bỏ',
            });
        } else {
            this.modal.info({
                title: 'Kết nối lại gian hàng',
                content: 'Bạn có chắc chắn muốn nối lại gian hàng?',
                onOk: () => this.updateLinkedShop(item),
                onCancel: () => {

                    item.is_active = false;
                },
                okText: 'Xác nhận',
                cancelText: 'Hủy bỏ',
            });
        }
    }

    updateLinkedShop(item: ShopInfo) {
        if (!item.require_reauthorize) {
            this.callApiUpdateLinkedShop(item);
        } else {
            this.getAuthenUrl(item.e_Channel);
        }
    }

    callApiUpdateLinkedShop(item: ShopInfo) {
        this.isLoading = true;
        this.shopService.updateLinkedShop(item.channel_token)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: () => {
                    this.message.success('Cập nhật thành công');
                    this.modalRef.destroy(true);
                },
                error: (err: TDSSafeAny) => {
                    this.message.error(err?.error?.message);
                }
            });
    }

    getAuthenUrl(channel: number) {
        let w = 1200, h = 800;
        let left = Number((screen.width / 2) - (w / 2));
        let tops = Number((screen.height / 2) - (h / 2));
        let windowPopup = window.open(`${this.urlDomain}/ecommerce/connect?channel=${channel}&app=omni&clientId=${this.storeId}`, ``, 'width=' + w + ', height=' + h + ', top=' + tops + ', left=' + left);
        // Xóa the new event listener nếu nó có tồn tại
        if (this.messageEventListener) {
            window.removeEventListener("message", this.messageEventListener);
        }

        // Kiểm tra sự kiện đóng của popup
        let popupTick = setInterval(() => {
            if (windowPopup?.closed) {
                clearInterval(popupTick);
                this.isLoading = false;
            }
        }, 1000);

        // Định nghĩa the new event listener
        this.messageEventListener = (event: MessageEvent) => {
            let body = event.data;
            if (body) {
                //Xử lý check xem gian hàng đã kết nối với cửa hàng khác chưa
                const parsedData = JSON.parse(body.data);
                const isSuccess = parsedData.IsSuccess;
                if (!isSuccess) this.message.error(`${parsedData.Message}`);
            }
        };
        // Thêm the new event listener
        window.addEventListener("message", this.messageEventListener);
    }

    readonly getIconStatus = (eChannel: number) => {
        let iconClass = '';
        switch (eChannel) {
          case ChannelType.TiktokShop:
            iconClass = 'tdsi-tiktok-fill';
            break;
          case ChannelType.Shopee:
            iconClass = 'tdsi-shopee-fill';
            break;
          case ChannelType.Lazada:
            iconClass = 'tdsi-lazada-fill';
            break;
        }
        return iconClass;
      }
}

