import { Component } from '@angular/core';

@Component({
  selector: 'app-access-deny',
  standalone: true,
  imports: [],
  templateUrl: './access-deny.component.html',
  styleUrl: './access-deny.component.scss'
})
export class AccessDenyComponent {

}
