import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DistrictResponse, ProvinceResponse, WardResponse } from 'app/core/models/address.model';
import { AddressService } from 'app/core/services/address.service';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { phoneValidatorTenDigits } from 'app/core/utilities/phone-validator.util';
import { finalize, takeUntil, tap } from 'rxjs';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSButtonMenuModule } from 'tds-ui/button-menu';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSInputNumberModule } from 'tds-ui/input-number';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { TDSSelectModule } from 'tds-ui/select';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSInputModule } from 'tds-ui/tds-input';
import { StoreBranch } from '../../models/store-branch.model';
import { UpdateBranchReq } from '../../models/update-branch.model';
import { BranchService } from '../../services/branch.service';
import { NumbersOnlyDirective } from 'app/shared/directives/numbers-only.directive';
import { emailValidator } from 'app/core/utilities/email-validator.util';

@Component({
  selector: 'app-edit-branch',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSAvatarModule,
    TDSButtonModule,
    TDSButtonMenuModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSSelectModule,
    TDSModalModule,
    TDSSpinnerModule,
    TDSInputNumberModule,
    NumbersOnlyDirective
  ],
  providers: [
    AddressService,
    {
      provide: ErrorStateMatcher, useClass: WiECommerceDirtyErrorStateMatcher
    },
    TDSMessageService,
    TDSDestroyService,
    BranchService
  ],
  templateUrl: './edit-branch.component.html',
  styleUrl: './edit-branch.component.scss'
})
export class EditBranchComponent implements OnInit {
  @Input() branch!: StoreBranch;
  branchForm!: FormGroup;

  isLoading = false;
  provinces: ProvinceResponse[] = [];
  districts: DistrictResponse[] = [];
  wards: WardResponse[] = [];

  curProvinceCode!: string;
  curDistCode!: string;

  phoneIsTouched = false;

  constructor(
    private readonly addressService: AddressService,
    private readonly msgService: TDSMessageService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly modalRef: TDSModalRef<EditBranchComponent>,
    private readonly branchService: BranchService
  ) { }

  ngOnInit(): void {
    this.loadProvinces();
    this.initForm();
    if (this.branch) {
      this.updateForm(this.branch);
    }
  }

  initForm(): void {
    this.branchForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      email: new FormControl(null, emailValidator),
      phoneNumber: new FormControl(null, Validators.compose([
        Validators.maxLength(10),
        phoneValidatorTenDigits()
      ])),
      subPhones: new FormArray([]),
      city: new FormControl(null, Validators.required),
      district: new FormControl(null, Validators.required),
      ward: new FormControl(null, Validators.required),
      street: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(1000)
      ])),
    })
  }

  get subPhones(): FormArray {
    return this.branchForm.get('subPhones') as FormArray;
  }

  newSubPhone(phone?: string): FormGroup {
    return new FormGroup({
      subPhone: new FormControl(phone, Validators.compose([
        Validators.maxLength(10),
        phoneValidatorTenDigits()
      ])),
    });
  }

  onAddSubPhone(phone?: string): void {
    this.subPhones.push(this.newSubPhone(phone));
  }

  onRemoveSubPhone(index: number): void {
    this.subPhones.removeAt(index);
  }

  updateForm(branch: StoreBranch): void {
    this.branchForm.patchValue(branch);
    if (branch.subPhoneNumber) {
      branch.subPhoneNumber.forEach((phone) => {
        this.onAddSubPhone(phone);
      });
    }
  }

  loadProvinces(): void {
    this.addressService.getProvinces$()
      .pipe(
        tap(res => {
          this.curProvinceCode = res.Data.find(city => city.Name === this.branch.city)?.Code as string;
          if (this.curProvinceCode) {
            this.loadDistricts(this.curProvinceCode);
          }
        }),
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.provinces = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách tỉnh/thành phố thất bại');
        }
      })
  }

  loadDistricts(provinceCode: string): void {
    this.addressService.getDistricts$(provinceCode)
      .pipe(
        tap(res => {
          this.curDistCode = res.Data.find(district => district.Name === this.branch.district)?.Code as string;
          if (this.curDistCode) {
            this.loadWards(this.curDistCode);
          }        
        }),
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.districts = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách quận/huyện thất bại');
        }
      })
  }

  loadWards(districtCode: string): void {
    this.addressService.getWards$(districtCode)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.wards = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách phường/xã thất bại');
        }
      })
  }

  private handleDeleteProvince(): void {
    this.branchForm.get('district')?.reset();
    this.districts = [];

    this.onChangeDistrict(null);
  }

  onChangeProvince(event: ProvinceResponse | null): void {
    if (!event) {
      this.handleDeleteProvince();
    } else {
      this.branchForm.get('district')?.reset();
      this.branchForm.get('ward')?.reset();

      this.branchForm.get('city')?.setValue(event.Name);
      this.loadDistricts(event.Code);
    }
  }
    
  private handleDeleteDistrict(): void {
    this.branchForm.get('ward')?.reset();
    this.wards = [];
  }

  onChangeDistrict(event: DistrictResponse | null): void {
    if (!event) {
      this.handleDeleteDistrict();
    } else {
      this.branchForm.get('ward')?.reset();

      this.branchForm.get('district')?.setValue(event.Name);
      this.loadWards(event.Code);
    }
  }

  onChangeWard(event: WardResponse): void {
    if (!event) return;

    this.branchForm.get('ward')?.setValue(event.Name);
  }

  onCancel(): void {
    this.modalRef.close();
  }

  private prepareModel(): UpdateBranchReq {
    const subPhones: string[] = [];
    this.branchForm.value.subPhones.forEach((phone: {subPhone: string}) => {
      if (phone.subPhone) subPhones.push(phone.subPhone);
    });

    const model: UpdateBranchReq = {
      Name: this.branchForm.value.name,
      Email: this.branchForm.value.email,
      PhoneNumber: this.branchForm.value.phoneNumber,
      SubPhoneNumber: subPhones.length > 0 ? subPhones : null,
      Street: this.branchForm.value.street,
      Ward: this.branchForm.value.ward,
      District: this.branchForm.value.district,
      City: this.branchForm.value.city,
      TimeZone: this.branch.timeZone,
    };

    return model;
  }

  private markFormAsDirty(): void {
    Object.keys(this.branchForm.controls).forEach(key => {
      this.branchForm.get(key)?.markAsDirty();

      if (key === 'subPhones') {
        this.subPhones.controls.forEach((ctrl) => {
          ctrl.get('subPhone')?.markAsDirty();
        });
      }
    });
  }

  onSubmit(): void {
    this.markFormAsDirty();

    if (this.branchForm.invalid) return;

    this.isLoading = true;
    
    const model = this.prepareModel();
    this.branchService.updateBranch$(this.branch.id, model)
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: StoreBranch) => {
          this.isLoading = false;
          this.msgService.success('Cập nhật chi nhánh thành công');
          this.modalRef.close(res);
        },
        error: () => {
          this.msgService.error('Cập nhật chi nhánh thất bại');
        }
      })
  }

  onPhoneBlur(): void {
    this.phoneIsTouched = true;
  }

  onPhoneFocus(): void {
    this.phoneIsTouched = false;
  }
}
