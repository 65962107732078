<div class="rounded-md bg-white flex flex-col w-full h-full">
    <div class="flex justify-between px-[10px] py-[6px] items-center category-toolbar">
        <span class="font-semibold text-tds-font-size-5 leading-tds-line-height-6">Danh sách danh mục</span>
        <div class="flex gap-2 category-actions">
            <button tds-button 
                color="primary" 
                (click)="addCategory()"
                [tdsPrefixIcon]="'tdsi-plus-circle-fill'">
                Thêm danh mục
            </button>
            <!-- @if (userInfo?.isOwner) {
                <div class="w-[300px] select-store">
                    <tds-select valueField="id" 
                        textField="name" 
                        placeholder='Chọn doanh nghiệp' 
                        [allowClear]="true"
                        [data]="listOfStore"
                        [(ngModel)]="selectedStore"
                        (selectChange)="onChangeStore($event)"
                        [allowClear]="true">
                    </tds-select>
                </div>
            } -->
            <tds-form-field class="w-[300px] input-category">
                <input autocomplete="off" 
                    tdsInput placeholder="Tìm theo Tên danh mục" 
                    [tdsInputDebounce]="750"
                    (inputKeyup)="onSearch($event)"
                    [(ngModel)]="searchText"/>
                <span tdsPrefix><i class="tdsi-search-fill"></i></span>
            </tds-form-field>
        </div>
    </div>

    <div class="h-full w-full">
        <tds-table #categoryTable 
            showSizeChanger 
            [loading]="isLoading" 
            [total]="paging.total" 
            [pageSize]="paging.size"
            [pageIndex]="paging.index"
            [frontPagination]="false"
            [scroll]="{'x':'auto','y': 'auto'}"
            (clickRefresh)="loadCategories()"
            (queryParams)="onQueryParamsChange($event)"
            [listData]="listOfCategory">
            <thead>
                <tr>
                    <th [width]="'350px'">Danh mục</th>
                    <!-- @if (userInfo?.isOwner) {
                        <th [width]="'280px'">Doanh nghiệp</th>
                    } -->
                    <th [width]="'220px'">Tạo bởi</th>
                    <th [width]="'150px'">Ngày tạo</th>
                    <th [width]="'220px'">Cập nhật bởi</th>
                    <th [width]="'150px'">Ngày cập nhật</th>
                    <th [width]="'148px'">Thao tác</th>
                </tr>
            </thead>
            <tbody>
                @for(category of listOfCategory; track category.id) {
                <tr>
                    <td>
                        <div class="tds-body-2 break-words">
                            {{ category.name }}
                        </div>
                    </td>
                    <!-- @if (userInfo?.isOwner) {
                        <td>
                            <div class="tds-body-2">
                                {{ category.storeName }}           
                            </div>
                        </td>
                    } -->
                    <td>
                        @if (category.creator) {
                            <app-avatar 
                                [avatar]="category.creator.avatar"
                                [isSystem]="category.creator.isSystem"
                                [textName]="category.creator.name">
                            </app-avatar>
                        } @else {
                            <span>---</span>
                        }
                    </td>
                    <td>
                        <span>{{category.creationTime | date: 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        @if (category.updator) {
                            <app-avatar 
                                [avatar]="category.updator.avatar"
                                [isSystem]="category.updator.isSystem"
                                [textName]="category.updator.name">
                            </app-avatar>
                        } @else {
                            <span>---</span>
                        }
                    </td>
                    <td>
                        @if (category.lastModificationTime) {
                            <span>{{category.lastModificationTime | date: 'dd/MM/yyyy'}}</span>
                        } @else {
                            <span>--</span>
                        }
                    </td>
                    <td>
                        <div class="flex gap-2">
                            <button 
                                tds-button-flat-icon
                                color="info"
                                size="sm"
                                tds-tooltip
                                tooltipTitle="Chỉnh sửa"
                                (tdsBtnClick)="editCategory(category)"
                            >
                                <i class="tdsi-edit-line"></i>
                            </button>
                            <button 
                                tds-button-flat-icon
                                color="error"
                                size="sm"
                                tds-tooltip
                                tooltipTitle="Xóa"
                                (tdsBtnClick)="removeCategory(category)"
                            >
                                <i class="tdsi-trash-line"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                }
            </tbody>
        </tds-table>

    </div>

</div>