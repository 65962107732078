<tds-spin [spinning]="isLoading">
  <tds-layout class="h-screen w-full overflow-hidden">
    <app-header 
      [(isLoading)]="isLoading"
      (roleListChange)="roleListChange($event)">
    </app-header>
    <tds-layout>
      <tds-layout-sider
        [tdsCollapsible]="true"
        [(tdsCollapsed)]="isCollapsed"
      >
        <tds-menu [mode]="'light'" [data]="menuItems" [showLogo]="true" [inlineCollapsed]="isCollapsed">
        </tds-menu>
      </tds-layout-sider>
      <tds-layout-content
        class="overflow-auto bg-neutral-3-200 dark:bg-d-neutral-3-50 relative border-t border-t-neutral-2-200 dark:border-t-d-neutral-2-100">
        <div class="absolute inset-0 overflow-y-auto overflow-x-visible tds-custom-scroll" cdkScrollable>
          <router-outlet></router-outlet>
        </div>
      </tds-layout-content>
    </tds-layout>
  </tds-layout>
</tds-spin>

<ng-template #deletedUserSvg>
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M37.2754 12.4219C37.2754 5.63777 31.7548 0 24.9707 0C18.1866 0 12.666 5.63777 12.666 12.4219C12.666 19.206 18.1866 24.7266 24.9707 24.7266C31.7548 24.7266 37.2754 19.206 37.2754 12.4219Z" fill="#7E8796"/>
  <path d="M17.9395 24.7266C9.20207 24.7266 2.11914 31.8095 2.11914 40.5469V45.8203H30.4218C31.2769 38.2198 37.4781 32.2726 45.1984 31.8454C42.3678 27.5611 37.5212 24.7266 32.002 24.7266H17.9395Z" fill="#7E8796"/>
  <path d="M46.0645 35.2734C39.2804 35.2734 33.7598 40.794 33.7598 47.5781C33.7598 54.3622 39.2804 60 46.0645 60C52.8486 60 58.3691 54.3622 58.3691 47.5781C58.3691 40.794 52.8486 35.2734 46.0645 35.2734ZM51.0358 50.0638L48.5501 52.5495L46.0645 50.0638L43.5788 52.5495L41.0931 50.0638L43.5788 47.5781L41.0931 45.0925L43.5788 42.6068L46.0645 45.0925L48.5501 42.6068L51.0358 45.0925L48.5501 47.5781L51.0358 50.0638Z" fill="#EA2733"/>
  </svg>
</ng-template>

<ng-template #blockedUserSvg>
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M37.2754 12.4219C37.2754 5.63777 31.7548 0 24.9707 0C18.1866 0 12.666 5.63777 12.666 12.4219C12.666 19.206 18.1866 24.7266 24.9707 24.7266C31.7548 24.7266 37.2754 19.206 37.2754 12.4219Z" fill="#7E8796"/>
  <path d="M17.9395 24.7266C9.20207 24.7266 2.11914 31.8095 2.11914 40.5469V45.8203H30.4218C31.2769 38.2198 37.4781 32.2726 45.1984 31.8454C42.3678 27.5611 37.5212 24.7266 32.002 24.7266H17.9395Z" fill="#7E8796"/>
  <path d="M46.0645 35.2734C39.2804 35.2734 33.7598 40.794 33.7598 47.5781C33.7598 54.3622 39.2804 60 46.0645 60C52.8486 60 58.3691 54.3622 58.3691 47.5781C58.3691 40.794 52.8486 35.2734 46.0645 35.2734ZM51.0358 50.0638L48.5501 52.5495L46.0645 50.0638L43.5788 52.5495L41.0931 50.0638L43.5788 47.5781L41.0931 45.0925L43.5788 42.6068L46.0645 45.0925L48.5501 42.6068L51.0358 45.0925L48.5501 47.5781L51.0358 50.0638Z" fill="#EA2733"/>
  </svg>
</ng-template>

<ng-template #changedUserRoleSvg>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M37.0312 12.4219C37.0312 5.63777 31.5107 0 24.7266 0C17.9425 0 12.4219 5.63777 12.4219 12.4219C12.4219 19.206 17.9425 24.7266 24.7266 24.7266C31.5107 24.7266 37.0312 19.206 37.0312 12.4219Z" fill="#7E8796"/>
  <path d="M17.6953 24.7266C8.95793 24.7266 1.875 31.8095 1.875 40.5469V45.8203H30.1777C31.0328 38.2198 37.234 32.2726 44.9543 31.8454C42.1236 27.5611 37.2771 24.7266 31.7578 24.7266H17.6953Z" fill="#7E8796"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M33.5156 47.5781C33.5156 40.794 39.0362 35.2734 45.8203 35.2734C52.6044 35.2734 58.125 40.794 58.125 47.5781C58.125 54.3622 52.6044 60 45.8203 60C39.0362 60 33.5156 54.3622 33.5156 47.5781ZM43.0411 42.4878C41.3344 43.4731 40.3235 45.2006 40.1804 47.0272L38.5364 46.8568C38.8099 43.5284 41.2846 40.8238 44.5018 40.2017C45.2717 40.0517 46.0527 40.0274 46.8157 40.121C48.4998 40.3252 50.0102 41.0951 51.1496 42.2335L52.8187 40.5644L53.2813 45.0923C53.3079 45.3524 53.0889 45.5712 52.8288 45.5446L48.3027 45.0802L49.9828 43.4002C49.2801 42.6983 48.3982 42.1777 47.4113 41.913L47.4111 41.9138C47.1707 41.8493 46.9262 41.8002 46.6792 41.767C46.4295 41.734 46.1747 41.7169 45.9158 41.7169C45.4877 41.7169 45.0705 41.7636 44.6691 41.8523C44.1112 41.9766 43.5625 42.1868 43.0411 42.4878ZM48.7915 52.4552C50.5007 51.4684 51.5122 49.7371 51.6528 47.9074L53.2969 48.0778C53.0268 51.41 50.5507 54.1187 47.3308 54.7413C46.5609 54.8913 45.7799 54.9156 45.0169 54.822C43.3328 54.6178 41.8223 53.8479 40.683 52.7095L39.0138 54.3786L38.5513 49.8507C38.5247 49.5906 38.7437 49.3717 39.0037 49.3984L43.5298 49.8627L41.8498 51.5427C42.5524 52.2446 43.4344 52.7653 44.4213 53.03L44.4215 53.0292C44.6619 53.0937 44.9064 53.1428 45.1533 53.176C45.4031 53.209 45.6579 53.2261 45.9167 53.2261C46.3448 53.2261 46.762 53.1794 47.1635 53.0907C47.7214 52.9664 48.2701 52.7562 48.7915 52.4552Z" fill="#2A92FF"/>
  </svg>
</ng-template>