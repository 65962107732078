import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSOtpInputComponent, TDSOtpInputModule } from "tds-ui/otp";
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { CommonModule } from '@angular/common';
import { TDSModalRef, TDSModalService } from 'tds-ui/modal';
import { VerifiedOtp, VerifyOtp } from '../../../../core/models/sign-up.model';
import { interval, Subscription } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { WiAccountService } from '../../../../core/services/wi-account.service';
import { SendOtpErrorResponse, SendOtpResponse, VerifyOtpErrorResponse, VerifyOtpReq, VerifyOtpResponse } from '../../../../core/models/otp.model';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSMessageService } from 'tds-ui/message';
import { TDSHelperString } from 'tds-ui/shared/utility';
import { OTP_LIMIT_EXCEEDED, OTP_INVALID } from '../../../../core/constants/wi-account-error-code.const';

@Component({
  selector: 'app-otp',
  standalone: true,
  imports: [
    CommonModule,
    TDSOtpInputModule,
    TDSButtonModule,
    TDSFormFieldModule
  ],
  providers: [
    TDSModalService,
    WiAccountService,
    TDSDestroyService,
    TDSMessageService
  ],
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.scss',
})
export class OtpComponent implements OnInit {
  @Input() inputData!: VerifyOtp;
  @ViewChild('limitOtp') limitOtpTemplate: TemplateRef<unknown> | undefined;
  @ViewChild('inputOtp') inputOtp!: TDSOtpInputComponent;

  otp = '';
  isInvalidOtp = false;
  isResendEnabled = false;
  isOtpEmpty = false;

  otpCountdown = 0;
  resendCountdown = 0;

  formattedOtpCountdown = '';
  formattedResendCountdown = '';

  private otpCountdownSubscription?: Subscription;
  private resendCountdownSubscription?: Subscription;

  sixHoursFromLatestTimeSend!: Date;

  constructor(
    private readonly modalService: TDSModalService,
    private readonly modalRef: TDSModalRef,
    private readonly wiAccountService: WiAccountService,
    private readonly destroyService: TDSDestroyService,
    private readonly messageService: TDSMessageService
  ) {}

  /**
   * Initializes the component by starting the OTP countdown and resend countdown.
   */
  ngOnInit(): void {
    this.startOtpCountdown(this.inputData.expiredTime);
    this.startResendCountdown();
  }

  /**
   * Starts the countdown for resending OTP.
   * Disables resend button for 30 seconds and updates the countdown display.
   */
  startResendCountdown(): void {
    this.resendCountdown = 30;
    this.formattedResendCountdown = this.formatCountdown(this.resendCountdown);
    this.isResendEnabled = false;

    this.resendCountdownSubscription = interval(1000)
      .pipe(
        takeWhile(() => this.resendCountdown > 0),
        takeUntil(this.destroyService)
      )
      .subscribe(() => {
        this.resendCountdown--;
        this.formattedResendCountdown = this.formatCountdown(this.resendCountdown);
        if (this.resendCountdown === 0) {
          this.isResendEnabled = true;
          this.resendCountdownSubscription?.unsubscribe();
        }
      });
  }

  /**
   * Starts the countdown for OTP expiration.
   * @param expiryTime The expiration time of the OTP.
   */
  startOtpCountdown(expiryTime: Date): void {
    const expiredTime = new Date(expiryTime).getTime();
    const now = new Date().getTime();
    this.otpCountdown = Math.floor((expiredTime - now) / 1000);

    this.formattedOtpCountdown = this.formatCountdown(this.otpCountdown);

    this.otpCountdownSubscription = interval(1000)
      .pipe(
        takeWhile(() => this.otpCountdown > 0),
        takeUntil(this.destroyService)
      )
      .subscribe(() => {
        this.otpCountdown--;
        this.formattedOtpCountdown = this.formatCountdown(this.otpCountdown);
        if (this.otpCountdown === 0) {
          this.otpCountdownSubscription?.unsubscribe();
        }
      });
  }

  /**
   * Formats the countdown time into a string representation.
   * @param countdown The countdown time in seconds.
   * @returns A formatted string of the countdown time.
   */
  private formatCountdown(countdown: number): string {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  /**
   * Handles the resend OTP action.
   * Resets countdowns, clears OTP input, and sends a new OTP request.
   */
  onResendOtp(): void {
    this.resetCountdowns();
    this.startResendCountdown();

    this.isResendEnabled = false;
    this.isInvalidOtp = false;
    this.isOtpEmpty = false;
    this.otp = '';
    this.inputOtp.setValue('');

    this.wiAccountService.sendOtp({
      phoneNumber: this.inputData.phone,
      method: this.inputData.type
    })
    .pipe(takeUntil(this.destroyService))
    .subscribe({
      next: (res: SendOtpResponse) => {
        if (res.codeVerifyExpiryDate) {
          this.startOtpCountdown(res.codeVerifyExpiryDate);
        }
      },
      error: (err: SendOtpErrorResponse) => this.handleResendOtpError(err)
    });
  }

  /**
   * Handles errors that occur during OTP resend.
   * @param err The error response from the OTP resend attempt.
   */
  private handleResendOtpError(err: SendOtpErrorResponse): void {
    if (err.error.code === OTP_LIMIT_EXCEEDED) {
      this.handleOtpLimitExceeded(err);
    } else {
      this.messageService.error(err.error.message);
    }
  }

  /**
   * Handles the case when OTP limit is exceeded.
   * @param err The error response containing limit exceeded information.
   */
  private handleOtpLimitExceeded(err: SendOtpErrorResponse): void {
    this.isResendEnabled = false;
    const latestTimeSend = (err.error.data as { latestTimeSend: Date }).latestTimeSend;
    const adjustedTimeSend = new Date(latestTimeSend);
    adjustedTimeSend.setHours(adjustedTimeSend.getHours() + 6);
    this.sixHoursFromLatestTimeSend = adjustedTimeSend;
    this.showLimitAccessOtpModal();
  }

  /**
   * Resets all countdown timers and their subscriptions.
   */
  private resetCountdowns(): void {
    this.otpCountdown = 0;
    this.resendCountdown = 0;
    this.formattedOtpCountdown = '';
    this.formattedResendCountdown = '';
    this.otpCountdownSubscription?.unsubscribe();
    this.resendCountdownSubscription?.unsubscribe();
  }

  /**
   * Handles changes to the OTP input.
   * @param otp The new OTP value.
   */
  onOtpChange(otp: string): void {
    // Chỉ reset isInvalidOtp khi người dùng bắt đầu nhập

    if (otp.length > 0) {
      this.isInvalidOtp = false;
      this.isOtpEmpty = false;
      this.otp = otp;
    }
  }
  /**
   * Handles the continue action after OTP input.
   * Validates OTP and sends verification request.
   */
  onContinue(): void {
    if (this.otpCountdown <= 0) {
      return;
    }

    if (!TDSHelperString.hasValueString(this.otp)) {
      this.isOtpEmpty = true;
      return;
    } else if (this.otp.length !== 6) {
      this.isInvalidOtp = true;
      return;
    }

    const verifyOtpData: VerifyOtpReq = {
      phoneNumber: this.inputData.phone,
      otpCode: this.otp,
    };

    this.wiAccountService.verifyOtp(verifyOtpData)
      .pipe(takeUntil(this.destroyService))
      .subscribe({
        next: (res: VerifyOtpResponse) => {
          const destroyData: VerifiedOtp = {
            smsOtpToken: res.smsOtpToken,
            otpCode: this.otp,
            phone: this.inputData.phone
          };
          this.modalRef.destroy(destroyData);
        },
        error: (err: VerifyOtpErrorResponse) => {
          if (err.error.code === OTP_INVALID) {
            this.isInvalidOtp = true;
            this.otp = '';
            this.inputOtp.setValue('');
          } else {
            this.messageService.error(err.error.message);
          }
        }
      });
  }

  /**
   * Handles the back action, closing the modal.
   */
  onBack(): void {
    this.modalRef.close();
  }

  /**
   * Displays a modal when OTP limit is exceeded.
   */
  private showLimitAccessOtpModal(): void {
    const modal = this.modalService.error({
      title: "Thông báo",
      content: this.limitOtpTemplate,
      okText: null,
      cancelText: 'Đóng',
      onCancel: () => {
        modal.close();
        this.onBack();
      },
    });
  }
}
