<div class="h-full w-full bg-white">
    <tds-header tdsTheme="dark" 
        urlLogoText="/assets/images/WiOnLogo.svg" 
        urlLogo="/assets/images/WiOnLogo.svg"
        class="!pl-[60px] !py-2"></tds-header>
  
    <div class="w-full h-full pt-24">
        @if (isBlock || isDelete) {
            <div class="flex m-auto w-[500px] rounded-xl border border-solid tds-color-border-secondary shadow-xl items-center p-6 flex-col gap-3 justify-center">
                @if (isBlock) {
                    <div class="icon-circle tds-color-background-error-subtle">
                        <i class="tdsi-lock-fill text-tds-font-size-10"></i>
                    </div>
                    <div class="flex flex-col gap-1.5 items-center justify-center">
                        <span class="font-semibold text-tds-font-size-6 leading-7">Tài khoản đã bị khóa</span>
                        <span class="text-center text-body-2">
                            Tài khoản của bạn đã bị khóa ở cửa hàng <strong>{{blockedStores}}</strong>. Nếu có bất kỳ thắc mắc nào, hãy liên hệ với quản lý.
                        </span>
                    </div>
                }
        
                @if(isDelete){
                    <div class="icon-circle tds-color-background-error-subtle">
                        <i class="tdsi-close-fill text-tds-font-size-10"></i>
                    </div>
                    <div class="flex flex-col gap-1.5 items-center justify-center">
                        <span class="font-semibold text-tds-font-size-6 leading-7">Tài khoản đã bị xóa</span>
                        <span class="text-center text-body-2">
                            Tài khoản của bạn đã bị xóa ở cửa hàng <strong>{{deletedStores}}</strong>. Nếu có bất kỳ thắc mắc nào, hãy liên hệ với quản lý.
                        </span>
                    </div>
                }
                
                <tds-alert type="info" 
                    [description]="descriptionTemplate" 
                    [footer]="footerTemplate"
                    showIcon>
                </tds-alert>
                <button tds-button-outline 
                    class="mr-2 min-w-[100px]" 
                    color="secondary" 
                    (click)="onClose()">
                    Đóng
                </button>
              </div>
        }
      
        @if(isBlockAndDelete){
            <div class="flex m-auto w-[500px] rounded-xl border border-solid tds-color-border-secondary shadow-xl p-6 flex-col gap-3 justify-center">
                <div class="flex flex-col items-center gap-3">
                    <div class="icon-circle tds-color-background-error-subtle">
                        <i class="tdsi-block-fill text-tds-font-size-10"></i>
                    </div>
                    <div class="font-semibold text-tds-font-size-6 leading-7 flex items-center">
                        Tài khoản đã bị vô hiệu hóa
                    </div>
                </div>
                
                <div class="flex flex-col mt-1.5 text-tds-font-size-3 leading-5">
                    <ul class="list-disc pl-5">
                        <li>Tài khoản đã bị khóa ở cửa hàng <strong>{{blockedStores}}</strong></li>
                        <li>Tài khoản đã bị xóa ở cửa hàng <strong>{{deletedStores}}</strong></li>
                    </ul>
                    <span>Nếu có bất kỳ thắc mắc nào, hãy liên hệ với quản lý.</span>
                </div>
                <tds-alert type="info" 
                    [description]="descriptionTemplate" 
                    [footer]="footerTemplate"
                    showIcon>
                </tds-alert>
                <div class="flex items-center justify-center">
                    <button tds-button-outline 
                        class="mr-2 w-[100px]" 
                        color="secondary" 
                        (click)="onClose()">
                        Đóng
                    </button>
                </div>
            </div>
        }
    </div>
  </div>
<ng-template #descriptionTemplate>
    <div class="w-full -mt-2 text-body-2 break-keep">
        Bạn có thể tạo mới doanh nghiệp để sở hữu giải pháp phần mềm bán hàng toàn diện trên sàn thương mại điện tử
    </div>
</ng-template>
  <ng-template #footerTemplate>
    <div class="w-full">
        <button tds-button color="info" class="mr-2" size="sm" (click)="addBranch()">
            Tạo doanh nghiệp
        </button>
    </div>
</ng-template>
