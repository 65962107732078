import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from 'app/core/services/auth.service';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSPopoverModule } from 'tds-ui/popover';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { TDSHelperString, TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSInputModule } from 'tds-ui/tds-input';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { ATTRIBUTE_RULE } from '../../constants/attribute-rule.constant';
import { CreateAttribute, ResponseCreateAttribute } from '../../models/attribute.model';
import { SortDateClassPipe } from '../../pipes/sort-date.pipe';
import { AttributeService } from '../../service/attribute.service';

@Component({
  selector: 'app-attribute-panel',
  standalone: true,
  imports: [
    CommonModule,
    TDSButtonModule,
    SortDateClassPipe,
    TDSPopoverModule,
    TDSFormFieldModule,
    TDSInputModule,
    ReactiveFormsModule,
    TDSToolTipModule,
    TDSSpinnerModule
  ],
  templateUrl: './attribute-panel.component.html',
})
export class AttributePanelComponent implements OnInit {
  @Output() refreshEvent = new EventEmitter<boolean>();
  @Output() sortDateCreatedEvent = new EventEmitter<string>();
  @Output() loadingEvent = new EventEmitter<boolean>();
  sortByName: string = '';
  form!: FormGroup;
  rule = ATTRIBUTE_RULE;
  isVisibleAddAttribute: boolean = false;
  storeId: string | null = null;
  sortDateCreated: string = 'DESC';
  isDuplicate: boolean = false;
  isLoading: boolean = false;
  constructor(private fb: FormBuilder,
    private attributeService: AttributeService,
    private messageService: TDSMessageService,
    private authService: AuthService,) {

  }
  ngOnInit(): void {
    this.getCurrentStore();
    this.createForm();
    this.valueChangeName();
  }

  valueChangeName() {
    this.form.get('name')?.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.isDuplicate = false;
      });
  }

  createForm() {
    this.form = this.fb.group({
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator()
        ])
      ]
    })
  }

  getCurrentStore() {
    this.storeId = this.authService.getCurrentStoreId();
  }

  addAttribute() {
    this._markDirtyAndTouched();
    if (!this.form.valid) return;
    this.isLoading = true;
    this.loadingEvent.emit(true);
    const model = this.prepareModel();
    this.attributeService.addAttributes$(model)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.loadingEvent.emit(false);
      }))
      .subscribe({
        next: (res: ResponseCreateAttribute) => {
          this.messageService.success("Thêm thuộc tính mới thành công");
          this.form.reset();
          this.isVisibleAddAttribute = false;
          this.refreshEvent.emit(true);
        }, error: (resError: TDSSafeAny) => {
          if (resError.error.message == "Tên đã tồn tại") {
            this.isDuplicate = true;
          } else {
            this.messageService.error("Có lỗi khi thêm thuộc tính!")
          }
        }
      })
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
  }

  prepareModel(): CreateAttribute {
    const valueForm = this.form.value;
    const model: CreateAttribute = {
      name: valueForm.name,
      storeId: TDSHelperString.hasValueString(this.storeId) ? this.storeId : ''
    }
    return model;
  }

  close() {
    this.form.reset();
    this.isVisibleAddAttribute = false;
  }

  changeSort() {
    this.sortDateCreated = this.sortDateCreated === 'DESC' ? 'ASC' : 'DESC';
    this.sortDateCreatedEvent.emit(this.sortDateCreated);
  }

  trimName() {
    let trimName = this.form.get('name')?.value.trim();
    this.form.get('name')?.setValue(trimName);
  }

  openPopover() {
    this.form.reset();
    this.isDuplicate = false;
  }
}
