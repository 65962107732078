import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseApiService {
  protected override readonly apiUrl = environment.apiUrl;

  constructor(_http: HttpClient) {
    super(_http);
  }
}