<ng-container>
  <div class="flex items-center gap-2">
    @if (status !== employeeStatus.Active && status !== employeeStatus.Inactive) {
      <button 
        tds-button-flat-icon
        color="info"
        size="sm"
        tds-tooltip
        tooltipTitle="Gửi lời mời"
      >
        <i class="tdsi-send-line"></i>
      </button>
    }

    @if (status !== employeeStatus.Pending && status !== employeeStatus.Undefined) {
      <button 
        tds-button-flat-icon
        color="info"
        size="sm"
        tds-tooltip
        tooltipTitle="Chỉnh sửa"
      >
        <i class="tdsi-edit-line"></i>
      </button>
      
      <button 
        tds-button-flat-icon
        color="error"
        size="sm"
        tds-tooltip
        [tooltipTitle]="isLocked ? 'Mở khóa nhân viên' : 'Khóa nhân viên'"
      >
        <i [ngClass]="isLocked ? 'tdsi-lock-line' : 'tdsi-unlock-line'"></i>
      </button>
    }

    <button 
      tds-button-flat-icon
      color="error"
      size="sm"
      tds-tooltip
      tooltipTitle="Xóa nhân viên"
    >
      <i class="tdsi-trash-line"></i>
    </button>
  </div>

</ng-container>
