import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DistrictResponse, ProvinceResponse, WardResponse } from 'app/core/models/address.model';
import { AddressService } from 'app/core/services/address.service';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { finalize, takeUntil } from 'rxjs';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSelectModule } from 'tds-ui/select';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSInputModule } from 'tds-ui/tds-input';
import { CreateBranchReq } from '../../models/create-branch.model';
import { BranchService } from '../../services/branch.service';
import { StoreBranch } from '../../models/store-branch.model';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { phoneValidatorTenDigits } from 'app/core/utilities/phone-validator.util';
import { Store } from 'app/features/stores/models/store.model';
import { TDSInputNumberModule } from 'tds-ui/input-number';
import { TDSButtonMenuModule } from 'tds-ui/button-menu';
import { ErrorResponse } from 'app/core/models/error-response.model';
import { NumbersOnlyDirective } from 'app/shared/directives/numbers-only.directive';
import { emailValidator } from 'app/core/utilities/email-validator.util';

@Component({
  selector: 'app-create-branch',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSAvatarModule,
    TDSButtonModule,
    TDSButtonMenuModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSSelectModule,
    TDSModalModule,
    TDSSpinnerModule,
    TDSInputNumberModule,
    NumbersOnlyDirective
  ],
  providers: [
    AddressService,
    {
      provide: ErrorStateMatcher, useClass: WiECommerceDirtyErrorStateMatcher
    },
    TDSMessageService,
    TDSDestroyService,
    BranchService
  ],
  templateUrl: './create-branch.component.html',
  styleUrl: './create-branch.component.scss'
})
export class CreateBranchComponent implements OnInit {
  @Input() store!: Store;
  @Input() storeBranch!: StoreBranch;

  branchForm!: FormGroup;
  provinces: ProvinceResponse[] = [];
  districts: DistrictResponse[] = [];
  wards: WardResponse[] = [];
  isLoading = false;

  phoneIsTouched = false;

  constructor(
    private readonly addressService: AddressService,
    private readonly msgService: TDSMessageService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly modalRef: TDSModalRef<CreateBranchComponent>,
    private readonly branchService: BranchService
  ) {

  }

  ngOnInit(): void {
    this.loadProvinces();
    this.initForm();
  }

  initForm(): void {
    this.branchForm = new FormGroup({
      branchName: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      email: new FormControl(this.store.email, emailValidator),
      phone: new FormControl(this.store.phoneNumber, Validators.compose([
        Validators.maxLength(10),
        phoneValidatorTenDigits()
      ])),
      subPhones: new FormArray([]),
      province: new FormControl(null, Validators.required),
      district: new FormControl(null, Validators.required),
      ward: new FormControl(null, Validators.required),
      street: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(1000)
      ])),
    })
  }

  get subPhones(): FormArray {
    return this.branchForm.get('subPhones') as FormArray;
  }

  newSubPhone(): FormGroup {
    return new FormGroup({
      subPhone: new FormControl(null, Validators.compose([
        Validators.maxLength(10),
        phoneValidatorTenDigits()
      ])),
    });
  }

  onAddSubPhone(): void {
    this.subPhones.push(this.newSubPhone());
  }

  onRemoveSubPhone(index: number): void {
    this.subPhones.removeAt(index);
  }

  loadProvinces(): void {
    this.addressService.getProvinces$()
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.provinces = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách tỉnh/thành phố thất bại');
        }
      })
  }

  loadDistricts(provinceCode: string): void {
    this.addressService.getDistricts$(provinceCode)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.districts = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách quận/huyện thất bại');
        }
      })
  }

  loadWards(districtCode: string): void {
    this.addressService.getWards$(districtCode)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.wards = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách phường/xã thất bại');
        }
      })
  }

  onChangeProvince(event: ProvinceResponse): void {
    if (!event) return;

    this.branchForm.get('district')?.reset();
    this.branchForm.get('ward')?.reset();

    this.branchForm.get('province')?.setValue(event.Name);
    this.loadDistricts(event.Code);
  }
    
  onChangeDistrict(event: DistrictResponse): void {
    if (!event) return;

    this.branchForm.get('ward')?.reset();

    this.branchForm.get('district')?.setValue(event.Name);
    this.loadWards(event.Code);
  }

  onChangeWard(event: WardResponse): void {
    if (!event) return;

    this.branchForm.get('ward')?.setValue(event.Name);
  }

  onCancel(): void {
    this.modalRef.close();
  }

  private prepareData(): CreateBranchReq {
    const formValue = this.branchForm.value;
    const subPhones: string[] = formValue.subPhones.map((item: { subPhone: string }) => item.subPhone);
    const model: CreateBranchReq = {
      Name: formValue.branchName,
      Email: formValue.email,
      PhoneNumber: formValue.phone,
      SubPhoneNumber: subPhones,
      StoreId: this.store.id,
      Street: formValue.street,
      Ward: formValue.ward,
      District: formValue.district,
      City: formValue.province,
      TimeZone: "UTC+7",
    }

    return model;
  }

  private markFormAsDirty(): void {
    Object.keys(this.branchForm.controls).forEach(key => {
      this.branchForm.get(key)?.markAsDirty();

      if (key === 'subPhones') {
        this.subPhones.controls.forEach((ctrl) => {
          ctrl.get('subPhone')?.markAsDirty();
        });
      }
    });
  }

  onSubmit(): void {
    this.markFormAsDirty();
    
    if (this.branchForm.invalid) return;

    this.isLoading = true;
    const model = this.prepareData();

    this.branchService.createBranch$(model)
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: StoreBranch) => {
          this.msgService.success('Tạo cửa hàng thành công');
          this.modalRef.destroy(res);
        },
        error: (err: ErrorResponse) => {
          this.msgService.error(err.error.code ? err.error.message : 'Tạo cửa hàng không thành công');
        }
      })
  }

  onPhoneBlur(): void {
    this.phoneIsTouched = true;
  }

  onPhoneFocus(): void {
    this.phoneIsTouched = false;
  }
}
