import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDS_DRAWER_DATA, TDSDrawerRef } from 'tds-ui/drawer';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { TDSSwitchModule } from 'tds-ui/switch';
import { TDSInputModule } from 'tds-ui/tds-input';
import { IMyPermission, IPermission } from '../../models/permission.model';
import { IRole } from '../../models/role.model';
import { IUpdateRoleReq } from '../../models/update-role.model';
import { RoleService } from '../../services/role.service';
import { SettingPermissionComponent } from '../setting-permission/setting-permission.component';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { WiECommerceDirtyErrorStateMatcher } from '../../../../core/utilities/error-state-matcher.util';

@Component({
  selector: 'app-edit-role',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSSwitchModule,
    SettingPermissionComponent,
    TDSButtonModule,
    TDSSpinnerModule
  ],
  providers: [
    TDSDestroyService,
    {
      provide: ErrorStateMatcher, useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
  templateUrl: './edit-role.component.html',
  styleUrl: './edit-role.component.scss'
})
export class EditRoleComponent implements OnInit {
  tdsData: { role: IRole } = inject(TDS_DRAWER_DATA);
  isLoading = false;

  form!: FormGroup;
  permissions!: IPermission[];
  @ViewChildren(SettingPermissionComponent) settingPermissions!: QueryList<SettingPermissionComponent>;

  isChildChanged = false;

  constructor(
    private readonly roleService: RoleService,
    private readonly destroyService: TDSDestroyService,
    private readonly drawerRef: TDSDrawerRef,
    private readonly msgService: TDSMessageService,
  ) { }

  ngOnInit(): void {
    this.permissions = this.tdsData.role.permissionList || [];
    
    this.createForm(this.tdsData.role);
  }

  createForm(role: IRole): void {
    this.form = new FormGroup({
      name: new FormControl(role.name, [Validators.required, Validators.maxLength(64)]),
      description: new FormControl(role.description, Validators.maxLength(500)),
      isAllPermission: new FormControl(role.isAllPermission),
      permissions: new FormArray([])
    });
  }

  get permissionsFormArray(): FormArray {
    return this.form.get('permissions') as FormArray;
  }

  // Check all child permissions are active
  private checkAllPermissionsActive(): boolean {
    let allActive = true;
    this.settingPermissions.forEach(component => {
      if (component.permissionDetailList.length > 0) {
        if (!component.checkAllPermissionsActive(component.permissionDetailList)) {
          allActive = false;
        }
      } else {
        if (!component.isActive.value) {
          allActive = false;
        }
      }

    });
    return allActive;
  }

  // Update form control 'isAllPermission'
  private updateIsAllPermission(allActive: boolean): void {
    this.form.get('isAllPermission')?.setValue(allActive);
  }

  onChangeAllPermission(event: boolean): void {
    if (this.isChildChanged) {
      this.isChildChanged = false;
      const allActive = this.checkAllPermissionsActive();
      if (allActive) {
        this.updateIsAllPermission(allActive);
      }
      return;
    }

    if (this.settingPermissions) {
      this.settingPermissions.forEach(component => {
        if (component.permissionDetailList.length > 0) {
          component.changeIsActivePermission(component.permissionDetailList, event);
          component.checkActivePermission();
        } else {
          component.setActive(event);
          component.setIndeterminate(false);
        }
      });
    }
  }

  handleCheckFullPermission(): void {
    this.isChildChanged = true;
    const allActive = this.checkAllPermissionsActive();
    this.updateIsAllPermission(allActive);
  }

  // If any permission detail is active, then the permission is active
  private preparePermissionDetailList(permissionDetailList: any[]): IMyPermission[] {
    permissionDetailList = permissionDetailList
      .map(item => {
        const { isIndeterminate, ...rest } = item;
        return rest as IMyPermission;
      })
      .map(item => {
        if (item.permissionDetailList && item.permissionDetailList.length > 0) {
          item.isActive = item.permissionDetailList.some(detail => detail.isActive);
          item.permissionDetailList = this.preparePermissionDetailList(item.permissionDetailList);
        }
        return item;
      });
  
    return permissionDetailList;
  }

  private preparePermissionList(): IPermission[] {
    const permissionList: IPermission[] = [];

    // Collect data from child forms
    this.settingPermissions.forEach((component) => {
      const permission = component.form.value;
      permissionList.push({
        permissionType: permission.permissionType,
        permissionName: permission.permissionName,
        displayName: permission.displayName,
        isActive: permission.isActive,
        permissionDetailList: this.preparePermissionDetailList(permission.permissionDetailList)
      });
    });
    
    return permissionList;
  }

  private preparePayload(): IUpdateRoleReq {
    const payload: IUpdateRoleReq = {
      name: this.form.get('name')?.value,
      description: this.form.get('description')?.value,
      isAllPermission: this.form.get('isAllPermission')?.value,
      permissionList: this.preparePermissionList(),
      userIdList: this.tdsData.role.userInRoleList?.map(item => item.id) as string[],
    }
    return payload;
  }

  private markFormAsDirty(): void {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key)?.markAsDirty();
    });
  }

  private scrollToError(): void {
    const errorElement = document.querySelector('.tds-error');
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private isAtLeastOnePermissionActive(): boolean {
    let isAtLeastOnePermission = false;
    this.settingPermissions.forEach(component => {
      if (component.checkAtLeastOnePermissionActive(component.permissionDetailList)) {
        isAtLeastOnePermission = true;
      } else if (component.isActive.value) {
        isAtLeastOnePermission = true;
      }
    });

    return isAtLeastOnePermission;
  }

  onSave(): void {
    if (!this.isAtLeastOnePermissionActive()) {
      this.msgService.error('Vui lòng chọn ít nhất một quyền cho vai trò này');
      return;
    }

    this.markFormAsDirty();

    if (this.form.invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }

    const payload = this.preparePayload();

    this.roleService.updateRole$(this.tdsData.role.id, payload)
      .pipe(
        takeUntil(this.destroyService)
      )
      .subscribe({
        next: () => {
          this.msgService.success('Đã cập nhật vai trò thành công');
          this.drawerRef.close(true);
        },
        error: () => {
          this.msgService.error('Cập nhật vai trò thất bại');
        }
      })
  }

  onCancel(): void {
    this.drawerRef.close();
  }
}
