import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { Observable, map, of, switchMap, timer } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule } from 'tds-ui/modal';
import { TDSSelectModule } from 'tds-ui/select';
import { TDSHelperString, TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSInputModule } from 'tds-ui/tds-input';
import { ATTRIBUTE_VALUE_RULE } from '../../constants/attribute-value-rule.constant';
import { CreateAttributeValue } from '../../models/attribute-value.model';
import { IAttribute } from '../../models/attribute.model';
import { AttributeValueService } from '../../service/attribute-value.service';

@Component({
  selector: 'app-add-attribute-value',
  standalone: true,
  imports: [
    TDSInputModule,
    TDSSelectModule,
    TDSButtonModule,
    CommonModule,
    TDSFormFieldModule,
    TDSModalModule,
    ReactiveFormsModule,
    TDSSelectModule
  ],
  templateUrl: './add-attribute-value.component.html',
  styleUrl: './add-attribute-value.component.scss'
})
export class AddAttributeValueComponent implements OnInit, OnChanges {
  @Input() attribute?: IAttribute;
  @Input() listOfAttribute: IAttribute[] = [];
  @Input() storeId!: string | null;
  @Output() loadingEvent = new EventEmitter<boolean>();
  @Output() loadDataEvent = new EventEmitter<boolean>();
  @ViewChild('formDirective') formDirective!: NgForm;

  selectedAttribute?: IAttribute;
  form!: FormGroup;
  rule = ATTRIBUTE_VALUE_RULE;
  isLoadingAdd: boolean = false;
  constructor(private fb: FormBuilder,
    private attributeValueService: AttributeValueService,
    private messageService: TDSMessageService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attribute'] && !changes['attribute'].firstChange) {
      if (TDSHelperString.hasValueString(changes['attribute'])) {
        this.form.controls['attributeId'].setValue(this.attribute?.id);
      }
    }

    if (changes['listOfAttribute'] && !changes['listOfAttribute'].firstChange) {
      this.form.reset();
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  changeAttributes(event: TDSSafeAny){
    this.form.controls['attributeId'].setValue(event);
    this.form.controls['name'].updateValueAndValidity();
  }

  createForm() {
    this.form = this.fb.group({
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator(),
        ]),this.checkExistName(this.attributeValueService)
      ],
      attributeId: [
        null, Validators.required
      ],
    })
  }

  onSave() {
    this._markDirtyAndTouched();
    if (!this.form.valid) return;
    this.loadingEvent.emit(true);
    const model = this.prepareModel();
    this.attributeValueService.addAttributeValue$(model)
      .subscribe({
        next: () => {
          this.messageService.success("Thêm giá trị thuộc tính thành công!");
          this.loadDataEvent.emit(true);
          this.form.reset();
          this.formDirective.resetForm(); 
        }
        , error: () => this.messageService.error("Có lỗi khi thêm giá trị thuộc tính!")
        , complete: () => this.loadingEvent.emit(false),
      })

  }

  prepareModel() {
    const valueForm = this.form.value;
    const model: CreateAttributeValue = {
      attributeId: valueForm.attributeId,
      name: valueForm.name,
      storeId: TDSHelperString.hasValueString(this.storeId) ? this.storeId : ''
    }
    return model;
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
    this.form.controls['attributeId'].markAsDirty();
    this.form.controls['attributeId'].markAsTouched();
  }

  checkExistName = (service: AttributeValueService) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.isLoadingAdd = true;
      if (!control.value || !TDSHelperString.hasValueString(this.form.controls['attributeId'].value)) {
        this.isLoadingAdd = false;
        return of(null);
      }

      return timer(500).pipe(
        switchMap(() =>
          service.checkExistAttributeValue$({
            name: control.value,
            storeId: this.storeId ?? '',
            attributeId: this.form.controls['attributeId'].value
          })
            .pipe(
              map((res: TDSSafeAny) => {
                this.isLoadingAdd = false;
                if (res) {
                  return { duplicate: true };
                } else {
                  return null;
                }
              })
            )
        )
      )
    }
  }

  trimName(){
    let trimName = this.form.get('name')?.value.trim();
    this.form.get('name')?.setValue(trimName);
  }
}
