<tds-spin [spinning]="isLoading" [size]="'md'">
    <div class="grid grid-cols-2 gap-8">
        <div *ngFor="let item of defaultChannel" class="flex flex-row p-4 border border-outline rounded-lg">
            <div class="w-1/3 m-auto">
                <img tds-image class="w-[118px] h-[118px]" [srcset]="item.image" alt="" />
            </div>
            <div class="w-3/4 flex flex-col gap-4">
                <div class="w-full flex flex-row items-center justify-between">
                    <div class="text-header-1 font-semibold">{{ item.name }}</div>
                    <button tds-button 
                        class="w-[100px]" 
                        color="primary"
                        (click)="connect(item.value)" 
                        [disabled]="!item.isEnabled"
                    > 
                        Kết nối
                    </button>
                </div>
                <div class="w-full">
                    <span class="text-body-2 font-regular">Kết nối hệ thống giúp đồng bộ sản phẩm, giá, tồn kho, đơn hàng. Xem thêm hướng dẫn.</span>
                </div>
            </div>
        </div>
    </div>
</tds-spin>
<div class="w-full flex justify-end p-4" *tdsModalFooter>
    <button tds-button-outline
        class="mr-2 min-w-[100px]" 
        color="secondary"
        type="button"
        [disabled]="isLoading"
        [size]="'md'"
        (click)="onCancel()">
        Đóng
    </button>
</div>