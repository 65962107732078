import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address',
  standalone: true
})
export class AddressPipe implements PipeTransform {

  transform(value: any): unknown {
    if (!value) return '--';
    
    const parts = [value?.street, value?.ward, value?.district, value?.city].filter(Boolean);
    return parts.length > 0 ? parts.join(', ') : '---';
  }
}
