import { tdsAssert } from 'tds-ui/cdk/classes';
import { TDSPureException } from 'tds-ui/cdk/exceptions';

/**
 * Decorator for checking input values for undefined. You can also pass
 * optional assertion to check input against.
 *
 * CAUTION: This decorator overwrites other getters and setters.
 */
function tdsDefaultProp(assertion, ...args) {
  return (target, key) => {
    const {
      name
    } = target.constructor;
    const errorGetDefaultMessage = errorGetDefault(key, name);
    const errorSetDefaultMessage = errorSetDefault(key, name);
    Object.defineProperty(target, key, {
      get() {
        tdsAssert.assert(false, errorGetDefaultMessage);
        return undefined;
      },
      set(initialValue) {
        const isValid = initialValue !== undefined;
        const errorMessage = errorSetDefaultInitial(key, name);
        let currentValue = initialValue;
        tdsAssert.assert(isValid, errorMessage);
        if (isValid && assertion) {
          tdsAssert.assert(assertion.call(this, initialValue), `${String(key)} in ${name} received:`, initialValue, ...args);
        }
        Object.defineProperty(this, key, {
          get() {
            return currentValue;
          },
          set(value) {
            const isValid = value !== undefined;
            const backupValue = initialValue;
            tdsAssert.assert(isValid, errorSetDefaultMessage, String(backupValue));
            if (isValid && assertion) {
              tdsAssert.assert(assertion.call(this, value), `${String(key)} in ${name} received:`, value, ...args);
            }
            currentValue = isValid ? value : backupValue;
          }
        });
      }
    });
  };
}
function errorGetDefault(key, component) {
  return `Default value for ${String(key)} was not provided in ${component}, error in Taiga UI Angular Kit`;
}
function errorSetDefault(key, component) {
  return `Undefined was passed as ${String(key)} to ${component}, which is invalid input, using default value:`;
}
function errorSetDefaultInitial(key, component) {
  return `Undefined was passed as default value for ${String(key)} to ${component}, error in Taiga UI Angular Kit`;
}

/**
 * Implements lazy initialization for getter or memoization of a function call similar to pure {@link: Pipe}.
 * Replaces getter with its calculated value upon first call or keeps track of last call arguments and returned
 * value for function, skipping calculation when arguments are strictly the same.
 *
 * @throws error if used not on getter or function
 *
 * CAUTION: `this` is not available inside such functions/getters, they must be pure.
 */
function tdsPure(_target, propertyKey, {
  get,
  enumerable,
  value
}) {
  if (get) {
    return {
      enumerable,
      get() {
        const value = get.call(this);
        Object.defineProperty(this, propertyKey, {
          enumerable,
          value
        });
        return value;
      }
    };
  }
  if (typeof value !== `function`) {
    throw new TDSPureException();
  }
  const original = value;
  return {
    enumerable,
    get() {
      let previousArgs = [];
      let originalFnWasCalledLeastAtOnce = false;
      let pureValue;
      const patched = (...args) => {
        const isPure = originalFnWasCalledLeastAtOnce && previousArgs.length === args.length && args.every((arg, index) => arg === previousArgs[index]);
        if (isPure) {
          return pureValue;
        }
        previousArgs = args;
        pureValue = original.apply(this, args);
        originalFnWasCalledLeastAtOnce = true;
        return pureValue;
      };
      Object.defineProperty(this, propertyKey, {
        value: patched
      });
      return patched;
    }
  };
}

/**
 * Decorator for checking input setter values against a custom assertion which
 * takes value passed to input setter and component instance as arguments.
 * It specifically checks for undefined values and prevents calls to the
 * original setter in this case.
 */
function tdsRequiredSetter(assertion, ...args) {
  return (target, key, {
    configurable,
    enumerable,
    get,
    set
  }) => {
    const {
      name
    } = target.constructor;
    return {
      configurable,
      enumerable,
      get,
      set(value) {
        if (value !== undefined && assertion) {
          tdsAssert.assert(assertion.call(this, value), `${String(key)} in ${name} received:`, value, ...args);
        }
        if (!set || value === undefined) {
          tdsAssert.assert(value !== undefined, errorSet(key, name));
          return;
        }
        set.call(this, value);
      }
    };
  };
}
function errorSet(key, component) {
  return `Undefined was passed as ${String(key)} to ${component}, setter will not be called`;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tdsDefaultProp, tdsPure, tdsRequiredSetter };
