import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { EEmployeeStatus } from '../../enums/employee-status.enum';

@Component({
  selector: 'app-employee-actions',
  standalone: true,
  imports: [
    CommonModule,
    TDSButtonModule,
    TDSToolTipModule
  ],
  templateUrl: './employee-actions.component.html',
  styleUrl: './employee-actions.component.scss'
})
export class EmployeeActionsComponent implements OnInit {
  @Input() status!: EEmployeeStatus;
  @Input() isLocked!: boolean;
  @Output() onActionCompleted = new EventEmitter<void>();

  employeeStatus = EEmployeeStatus;

  ngOnInit(): void {
  }
}
