import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { WiECommerceErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { distinctUntilChanged, finalize } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { TDSSelectModule } from 'tds-ui/select';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSHelperString, TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSInputModule } from 'tds-ui/tds-input';
import { ATTRIBUTE_VALUE_RULE } from '../../constants/attribute-value-rule.constant';
import { CreateAttributeValue } from '../../models/attribute-value.model';
import { IAttribute } from '../../models/attribute.model';
import { AttributeValueService } from '../../service/attribute-value.service';

@Component({
  selector: 'app-add-attribute-value',
  standalone: true,
  imports: [
    TDSInputModule,
    TDSSelectModule,
    TDSButtonModule,
    CommonModule,
    TDSFormFieldModule,
    TDSModalModule,
    ReactiveFormsModule,
    TDSSpinnerModule
  ],
  providers: [
    TDSDestroyService,
    {
      provide: ErrorStateMatcher, useClass: WiECommerceErrorStateMatcher
    }
  ],
  templateUrl: './add-attribute-value.component.html',
})
export class AddAttributeValueComponent implements OnInit, OnChanges{
  @Input() attribute?: IAttribute;
  @Input() listOfAttribute: IAttribute[] = [];
  @Input() storeId!: string | null;
  @Output() loadingEvent = new EventEmitter<boolean>();
  @Output() loadDataEvent = new EventEmitter<boolean>();

  selectedAttribute?: IAttribute;
  form!: FormGroup;
  rule = ATTRIBUTE_VALUE_RULE;
  isLoadingAdd: boolean = false;
  isDuplicate: boolean = false;
  hasNeverSubmitted: boolean = true;
  constructor(private fb: FormBuilder,
    private attributeValueService: AttributeValueService,
    private messageService: TDSMessageService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attribute'] && !changes['attribute'].firstChange) {
      if (TDSHelperString.hasValueString(changes['attribute'])) {
        this.form.controls['attributeId'].setValue(this.attribute?.id);
      }
      if (changes['listOfAttribute'] && !changes['listOfAttribute'].firstChange) {
        this.form.reset();
      }
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.valueChangeName();
  }

  valueChangeName() {
    this.form.get('name')?.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.isDuplicate = false;
      });
  }

  changeAttributes(event: TDSSafeAny) {
    this.form.controls['attributeId'].setValue(event);
    this.isDuplicate = false;
  }

  createForm() {
    this.form = this.fb.group({
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator()
        ])
      ],
      attributeId: [
        null, Validators.required
      ],
    })
  }

  onSave(formDirective: TDSSafeAny) {
    this.hasNeverSubmitted = false;
    this._markDirtyAndTouched();
    if (!this.form.valid) return;
    this.isLoadingAdd = true;
    this.loadingEvent.emit(true);
    const model = this.prepareModel();
    this.attributeValueService.addAttributeValue$(model)
      .pipe(finalize(() => {
        this.isLoadingAdd = false;
        this.loadingEvent.emit(false)
      }))
      .subscribe({
        next: () => {
          this.isDuplicate = false;
          this.messageService.success("Thêm giá trị thuộc tính thành công!");
          this.loadDataEvent.emit(true);
          this.form.reset();
          formDirective.resetForm();
          this.addAttributeIdAfterSubmit();
          this.hasNeverSubmitted = true;
        }
        , error: (resError: TDSSafeAny) => {
          if (resError.error.message == "Tên đã tồn tại") {
            this.isDuplicate = true;
          } else {
            this.messageService.error("Có lỗi khi thêm giá trị thuộc tính")
          }
        }
      })
  }

  prepareModel() {
    const valueForm = this.form.value;
    const model: CreateAttributeValue = {
      attributeId: valueForm.attributeId,
      name: valueForm.name,
      storeId: TDSHelperString.hasValueString(this.storeId) ? this.storeId : ''
    }
    return model;
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
    this.form.controls['attributeId'].markAsDirty();
    this.form.controls['attributeId'].markAsTouched();
  }

  trimName() {
    let trimName = this.form.get('name')?.value?.trim();
    this.form.get('name')?.setValue(trimName);
  }

  addAttributeIdAfterSubmit() {
    if (TDSHelperString.hasValueString(this.attribute)) {
      this.form.controls['attributeId'].setValue(this.attribute?.id);
    }
  }
}
