import { EMPTY_FUNCTION } from 'tds-ui/cdk/contants';
const tdsAssert = {
  enabled: false,
  get assert() {
    return this.enabled ? Function.prototype.bind.call(console.assert, console) : EMPTY_FUNCTION;
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { tdsAssert };
