import { TemplateRef } from '@angular/core';
import { TDSHelperString } from 'tds-ui/shared/utility';
import { isObservable, from, of } from 'rxjs';
function isNotNil(value) {
  return typeof value !== 'undefined' && value !== null;
}
function isNil(value) {
  return typeof value === 'undefined' || value === null;
}
/**
 * Examine if two objects are shallowly equaled.
 */
function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }
  if (typeof objA !== 'object' || !objA || typeof objB !== 'object' || !objB) {
    return false;
  }
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) {
    return false;
  }
  const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
  // tslint:disable-next-line:prefer-for-of
  for (let idx = 0; idx < keysA.length; idx++) {
    const key = keysA[idx];
    if (!bHasOwnProperty(key)) {
      return false;
    }
    if (objA[key] !== objB[key]) {
      return false;
    }
  }
  return true;
}
function isNonEmptyString(value) {
  return typeof value === 'string' && value !== '';
}
function isTemplateRef(value) {
  return value instanceof TemplateRef;
}

/**
 * This module provides utility functions to query DOM information or
 * set properties.
 */
/**
 * Silent an event by stopping and preventing it.
 */
function silentEvent(e) {
  e.stopPropagation();
  e.preventDefault();
}
function getElementOffset(elem) {
  if (!elem.getClientRects().length) {
    return {
      top: 0,
      left: 0
    };
  }
  const rect = elem.getBoundingClientRect();
  const win = elem.ownerDocument.defaultView;
  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset
  };
}
/**
 * Investigate if an event is a `TouchEvent`.
 */
function isTouchEvent(event) {
  return event.type.startsWith('touch');
}
function getEventPosition(event) {
  return isTouchEvent(event) ? event.touches[0] || event.changedTouches[0] : event;
}
function isPromise(obj) {
  return !!obj && typeof obj.then === 'function' && typeof obj.catch === 'function';
}
function isStyleSupport(styleName) {
  if (typeof window !== 'undefined' && window.document && window.document.documentElement) {
    const styleNameList = Array.isArray(styleName) ? styleName : [styleName];
    const {
      documentElement
    } = window.document;
    return styleNameList.some(name => name in documentElement.style);
  }
  return false;
}
function getStyleAsText(styles) {
  if (!styles) {
    return '';
  }
  return Object.keys(styles).map(key => {
    const val = styles[key];
    return `${key}:${typeof val === 'string' ? val : val + 'px'}`;
  }).join(';');
}
function scrollIntoView(node) {
  const nodeAsAny = node;
  if (nodeAsAny.scrollIntoViewIfNeeded) {
    /* eslint-disable-next-line @typescript-eslint/dot-notation */
    nodeAsAny.scrollIntoViewIfNeeded(false);
    return;
  }
  if (node.scrollIntoView) {
    node.scrollIntoView(false);
    return;
  }
}

// We only handle element & text node.
const ELEMENT_NODE = 1;
const TEXT_NODE = 3;
const COMMENT_NODE = 8;
let ellipsisContainer;
const wrapperStyle = {
  padding: '0',
  margin: '0',
  display: 'inline',
  lineHeight: 'inherit'
};
function pxToNumber(value) {
  if (!value) {
    return 0;
  }
  const match = value.match(/^\d*(\.\d*)?/);
  return match ? Number(match[0]) : 0;
}
function styleToString(style) {
  // There are some different behavior between Firefox & Chrome.
  // We have to handle this ourself.
  const styleNames = Array.prototype.slice.apply(style);
  return styleNames.map(name => `${name}: ${style.getPropertyValue(name)};`).join('');
}
function mergeChildren(children) {
  const childList = [];
  children.forEach(child => {
    const prevChild = childList[childList.length - 1];
    if (prevChild && child.nodeType === TEXT_NODE && prevChild.nodeType === TEXT_NODE) {
      prevChild.data += child.data;
    } else {
      childList.push(child);
    }
  });
  return childList;
}
function measure(originEle, rows, contentNodes, fixedContent, ellipsisStr, suffixStr = '') {
  if (!ellipsisContainer) {
    ellipsisContainer = document.createElement('div');
    ellipsisContainer.setAttribute('aria-hidden', 'true');
    document.body.appendChild(ellipsisContainer);
  }
  // Get origin style
  const originStyle = window.getComputedStyle(originEle);
  const originCSS = styleToString(originStyle);
  const lineHeight = pxToNumber(originStyle.lineHeight);
  const maxHeight = Math.round(lineHeight * (rows + 1) + pxToNumber(originStyle.paddingTop) + pxToNumber(originStyle.paddingBottom));
  // Set shadow
  ellipsisContainer.setAttribute('style', originCSS);
  ellipsisContainer.style.position = 'fixed';
  ellipsisContainer.style.left = '0';
  ellipsisContainer.style.height = 'auto';
  ellipsisContainer.style.minHeight = 'auto';
  ellipsisContainer.style.maxHeight = 'auto';
  ellipsisContainer.style.top = '-999999px';
  ellipsisContainer.style.zIndex = '-1000';
  // clean up css overflow
  ellipsisContainer.style.textOverflow = 'clip';
  ellipsisContainer.style.whiteSpace = 'normal';
  ellipsisContainer.style.webkitLineClamp = 'none';
  const contentList = mergeChildren(contentNodes);
  const container = document.createElement('div');
  const contentContainer = document.createElement('span');
  const suffixContainer = document.createTextNode(suffixStr);
  const fixedContainer = document.createElement('span');
  // Add styles in container
  Object.assign(container.style, wrapperStyle);
  Object.assign(contentContainer.style, wrapperStyle);
  Object.assign(fixedContainer.style, wrapperStyle);
  contentList.forEach(n => {
    contentContainer.appendChild(n);
  });
  contentContainer.appendChild(suffixContainer);
  fixedContent.forEach(node => {
    fixedContainer.appendChild(node.cloneNode(true));
  });
  container.appendChild(contentContainer);
  container.appendChild(fixedContainer);
  // Render in the fake container
  ellipsisContainer.appendChild(container);
  // Check if ellipsis in measure div is height enough for content
  function inRange() {
    return ellipsisContainer.offsetHeight < maxHeight;
  }
  if (inRange()) {
    const text = ellipsisContainer.innerHTML;
    ellipsisContainer.removeChild(container);
    return {
      contentNodes,
      text,
      ellipsis: false
    };
  }
  // We should clone the childNode since they're controlled by React and we can't reuse it without warning
  const childNodes = Array.prototype.slice.apply(ellipsisContainer.childNodes[0].childNodes[0].cloneNode(true).childNodes).filter(({
    nodeType
  }) => nodeType !== COMMENT_NODE);
  const fixedNodes = Array.prototype.slice.apply(ellipsisContainer.childNodes[0].childNodes[1].cloneNode(true).childNodes);
  ellipsisContainer.removeChild(container);
  // ========================= Find match ellipsis content =========================
  ellipsisContainer.innerHTML = '';
  // Create origin content holder
  const ellipsisContentHolder = document.createElement('span');
  ellipsisContainer.appendChild(ellipsisContentHolder);
  const ellipsisTextNode = document.createTextNode(ellipsisStr + suffixStr);
  ellipsisContentHolder.appendChild(ellipsisTextNode);
  fixedNodes.forEach(childNode => {
    ellipsisContainer.appendChild(childNode);
  });
  // Append before fixed nodes
  function appendChildNode(node) {
    ellipsisContentHolder.insertBefore(node, ellipsisTextNode);
  }
  // Get maximum text
  function measureText(textNode, fullText, startLoc = 0, endLoc = fullText.length, lastSuccessLoc = 0) {
    const midLoc = Math.floor((startLoc + endLoc) / 2);
    textNode.textContent = fullText.slice(0, midLoc);
    if (startLoc >= endLoc - 1) {
      // Loop when step is small
      for (let step = endLoc; step >= startLoc; step -= 1) {
        const currentStepText = fullText.slice(0, step);
        textNode.textContent = currentStepText;
        if (inRange() || !currentStepText) {
          return step === fullText.length ? {
            finished: false,
            node: document.createTextNode(fullText)
          } : {
            finished: true,
            node: document.createTextNode(currentStepText)
          };
        }
      }
    }
    if (inRange()) {
      return measureText(textNode, fullText, midLoc, endLoc, midLoc);
    } else {
      return measureText(textNode, fullText, startLoc, midLoc, lastSuccessLoc);
    }
  }
  function measureNode(childNode, index) {
    const type = childNode.nodeType;
    if (type === ELEMENT_NODE) {
      // We don't split element, it will keep if whole element can be displayed.
      // appendChildNode(childNode);
      if (inRange()) {
        return {
          finished: false,
          node: contentList[index]
        };
      }
      // Clean up if can not pull in
      ellipsisContentHolder.removeChild(childNode);
      return {
        finished: true,
        node: null
      };
    } else if (type === TEXT_NODE) {
      const fullText = childNode.textContent || '';
      const textNode = document.createTextNode(fullText);
      appendChildNode(textNode);
      return measureText(textNode, fullText);
    }
    // Not handle other type of content
    // PS: This code should not be attached after react 16
    return {
      finished: false,
      node: null
    };
  }
  const ellipsisNodes = [];
  childNodes.some((childNode, index) => {
    const {
      finished,
      node
    } = measureNode(childNode, index);
    if (node) {
      ellipsisNodes.push(node);
    }
    return finished;
  });
  const result = {
    contentNodes: ellipsisNodes,
    text: ellipsisContainer.innerHTML,
    ellipsis: true
  };
  while (ellipsisContainer.firstChild) {
    ellipsisContainer.removeChild(ellipsisContainer.firstChild);
  }
  return result;
}
function toArray(value) {
  let ret;
  if (value == null) {
    ret = [];
  } else if (!Array.isArray(value)) {
    ret = [value];
  } else {
    ret = value;
  }
  return ret;
}
function arraysEqual(array1, array2) {
  if (!array1 || !array2 || array1.length !== array2.length) {
    return false;
  }
  const len = array1.length;
  for (let i = 0; i < len; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
}
function shallowCopyArray(source) {
  return source.slice();
}
let scrollbarVerticalSize;
let scrollbarHorizontalSize;
// Measure scrollbar width for padding body during modal show/hide
const scrollbarMeasure = {
  position: 'absolute',
  top: '-9999px',
  width: '50px',
  height: '50px'
};
function measureScrollbar(direction = 'vertical', prefix = 'tds') {
  if (typeof document === 'undefined' || typeof window === 'undefined') {
    return 0;
  }
  const isVertical = direction === 'vertical';
  if (isVertical && scrollbarVerticalSize) {
    return scrollbarVerticalSize;
  } else if (!isVertical && scrollbarHorizontalSize) {
    return scrollbarHorizontalSize;
  }
  const scrollDiv = document.createElement('div');
  Object.keys(scrollbarMeasure).forEach(scrollProp => {
    // @ts-ignore
    scrollDiv.style[scrollProp] = scrollbarMeasure[scrollProp];
  });
  // apply hide scrollbar className ahead
  scrollDiv.className = `${prefix}-hide-scrollbar scroll-div-append-to-body tds-custom-scroll`;
  // Append related overflow style
  if (isVertical) {
    scrollDiv.style.overflowY = 'scroll';
  } else {
    scrollDiv.style.overflowX = 'scroll';
  }
  document.body.appendChild(scrollDiv);
  let size = 0;
  if (isVertical) {
    size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    scrollbarVerticalSize = size;
  } else {
    size = scrollDiv.offsetHeight - scrollDiv.clientHeight;
    scrollbarHorizontalSize = size;
  }
  document.body.removeChild(scrollDiv);
  return size;
}
function getPercent(min, max, value) {
  return (value - min) / (max - min) * 100;
}
function getPrecision(num) {
  const numStr = num.toString();
  const dotIndex = numStr.indexOf('.');
  return dotIndex >= 0 ? numStr.length - dotIndex - 1 : 0;
}
function ensureNumberInRange(num, min, max) {
  if (isNaN(num) || num < min) {
    return min;
  } else if (num > max) {
    return max;
  } else {
    return num;
  }
}
function isNumberFinite(value) {
  return typeof value === 'number' && isFinite(value);
}
function toDecimal(value, decimal) {
  return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}
function sum(input, initial = 0) {
  return input.reduce((previous, current) => previous + current, initial);
}
function formatNumberWithCommas(value, commas = ',') {
  if (value != null) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, commas);
  }
  return value;
}
;
function parserNumberWithCommas(value, commas = ',') {
  if (value != null) {
    return TDSHelperString.replaceAll(value, commas, '');
  }
  return value;
}
;
function wrapIntoObservable(value) {
  if (isObservable(value)) {
    return value;
  }
  if (isPromise(value)) {
    // Use `Promise.resolve()` to wrap promise-like instances.
    return from(Promise.resolve(value));
  }
  return of(value);
}
class KeyboardUtil {
  static ifBackspaceOrDelete(event) {
    return this.ifKey(event, 'Backspace;Delete;Del');
  }
  static ifRightArrow(event) {
    return this.ifKey(event, 'ArrowRight;Right');
  }
  static ifLeftArrow(event) {
    return this.ifKey(event, 'ArrowLeft;Left');
  }
  static ifSpacebar(event) {
    return this.ifKey(event, 'Spacebar; '); //don't remove the space after ; as this will check for space key
  }
  static ifKey(event, keys) {
    let keysToCheck = keys.split(';');
    return keysToCheck.some(k => k === event.key);
  }
}

/**
 * Much like lodash.
 */
function padStart(toPad, length, element) {
  if (toPad.length > length) {
    return toPad;
  }
  const joined = `${getRepeatedElement(length, element)}${toPad}`;
  return joined.slice(joined.length - length, joined.length);
}
function padEnd(toPad, length, element) {
  const joined = `${toPad}${getRepeatedElement(length, element)}`;
  return joined.slice(0, length);
}
function getRepeatedElement(length, element) {
  return Array(length).fill(element).join('');
}
const isChanged = (propertyName, changes, skipFirstChange = true) => typeof changes[propertyName] !== 'undefined' && (!changes[propertyName].isFirstChange() || !skipFirstChange) && changes[propertyName].previousValue !== changes[propertyName].currentValue;
const anyChanged = (propertyNames, changes, skipFirstChange = true) => propertyNames.some(name => isChanged(name, changes, skipFirstChange));

/**
 * Generated bundle index. Do not edit.
 */

export { KeyboardUtil, anyChanged, arraysEqual, ensureNumberInRange, formatNumberWithCommas, getElementOffset, getEventPosition, getPercent, getPrecision, getRepeatedElement, getStyleAsText, isChanged, isNil, isNonEmptyString, isNotNil, isNumberFinite, isPromise, isStyleSupport, isTemplateRef, isTouchEvent, measure, measureScrollbar, padEnd, padStart, parserNumberWithCommas, pxToNumber, scrollIntoView, shallowCopyArray, shallowEqual, silentEvent, sum, toArray, toDecimal, wrapIntoObservable };
