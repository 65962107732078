import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '../../../stores/models/store.model';
import { takeUntil } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { TDSTagModule } from 'tds-ui/tag';
import { StoreBranch } from '../../models/store-branch.model';
import { BranchService } from '../../services/branch.service';
import { CreateBranchComponent } from '../create-branch/create-branch.component';
import { EditBranchComponent } from '../edit-branch/edit-branch.component';
import { AddressPipe } from '../../../../shared/pipes/address.pipe';
import { HeaderService } from '../../../../shared/services/header.service';

@Component({
  selector: 'app-branch-list',
  standalone: true,
  imports: [
    CommonModule,
    TDSButtonModule,
    TDSTagModule,
    TDSModalModule,
    AddressPipe
  ],
  providers: [
    TDSModalService,
    BranchService,
    TDSDestroyService,
    TDSMessageService,
  ],
  templateUrl: './branch-list.component.html',
  styleUrl: './branch-list.component.scss'
})
export class BranchListComponent implements OnChanges {
  @Input() store!: Store;
  @Output() onModalClose = new EventEmitter<any>();
  storeBranches!: StoreBranch[];

  constructor(
    private readonly modalService: TDSModalService,
    private readonly branchService: BranchService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly msgService: TDSMessageService,
    private readonly headerService: HeaderService
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['store'] && changes['store'].currentValue) {
      this.storeBranches = this.store.storeBranchList;
    }
  }

  onAddBranch(): void {
    const modal = this.modalService.create({
      title: 'Tạo cửa hàng',
      content: CreateBranchComponent,
      componentParams: {
        store: this.store
      },
      size: 'lg',
      bodyStyle: {
        'padding': '0px'
      },
      footer: null
    });

    modal.afterClose.subscribe((result: any) => {
      if (result) {
        this.headerService.triggerReloadHeader();
        this.onModalClose.emit(result);
      }
    });
  }
  /**
   * Edit branch
   * @param branch 
   */
  onEditBranch(branch: StoreBranch): void {
    const modal = this.modalService.create({
      title: 'Chỉnh sửa cửa hàng',
      content: EditBranchComponent,
      componentParams: {
        branch: branch
      },
      size: 'lg',
      bodyStyle: {
        'padding': '0px'
      },
      footer: null
    });

    modal.afterClose.subscribe((result: any) => {
      if (result) {
        this.headerService.triggerReloadHeader();
        this.onModalClose.emit(result);
      }
    });
  }
  /**
   * Active/de-active branch
   * @param branch
   */
  setActive(branch: StoreBranch): void {
    if (branch.isActive) {
      this.deActiveBranch(branch);
    } else {
      this.activeBranch(branch);
    }
  }

  activeBranch(branch: StoreBranch): void {
    const modal = this.modalService.info({
      title: `Kích hoạt cửa hàng`,
      content: `Xác nhận kích hoạt cửa hàng <span class="tds-body-2 font-semibold">${branch.name}</span>?`,
      onOk: () => { this.onSetActiveBranch(branch)},
      onCancel:()=>{ modal.close()},
      okText: "Đồng ý",
      cancelText: "Đóng",
      autoClose: false
    });
  }

  deActiveBranch(branch: StoreBranch): void {
    const modal = this.modalService.error({
      title: `Hủy kích hoạt cửa hàng`,
      content: `Xác nhận hủy kích hoạt cửa hàng <span class="tds-body-2 font-semibold">${branch.name}</span>?`,
      onOk: () => { this.onSetActiveBranch(branch)},
      onCancel:()=>{ modal.close()},
      okText: "Đồng ý",
      cancelText: "Đóng",
      autoClose: false
    });
  }

  onSetActiveBranch(branch: StoreBranch): void {
    this.branchService.setActiveBranch$(branch.id)
      .pipe(
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res: StoreBranch) => {
          this.msgService.success(res.isActive ? 'Kích hoạt cửa hàng thành công' : 'Hủy kích hoạt thành công');
          this.onModalClose.emit(res);
        },
        error: (err) => {
          this.msgService.error(branch.isActive ? 'Hủy kích hoạt cửa hàng thất bại' : 'Kích hoạt cửa hàng thất bại');
        },
      })
  }
}
