import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSInputModule } from 'tds-ui/tds-input';
import { ATTRIBUTE_VALUE_RULE } from '../../constants/attribute-value-rule.constant';
import { IAttributeValue, UpdateAttributeValue } from '../../models/attribute-value.model';
import { AttributeValueService } from '../../service/attribute-value.service';

@Component({
  selector: 'app-edit-attribute-value',
  standalone: true,
  imports: [
    TDSButtonModule,
    TDSModalModule,
    TDSFormFieldModule,
    TDSInputModule,
    ReactiveFormsModule,
    TDSSpinnerModule
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
  templateUrl: './edit-attribute-value.component.html',
})
export class EditAttributeValueComponent implements OnInit {
  @Input() attributeValue!: IAttributeValue;
  @Input() storeId: string | null | undefined;
  isLoading: boolean = false;
  form!: FormGroup;
  rule = ATTRIBUTE_VALUE_RULE;
  isDuplicate: boolean = false;

  constructor(private modal: TDSModalRef,
    private messageService: TDSMessageService,
    private attributeValueService: AttributeValueService,
    private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.valueChangeName();
  }

  valueChangeName() {
    this.form.get('name')?.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.isDuplicate = false;
      });
  }

  createForm() {
    this.form = this.fb.group({
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator(),
        ])
      ],
      attributeId: [
        null, Validators.required
      ],
    })
  }

  updateForm() {
    this.form.get('name')?.setValue(this.attributeValue.name);
    this.form.get('attributeId')?.setValue(this.attributeValue.attributeId);
  }

  onSave() {
    this.trimName();
    this._markDirtyAndTouched();
    if (!this.form.valid) return;
    this.isLoading = true;
    const model = this.prepareModel();
    this.attributeValueService.updateAttributeValue$(this.attributeValue.id, model)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: () => {
          this.isDuplicate = false;
          this.messageService.success("Cập nhật giá trị thuộc tính thành công");
          this.modal.destroy(true);
        }, error: (resError: TDSSafeAny) => {
          if (resError.error.message == "Tên đã tồn tại") {
            this.isDuplicate = true;
          } else {
            this.messageService.error("Có lỗi khi cập nhật giá trị thuộc tính")
          }
        }
      })
  }

  onCancel() {
    this.modal.destroy(false);
  }

  prepareModel() {
    const valueForm = this.form.value;
    const model: UpdateAttributeValue = {
      name: valueForm.name,
      storeId: this.storeId ?? '',
      attributeId: valueForm.attributeId
    };
    return model;
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
    this.form.controls['attributeId'].markAsDirty();
    this.form.controls['attributeId'].markAsTouched();
  }

  trimName() {
    let trimName = this.form.get('name')?.value.trim();
    this.form.get('name')?.setValue(trimName);
  }
}
