<div class="flex items-center gap-2">
  <!-- Show filter info if there are active filters -->
  <div class="flex items-center py-1.5">
    <div class="flex flex-row items-center gap-2">
      @if (isOwner) {
        <tds-select class="w-[300px]"
          placeholder="Chọn cửa hàng"
          [data]="branches"
          [valuePrimitive]="false"
          valueField="id"
          textField="name"
          [allowClear]="true"
          [(ngModel)]="currentBranchId"
          (selectChange)="onSelectBranch($event)"
        >
        </tds-select>
      }

      <tds-form-field class="flex-1">
        <input tdsInput
          #searchInput 
          class="text-neutral-1-900 min-w-[320px]"
          placeholder="Tìm theo tên vai trò, thành viên"
          autocomplete="off"
          [(ngModel)]="searchValue"
        />
        <span tdsPrefix><em class="text-neutral-1-500 tdsi-search-fill text-xl leading-none"></em></span>
      </tds-form-field>

      <button tds-button-outline-icon
        color="secondary"
        tds-popover
        popoverTrigger="click"
        popoverPlacement="bottomRight"
        [popoverContent]="filterTmp"
        [popoverOverlayClassName]="'custom-padding-popover'"
        [(popoverVisible)]="isVisible"
        size="md"
        class="relative"
      >
        <span class="flex items-center">
          <em class="tdsi-filter-1-fill text-lg text-neutral-1-500 leading-none"></em>
        </span>

        @if (isApplyingFilter) {
          <span class="absolute top-[-11px] right-[-6px]">
            <tds-badge
              [standalone]="true"
              [count]="getFilterCount()"
            >
              <a class="w-10 h-10 rounded tds-color-background-success-subtle"></a>
            </tds-badge>
          </span>
        }
      </button>
    </div>
  </div>
</div>

<ng-template #filterTmp>
  <div class="w-[400px] p-3 flex flex-col gap-4">
    <div class="w-full flex flex-col gap-3">
      <tds-label>TRẠNG THÁI</tds-label>
      <div class="flex items-center gap-2">
        @for (item of lstStatus; track item.id) {
          <tds-tag
            mode="checkable"
            [checked]="item.checked"
            (checkedChange)="onCheckedItem($event, item)"
          >
            {{item.name}}
          </tds-tag>
        }
      </div>
    </div>

    @if (isOwner) {
      <div class="w-full flex flex-col gap-3">
        <tds-label>DOANH NGHIỆP</tds-label>
        <tds-form-field>
          <tds-select
            [size]="'md'"
            valueField="id"
            textField="name"
            placeholder="Chọn doanh nghiệp"
            [valuePrimitive]="false"
            [allowClear]="true"
            [data]="stores"
            [(ngModel)]="currentStore"
            (selectChange)="onChangeStore($event)"
          >
          </tds-select>
        </tds-form-field>
      </div>
    }

    <div class="w-full flex flex-col gap-3">
      <tds-label>NGÀY TẠO</tds-label>
      <tds-form-field class="w-full">
        <tds-range-picker
          [disabledDate]="disabledDate"
          [(ngModel)]="rangeDate"
        >
        </tds-range-picker>
    </tds-form-field>
    </div>
  </div>

  <div class="w-full flex items-center justify-between px-3 py-2 border-t">
    <button
      tds-button-flat
      color="error"
      size="sm"
      [tdsPrefixIcon]="'tdsi-clear-filter-fill'"
      (tdsBtnClick)="deleteFilter()"
    >
      Hủy lọc
    </button>
    <div class="flex flex-row gap-2">
      <button 
        type="button"
        tds-button-outline
        color="secondary"
        size="sm"
        class="w-[80px]"
        (click)="onCancel()"
      >
        Hủy bỏ
      </button>
      <button 
        type="button"
        tds-button
        color="primary"
        class="w-[80px]"
        size="sm"
        (click)="onApply()"
      >
        Áp dụng
      </button>
    </div>
  </div>
</ng-template>