<div class="flex flex-col gap-6 h-full pt-16 px-[70px] w-full min-h-[852px] max-h-[920px]">
  <section class="flex justify-center">
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.6329 31.5826H38.2156V24.9565C38.2156 22.5226 36.2321 20.5392 33.7982 20.5392C31.3642 20.5392 29.3808 22.5226 29.3808 24.9565V31.5826H24.9634V24.9565C24.9634 20.0842 28.9258 16.1218 33.7982 16.1218C38.6705 16.1218 42.6329 20.0842 42.6329 24.9565V31.5826Z" fill="#CCD2D4" />
      <path d="M67.6158 22.5757L65.7712 27.4435C61.9811 13.3123 49.1044 2.86954 33.7982 2.86954C15.5278 2.86954 0.667725 17.7296 0.667725 36C0.667725 54.2703 15.5278 69.1304 33.7982 69.1304C42.6462 69.1304 50.9686 65.6849 57.228 59.4298L54.1049 56.3067C48.6759 61.7269 41.4668 64.713 33.7982 64.713C17.9662 64.713 5.08512 51.8319 5.08512 36C5.08512 20.168 17.9662 7.28693 33.7982 7.28693C47.5848 7.28693 59.1275 17.0582 61.8795 30.0365L56.4735 25.1468L53.3971 28.4122L65.7712 39.4107L71.8969 24.2566L67.6158 22.5757Z" fill="#CCD2D4" />
      <path d="M45.001 29.2499H22.5005C21.2585 29.2499 20.2505 30.2579 20.2505 31.4999V49.5003C20.2505 50.7423 21.2585 51.7503 22.5005 51.7503H45.001C46.243 51.7503 47.251 50.7423 47.251 49.5003V31.4999C47.251 30.2579 46.243 29.2499 45.001 29.2499Z" fill="currentColor" class="tds-color-text-brand-primary" />
    </svg>
  </section>
  <form [formGroup]="form" (ngSubmit)="onComplete()" class="w-full flex flex-col gap-6">
    <section class="flex flex-col gap-4 justify-center items-center">
      <h3 class="tds-heading-4 tds-color-text-primary font-semibold">Tạo mật khẩu để đăng nhập</h3>
      <p class="tds-body-1 text-center px-1 tds-color-text-tertiary">
        <span class="font-semibold">Lưu ý:</span> Mật khẩu bao gồm 8 đến 16 ký tự, có phân biệt in hoa, thường và không chứa khoảng trắng.
      </p>
    </section>
    <section class="w-full flex flex-col gap-4">
      <div>
        <tds-label [tdsRequired]="true">Họ và tên</tds-label>
        <tds-form-field class="w-full pt-2">
          <input 
            tdsInput
            size="xl"
            autocomplete="off"
            placeholder="Nhập họ và tên"
            formControlName="fullName"
            [maxlength]="100"
            (blur)="onBlur()"
          />
          @if(fullName.errors?.['required']) {
            <tds-error>Vui lòng nhập họ và tên</tds-error>
          }
        </tds-form-field>
      </div>
      <div>
        <tds-label>Email</tds-label>
        <tds-form-field class="w-full pt-2">
          <input 
            tdsInput
            size="xl"
            autocomplete="off"
            placeholder="Nhập email"
            formControlName="email"
          />
          @if (email.errors?.['inValidEmail']) {
            <tds-error>Email không hợp lệ</tds-error>
          } @else if (email.errors?.['existEmail']) {
            <tds-error>Email đã tồn tại</tds-error>
          }
        </tds-form-field>
      </div>
      <div>
        <tds-label [tdsRequired]="true">Mật khẩu</tds-label>
        <div class="w-full flex flex-col">
          <tds-form-field class="w-full pt-2">
            <input 
              tdsInput
              size="xl"
              autocomplete="off"
              [type]="passwordFieldType"
              placeholder="Nhập mật khẩu"
              formControlName="password"
              (focus)="onFocus()"
              (blur)="showPasswordHint = false"
            />
            <span onkeyup tdsSuffix (click)="togglePasswordVisibility()">
              <i [ngClass]="isPasswordVisible ? 'tdsi-eye-fill' : 'tdsi-eye-off-fill'" class="cursor-pointer"></i>
            </span>
          </tds-form-field>
          @if (showPasswordHint && password.errors
            && !password.errors['hasWhitespace'] && !password.errors['hasVietnamese']) {
            <div class="flex items-center tds-caption-1 tds-color-text-secondary w-full mt-1">
              <i class="tdsi-radio-circle-fill text-sm leading-none mr-1.5"></i>
              Mật khẩu bao gồm 8-16 ký tự
            </div>
          } @else if (showPasswordHint && password.valid) {
            <div class="flex items-center tds-caption-1 tds-color-text-success w-full mt-1">
              <i class="tdsi-check-fill text-sm leading-none mr-1.5"></i>
              Mật khẩu bao gồm 8-16 ký tự
            </div>
          } @else if (password.touched && password.errors?.['pattern'] 
            && !password.errors?.['hasWhitespace'] && !password.errors?.['hasVietnamese']) {
            <tds-error>Mật khẩu bao gồm 8-16 ký tự</tds-error>
          } @else if (password.errors?.['hasWhitespace'] || password.errors?.['hasVietnamese']) {
            <tds-error>Mật khẩu không được chứa khoảng trắng hoặc ký tự có dấu</tds-error>
          } @else if (password.errors?.['required'] && (password.touched || password.dirty)) {
            <tds-error>Vui lòng nhập mật khẩu</tds-error>
          }
        </div>
      </div>
      <div>
        <tds-label [tdsRequired]="true">Xác nhận mật khẩu</tds-label>
        <tds-form-field class="w-full pt-2">
          <input 
            tdsInput
            size="xl"
            autocomplete="off"
            [type]="confirmPasswordFieldType"
            placeholder="Nhập lại mật khẩu"
            formControlName="confirmPassword"
          />
          @if(confirmPassword.errors?.['mismatch'] && confirmPassword.value?.length) {
            <tds-error>Mật khẩu xác nhận không trùng khớp với mật khẩu</tds-error>
          } @else if (confirmPassword.errors?.['required'] && !confirmPassword.value) {
            <tds-error>Vui lòng xác nhận mật khẩu</tds-error>
          }
          <span onkeyup tdsSuffix (click)="toggleConfirmPasswordVisibility()">
            <i [ngClass]="isConfirmPasswordVisible ? 'tdsi-eye-fill' : 'tdsi-eye-off-fill'" class="cursor-pointer"></i>
          </span>
        </tds-form-field>
      </div>
    </section>
    <section class="flex flex-col gap-2">
      <button tds-button color="primary" type="submit" size="xl">
        Hoàn tất
      </button>
      <button tds-button-outline color="secondary" type="button" (tdsBtnClick)="onBack()" size="xl">
        Về trang đăng ký
      </button>
    </section>
  </form>
</div>