<tds-spin [spinning]="isLoadingAdd" class="w-full h-full">
    <div class="flex flex-col gap-3 w-full">
        <div class="tds-heading-6 font-semibold">Thêm mới giá trị thuộc tính</div>
        <form [formGroup]="form" id="form" #formDirective="ngForm">
            <div class="flex gap-2 w-full">
                <tds-form-field class="w-[545px]">
                    <tds-select 
                        valueField="id" 
                        textField="name" 
                        placeholder='Chọn thuộc tính' 
                        disabledField="isActive"
                        [data]="listOfAttribute"
                        formControlName="attributeId"
                        (selectChange)="changeAttributes($event)"
                        [required]='true' 
                        (blur)="trimName()"
                        [allowClear]="true">
                    </tds-select>
                    @if (this.form.get('attributeId')?.errors?.['required'] && !hasNeverSubmitted) {
                        <tds-error>Vui lòng chọn thuộc tính</tds-error>
                    }
                </tds-form-field>
                
                <div class="flex flex-col items-start">
                    <tds-form-field class="w-[545px]">
                        <input tdsInput 
                            autocomplete="off" 
                            formControlName="name"
                            [maxlength]="rule.name.maxLength"
                            placeholder="Nhập giá trị thuộc tính" 
                            (blur)="trimName()"
                            [required]='true' />
                        @if ((this.form.get('name')?.errors?.['required'] || this.form.get('name')?.errors?.['whitespace']) && !hasNeverSubmitted) {
                            <tds-error>Vui lòng nhập tên giá trị thuộc tính</tds-error>
                        }
                    </tds-form-field>
                    @if (isDuplicate && !hasNeverSubmitted) {
                        <tds-error>Giá trị thuộc tính đã tồn tại</tds-error>
                    }
                </div>
                <button tds-button 
                    color="primary" 
                    class="w-[100px]"
                    (click)="onSave(formDirective)"
                    >Thêm
                </button>
            </div>
        </form>
    </div>
</tds-spin>
   
