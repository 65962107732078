import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSButtonMenuModule } from 'tds-ui/button-menu';
import { TDSMapperPipeModule } from 'tds-ui/cdk/pipes/mapper';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSInputNumberModule } from 'tds-ui/input-number';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';

import { AuthService } from 'app/core/services/auth.service';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { AvatarComponent } from 'app/shared/components/avatar/avatar.component';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { Paging } from 'app/shared/models/paging.model';
import { Observable, map, of, switchMap, timer } from 'rxjs';
import { TDSMessageModule, TDSMessageService } from 'tds-ui/message';
import { TDSPopoverModule } from 'tds-ui/popover';
import { TDSScrollIntoViewModule } from "tds-ui/scroll-into-view";
import { TDSSelectModule } from 'tds-ui/select';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSHelperString, TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSTableModule } from 'tds-ui/table';
import { TDSInputModule } from 'tds-ui/tds-input';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { ATTRIBUTE_RULE } from '../../constants/attribute-rule.constant';
import { AttributeParam, IAttribute, IAttributeList, UpdateAttribute } from '../../models/attribute.model';
import { AttributeService } from '../../service/attribute.service';
import { AttributePanelComponent } from '../attribute-panel/attribute-panel.component';
import { ListAttributeValueComponent } from '../list-attribute-value/list-attribute-value.component';

@Component({
  selector: 'app-list-attribute',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TDSSelectModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSButtonModule,
    TDSButtonMenuModule,
    TDSInputNumberModule,
    TDSTableModule,
    DragDropModule,
    TDSSpinnerModule,
    TDSModalModule,
    TDSMapperPipeModule,
    TDSToolTipModule,
    TDSScrollIntoViewModule,
    AttributePanelComponent,
    TDSMessageModule,
    AvatarComponent,
    TDSPopoverModule,
    ListAttributeValueComponent
  ],
  templateUrl: './list-attribute.component.html',
  styleUrl: './list-attribute.component.scss',
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
})
export class ListAttributeComponent implements OnInit {
  isLoading: boolean = false;
  paging: Paging = {
    size: 20,
    index: 1,
    total: 0,
  };
  searchText: string = '';
  storeId!: string | null;
  attributeIndex: number = -1;
  listOfAttribute: IAttribute[] = [];
  selectedAttributeDetail !: IAttribute;
  form!: FormGroup;
  rule = ATTRIBUTE_RULE;
  editAttributeIndex: number = -1;
  selectedEditAttribute !: IAttribute;
  sortDateCreated: string = 'DESC';
  selectedAttribute!: IAttribute | undefined;
  isChoosenAllAttribute: boolean = true;
  constructor(private attributeService: AttributeService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modalService: TDSModalService,
    private messageService: TDSMessageService,
    private authService: AuthService,) {
  }

  ngOnInit(): void {
    this.getCurrentStore();
    this.loadAttributes();
    this.createForm();
  }

  getCurrentStore() {
    this.storeId = this.authService.getCurrentStoreId();
  }

  loadAttributes() {
    const model = this.prepareParam();
    this._enableLoading();
    this.attributeService.getAttributes$(
      (model.skip - 1) * model.take,
      model.take,
      model.filter,
      model.storeId,
      model.sort
    ).subscribe({
      next: (res: IAttributeList) => {
        this.listOfAttribute = res.items;
        this.changeSelectedAttribute(!this.selectedAttribute, this.selectedAttribute);
      }, error: () => this.messageService.error("Không lấy được danh sách thuộc tính")
      , complete: () => this._disableLoading()
    })
  }

  prepareParam() {
    const model: AttributeParam = {
      skip: this.paging.index,
      take: this.paging.size,
      filter: this.searchText,
      storeId: TDSHelperString.hasValueString(this.storeId) ? this.storeId : null,
      sort: this.sortDateCreated
    };
    return model;
  }

  private _enableLoading() {
    this.isLoading = true;
  }

  private _disableLoading() {
    this.isLoading = false;
  }

  enableAttributeDetail(item: IAttribute, index: number) {
    this.attributeIndex = index;
    this.selectedAttributeDetail = item;
  }

  removeAttribute(item: IAttribute, event: TDSSafeAny) {
    event.preventDefault();
    event.stopPropagation();
    this.modalService.error({
      title: 'Xóa thuộc tính',
      confirmIcon: 'tdsi-trash-fill',
      content: `Bạn có chắc chắn muốn xóa thuộc tính "<strong>${item.name}</strong>” ?`,
      onOk: () => this.callApiRemoveAttribute(item),
      okText: "Xác nhận",
      cancelText: "Đóng"
    });
  }

  callApiRemoveAttribute(item: IAttribute) {
    this.attributeService.removeAttribute$(item.id)
      .subscribe({
        next: () => {
          this.messageService.success("Đã xóa thành công!");
          this.loadAttributes();
          if(this.selectedAttribute?.id == item.id) this.selectedAttribute = undefined;
        }, error: () => this.messageService.error("Có lỗi khi xóa, vui lòng thử lại!")
      })
  }

  closeEdit(event: TDSSafeAny) {
    event.preventDefault();
    event.stopPropagation();
    this.editAttributeIndex = -1;
  }

  editAttribute(item: IAttribute, index: number, event: TDSSafeAny) {
    event.preventDefault();
    event.stopPropagation();
    this.updateForm(item);
    this.editAttributeIndex = index;
  }

  createForm() {
    this.form = this.fb.group({
      id: [null, Validators.required],
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator(),
        ]), this.checkExistName(this.attributeService)
      ]
    })
  }

  updateForm(item: IAttribute) {
    this.selectedEditAttribute = item;
    this.form.get('name')?.setValue(item.name);
    this.form.get('id')?.setValue(item.id);
  }

  saveEditAttribute() {
    this._markDirtyAndTouched();
    if (!this.form.valid) return;
    this.isLoading = true;
    const model = this.prepareModel();
    this.attributeService.updateAttribute$(this.form.value.id, model)
      .subscribe({
        next: (res: TDSSafeAny) => {
          this.messageService.success("Cập nhật thuộc tính thành công!");
          this.editAttributeIndex = -1;
          this.loadAttributes();
        }, error: () => this.messageService.error("Có lỗi khi cập nhật thuộc tính!")
        , complete: () => this.isLoading = false
      })
  }

  prepareModel(): UpdateAttribute {
    const valueForm = this.form.value;
    const model: UpdateAttribute = {
      name: valueForm.name,
      storeId: this.storeId ?? ''
    };
    return model;
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
  }

  checkExistName = (service: AttributeService) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        this.isLoading = false;
        return of(null)
      }

      if(this.selectedEditAttribute.name.toLowerCase() == control.value.toLowerCase()){
        return of({ duplicate: true });
      }

      // this.isLoading = true;
      return timer(500).pipe(
        switchMap(() =>
          service.checkExistAttribute$({
            name: control.value,
            storeId: this.storeId ?? ''
          })
            .pipe(
              map((res: TDSSafeAny) => {
                // this.isLoading = false;
                if (res) {
                  return { duplicate: true };
                } else {
                  return null;
                }
              })
            )
        )
      )
    }
  }

  sortDateCreatedEvent() {
    this.sortDateCreated = this.sortDateCreated === 'DESC' ? 'ASC' : 'DESC';
    this.loadAttributes();
    this.attributeIndex = -1;
  }

  changeSelectedAttribute(isAll: boolean = true, item?: IAttribute) {
    this.isChoosenAllAttribute = isAll;

    if (item && !isAll) {
      this.listOfAttribute.forEach(attr => attr.isChoosen = attr.id === item.id);
      this.selectedAttribute = item;
    } else {
      this.listOfAttribute.forEach(attr => attr.isChoosen = false);
      this.selectedAttribute = undefined;
    }
    
    this.cdr.detectChanges();
  }

  loadingAttributeEvent(event: boolean) {
    this.isLoading = event;
  }

  eventAfterAddAttribute(){
    this.attributeIndex = -1;
    this.loadAttributes();
  }

  trimName(){
    let trimName = this.form.get('name')?.value.trim();
    this.form.get('name')?.setValue(trimName);
  }
}
