import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from 'app/core/services/auth.service';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSConfigService } from 'tds-ui/core/config';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSInputModule } from 'tds-ui/tds-input';
import { CATEGORY_RULE } from '../../constants/category-rule.constant';
import { ICategory, UpdateCategory } from '../../models/category.model';
import { CategoryService } from '../../service/category.service';

@Component({
  selector: 'app-edit-category',
  standalone: true,
  imports: [
    CommonModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSModalModule,
    TDSButtonModule,
    ReactiveFormsModule,
    TDSSpinnerModule
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
  templateUrl: './edit-category.component.html',
})
export class EditCategoryComponent implements OnInit {
  @Input() category!: ICategory;
  form!: FormGroup;
  rule = CATEGORY_RULE;
  isLoading: boolean = false;
  storeId: string | null = null;
  isDuplicate: boolean = false;

  constructor(private fb: FormBuilder,
    private messageService: TDSMessageService,
    private authService: AuthService,
    private readonly tdsConfigService: TDSConfigService,
    private modal: TDSModalRef,
    private categoryService: CategoryService,) {

  }

  ngOnInit(): void {
    this.getCurrentStore();
    this.createForm();
    this.updateForm();
    this.configMessage();
    this.valueChangeName();
  }

  valueChangeName() {
    this.form.get('name')?.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.isDuplicate = false;
      });
  }


  configMessage() {
    this.tdsConfigService.set('message', {
      maxStack: 3
    });
  }

  getCurrentStore() {
    this.storeId = this.authService.getCurrentStoreId();
  }

  createForm() {
    this.form = this.fb.group({
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator(),
        ])
      ]
    })
  }

  updateForm() {
    this.form.get('name')?.setValue(this.category.name);
  }

  onSave() {
    this.trimName()  // Had to call because when pressing enter there was no trim value
    this._markDirtyAndTouched();
    if (!this.form.valid) return;
    this.isLoading = true;
    const model = this.prepareModel();
    this.categoryService.updateCategory$(this.category.id, model)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (res: TDSSafeAny) => {
          this.isDuplicate = false;
          this.messageService.success("Cập nhật danh mục thành công!");
          this.modal.destroy(true);
        }, error: (resError: TDSSafeAny) => {
          if (resError.error.message == "Tên đã tồn tại") {
            this.isDuplicate = true;
          } else {
            this.messageService.error("Có lỗi khi cập nhật danh mục!")
          }
        }
      })
  }

  prepareModel(): UpdateCategory {
    const valueForm = this.form.value;
    const model: UpdateCategory = {
      name: valueForm.name,
      storeId: this.storeId ?? ''
    };
    return model;
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
  }

  onCancel() {
    this.modal.destroy(false);
  }

  trimName() {
    let trimName = this.form.get('name')?.value.trim();
    this.form.get('name')?.setValue(trimName);
  }
}
