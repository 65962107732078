import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from 'app/core/services/auth.service';
import { SignalRService } from 'app/core/services/signalr.service';
import { IMyPermission, IPermission } from 'app/features/roles/models/permission.model';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSHeaderModule } from 'tds-ui/header';
import { TDSLayoutModule } from 'tds-ui/layout';
import { TDSMenuDTO, TDSMenuModule } from 'tds-ui/menu';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { TDSSpinnerModule } from "tds-ui/progress-spinner";
import { HeaderComponent } from '../components/header/header.component';
import { DATA_MENU } from '../data/menu.data';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs';
import { TDSDestroyService } from 'tds-ui/core/services';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    TDSLayoutModule,
    TDSHeaderModule,
    TDSMenuModule,
    RouterOutlet,
    HeaderComponent,
    TDSAvatarModule,
    TDSSpinnerModule,
    TDSModalModule
  ],
  providers: [
    TDSModalService,
    TDSDestroyService
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {
  menuItems = DATA_MENU;
  isLoading = true;
  isCollapsed = false;

  @ViewChild('deletedUserSvg') deletedUserSvg!: TemplateRef<void>;
  @ViewChild('blockedUserSvg') blockedUserSvg!: TemplateRef<void>;
  @ViewChild('changedUserRoleSvg') changedUserRoleSvg!: TemplateRef<void>;

  isLockedOrDeleted = false;

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    if (this.isLockedOrDeleted) {
      this.authService.signOut();
    }
  }


  private readonly MENU_PERMISSION_MAP: Record<string, string> = {
    '/dashboard': 'Report',
    '/connection': 'Connect',
    '/products/list': 'Product',
    '/warehouses': 'Warehouse',
    '/ecom-products': 'ECommerceProduct',
    '/orders': 'ECommerceOrder',
    '/return-refund': 'ECommerceReverse',
    '/suppliers': 'Supplier',
    'settings/categories/list': 'SettingGeneral',
    'roles/list': 'Permission',
    'employees/list': 'Employee',
  };

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly signalRService: SignalRService,
    private readonly modalService: TDSModalService,
    private readonly breakpointObs: BreakpointObserver,
    private readonly tdsDestroyService: TDSDestroyService,
  ) {}

  ngOnInit(): void {
    this.initializeMenu();
    this.breakpointObserver();

    this.signalRService.messages$.subscribe((data: any) => {
      const dto = data.dto;
      const userId = this.authService.getUserInfo()?.id;
      const tenantId = this.authService.getTenantId();

      if (!userId || !tenantId) return;

      if (data.message === "DeletedUserInBranch" || data.message === "LockedUserInBranch") {
        this.isLockedOrDeleted = true;
      }

      switch (data.message) {
        case 'DeletedUserInBranch':
          if (dto.tenantId === tenantId && dto.employeeId === userId) {
            this.notifyDeletedUser();
          }
          break;
        case 'LockedUserInBranch':
          if (dto.tenantId === tenantId && dto.employeeId === userId) {
            this.notifyLockedUser();
          }
          break;
        case 'ChangedUserRole':
          if (dto.tenantId === tenantId && dto.employeeId === userId) {
            this.notifyChangedUserRoles();
          }
          break;
        default:
          break;
      }
    });
  }

  /**
   * Observer to react to screen-size changes
   */
  breakpointObserver(): void {
    this.breakpointObs.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(
        takeUntil(this.tdsDestroyService)
      ).subscribe((res: BreakpointState) => {
        this.isCollapsed = res.matches;
        // this.cdr.detectChanges();
      });
  }

  /**
   * Handle role list change
   * @param roleList 
   */
  roleListChange(roleList: string[]) {
    if (!roleList.length) {
      this.router.navigate(['/user/profile']);
    }
  }

  private initializeMenu() {
    this.authService.permissions$.subscribe(permissions => {
      if (!permissions) return;

      this.menuItems = this.updateMenuVisibility(this.menuItems, permissions.permissionList);
    });
  }

  private updateMenuVisibility(menuItems: TDSMenuDTO[], permissions: IPermission[]): TDSMenuDTO[] {
    return menuItems.map(item => ({
      ...item,
      hidden: this.hideMenuItem(item, permissions),
      listChild: item.listChild?.map(child => ({
        ...child,
        hidden: this.hideMenuItem(child, permissions)
      }))
    }));
  }

  private hideMenuItem(menuItem: TDSMenuDTO, permissions: IPermission[]): boolean {
    if (menuItem.listChild) {
      return !menuItem.listChild.some(child =>
        this.hasPermission(permissions, this.MENU_PERMISSION_MAP[child.link])
      );
    }
    return !this.hasPermission(permissions, this.MENU_PERMISSION_MAP[menuItem.link || '']);
  }

  private hasPermission(permissions: IPermission[], permissionType: string): boolean {
    return permissionType ? this.existPermission(permissions, permissionType) : false;
  }

  private existPermission(permissions: IPermission[], permissionType: string): boolean {
    if (!permissions?.length || !permissionType) return false;

    const rootPermission = permissions.find(p => p.permissionName === permissionType);
    if (rootPermission) return rootPermission.isActive;

    const hasPermission = permissions.some(permission =>
      permission.permissionDetailList?.length > 0 &&
      this.searchInPermissionDetails(permission.permissionDetailList, permissionType)
    );

    return hasPermission || false;
  }

  private searchInPermissionDetails(details: IMyPermission[], permissionType: string): boolean {
    return details.some(detail =>
      (detail.name === permissionType && detail.isActive) ||
      (detail.permissionDetailList?.length > 0 &&
        this.searchInPermissionDetails(detail.permissionDetailList, permissionType))
    );
  }

  private showNotification(title: string, content: string, confirmIcon: TemplateRef<void>): void {
    const modal = this.modalService.success({
      title: title,
      confirmIcon: confirmIcon,
      content: content,
      maskStyle: { 'z-index': '1001' },
      onOk: () => {},
      okText: "Đăng nhập lại",
    });

    const node = modal.getBackdropElement()?.nextElementSibling as HTMLElement;
    node.style.zIndex = '1002';

    modal.afterClose.subscribe(_ => {
      this.authService.signOut();
    });
  }

  notifyDeletedUser(): void {
    this.showNotification(
      'Tài khoản của bạn đã bị xóa',
      'Tài khoản của bạn đã bị chủ cửa hàng xóa. Nếu có bất kỳ thắc mắc nào, bạn hãy liên hệ với chủ cửa hàng.',
      this.deletedUserSvg
    );
  }

  notifyLockedUser(): void {
    this.showNotification(
      'Tài khoản của bạn đã bị khóa',
      'Tài khoản của bạn đã bị chủ cửa hàng khóa. Nếu có bất kỳ thắc mắc nào, bạn hãy liên hệ với chủ cửa hàng.',
      this.blockedUserSvg
    );
  }

  notifyChangedUserRoles(): void {
    this.modalService.success({
      title: 'Vai trò của bạn đã được thay đổi',
      confirmIcon: this.changedUserRoleSvg,
      content: 'Chủ cửa hàng đã chuyển đổi vai trò của bạn. Vui lòng cập nhật sự thay đổi này.',
      onOk: () => {
        location.reload();
      },
      okText: "Cập nhật vai trò",
    });
  }
}
