import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IFilterRole } from "../models/filter-role.model";

@Injectable({
  providedIn: 'root'
})
export class FilterRoleService {
  currentFilter: IFilterRole = {}

  private filterCriteriaSource = new BehaviorSubject<IFilterRole | null>({});
  currentFilterCriteria$ = this.filterCriteriaSource.asObservable();

  private isAddFilter = new BehaviorSubject<boolean>(false);
  isAddFilter$ = this.isAddFilter.asObservable();

  constructor() {}

  changeFilterCriteria(criteria: IFilterRole) {
    this.filterCriteriaSource.next(criteria);
  }

  addFilter(): void {
    this.isAddFilter.next(true);
  }

  deleteFilter(): void {
    this.currentFilter = {};
    this.filterCriteriaSource.next(this.currentFilter);
  }
}