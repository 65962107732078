import { Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { CreateStoreCategoryReq, StoreCategory } from '../models/store-category.model';
import { Observable } from 'rxjs';
import { STORE_CATEGORY_ENDPOINT } from 'app/core/constants/api-endpoint.const';

@Injectable({
  providedIn: 'root'
})
export class StoreCategoryService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getStoreCategories$(storeId?: string): Observable<StoreCategory[]> {
    const endpoint = storeId ? `${STORE_CATEGORY_ENDPOINT}?storeId=${storeId}` : STORE_CATEGORY_ENDPOINT;
    return this.apiService.get<StoreCategory[]>(endpoint);
  }

  createStoreCategory$(req: CreateStoreCategoryReq): Observable<StoreCategory> {
    return this.apiService.post<StoreCategory>(STORE_CATEGORY_ENDPOINT, req);
  }

  deleteStoreCategory$(id: string): Observable<void> {
    return this.apiService.delete(`${STORE_CATEGORY_ENDPOINT}/${id}`);
  }
}
