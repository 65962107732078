import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';
import { SIGN_IN_ENDPOINT } from '../constants/api-endpoint.const';
import { SignInResponse } from '../models/sign-in.model';
import { CURRENT_BRANCH_ID, CURRENT_STORE_ID, PERMISSIONS, REFRESH_TOKEN, TENANT_ID, TOKEN, USER_INFO } from '../constants/authen-key.const';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { UserInfo } from '../models/user.model';
import { IUserPermission } from 'app/features/roles/models/permission.model';
import { SignalRService } from './signalr.service';
import { TDSSafeAny } from 'tds-ui/shared/utility';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly APP_VERSION_KEY = 'app_version';
  private readonly CURRENT_VERSION = environment.version; // Update this version when deploying

  private permissionsSubject = new BehaviorSubject<IUserPermission | null>(null);
  permissions$ = this.permissionsSubject.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly signalRService: SignalRService
  ) {
    this.checkAndClearLocalStorage();

    if (this.isAuthenticated()) {
      this.connectSignalR();
    }

    const savedPermissions = this.getUserPermissions();
    if (savedPermissions) {
      this.permissionsSubject.next(savedPermissions);
    }
  }

  private checkAndClearLocalStorage() {
    const storedVersion = localStorage.getItem(this.APP_VERSION_KEY);
    if (storedVersion !== this.CURRENT_VERSION) {
      localStorage.clear();
      localStorage.setItem(this.APP_VERSION_KEY, this.CURRENT_VERSION);
    }
  }

  connectSignalR() {
    const tenantId = this.getTenantId();
    const accessToken = this.getAccessToken();
    
    if (tenantId && accessToken) {
      this.signalRService.startConnection(tenantId, accessToken);
    }
  }

  signIn(phoneNumber: string, password: string): Observable<SignInResponse> {
    const headers = new HttpHeaders().set('app', 'omni');//add app to header to identify the app
  
    return this.apiService.post<SignInResponse>(SIGN_IN_ENDPOINT, { phoneNumber, password }, headers)
      .pipe(
        tap((res) => {
          localStorage.setItem(TOKEN, res.accessToken);
          localStorage.setItem(REFRESH_TOKEN, res.refreshToken);
        }),
      );
  }

  clearStorage() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(TENANT_ID);
    localStorage.removeItem(CURRENT_BRANCH_ID);
    localStorage.removeItem(CURRENT_STORE_ID);
    localStorage.removeItem(USER_INFO);
    this.clearPermissions();
  }

  signOut() {
    this.signalRService.stopConnection();
    this.clearStorage();
    this.router.navigate(['/sign-in']).then(_ => location.reload());
  }

  refreshToken(): Observable<TDSSafeAny> {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    return this.apiService.post('/api/refresh-token', { refreshToken })
      .pipe(
        tap((res: TDSSafeAny) => {
          localStorage.setItem(TOKEN, res.accessToken);
          localStorage.setItem(REFRESH_TOKEN, res.refreshToken);
        })
      );
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem(TOKEN);
  }
  /**
   * Set tenantId of store branch to local storage to add into header to request
   * @param tenantId - tenantId of store branch
   */
  setBranchTenantId(tenantId: string): void {
    localStorage.setItem(TENANT_ID, tenantId);
  }

  /**
   * Get tenantId of store branch from local storage
   * @returns tenantId of store branch
   */
  getTenantId(): string | null {
    return localStorage.getItem(TENANT_ID);
  }

  clearTenantId() {
    localStorage.removeItem(TENANT_ID);
  }

  setCurrentBranchId(branchId: string): void {
    localStorage.setItem(CURRENT_BRANCH_ID, branchId);
  }

  getCurrentBranchId(): string | null {
    return localStorage.getItem(CURRENT_BRANCH_ID);
  }

  clearBranchId() {
    localStorage.removeItem(CURRENT_BRANCH_ID);
  }

  setCurrentStoreId(storeId: string): void {
    localStorage.setItem(CURRENT_STORE_ID, storeId);
  }

  getCurrentStoreId(): string | null {
    return localStorage.getItem(CURRENT_STORE_ID);
  }

  clearStoreId() {
    localStorage.removeItem(CURRENT_STORE_ID);
  }

  getAccessToken(): string | null {
    return localStorage.getItem(TOKEN);
  }

  setUserInfo(user: UserInfo): void {
    localStorage.setItem(USER_INFO, JSON.stringify(user));
  }

  getUserInfo(): UserInfo | null {
    return localStorage.getItem(USER_INFO) ? JSON.parse(localStorage.getItem(USER_INFO) as string) as UserInfo : null;
  }

  setUserPermissions(permissions: IUserPermission) {
    localStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
    this.permissionsSubject.next(permissions);
  }

  getUserPermissions(): IUserPermission | null {
    const permissions = localStorage.getItem(PERMISSIONS);
    return permissions ? JSON.parse(permissions) : null;
  }

  clearPermissions() {
    localStorage.removeItem(PERMISSIONS);
    this.permissionsSubject.next(null);
  }
  /**
   * Check is owner of logged user
   * @returns 
   */
  isOwner(): boolean {
    return this.getUserInfo()?.isOwner as boolean;
  }
}