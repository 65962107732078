@if (stores) {
  <div class="w-full h-screen flex flex-col">
    <tds-header tdsTheme="dark"
      urlLogoText="/assets/images/WiOnLogo.svg"
      urlLogo="/assets/images/WiOnLogo.svg"
      class="!pl-[60px] !py-2"
    ></tds-header>

    <tds-spin [spinning]="isLoading" size="lg">
      <div class="flex justify-center w-full h-auto pt-24">
        <div class="border border-solid flex pb-6 pt-16 px-6 rounded-xl shadow-xl tds-color-border-secondary min-w-[520px] tds-color-surface-lowest">
          <div class="flex flex-col gap-12 w-full">
            <div class="font-semibold tds-color-text-primary tds-heading-3 text-center">Chọn cửa hàng</div>
            <div class="w-full max-h-[500px] overflow-scroll tds-custom-scroll">
              <div class="grid gap-4"
                [ngClass]="stores.length >= 3 ? 'grid-cols-2' : 'grid-cols'"
              >
                @for (store of stores; track store.id) {
                  <div class="min-w-[488px] max-w-[488px] rounded-lg border border-solid tds-color-border-secondary tds-color-background-neutral-minimalist
                    flex flex-col gap-4 p-2"
                  >
                    <section class="flex gap-3 items-center">
                      <tds-avatar class="bg-gray-300 text-white" size="xl"
                        [tdsSrc]="store.avatar || 'error-link'"
                      ></tds-avatar>
                      <h3 class="text-xl font-bold">{{store.name}}</h3>
                    </section>
                    <section class="flex flex-col gap-1 w-full">
                      @for (branch of store.storeBranchList; track branch.id) {
                        <div class="flex items-center justify-between gap-2 pl-3 pr-2 py-2 rounded-lg tds-color-background-white">
                          <p class="tds-body-2 font-semibold tds-color-text-primary">{{branch.name}}</p>
                          <button tds-button-outline color="success"
                            (click)="onAccessBranch(branch)"
                          >
                            Truy cập
                          </button>
                        </div>
                      }
                    </section>
                  </div>
                }
              </div>
            </div>
            <a tds-link (click)="goToSignInPage()"
              class="w-fit"
            >
              <i class="tdsi-back-fill text-sm"></i>
              Về trang đăng nhập
            </a>
          </div>
        </div>
      </div>
    </tds-spin>
  </div>
}
