<div class="w-full h-full flex flex-col">
  <div class="flex flex-col tds-page-header-color-background">
    <tds-page-header tdsTitle="Danh sách nhân viên">
      <tds-page-header-extra>
        <button tds-button
          [tdsPrefixIcon]="'tdsi-plus-circle-fill'"
          color="primary"
          (tdsBtnClick)="addNewEmployee()"
        >
          Thêm nhân viên
        </button>
      </tds-page-header-extra>
    </tds-page-header>
  </div>

  <div class="w-full flex-auto">
    <div class="w-full h-full p-2">
      @if (employees.length > 0) {
        <div class="flex flex-col w-full h-full bg-white rounded-md">
          <div class="flex flex-row justify-end">
            <tds-form-field class="w-96 py-1.5 pr-2.5">
              <input
                tdsInput
                #searchInput
                placeholder="Tìm theo Tên/Số điện thoại/Email nhân viên"
                class="placeholder-neutral-1-400"
              />
              <span tdsPrefix><i class="text-neutral-1-500 tdsi-search-fill text-base"></i></span>
            </tds-form-field>
          </div>
          <div class="flex-auto w-full tds-body-2">
            <tds-table
              #employeesTable
              [widthConfig]="['24px', '290px', '200px', '270px', '200px', '250px', '220px', '200px']"
              showPagination
              showSizeChanger
              [scroll]="{'x':'auto','y': 'auto'}"
              [listData]="employees"
              [(pageIndex)]="pageIndex"
              [(pageSize)]="pageSize"
              [total]="total"
              [loading]="isLoading"
              [frontPagination]="false"
              [pageSizeOptions]="[10, 20, 50, 100, 200]"
              (queryParams)="onQueryParamsChange($event)"
            >
              <thead>
                <tr>
                  <th scope="col" [tdsLeft]="true"></th>
                  <th scope="col">Nhân viên</th>
                  <th scope="col">Số điện thoại</th>
                  <th scope="col">Email </th>
                  <th scope="col">Trạng thái</th>
                  <th scope="col">Tạo bởi</th>
                  <th scope="col">Ngày tạo</th>
                  <th scope="col" [tdsRight]="true">Thao tác</th>
                </tr>
              </thead>
              <tbody>
                @for (employee of employees; track employee.id) {
                <tr>
                  <td
                    [expand]="expandSet.has(employee.id)"
                    (expandChange)="onExpandChange(employee.id)"
                  ></td>
                  <td>
                    <span class="font-semibold tds-color-text-info">{{ employee.name }}</span>
                  </td>
                  <td>{{ employee.phoneNumber }}</td>
                  <td>{{ employee.email }}</td>
                  <td>
                    <app-employee-status
                      [status]="employee.userStatus"
                      [statusName]="employee.userStatusName!"
                    ></app-employee-status>
                  </td>
                  <td>
                    <div class="flex items-center gap-2"> 
                      <tds-avatar class="bg-base-orange-500 dark:bg-d-base-orange-500 text-white" size="md"
                        [tdsSrc]="employee.creator!.avatar">
                      </tds-avatar>
                      <div>{{employee.creator!.name}}</div> 
                    </div>
                  </td>
                  <td>{{ employee.creationTime | date: 'dd/MM/yyyy HH:mm' }}</td>
                  <td [tdsRight]="true">
                    <app-employee-actions
                      [status]="employee.userStatus"
                      [isLocked]="employee.isLocked!"
                      (onActionCompleted)="onActionCompleted()"
                    ></app-employee-actions>
                  </td>
                </tr>

                <tr [expand]="expandSet.has(employee.id)" class="w-full">
                  <app-employee-branches-detail
                    [employeeBranches]="employee.userInStoreBranches ?? []"
                  ></app-employee-branches-detail>
                </tr>
                }
              </tbody>
            </tds-table>
          </div>
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="emptyEmployee"></ng-container>
      }
    </div>
  </div>
</div>

<ng-template #emptyEmployee>
  <div class="flex justify-center items-center w-full h-full bg-white rounded-md">
    <div class="flex flex-col gap-4 w-[400px] h-[430px]">
      <div class="w-full h-full px-[48px]">
        <img src="/assets/images/empty-employee.svg"
          alt="empty-employee" class="w-full h-full">
      </div>
      <div class="flex flex-col gap-2 items-center">
        <div class="tds-heading-5 font-semibold tds-color-text-primary">Chưa có nhân viên nào</div>
        <div class="text-body-2 tds-color-text-secondary">Hãy thêm mới nhân viên vào doanh nghiệp của bạn</div>
      </div>
      <div class="flex justify-center">
        <button tds-button
          [tdsPrefixIcon]="'tdsi-plus-circle-fill'"
          color="primary"
          size="lg"
          (tdsBtnClick)="addNewEmployee()"
        >
          Thêm nhân viên
        </button>
      </div>
    </div>
  </div>
</ng-template>
