import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';
import { SIGN_IN_ENDPOINT } from '../constants/api-endpoint.const';
import { SignInResponse } from '../models/sign-in.model';
import { CURRENT_BRANCH_ID, CURRENT_STORE_ID, REFRESH_TOKEN, TENANT_ID, TOKEN } from '../constants/authen-key.const';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly APP_VERSION_KEY = 'app_version';
  private readonly CURRENT_VERSION = environment.version; // Update this version when deploying

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router
  ) {
    this.checkAndClearLocalStorage();
  }

  private checkAndClearLocalStorage() {
    const storedVersion = localStorage.getItem(this.APP_VERSION_KEY);
    if (storedVersion !== this.CURRENT_VERSION) {
      localStorage.clear();
      localStorage.setItem(this.APP_VERSION_KEY, this.CURRENT_VERSION);
    }
  }

  signIn(phoneNumber: string, password: string): Observable<SignInResponse> {
    const headers = new HttpHeaders().set('app', 'omni');//add app to header to identify the app
  
    return this.apiService.post<SignInResponse>(SIGN_IN_ENDPOINT, { phoneNumber, password }, headers)
      .pipe(tap((res) => {
        localStorage.setItem(TOKEN, res.accessToken);
        localStorage.setItem(REFRESH_TOKEN, res.refreshToken);
      }));
  }

  clearStorage() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(TENANT_ID);
    localStorage.removeItem(CURRENT_BRANCH_ID);
    localStorage.removeItem(CURRENT_STORE_ID);
  }

  signOut() {
    this.clearStorage();
    this.router.navigate(['/sign-in']);
  }

  refreshToken(): Observable<any> {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    return this.apiService.post('/api/refresh-token', { refreshToken })
      .pipe(
        tap((res) => {
          localStorage.setItem(TOKEN, res.accessToken);
          localStorage.setItem(REFRESH_TOKEN, res.refreshToken);
        })
      );
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem(TOKEN);
  }
  /**
   * Set tenantId of store branch to local storage to add into header to request
   * @param tenantId - tenantId of store branch
   */
  setBranchTenantId(tenantId: string): void {
    localStorage.setItem(TENANT_ID, tenantId);
  }

  /**
   * Get tenantId of store branch from local storage
   * @returns tenantId of store branch
   */
  getTenantId(): string | null {
    return localStorage.getItem(TENANT_ID);
  }

  setCurrentBranchId(branchId: string): void {
    localStorage.setItem(CURRENT_BRANCH_ID, branchId);
  }

  getCurrentBranchId(): string | null {
    return localStorage.getItem(CURRENT_BRANCH_ID);
  }

  setCurrentStoreId(storeId: string): void {
    localStorage.setItem(CURRENT_STORE_ID, storeId);
  }

  getCurrentStoreId(): string | null {
    return localStorage.getItem(CURRENT_STORE_ID);
  }
}