<tds-header
  tdsTheme="dark"
  urlLogoText="https://static-tds-public-projects.tmtco.org/branding-assets/logos/wi/wion-e-commerce/hoz/dark.svg"
>
  <div class="flex items-center gap-4 tds-header-toolbar-right">
    <div class="flex items-center gap-2">
      <button tds-button-flat-icon color="secondary">
        <span class="flex items-center">
          <i class="tdsi-notification-fill"></i>
        </span>
      </button>
      <button tds-button-flat-icon color="secondary">
        <span class="flex items-center">
          <i class="tdsi-light-mode-fill"></i>
        </span>
      </button>

      @if (currentBranch && currentStore) {
        <div class="flex items-center gap-2 cursor-pointer border tds-color-border-primary rounded-full pl-0.5 py-0.5 pr-2"
          tds-dropdown
          [disabled]="userStoreBranches && userStoreBranches.length === 1 && userStoreBranches[0].storeBranchList.length === 1"
          [placement]="'bottomLeft'"
          [clickHide]="false"
          [tdsDropdownMenu]="dropdownEnterpriseMenu"
          trigger="click"
        >
          <tds-avatar class="bg-gray-300 text-white leading-none" [size]="28" icon="tdsi-user-line"
            [tdsSrc]="currentStore.avatar!"
          ></tds-avatar>
          <p class="tds-body-2 font-semibold tds-color-text-primary">{{ currentBranch.name }}</p>
          <div class="text-d-neutral-1-700 items-center">
            <i class="text-base leading-4 tdsi-arrow-down-fill"></i>
          </div>
        </div>
      }
      
      <tds-dropdown-menu #dropdownEnterpriseMenu
        class="!p-0"
      >
        <div class="flex flex-col gap-2 px-1.5 py-2 tds-color-background-neutral-minimalist w-[400px] max-h-[400px] overflow-y-auto custom-scroll">
          @if (userStoreBranches.length > 1) {
            <div class="w-full flex items-center justify-between cursor-pointer"
              (click)="changeToDefault()"
            >
              <div class="flex gap-1 items-center tds-body-1 tds-color-text-brand-primary font-semibold">
                <span class="tdsi-list-view-line text-2xl leading-none"></span>
                <span>Tất cả</span>
              </div>
              @if (isDefaultWorkspace) {
                <span class="tdsi-success-fill text-xl leading-none tds-color-icon-success"></span>
              }
            </div>
          }
          @for (storeBranch of userStoreBranches; track storeBranch.id) {
            <div class="w-full flex flex-col gap-2">
              <div class="flex items-center gap-2">
                <tds-avatar class="bg-gray-300 text-white leading-none" size="md"
                  [tdsSrc]="storeBranch.avatar!"
                ></tds-avatar>
                <p class="tds-body-2 font-semibold tds-color-text-primary">{{ storeBranch.name }}</p>
              </div>

              <div class="flex flex-col gap-0.5">
                @for (branch of storeBranch.storeBranchList; track branch.id) {
                  <div tds-dropdown-item
                    class="tds-body-2 flex items-center justify-between gap-0.5 w-full bg-white !px-3 !py-2"
                    (click)="changeBranch(branch)"
                  >
                    <span [ngClass]="{'tds-color-text-primary font-semibold': branch.id === currentBranch.id}">{{ branch.name }}</span>
                    @if (branch.id === currentBranch.id) {
                      <span class="tdsi-success-fill text-xl leading-none tds-color-icon-success"></span>
                    }
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </tds-dropdown-menu>
    </div>

    @if (userInfo) {
      <tds-avatar
        [tdsSrc]="userInfo.avatar!"
        [shape]="'circle'"
        alt="user info"
        tds-dropdown
        [tdsDropdownMenu]="dropdownAvatar"
        trigger="click"
        class="border-2 tds-color-border-white cursor-pointer"
      ></tds-avatar>

      <tds-dropdown-menu #dropdownAvatar="tdsDropdownMenu">
        <div class="w-56">
          <div tds-dropdown-item
            class="flex items-center tds-color-background-neutral-minimalist"
            (click)="navigateToProfile()"
          >
            <div class="flex items-center gap-3 w-full">
              <tds-avatar
                [size]="48"
                [shape]="'circle'"
                [tdsSrc]="userInfo.avatar!"
              >
              </tds-avatar>
              <div class="flex-1">
                <div class="text-body-2 font-semibold"> {{ userInfo.name }} </div>
                @if (userInfo.isOwner) {
                  <tds-tag status="success" size="md">Chủ cửa hàng</tds-tag>
                }
                @else {
                  <tds-tag status="info" size="md">{{ userInfo.currentRole }}</tds-tag>
                }
              </div>
            </div>
            <span class="tdsi-chevron-right-fill tds-color-icon-secondary"></span>
          </div>
          <div tds-dropdown-item
            class="tds-body-2 flex items-center gap-2"
          >
            <i class="tdsi-lock-fill tds-color-icon-secondary"></i>
            Đổi mật khẩu
          </div>
          <div tds-dropdown-item
            class="tds-body-2 flex items-center gap-2 w-full"
            (click)="signOut()"
          >
            <i class="tdsi-log-out-fill tds-color-icon-error"></i>
            <a class="text-error-400">Đăng xuất</a>
          </div>
        </div>
      </tds-dropdown-menu>
    }
  </div>
</tds-header>