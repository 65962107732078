import { AbstractControl, ValidationErrors } from "@angular/forms";

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const email = control.value.toLowerCase();
  const [username, domain] = email.split('@');

  // Check basic email format
  const basicEmailFormat = /^[a-z0-9._-]+@[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,}$/;
  if (!basicEmailFormat.test(email)) {
    return { inValidEmail: true };
  }

  // Check username
  if (
    username.startsWith('.') ||
    username.endsWith('.') ||
    username.includes('..') ||
    /\s/.test(username)
  ) {
    return { inValidEmail: true };
  }

  // Check domain
  if (!domain || domain.split('.').length < 2) {
    return { inValidEmail: true };
  }

  return null;
}