import { coerceBooleanProperty, _isNumberValue, coerceCssPixelValue } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

// @dynamic
class TDSHelperArray {
  static joinNumber(arr) {
    if (this.hasListValue(arr)) {
      return arr.join(",");
    }
    return "";
  }
  static joinString(arr) {
    if (this.hasListValue(arr)) {
      return arr.join("],[");
    }
    return "";
  }
  static hasListValue(value) {
    return !(value === undefined || value === null || value.length == 0);
  }
  /**
   * Kiểm tra a là array
   * @param a
   * @returns
   */
  static isArray(a) {
    a = Object.prototype.toString.call(a);
    return "[object Array]" === a || "[object Array Iterator]" === a;
  }
  /**
  * tìm min của mảng a
  * @param a
  * @returns
  */
  static arrayMin(a) {
    for (var c = a.length, k = a[0]; c--;) a[c] < k && (k = a[c]);
    return k;
  }
  /**
   * tìm max của mảng a
   * @param a
   * @returns
   */
  static arrayMax(a) {
    for (var c = a.length, k = a[0]; c--;) a[c] > k && (k = a[c]);
    return k;
  }
  /**
   * gộp mảng 2 vào mảng 1
   * @param array1
   * @param array2
   */
  static concat(array1, array2) {
    array1.push.apply(array1, array2);
  }
  //   var exampleData = [
  //     { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  //     { position: 1, name: 'Helium', weight: 4.0026, symbol: 'H' },
  //     { position: 2, name: 'Lithium', weight: 6.941, symbol: 'L' },    //    
  //   ]
  //   // expect 
  //   // key = ['symbol']
  //   var TREE1 = [
  //     {
  //       symbol: 'H',
  //       children: [
  //         { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  //         { position: 1, name: 'Helium', weight: 4.0026, symbol: 'H' },
  //       ]
  //     },
  //     {
  //       symbol: 'L',
  //       children: [
  //         { position: 2, name: 'Lithium', weight: 6.941, symbol: 'L' },
  //       ]
  //     },
  //   ]
  //   // OR -------------------------------------------------------------------------
  //   // key = ['symbol', 'position']
  //   var TREE2 = [ 
  //     {
  //       symbol: 'H',
  //       children: [
  //         {
  //           position: 1,
  //           children: [
  //             { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  //             { position: 1, name: 'Helium', weight: 4.0026, symbol: 'H' },
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       symbol: 'N',
  //       children: [
  //         {
  //           position: 5,
  //           children: [{ position: 5, name: 'Nitrogen', weight: 14.0067, symbol: 'N' }]
  //         },
  //         {
  //           position: 8,
  //           children: [{ position: 8, name: 'Neon', weight: 20.1797, symbol: 'N' }]
  //         }
  //       ]
  //     },
  //   ]
  /**
   *
   * @param items mảng đối tượng
   * @param keys các  fields cần groupby
   * @returns [[field groupby]:'',children:[]]
   */
  static groupByKey(items, ...keys) {
    if (!this.hasListValue(keys)) {
      return items;
    }
    const result = [];
    const keySet = new Set();
    const groupByKey = keys.shift();
    items.forEach(item => {
      if (!keySet.has(item[groupByKey])) {
        keySet.add(item[groupByKey]);
        result.push({
          [groupByKey]: item[groupByKey],
          children: [item]
        });
      } else {
        result.find(res => res[groupByKey] === item[groupByKey]).children.push(item);
      }
    });
    if (keys.length) {
      result.forEach(res => {
        res.children = this.groupByKey(res.children, ...keys);
      });
    }
    return result;
  }
}

// @dynamic
class TDSHelperObject {
  /**
    * Kiểm tra đối tượng có giá trị hay ko
    * @param value
    *
    */
  static hasValue(value) {
    return !(value === undefined || value === null);
  }
  /**
  * sao chép đối tượng
  * @param obj
  *
  */
  static cloneObject(obj) {
    if (this.hasValue(obj)) {
      return JSON.parse(JSON.stringify(obj));
    } else {
      return obj;
    }
  }
  static copyObject(taget, source) {
    taget = Object.assign(taget, source);
  }
  static isObject(k, c) {
    return !!k && "object" === typeof k && (!c || !TDSHelperArray.isArray(k));
  }
  /** Coerces a data-bound value (typically a string) to a boolean. */
  static coerceBooleanProperty(value) {
    return value != null && `${value}` !== 'false';
  }
  /**
   * kiểm tra đối tượng là function
   * @param fun
   * return true/false
   */
  static isFunction(fun) {
    return typeof fun === 'function';
  }
  /**
   * lấy giá trị theo Property của Obj
   * @param obj : object
   * @param field : tên property của obj
   * @param separator : ".", dựa vào separator để tách chuỗi thành mảng các phần tử con, Ví dụ: propa.propb hoặc propA#propB
   * return undefined hoặc giá trị của property
   */
  static getValueByField(obj, field, separator = ".") {
    if (!this.hasValue(obj)) {
      return undefined;
    }
    const keys = field.split(separator); // Chia chuỗi thành mảng các phần tử con
    let result = obj;
    for (const key of keys) {
      if (result.hasOwnProperty(key)) {
        result = result[key];
      } else {
        // Trả về undefined nếu không tìm thấy thuộc tính
        return undefined;
      }
    }
    return result;
  }
  static isEmpty(obj) {
    return !TDSHelperObject.hasValue(obj) || Object.keys(obj).length === 0;
  }
}

// @dynamic
class TDSHelperString {
  static hasValueString(value) {
    return !(value === undefined || value === null || value === "");
  }
  static guid() {
    let str = "";
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    });
  }
  static gen(count) {
    let out = "";
    for (let i = 0; i < count; i++) {
      out += ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    }
    return out;
  }
  static genid() {
    return this.guid().replace(/-/g, "");
  }
  static isString(str) {
    return "string" === typeof str;
  }
  /**
   * replace chuỗi
   * @param str
   * @param searchValue chuỗi cần replace
   * @param replaceValue  chuỗi replace
   * @returns
   */
  static replaceAll(str, searchValue, replaceValue) {
    if (this.hasValueString(str)) {
      if (str.indexOf(searchValue) > -1) {
        return str.split(searchValue).join(replaceValue);
      }
    }
    return str;
  }
  /**
   * Kiểm tra chuỗi chứa tất cả các kí tự là khoảng trắng (space)
   * @param value
   * @returns trả về true nếu tất cả kí tự KHÔNG là khoảng trắng (space)
   */
  static checkStringAllSpace(value) {
    return this.hasValueString(value.trim());
  }
  static getDiacritics() {
    return {
      '\u24B6': 'A',
      '\uFF21': 'A',
      '\u00C0': 'A',
      '\u00C1': 'A',
      '\u00C2': 'A',
      '\u1EA6': 'A',
      '\u1EA4': 'A',
      '\u1EAA': 'A',
      '\u1EA8': 'A',
      '\u00C3': 'A',
      '\u0100': 'A',
      '\u0102': 'A',
      '\u1EB0': 'A',
      '\u1EAE': 'A',
      '\u1EB4': 'A',
      '\u1EB2': 'A',
      '\u0226': 'A',
      '\u01E0': 'A',
      '\u00C4': 'A',
      '\u01DE': 'A',
      '\u1EA2': 'A',
      '\u00C5': 'A',
      '\u01FA': 'A',
      '\u01CD': 'A',
      '\u0200': 'A',
      '\u0202': 'A',
      '\u1EA0': 'A',
      '\u1EAC': 'A',
      '\u1EB6': 'A',
      '\u1E00': 'A',
      '\u0104': 'A',
      '\u023A': 'A',
      '\u2C6F': 'A',
      '\uA732': 'AA',
      '\u00C6': 'AE',
      '\u01FC': 'AE',
      '\u01E2': 'AE',
      '\uA734': 'AO',
      '\uA736': 'AU',
      '\uA738': 'AV',
      '\uA73A': 'AV',
      '\uA73C': 'AY',
      '\u24B7': 'B',
      '\uFF22': 'B',
      '\u1E02': 'B',
      '\u1E04': 'B',
      '\u1E06': 'B',
      '\u0243': 'B',
      '\u0182': 'B',
      '\u0181': 'B',
      '\u24B8': 'C',
      '\uFF23': 'C',
      '\u0106': 'C',
      '\u0108': 'C',
      '\u010A': 'C',
      '\u010C': 'C',
      '\u00C7': 'C',
      '\u1E08': 'C',
      '\u0187': 'C',
      '\u023B': 'C',
      '\uA73E': 'C',
      '\u24B9': 'D',
      '\uFF24': 'D',
      '\u1E0A': 'D',
      '\u010E': 'D',
      '\u1E0C': 'D',
      '\u1E10': 'D',
      '\u1E12': 'D',
      '\u1E0E': 'D',
      '\u0110': 'D',
      '\u018B': 'D',
      '\u018A': 'D',
      '\u0189': 'D',
      '\uA779': 'D',
      '\u01F1': 'DZ',
      '\u01C4': 'DZ',
      '\u01F2': 'Dz',
      '\u01C5': 'Dz',
      '\u24BA': 'E',
      '\uFF25': 'E',
      '\u00C8': 'E',
      '\u00C9': 'E',
      '\u00CA': 'E',
      '\u1EC0': 'E',
      '\u1EBE': 'E',
      '\u1EC4': 'E',
      '\u1EC2': 'E',
      '\u1EBC': 'E',
      '\u0112': 'E',
      '\u1E14': 'E',
      '\u1E16': 'E',
      '\u0114': 'E',
      '\u0116': 'E',
      '\u00CB': 'E',
      '\u1EBA': 'E',
      '\u011A': 'E',
      '\u0204': 'E',
      '\u0206': 'E',
      '\u1EB8': 'E',
      '\u1EC6': 'E',
      '\u0228': 'E',
      '\u1E1C': 'E',
      '\u0118': 'E',
      '\u1E18': 'E',
      '\u1E1A': 'E',
      '\u0190': 'E',
      '\u018E': 'E',
      '\u24BB': 'F',
      '\uFF26': 'F',
      '\u1E1E': 'F',
      '\u0191': 'F',
      '\uA77B': 'F',
      '\u24BC': 'G',
      '\uFF27': 'G',
      '\u01F4': 'G',
      '\u011C': 'G',
      '\u1E20': 'G',
      '\u011E': 'G',
      '\u0120': 'G',
      '\u01E6': 'G',
      '\u0122': 'G',
      '\u01E4': 'G',
      '\u0193': 'G',
      '\uA7A0': 'G',
      '\uA77D': 'G',
      '\uA77E': 'G',
      '\u24BD': 'H',
      '\uFF28': 'H',
      '\u0124': 'H',
      '\u1E22': 'H',
      '\u1E26': 'H',
      '\u021E': 'H',
      '\u1E24': 'H',
      '\u1E28': 'H',
      '\u1E2A': 'H',
      '\u0126': 'H',
      '\u2C67': 'H',
      '\u2C75': 'H',
      '\uA78D': 'H',
      '\u24BE': 'I',
      '\uFF29': 'I',
      '\u00CC': 'I',
      '\u00CD': 'I',
      '\u00CE': 'I',
      '\u0128': 'I',
      '\u012A': 'I',
      '\u012C': 'I',
      '\u0130': 'I',
      '\u00CF': 'I',
      '\u1E2E': 'I',
      '\u1EC8': 'I',
      '\u01CF': 'I',
      '\u0208': 'I',
      '\u020A': 'I',
      '\u1ECA': 'I',
      '\u012E': 'I',
      '\u1E2C': 'I',
      '\u0197': 'I',
      '\u24BF': 'J',
      '\uFF2A': 'J',
      '\u0134': 'J',
      '\u0248': 'J',
      '\u24C0': 'K',
      '\uFF2B': 'K',
      '\u1E30': 'K',
      '\u01E8': 'K',
      '\u1E32': 'K',
      '\u0136': 'K',
      '\u1E34': 'K',
      '\u0198': 'K',
      '\u2C69': 'K',
      '\uA740': 'K',
      '\uA742': 'K',
      '\uA744': 'K',
      '\uA7A2': 'K',
      '\u24C1': 'L',
      '\uFF2C': 'L',
      '\u013F': 'L',
      '\u0139': 'L',
      '\u013D': 'L',
      '\u1E36': 'L',
      '\u1E38': 'L',
      '\u013B': 'L',
      '\u1E3C': 'L',
      '\u1E3A': 'L',
      '\u0141': 'L',
      '\u023D': 'L',
      '\u2C62': 'L',
      '\u2C60': 'L',
      '\uA748': 'L',
      '\uA746': 'L',
      '\uA780': 'L',
      '\u01C7': 'LJ',
      '\u01C8': 'Lj',
      '\u24C2': 'M',
      '\uFF2D': 'M',
      '\u1E3E': 'M',
      '\u1E40': 'M',
      '\u1E42': 'M',
      '\u2C6E': 'M',
      '\u019C': 'M',
      '\u24C3': 'N',
      '\uFF2E': 'N',
      '\u01F8': 'N',
      '\u0143': 'N',
      '\u00D1': 'N',
      '\u1E44': 'N',
      '\u0147': 'N',
      '\u1E46': 'N',
      '\u0145': 'N',
      '\u1E4A': 'N',
      '\u1E48': 'N',
      '\u0220': 'N',
      '\u019D': 'N',
      '\uA790': 'N',
      '\uA7A4': 'N',
      '\u01CA': 'NJ',
      '\u01CB': 'Nj',
      '\u24C4': 'O',
      '\uFF2F': 'O',
      '\u00D2': 'O',
      '\u00D3': 'O',
      '\u00D4': 'O',
      '\u1ED2': 'O',
      '\u1ED0': 'O',
      '\u1ED6': 'O',
      '\u1ED4': 'O',
      '\u00D5': 'O',
      '\u1E4C': 'O',
      '\u022C': 'O',
      '\u1E4E': 'O',
      '\u014C': 'O',
      '\u1E50': 'O',
      '\u1E52': 'O',
      '\u014E': 'O',
      '\u022E': 'O',
      '\u0230': 'O',
      '\u00D6': 'O',
      '\u022A': 'O',
      '\u1ECE': 'O',
      '\u0150': 'O',
      '\u01D1': 'O',
      '\u020C': 'O',
      '\u020E': 'O',
      '\u01A0': 'O',
      '\u1EDC': 'O',
      '\u1EDA': 'O',
      '\u1EE0': 'O',
      '\u1EDE': 'O',
      '\u1EE2': 'O',
      '\u1ECC': 'O',
      '\u1ED8': 'O',
      '\u01EA': 'O',
      '\u01EC': 'O',
      '\u00D8': 'O',
      '\u01FE': 'O',
      '\u0186': 'O',
      '\u019F': 'O',
      '\uA74A': 'O',
      '\uA74C': 'O',
      '\u01A2': 'OI',
      '\uA74E': 'OO',
      '\u0222': 'OU',
      '\u24C5': 'P',
      '\uFF30': 'P',
      '\u1E54': 'P',
      '\u1E56': 'P',
      '\u01A4': 'P',
      '\u2C63': 'P',
      '\uA750': 'P',
      '\uA752': 'P',
      '\uA754': 'P',
      '\u24C6': 'Q',
      '\uFF31': 'Q',
      '\uA756': 'Q',
      '\uA758': 'Q',
      '\u024A': 'Q',
      '\u24C7': 'R',
      '\uFF32': 'R',
      '\u0154': 'R',
      '\u1E58': 'R',
      '\u0158': 'R',
      '\u0210': 'R',
      '\u0212': 'R',
      '\u1E5A': 'R',
      '\u1E5C': 'R',
      '\u0156': 'R',
      '\u1E5E': 'R',
      '\u024C': 'R',
      '\u2C64': 'R',
      '\uA75A': 'R',
      '\uA7A6': 'R',
      '\uA782': 'R',
      '\u24C8': 'S',
      '\uFF33': 'S',
      '\u1E9E': 'S',
      '\u015A': 'S',
      '\u1E64': 'S',
      '\u015C': 'S',
      '\u1E60': 'S',
      '\u0160': 'S',
      '\u1E66': 'S',
      '\u1E62': 'S',
      '\u1E68': 'S',
      '\u0218': 'S',
      '\u015E': 'S',
      '\u2C7E': 'S',
      '\uA7A8': 'S',
      '\uA784': 'S',
      '\u24C9': 'T',
      '\uFF34': 'T',
      '\u1E6A': 'T',
      '\u0164': 'T',
      '\u1E6C': 'T',
      '\u021A': 'T',
      '\u0162': 'T',
      '\u1E70': 'T',
      '\u1E6E': 'T',
      '\u0166': 'T',
      '\u01AC': 'T',
      '\u01AE': 'T',
      '\u023E': 'T',
      '\uA786': 'T',
      '\uA728': 'TZ',
      '\u24CA': 'U',
      '\uFF35': 'U',
      '\u00D9': 'U',
      '\u00DA': 'U',
      '\u00DB': 'U',
      '\u0168': 'U',
      '\u1E78': 'U',
      '\u016A': 'U',
      '\u1E7A': 'U',
      '\u016C': 'U',
      '\u00DC': 'U',
      '\u01DB': 'U',
      '\u01D7': 'U',
      '\u01D5': 'U',
      '\u01D9': 'U',
      '\u1EE6': 'U',
      '\u016E': 'U',
      '\u0170': 'U',
      '\u01D3': 'U',
      '\u0214': 'U',
      '\u0216': 'U',
      '\u01AF': 'U',
      '\u1EEA': 'U',
      '\u1EE8': 'U',
      '\u1EEE': 'U',
      '\u1EEC': 'U',
      '\u1EF0': 'U',
      '\u1EE4': 'U',
      '\u1E72': 'U',
      '\u0172': 'U',
      '\u1E76': 'U',
      '\u1E74': 'U',
      '\u0244': 'U',
      '\u24CB': 'V',
      '\uFF36': 'V',
      '\u1E7C': 'V',
      '\u1E7E': 'V',
      '\u01B2': 'V',
      '\uA75E': 'V',
      '\u0245': 'V',
      '\uA760': 'VY',
      '\u24CC': 'W',
      '\uFF37': 'W',
      '\u1E80': 'W',
      '\u1E82': 'W',
      '\u0174': 'W',
      '\u1E86': 'W',
      '\u1E84': 'W',
      '\u1E88': 'W',
      '\u2C72': 'W',
      '\u24CD': 'X',
      '\uFF38': 'X',
      '\u1E8A': 'X',
      '\u1E8C': 'X',
      '\u24CE': 'Y',
      '\uFF39': 'Y',
      '\u1EF2': 'Y',
      '\u00DD': 'Y',
      '\u0176': 'Y',
      '\u1EF8': 'Y',
      '\u0232': 'Y',
      '\u1E8E': 'Y',
      '\u0178': 'Y',
      '\u1EF6': 'Y',
      '\u1EF4': 'Y',
      '\u01B3': 'Y',
      '\u024E': 'Y',
      '\u1EFE': 'Y',
      '\u24CF': 'Z',
      '\uFF3A': 'Z',
      '\u0179': 'Z',
      '\u1E90': 'Z',
      '\u017B': 'Z',
      '\u017D': 'Z',
      '\u1E92': 'Z',
      '\u1E94': 'Z',
      '\u01B5': 'Z',
      '\u0224': 'Z',
      '\u2C7F': 'Z',
      '\u2C6B': 'Z',
      '\uA762': 'Z',
      '\u24D0': 'a',
      '\uFF41': 'a',
      '\u1E9A': 'a',
      '\u00E0': 'a',
      '\u00E1': 'a',
      '\u00E2': 'a',
      '\u1EA7': 'a',
      '\u1EA5': 'a',
      '\u1EAB': 'a',
      '\u1EA9': 'a',
      '\u00E3': 'a',
      '\u0101': 'a',
      '\u0103': 'a',
      '\u1EB1': 'a',
      '\u1EAF': 'a',
      '\u1EB5': 'a',
      '\u1EB3': 'a',
      '\u0227': 'a',
      '\u01E1': 'a',
      '\u00E4': 'a',
      '\u01DF': 'a',
      '\u1EA3': 'a',
      '\u00E5': 'a',
      '\u01FB': 'a',
      '\u01CE': 'a',
      '\u0201': 'a',
      '\u0203': 'a',
      '\u1EA1': 'a',
      '\u1EAD': 'a',
      '\u1EB7': 'a',
      '\u1E01': 'a',
      '\u0105': 'a',
      '\u2C65': 'a',
      '\u0250': 'a',
      '\uA733': 'aa',
      '\u00E6': 'ae',
      '\u01FD': 'ae',
      '\u01E3': 'ae',
      '\uA735': 'ao',
      '\uA737': 'au',
      '\uA739': 'av',
      '\uA73B': 'av',
      '\uA73D': 'ay',
      '\u24D1': 'b',
      '\uFF42': 'b',
      '\u1E03': 'b',
      '\u1E05': 'b',
      '\u1E07': 'b',
      '\u0180': 'b',
      '\u0183': 'b',
      '\u0253': 'b',
      '\u24D2': 'c',
      '\uFF43': 'c',
      '\u0107': 'c',
      '\u0109': 'c',
      '\u010B': 'c',
      '\u010D': 'c',
      '\u00E7': 'c',
      '\u1E09': 'c',
      '\u0188': 'c',
      '\u023C': 'c',
      '\uA73F': 'c',
      '\u2184': 'c',
      '\u24D3': 'd',
      '\uFF44': 'd',
      '\u1E0B': 'd',
      '\u010F': 'd',
      '\u1E0D': 'd',
      '\u1E11': 'd',
      '\u1E13': 'd',
      '\u1E0F': 'd',
      '\u0111': 'd',
      '\u018C': 'd',
      '\u0256': 'd',
      '\u0257': 'd',
      '\uA77A': 'd',
      '\u01F3': 'dz',
      '\u01C6': 'dz',
      '\u24D4': 'e',
      '\uFF45': 'e',
      '\u00E8': 'e',
      '\u00E9': 'e',
      '\u00EA': 'e',
      '\u1EC1': 'e',
      '\u1EBF': 'e',
      '\u1EC5': 'e',
      '\u1EC3': 'e',
      '\u1EBD': 'e',
      '\u0113': 'e',
      '\u1E15': 'e',
      '\u1E17': 'e',
      '\u0115': 'e',
      '\u0117': 'e',
      '\u00EB': 'e',
      '\u1EBB': 'e',
      '\u011B': 'e',
      '\u0205': 'e',
      '\u0207': 'e',
      '\u1EB9': 'e',
      '\u1EC7': 'e',
      '\u0229': 'e',
      '\u1E1D': 'e',
      '\u0119': 'e',
      '\u1E19': 'e',
      '\u1E1B': 'e',
      '\u0247': 'e',
      '\u025B': 'e',
      '\u01DD': 'e',
      '\u24D5': 'f',
      '\uFF46': 'f',
      '\u1E1F': 'f',
      '\u0192': 'f',
      '\uA77C': 'f',
      '\u24D6': 'g',
      '\uFF47': 'g',
      '\u01F5': 'g',
      '\u011D': 'g',
      '\u1E21': 'g',
      '\u011F': 'g',
      '\u0121': 'g',
      '\u01E7': 'g',
      '\u0123': 'g',
      '\u01E5': 'g',
      '\u0260': 'g',
      '\uA7A1': 'g',
      '\u1D79': 'g',
      '\uA77F': 'g',
      '\u24D7': 'h',
      '\uFF48': 'h',
      '\u0125': 'h',
      '\u1E23': 'h',
      '\u1E27': 'h',
      '\u021F': 'h',
      '\u1E25': 'h',
      '\u1E29': 'h',
      '\u1E2B': 'h',
      '\u1E96': 'h',
      '\u0127': 'h',
      '\u2C68': 'h',
      '\u2C76': 'h',
      '\u0265': 'h',
      '\u0195': 'hv',
      '\u24D8': 'i',
      '\uFF49': 'i',
      '\u00EC': 'i',
      '\u00ED': 'i',
      '\u00EE': 'i',
      '\u0129': 'i',
      '\u012B': 'i',
      '\u012D': 'i',
      '\u00EF': 'i',
      '\u1E2F': 'i',
      '\u1EC9': 'i',
      '\u01D0': 'i',
      '\u0209': 'i',
      '\u020B': 'i',
      '\u1ECB': 'i',
      '\u012F': 'i',
      '\u1E2D': 'i',
      '\u0268': 'i',
      '\u0131': 'i',
      '\u24D9': 'j',
      '\uFF4A': 'j',
      '\u0135': 'j',
      '\u01F0': 'j',
      '\u0249': 'j',
      '\u24DA': 'k',
      '\uFF4B': 'k',
      '\u1E31': 'k',
      '\u01E9': 'k',
      '\u1E33': 'k',
      '\u0137': 'k',
      '\u1E35': 'k',
      '\u0199': 'k',
      '\u2C6A': 'k',
      '\uA741': 'k',
      '\uA743': 'k',
      '\uA745': 'k',
      '\uA7A3': 'k',
      '\u24DB': 'l',
      '\uFF4C': 'l',
      '\u0140': 'l',
      '\u013A': 'l',
      '\u013E': 'l',
      '\u1E37': 'l',
      '\u1E39': 'l',
      '\u013C': 'l',
      '\u1E3D': 'l',
      '\u1E3B': 'l',
      '\u017F': 'l',
      '\u0142': 'l',
      '\u019A': 'l',
      '\u026B': 'l',
      '\u2C61': 'l',
      '\uA749': 'l',
      '\uA781': 'l',
      '\uA747': 'l',
      '\u01C9': 'lj',
      '\u24DC': 'm',
      '\uFF4D': 'm',
      '\u1E3F': 'm',
      '\u1E41': 'm',
      '\u1E43': 'm',
      '\u0271': 'm',
      '\u026F': 'm',
      '\u24DD': 'n',
      '\uFF4E': 'n',
      '\u01F9': 'n',
      '\u0144': 'n',
      '\u00F1': 'n',
      '\u1E45': 'n',
      '\u0148': 'n',
      '\u1E47': 'n',
      '\u0146': 'n',
      '\u1E4B': 'n',
      '\u1E49': 'n',
      '\u019E': 'n',
      '\u0272': 'n',
      '\u0149': 'n',
      '\uA791': 'n',
      '\uA7A5': 'n',
      '\u01CC': 'nj',
      '\u24DE': 'o',
      '\uFF4F': 'o',
      '\u00F2': 'o',
      '\u00F3': 'o',
      '\u00F4': 'o',
      '\u1ED3': 'o',
      '\u1ED1': 'o',
      '\u1ED7': 'o',
      '\u1ED5': 'o',
      '\u00F5': 'o',
      '\u1E4D': 'o',
      '\u022D': 'o',
      '\u1E4F': 'o',
      '\u014D': 'o',
      '\u1E51': 'o',
      '\u1E53': 'o',
      '\u014F': 'o',
      '\u022F': 'o',
      '\u0231': 'o',
      '\u00F6': 'o',
      '\u022B': 'o',
      '\u1ECF': 'o',
      '\u0151': 'o',
      '\u01D2': 'o',
      '\u020D': 'o',
      '\u020F': 'o',
      '\u01A1': 'o',
      '\u1EDD': 'o',
      '\u1EDB': 'o',
      '\u1EE1': 'o',
      '\u1EDF': 'o',
      '\u1EE3': 'o',
      '\u1ECD': 'o',
      '\u1ED9': 'o',
      '\u01EB': 'o',
      '\u01ED': 'o',
      '\u00F8': 'o',
      '\u01FF': 'o',
      '\u0254': 'o',
      '\uA74B': 'o',
      '\uA74D': 'o',
      '\u0275': 'o',
      '\u01A3': 'oi',
      '\u0223': 'ou',
      '\uA74F': 'oo',
      '\u24DF': 'p',
      '\uFF50': 'p',
      '\u1E55': 'p',
      '\u1E57': 'p',
      '\u01A5': 'p',
      '\u1D7D': 'p',
      '\uA751': 'p',
      '\uA753': 'p',
      '\uA755': 'p',
      '\u24E0': 'q',
      '\uFF51': 'q',
      '\u024B': 'q',
      '\uA757': 'q',
      '\uA759': 'q',
      '\u24E1': 'r',
      '\uFF52': 'r',
      '\u0155': 'r',
      '\u1E59': 'r',
      '\u0159': 'r',
      '\u0211': 'r',
      '\u0213': 'r',
      '\u1E5B': 'r',
      '\u1E5D': 'r',
      '\u0157': 'r',
      '\u1E5F': 'r',
      '\u024D': 'r',
      '\u027D': 'r',
      '\uA75B': 'r',
      '\uA7A7': 'r',
      '\uA783': 'r',
      '\u24E2': 's',
      '\uFF53': 's',
      '\u00DF': 's',
      '\u015B': 's',
      '\u1E65': 's',
      '\u015D': 's',
      '\u1E61': 's',
      '\u0161': 's',
      '\u1E67': 's',
      '\u1E63': 's',
      '\u1E69': 's',
      '\u0219': 's',
      '\u015F': 's',
      '\u023F': 's',
      '\uA7A9': 's',
      '\uA785': 's',
      '\u1E9B': 's',
      '\u24E3': 't',
      '\uFF54': 't',
      '\u1E6B': 't',
      '\u1E97': 't',
      '\u0165': 't',
      '\u1E6D': 't',
      '\u021B': 't',
      '\u0163': 't',
      '\u1E71': 't',
      '\u1E6F': 't',
      '\u0167': 't',
      '\u01AD': 't',
      '\u0288': 't',
      '\u2C66': 't',
      '\uA787': 't',
      '\uA729': 'tz',
      '\u24E4': 'u',
      '\uFF55': 'u',
      '\u00F9': 'u',
      '\u00FA': 'u',
      '\u00FB': 'u',
      '\u0169': 'u',
      '\u1E79': 'u',
      '\u016B': 'u',
      '\u1E7B': 'u',
      '\u016D': 'u',
      '\u00FC': 'u',
      '\u01DC': 'u',
      '\u01D8': 'u',
      '\u01D6': 'u',
      '\u01DA': 'u',
      '\u1EE7': 'u',
      '\u016F': 'u',
      '\u0171': 'u',
      '\u01D4': 'u',
      '\u0215': 'u',
      '\u0217': 'u',
      '\u01B0': 'u',
      '\u1EEB': 'u',
      '\u1EE9': 'u',
      '\u1EEF': 'u',
      '\u1EED': 'u',
      '\u1EF1': 'u',
      '\u1EE5': 'u',
      '\u1E73': 'u',
      '\u0173': 'u',
      '\u1E77': 'u',
      '\u1E75': 'u',
      '\u0289': 'u',
      '\u24E5': 'v',
      '\uFF56': 'v',
      '\u1E7D': 'v',
      '\u1E7F': 'v',
      '\u028B': 'v',
      '\uA75F': 'v',
      '\u028C': 'v',
      '\uA761': 'vy',
      '\u24E6': 'w',
      '\uFF57': 'w',
      '\u1E81': 'w',
      '\u1E83': 'w',
      '\u0175': 'w',
      '\u1E87': 'w',
      '\u1E85': 'w',
      '\u1E98': 'w',
      '\u1E89': 'w',
      '\u2C73': 'w',
      '\u24E7': 'x',
      '\uFF58': 'x',
      '\u1E8B': 'x',
      '\u1E8D': 'x',
      '\u24E8': 'y',
      '\uFF59': 'y',
      '\u1EF3': 'y',
      '\u00FD': 'y',
      '\u0177': 'y',
      '\u1EF9': 'y',
      '\u0233': 'y',
      '\u1E8F': 'y',
      '\u00FF': 'y',
      '\u1EF7': 'y',
      '\u1E99': 'y',
      '\u1EF5': 'y',
      '\u01B4': 'y',
      '\u024F': 'y',
      '\u1EFF': 'y',
      '\u24E9': 'z',
      '\uFF5A': 'z',
      '\u017A': 'z',
      '\u1E91': 'z',
      '\u017C': 'z',
      '\u017E': 'z',
      '\u1E93': 'z',
      '\u1E95': 'z',
      '\u01B6': 'z',
      '\u0225': 'z',
      '\u0240': 'z',
      '\u2C6C': 'z',
      '\uA763': 'z',
      '\u0386': '\u0391',
      '\u0388': '\u0395',
      '\u0389': '\u0397',
      '\u038A': '\u0399',
      '\u03AA': '\u0399',
      '\u038C': '\u039F',
      '\u038E': '\u03A5',
      '\u03AB': '\u03A5',
      '\u038F': '\u03A9',
      '\u03AC': '\u03B1',
      '\u03AD': '\u03B5',
      '\u03AE': '\u03B7',
      '\u03AF': '\u03B9',
      '\u03CA': '\u03B9',
      '\u0390': '\u03B9',
      '\u03CC': '\u03BF',
      '\u03CD': '\u03C5',
      '\u03CB': '\u03C5',
      '\u03B0': '\u03C5',
      '\u03C9': '\u03C9',
      '\u03C2': '\u03C3'
    };
  }
  /**
   * Chuyển chuỗi có dấu sang không dấu ví dụ: á,ă,â... => a
   * @param text : chuỗi có dấu
   * @returns chuỗi không dấu
   */
  static stripSpecialChars(text) {
    const match = a => {
      return this.getDiacritics()[a] || a;
    };
    return this.compoundUnicode(text).replace(/[^\u0000-\u007E]/g, match);
  }
  /**
   * Chuyển chuỗi Unicode Tổ Hợp sang Unicode Dựng Sẵn
   * @param text : Unicode Tổ Hợp
   * @returns Unicode Dựng Sẵn
   */
  static compoundUnicode(unicode_str) {
    unicode_str = unicode_str.replace(/\u0065\u0309/g, "\u1EBB"); // ẻ
    unicode_str = unicode_str.replace(/\u0065\u0301/g, "\u00E9"); // é
    unicode_str = unicode_str.replace(/\u0065\u0300/g, "\u00E8"); // è
    unicode_str = unicode_str.replace(/\u0065\u0323/g, "\u1EB9"); // ẹ
    unicode_str = unicode_str.replace(/\u0065\u0303/g, "\u1EBD"); // ẽ
    unicode_str = unicode_str.replace(/\u00EA\u0309/g, "\u1EC3"); // ể
    unicode_str = unicode_str.replace(/\u00EA\u0301/g, "\u1EBF"); // ế
    unicode_str = unicode_str.replace(/\u00EA\u0300/g, "\u1EC1"); // ề
    unicode_str = unicode_str.replace(/\u00EA\u0323/g, "\u1EC7"); // ệ
    unicode_str = unicode_str.replace(/\u00EA\u0303/g, "\u1EC5"); // ễ
    unicode_str = unicode_str.replace(/\u0079\u0309/g, "\u1EF7"); // ỷ
    unicode_str = unicode_str.replace(/\u0079\u0301/g, "\u00FD"); // ý
    unicode_str = unicode_str.replace(/\u0079\u0300/g, "\u1EF3"); // ỳ
    unicode_str = unicode_str.replace(/\u0079\u0323/g, "\u1EF5"); // ỵ
    unicode_str = unicode_str.replace(/\u0079\u0303/g, "\u1EF9"); // ỹ
    unicode_str = unicode_str.replace(/\u0075\u0309/g, "\u1EE7"); // ủ
    unicode_str = unicode_str.replace(/\u0075\u0301/g, "\u00FA"); // ú
    unicode_str = unicode_str.replace(/\u0075\u0300/g, "\u00F9"); // ù
    unicode_str = unicode_str.replace(/\u0075\u0323/g, "\u1EE5"); // ụ
    unicode_str = unicode_str.replace(/\u0075\u0303/g, "\u0169"); // ũ
    unicode_str = unicode_str.replace(/\u01B0\u0309/g, "\u1EED"); // ử
    unicode_str = unicode_str.replace(/\u01B0\u0301/g, "\u1EE9"); // ứ
    unicode_str = unicode_str.replace(/\u01B0\u0300/g, "\u1EEB"); // ừ
    unicode_str = unicode_str.replace(/\u01B0\u0323/g, "\u1EF1"); // ự
    unicode_str = unicode_str.replace(/\u01B0\u0303/g, "\u1EEF"); // ữ
    unicode_str = unicode_str.replace(/\u0069\u0309/g, "\u1EC9"); // ỉ
    unicode_str = unicode_str.replace(/\u0069\u0301/g, "\u00ED"); // í
    unicode_str = unicode_str.replace(/\u0069\u0300/g, "\u00EC"); // ì
    unicode_str = unicode_str.replace(/\u0069\u0323/g, "\u1ECB"); // ị
    unicode_str = unicode_str.replace(/\u0069\u0303/g, "\u0129"); // ĩ
    unicode_str = unicode_str.replace(/\u006F\u0309/g, "\u1ECF"); // ỏ
    unicode_str = unicode_str.replace(/\u006F\u0301/g, "\u00F3"); // ó
    unicode_str = unicode_str.replace(/\u006F\u0300/g, "\u00F2"); // ò
    unicode_str = unicode_str.replace(/\u006F\u0323/g, "\u1ECD"); // ọ
    unicode_str = unicode_str.replace(/\u006F\u0303/g, "\u00F5"); // õ
    unicode_str = unicode_str.replace(/\u01A1\u0309/g, "\u1EDF"); // ở
    unicode_str = unicode_str.replace(/\u01A1\u0301/g, "\u1EDB"); // ớ
    unicode_str = unicode_str.replace(/\u01A1\u0300/g, "\u1EDD"); // ờ
    unicode_str = unicode_str.replace(/\u01A1\u0323/g, "\u1EE3"); // ợ
    unicode_str = unicode_str.replace(/\u01A1\u0303/g, "\u1EE1"); // ỡ
    unicode_str = unicode_str.replace(/\u00F4\u0309/g, "\u1ED5"); // ổ
    unicode_str = unicode_str.replace(/\u00F4\u0301/g, "\u1ED1"); // ố
    unicode_str = unicode_str.replace(/\u00F4\u0300/g, "\u1ED3"); // ồ
    unicode_str = unicode_str.replace(/\u00F4\u0323/g, "\u1ED9"); // ộ
    unicode_str = unicode_str.replace(/\u00F4\u0303/g, "\u1ED7"); // ỗ
    unicode_str = unicode_str.replace(/\u0061\u0309/g, "\u1EA3"); // ả
    unicode_str = unicode_str.replace(/\u0061\u0301/g, "\u00E1"); // á
    unicode_str = unicode_str.replace(/\u0061\u0300/g, "\u00E0"); // à
    unicode_str = unicode_str.replace(/\u0061\u0323/g, "\u1EA1"); // ạ
    unicode_str = unicode_str.replace(/\u0061\u0303/g, "\u00E3"); // ã
    unicode_str = unicode_str.replace(/\u0103\u0309/g, "\u1EB3"); // ẳ
    unicode_str = unicode_str.replace(/\u0103\u0301/g, "\u1EAF"); // ắ
    unicode_str = unicode_str.replace(/\u0103\u0300/g, "\u1EB1"); // ằ
    unicode_str = unicode_str.replace(/\u0103\u0323/g, "\u1EB7"); // ặ
    unicode_str = unicode_str.replace(/\u0103\u0303/g, "\u1EB5"); // ẵ
    unicode_str = unicode_str.replace(/\u00E2\u0309/g, "\u1EA9"); // ẩ
    unicode_str = unicode_str.replace(/\u00E2\u0301/g, "\u1EA5"); // ấ
    unicode_str = unicode_str.replace(/\u00E2\u0300/g, "\u1EA7"); // ầ
    unicode_str = unicode_str.replace(/\u00E2\u0323/g, "\u1EAD"); // ậ
    unicode_str = unicode_str.replace(/\u00E2\u0303/g, "\u1EAB"); // ẫ
    unicode_str = unicode_str.replace(/\u0045\u0309/g, "\u1EBA"); // Ẻ
    unicode_str = unicode_str.replace(/\u0045\u0301/g, "\u00C9"); // É
    unicode_str = unicode_str.replace(/\u0045\u0300/g, "\u00C8"); // È
    unicode_str = unicode_str.replace(/\u0045\u0323/g, "\u1EB8"); // Ẹ
    unicode_str = unicode_str.replace(/\u0045\u0303/g, "\u1EBC"); // Ẽ
    unicode_str = unicode_str.replace(/\u00CA\u0309/g, "\u1EC2"); // Ể
    unicode_str = unicode_str.replace(/\u00CA\u0301/g, "\u1EBE"); // Ế
    unicode_str = unicode_str.replace(/\u00CA\u0300/g, "\u1EC0"); // Ề
    unicode_str = unicode_str.replace(/\u00CA\u0323/g, "\u1EC6"); // Ệ
    unicode_str = unicode_str.replace(/\u00CA\u0303/g, "\u1EC4"); // Ễ
    unicode_str = unicode_str.replace(/\u0059\u0309/g, "\u1EF6"); // Ỷ
    unicode_str = unicode_str.replace(/\u0059\u0301/g, "\u00DD"); // Ý
    unicode_str = unicode_str.replace(/\u0059\u0300/g, "\u1EF2"); // Ỳ
    unicode_str = unicode_str.replace(/\u0059\u0323/g, "\u1EF4"); // Ỵ
    unicode_str = unicode_str.replace(/\u0059\u0303/g, "\u1EF8"); // Ỹ
    unicode_str = unicode_str.replace(/\u0055\u0309/g, "\u1EE6"); // Ủ
    unicode_str = unicode_str.replace(/\u0055\u0301/g, "\u00DA"); // Ú
    unicode_str = unicode_str.replace(/\u0055\u0300/g, "\u00D9"); // Ù
    unicode_str = unicode_str.replace(/\u0055\u0323/g, "\u1EE4"); // Ụ
    unicode_str = unicode_str.replace(/\u0055\u0303/g, "\u0168"); // Ũ
    unicode_str = unicode_str.replace(/\u01AF\u0309/g, "\u1EEC"); // Ử
    unicode_str = unicode_str.replace(/\u01AF\u0301/g, "\u1EE8"); // Ứ
    unicode_str = unicode_str.replace(/\u01AF\u0300/g, "\u1EEA"); // Ừ
    unicode_str = unicode_str.replace(/\u01AF\u0323/g, "\u1EF0"); // Ự
    unicode_str = unicode_str.replace(/\u01AF\u0303/g, "\u1EEE"); // Ữ
    unicode_str = unicode_str.replace(/\u0049\u0309/g, "\u1EC8"); // Ỉ
    unicode_str = unicode_str.replace(/\u0049\u0301/g, "\u00CD"); // Í
    unicode_str = unicode_str.replace(/\u0049\u0300/g, "\u00CC"); // Ì
    unicode_str = unicode_str.replace(/\u0049\u0323/g, "\u1ECA"); // Ị
    unicode_str = unicode_str.replace(/\u0049\u0303/g, "\u0128"); // Ĩ
    unicode_str = unicode_str.replace(/\u004F\u0309/g, "\u1ECE"); // Ỏ
    unicode_str = unicode_str.replace(/\u004F\u0301/g, "\u00D3"); // Ó
    unicode_str = unicode_str.replace(/\u004F\u0300/g, "\u00D2"); // Ò
    unicode_str = unicode_str.replace(/\u004F\u0323/g, "\u1ECC"); // Ọ
    unicode_str = unicode_str.replace(/\u004F\u0303/g, "\u00D5"); // Õ
    unicode_str = unicode_str.replace(/\u01A0\u0309/g, "\u1EDE"); // Ở
    unicode_str = unicode_str.replace(/\u01A0\u0301/g, "\u1EDA"); // Ớ
    unicode_str = unicode_str.replace(/\u01A0\u0300/g, "\u1EDC"); // Ờ
    unicode_str = unicode_str.replace(/\u01A0\u0323/g, "\u1EE2"); // Ợ
    unicode_str = unicode_str.replace(/\u01A0\u0303/g, "\u1EE0"); // Ỡ
    unicode_str = unicode_str.replace(/\u00D4\u0309/g, "\u1ED4"); // Ổ
    unicode_str = unicode_str.replace(/\u00D4\u0301/g, "\u1ED0"); // Ố
    unicode_str = unicode_str.replace(/\u00D4\u0300/g, "\u1ED2"); // Ồ
    unicode_str = unicode_str.replace(/\u00D4\u0323/g, "\u1ED8"); // Ộ
    unicode_str = unicode_str.replace(/\u00D4\u0303/g, "\u1ED6"); // Ỗ
    unicode_str = unicode_str.replace(/\u0041\u0309/g, "\u1EA2"); // Ả
    unicode_str = unicode_str.replace(/\u0041\u0301/g, "\u00C1"); // Á
    unicode_str = unicode_str.replace(/\u0041\u0300/g, "\u00C0"); // À
    unicode_str = unicode_str.replace(/\u0041\u0323/g, "\u1EA0"); // Ạ
    unicode_str = unicode_str.replace(/\u0041\u0303/g, "\u00C3"); // Ã
    unicode_str = unicode_str.replace(/\u0102\u0309/g, "\u1EB2"); // Ẳ
    unicode_str = unicode_str.replace(/\u0102\u0301/g, "\u1EAE"); // Ắ
    unicode_str = unicode_str.replace(/\u0102\u0300/g, "\u1EB0"); // Ằ
    unicode_str = unicode_str.replace(/\u0102\u0323/g, "\u1EB6"); // Ặ
    unicode_str = unicode_str.replace(/\u0102\u0303/g, "\u1EB4"); // Ẵ
    unicode_str = unicode_str.replace(/\u00C2\u0309/g, "\u1EA8"); // Ẩ
    unicode_str = unicode_str.replace(/\u00C2\u0301/g, "\u1EA4"); // Ấ
    unicode_str = unicode_str.replace(/\u00C2\u0300/g, "\u1EA6"); // Ầ
    unicode_str = unicode_str.replace(/\u00C2\u0323/g, "\u1EAC"); // Ậ
    unicode_str = unicode_str.replace(/\u00C2\u0303/g, "\u1EAA"); // Ẫ
    return unicode_str;
  }
}
function toBoolean(value) {
  return coerceBooleanProperty(value);
}
function toNumber(value, fallbackValue = 0) {
  return _isNumberValue(value) ? Number(value) : fallbackValue;
}
function toCssPixel(value) {
  return coerceCssPixelValue(value);
}
// tslint:disable no-invalid-this
/**
 * Get the function-property type's value
 */
function valueFunctionProp(prop, ...args) {
  return typeof prop === 'function' ? prop(...args) : prop;
}
function propDecoratorFactory(name, fallback) {
  function propDecorator(target, propName, originalDescriptor) {
    const privatePropName = `$$__${propName}`;
    if (Object.prototype.hasOwnProperty.call(target, privatePropName)) {
      console.log(`The prop "${privatePropName}" is already exist, it will be overrided by ${name} decorator.`);
    }
    Object.defineProperty(target, privatePropName, {
      configurable: true,
      writable: true
    });
    return {
      get() {
        return originalDescriptor && originalDescriptor.get ? originalDescriptor.get.bind(this)() : this[privatePropName];
      },
      set(value) {
        if (originalDescriptor && originalDescriptor.set) {
          originalDescriptor.set.bind(this)(fallback(value));
        }
        this[privatePropName] = fallback(value);
      }
    };
  }
  return propDecorator;
}
/**
 * Input decorator that handle a prop to do get/set automatically with toBoolean
 *
 * Why not using @InputBoolean alone without @Input? AOT needs @Input to be visible
 *
 * @howToUse
 * ```
 * @Input() @InputBoolean() visible: boolean = false;
 *
 * // Act as below:
 * // @Input()
 * // get visible() { return this.__visible; }
 * // set visible(value) { this.__visible = value; }
 * // __visible = false;
 * ```
 */
function InputBoolean() {
  return propDecoratorFactory('InputBoolean', toBoolean);
}
function InputCssPixel() {
  return propDecoratorFactory('InputCssPixel', toCssPixel);
}
function InputNumber(fallbackValue) {
  return propDecoratorFactory('InputNumber', value => toNumber(value, fallbackValue));
}
function ensureInBounds(value, boundValue) {
  return value ? value < boundValue ? value : boundValue : boundValue;
}
function inNextTick() {
  const timer = new Subject();
  Promise.resolve().then(() => timer.next());
  return timer.pipe(take(1));
}

/**
 * Generated bundle index. Do not edit.
 */

export { InputBoolean, InputCssPixel, InputNumber, TDSHelperArray, TDSHelperObject, TDSHelperString, ensureInBounds, inNextTick, toBoolean, toCssPixel, toNumber, valueFunctionProp };
