import {HttpErrorResponse, HttpEvent, HttpInterceptorFn, HttpStatusCode} from '@angular/common/http';
import { TOKEN } from '../constants/authen-key.const';
import { catchError, Observable, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from '@environments/environment';

export const authInterceptor: HttpInterceptorFn = (req, next): Observable<HttpEvent<unknown>> => {
  const router = inject(Router);
  const authService = inject(AuthService);
  // Skip interceptor for aShip
  if (req.url.includes(environment.aShipUrl)) {
    return next(req);
  }

  // Retrieve the JWT token from localStorage or a secure storage
  const token = localStorage.getItem(TOKEN);
  const tenantId = authService.getTenantId();

  // Clone the request and add the Authorization header if the token exists
  const authReq = token
    ? req.clone({
        setHeaders: {
          app: "omni",
          Authorization: `Bearer ${token}`,
        }, 
      })
    : req;
  
  // Add tenantId to the request headers to identify the tenant
  const authWithTenantIdReq = tenantId
    ? authReq.clone({
        setHeaders: {
          Tenant: tenantId,
        },
      })
    : authReq;
  
  return next(authWithTenantIdReq)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          authService.clearStorage();
          router.navigate(['/sign-in']);
        } 
        // else if (error.status === HttpStatusCode.Forbidden) {
        //   router.navigate(['/403']);
        // }
        return throwError(() => error.error);
      })
    );
}