import { Component, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs';
import { StoreListComponent } from '../../../stores/components/store-list/store-list.component';
import { TDSCardModule } from "tds-ui/card";
import { UserService } from '../../services/user.service';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { UserInfo } from '../../../../core/models/user.model';
import { TDSMessageService } from 'tds-ui/message';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { AddressPipe } from '../../../../shared/pipes/address.pipe';
import { TDSModalService } from 'tds-ui/modal';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { CommonModule } from '@angular/common';
import { HeaderService } from '../../../../shared/services/header.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    StoreListComponent,
    TDSCardModule,
    TDSButtonModule,
    TDSAvatarModule,
    TDSTagModule,
    TDSSpinnerModule,
    AddressPipe
  ],
  providers: [
    UserService,
    TDSMessageService,
    TDSDestroyService,
    TDSModalService
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  userInfo!: UserInfo;
  isLoading = false;

  constructor(
    private readonly userService: UserService,
    private readonly msgService: TDSMessageService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly modalService: TDSModalService,
    private readonly headerService: HeaderService,
  ) {

  }

  ngOnInit(): void {
    this.loadUserInfo();
  }

  loadUserInfo(): void {
    this.isLoading = true;

    this.userService.getUserInfo$()
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res) => {
          this.userInfo = res;
        },
        error: (error) => {
          this.msgService.error(error.message);
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  openEditProfile(user: UserInfo): void {
    const modal = this.modalService.create({
      title: 'Chỉnh sửa thông tin cá nhân',
      content: EditProfileComponent,
      size:'lg',
      bodyStyle: {
        padding: '0px'
      },
      componentParams: {
        userInfo: user
      },
      footer: null
    });

    modal.afterClose.subscribe((result: any) => {
      if (result) {
        this.loadUserInfo();
        this.headerService.triggerReloadHeader();
      }
    });
  }
}