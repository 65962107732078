import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { authInterceptor } from './core/interceptors/auth.interceptor';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi'; 
import { TDS_I18N, vi_VN } from 'tds-ui/i18n';

registerLocaleData(localeVi);

/**
 * Register interceptors
 */
const HTTP_INTERCEPTORS = [
  authInterceptor
]

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      withInterceptors(HTTP_INTERCEPTORS)
    ),
    { provide: TDS_I18N, useValue: vi_VN }
  ],
};
