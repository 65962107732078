import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSPopoverModule } from 'tds-ui/popover';
import { TDSInputModule } from 'tds-ui/tds-input';
import { StoreCategoryService } from '../../services/store-category.service';
import { TDSMessageService } from 'tds-ui/message';
import { CreateStoreCategoryReq, StoreCategory } from '../../models/store-category.model';
import { takeUntil } from 'rxjs';
import { ErrorResponse } from 'app/core/models/error-response.model';
import { TDSToolTipModule } from "tds-ui/tooltip";

@Component({
  selector: 'app-quick-add-store-category',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSFormFieldModule,
    TDSButtonModule,
    TDSInputModule,
    TDSPopoverModule,
    TDSToolTipModule
  ],
  providers: [TDSDestroyService],
  templateUrl: './quick-add-store-category.component.html',
  styleUrl: './quick-add-store-category.component.scss'
})
export class QuickAddStoreCategoryComponent implements OnInit {
  @Input() storeId!: string;
  @Output() categoryAdded = new EventEmitter<void>();
  visible = false;
  categoryForm!: FormGroup;

  constructor(
    private readonly storeCategoryService: StoreCategoryService,
    private readonly msgService: TDSMessageService,
    private readonly tdsDestroyService: TDSDestroyService
  ) {}

  ngOnInit(): void {
    this.createCategoryForm();
  }

  createCategoryForm(): void {
    this.categoryForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(100)])
    });
  }

  change(event: boolean): void {
    this.visible = event;
    if (event) {
      this.categoryForm.reset();
    }
  }

  cancel(): void {
    this.visible = false;
    this.categoryForm.reset();
  }

  save(): void {
    if (!this.categoryForm.valid) return;
    
    const payload: CreateStoreCategoryReq = {
      name: this.categoryForm.get('name')?.value!,
      storeId: this.storeId
    };

    this.storeCategoryService.createStoreCategory$(payload)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res: StoreCategory) => {
          if (res) {
            this.msgService.success('Thêm mới ngành hàng thành công');
            this.categoryForm.reset();
            this.categoryAdded.emit();
            this.visible = false;
          }
        },
        error: (err: ErrorResponse) => {
          this.msgService.error(err.error.message ? err.error.message : 'Thêm mới ngành hàng thất bại');
        }
      });
  }
}