<button tds-button-icon 
  color="primary"
  type="button"
  tds-tooltip
  tooltipTitle="Thêm ngành hàng"
  tds-popover
  [(popoverVisible)]="visible"
  (popoverVisibleChange)="change($event)" 
  popoverTrigger="click" 
  [popoverPlacement]="['topRight']"
  [popoverContent]="categoryFormTpl"
>
  <i class="tdsi-plus-fill"></i>
</button>

<ng-template #categoryFormTpl>
  <div class="p-1 w-[312px]">
    <form [formGroup]="categoryForm"
      class="flex flex-col gap-4"
    >
      <div class="flex flex-col gap-2">
        <div class="tds-body-1 tds-color-text-primary font-semibold w-full">
          Thêm ngành hàng
        </div>
        <tds-form-field class="w-full">                    
          <input
            tdsInput
            formControlName="name"
            placeholder="Nhập tên ngành hàng"
            [maxlength]="100"
          />
          <tds-error>Vui lòng nhập tên ngành hàng</tds-error>
        </tds-form-field>
      </div>
      <div class="flex gap-2 justify-end">
        <button 
          tds-button-outline
          color="secondary"
          size="sm"
          (click)="cancel()"
          class="min-w-[50px]"
          type="button"
        >
          Hủy
        </button>
        <button
          tds-button
          size="sm"
          color="primary"
          (click)="save()"
          class="min-w-[62px]"
          type="submit"
        >
          Lưu
        </button>
      </div>
    </form>
  </div>
</ng-template>