<div class="tds-color-background-neutral-minimalist w-full h-full rounded-lg px-2 py-3 flex flex-col gap-3">
  <section class="flex items-center w-full justify-between px-2">
    <h3 class="tds-heading-6 font-semibold">Cửa hàng quản lý ({{storeBranches.length}})</h3>
    <button 
      tds-button-outline
      color="info"
      [tdsPrefixIcon]="'tdsi-plus-circle-fill'"
      (tdsBtnClick)="onAddBranch()"
    >
      Thêm cửa hàng
    </button>
  </section>

  <section class="grid grid-cols-2 gap-3 w-full max-h-[484px] overflow-y-auto tds-custom-scroll">
    @for (branch of storeBranches; track branch.id; let i = $index) {
      <section class="w-full bg-white flex flex-col gap-3 p-3 rounded"
      >
        <div class="tds-body-1 flex items-center gap-2">
          <i class="tdsi-shop-fill tds-color-icon-secondary text-xl leading-none"></i>
          <span class="font-semibold">{{branch.name}}</span>
          @if (!branch.isActive) {
            <tds-tag status="secondary">Dừng hoạt động</tds-tag>
          }
        </div>
        <div class="flex items-center gap-2 tds-body-2">
          <span class="w-45">Số điện thoại:</span>
          <span class="flex-1 font-semibold">{{branch.phoneNumber}}</span>
        </div>
        <div class="flex items-center gap-2 tds-body-2">
          <span class="w-45">Email:</span>
          <span class="flex-1 font-semibold">{{branch.email || '---'}}</span>
        </div>
        <div class="flex items-start gap-2 tds-body-2">
          <span class="w-45">Địa chỉ:</span>
          <span class="flex-1 font-semibold">{{branch | address}}</span>
        </div>
        <div class="flex items-center gap-2 w-full">
          <button
            tds-button-outline
            [size]="'sm'"
            color="info"
            (tdsBtnClick)="onEditBranch(branch)"
            
          >
            Chỉnh sửa
          </button>
          <button
            tds-button-outline
            [size]="'sm'"
            [color]="!branch.isActive ? 'primary' : 'error'"
            (tdsBtnClick)="setActive(branch)"
          >
            {{!branch.isActive ? 'Kích hoạt' : 'Hủy kích hoạt'}}
          </button>
        </div>
      </section>
    }
  </section>
</div>