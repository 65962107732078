  @if (store) {
    <div class="w-full flex flex-col gap-2">
      <tds-card>
        <tds-card-header>
          <div class="flex items-start justify-between w-full pt-1">
            <div class="flex items-center gap-3">
              <tds-avatar
                [tdsSrc]="store.avatar!"
                [shape]="'circle'"
                [size]="96"
                class="border tds-color-border-secondary"
              ></tds-avatar>
              <div class="flex flex-1 flex-col gap-1">
                <span class="tds-color-text-info font-semibold">Doanh nghiệp</span>
                <span class="tds-heading-5 font-semibold tds-color-text-primary">{{store.name}}</span>
              </div>
            </div>

            <div class="flex items-center gap-3">
              <button tds-button-outline color="secondary" class="min-w-[100px]"
                (tdsBtnClick)="onEditStore(store)"
              >
                <span class="flex items-center">
                  <i class="tdsi-edit-fill"></i>
                  Chỉnh sửa
                </span>
              </button>
              <button tds-button color="primary" class="min-w-[100px]"
                (tdsBtnClick)="onCreateStore()"
              >
                <span class="flex items-center">
                  <i class="tdsi-plus-circle-fill"></i>
                  Tạo doanh nghiệp
                </span>
              </button>
            </div>
          </div>
        </tds-card-header>
        <tds-card-content>
          <div class="w-full px-3 py-4 flex flex-col gap-3">  
            <div class="flex items-center gap-2 w-full tds-body-2 tds-color-text-primary">
              <span class="w-45">Chủ doanh nghiệp:</span>
              <span class="flex-1 font-semibold">{{store.ownerName}}</span>
            </div>
            <div class="flex items-center gap-2 w-full">
              <span class="w-45">Số điện thoại:</span>
              <span class="flex-1 font-semibold">{{store.phoneNumber}}</span>
            </div>
            <div class="flex items-center gap-2 w-full">
              <span class="w-45">Email:</span>
              <span class="flex-1 font-semibold">{{store.email || '---'}}</span>
            </div>
            <div class="flex items-center gap-2 w-full">
              <span class="w-45">Ngành hàng kinh doanh:</span>
              <span class="flex-1 flex gap-2">
                @if (store.storeCategoryList && store.storeCategoryList.length) {
                  @for (category of store.storeCategoryList; track category.id) {
                    <tds-tag status='custom'
                      [size]="'md'"
                      class="!tds-color-background-neutral-minimal text-caption-1 font-semibold"
                    >
                      <span class="tds-color-text-secondary">{{category?.name}}</span>
                    </tds-tag>
                  }
                } @else {
                  <span class="flex-1 font-semibold">---</span>
                }
              </span>
            </div>
            <div class="flex items-center gap-2 w-full">
              <span class="w-45">Địa chỉ:</span>
              <span class="flex-1 font-semibold">{{store | address}}</span>
            </div>
          </div>
        </tds-card-content>
      </tds-card>
      @if (store.storeBranchList) {
        <app-branch-list
          [store]="store"
          (onModalClose)="onReloadData()"
        ></app-branch-list>
      }
    </div>
  }
