import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, fromEvent, map, takeUntil } from 'rxjs';
import { TDSAvatarModule } from "tds-ui/avatar";
import { TDSButtonModule } from "tds-ui/button";
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSDrawerModule, TDSDrawerService } from "tds-ui/drawer";
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalRef, TDSModalService } from 'tds-ui/modal';
import { TDSPageHeaderModule } from "tds-ui/page-header";
import { TDSTableModule, TDSTableQueryParams } from "tds-ui/table";
import { TDSTagModule } from "tds-ui/tag";
import { TDSInputModule } from "tds-ui/tds-input";
import { IRole, IRoleList } from '../../models/role.model';
import { RoleService } from '../../services/role.service';
import { CreateRoleComponent } from '../create-role/create-role.component';
import { EditRoleComponent } from '../edit-role/edit-role.component';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { TDSDropDownModule } from "tds-ui/dropdown";

@Component({
  selector: 'app-list-role',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TDSPageHeaderModule,
    TDSButtonModule,
    TDSTableModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSAvatarModule,
    TDSTagModule,
    TDSDrawerModule,
    TDSDropDownModule,
    TDSToolTipModule,
  ],
  providers: [
    TDSDrawerService,
    TDSModalService,
    TDSDestroyService
  ],
  templateUrl: './list-role.component.html',
  styleUrl: './list-role.component.scss'
})
export class ListRoleComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;
  searchValue: string = '';
  roles: IRole[] = [];
  total: number = 0;

  isVisible: boolean = false;
  isVisibleEdit: boolean = false;

  isLoading = false;
  pageIndex = 1;
  pageSize = 20;
  search: string = '';

  constructor(
    private readonly roleService: RoleService,
    private readonly drawerService: TDSDrawerService,
    private readonly msgService: TDSMessageService,
    private readonly modalService: TDSModalService,
    private readonly destroyService: TDSDestroyService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((event: any) => event.target.value),
        takeUntil(this.destroyService)
      )
      .subscribe((value: string) => {
        this.searchValue = value;
        this.resetTableParams();
        this.loadRoles(0, this.pageSize, this.searchValue);
      });
  }

  onQueryParamsChange(params: TDSTableQueryParams): void {
    let { pageIndex, pageSize } = params;  
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.loadRoles(this.pageIndex - 1, pageSize, this.searchValue);
  }

  loadRoles(pageIndex: number = 0, pageSize: number = 20, search?: string): void {
    this.isLoading = true;
    this.roleService.getRoles$(
      pageIndex * pageSize, 
      pageSize, 
      search ?? this.searchValue
    )
    .pipe(
      takeUntil(this.destroyService)
    )
    .subscribe({
      next: (res: IRoleList) => {
        this.roles = res.roleList;
        this.total = res.totalCount;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
      }
    });
  }

  addNewRole(): void {
    const drawerRef = this.drawerService.create<CreateRoleComponent>({
      title: 'Thêm mới nhóm vai trò',
      content: CreateRoleComponent,
      width: '768px',
      height: '100vh',
      maskClosable: false,
      bodyStyle: {
        padding: '0px'
      }
    });

    drawerRef.afterClose.subscribe((res: boolean | undefined | null) => {
      if (!res) return;
      this.resetTableParams();
      this.loadRoles();
    });
  }

  editRole(role: IRole): void {
    const drawerRef = this.drawerService.create<EditRoleComponent, { role: IRole }>({
      title: 'Chỉnh sửa nhóm vai trò',
      closable: true,
      content: EditRoleComponent,
      width: '768px',
      height: '100vh',
      maskClosable: false,
      bodyStyle: {
        padding: '0px'
      },
      tdsData: {
        role: role
      }
    });

    drawerRef.afterClose.subscribe((res: boolean | undefined | null) => {
      if (!res) return;
      this.resetTableParams();
      this.loadRoles();
    });
  }

  onDeleteRole(id: string, modal: TDSModalRef): void {
    this.roleService.deleteRole$(id)
      .pipe(
        takeUntil(this.destroyService)
      )
      .subscribe({
        next: () => {
          this.msgService.success('Xóa nhóm vai trò thành công');
          modal.destroy(true);
        },
        error: () => {
          this.msgService.success('Đã xảy ra lỗi, vui lòng thử lại sau');
          modal.destroy();
        }
      })
  }

  deleteRole(role: IRole): void {
    const modal = this.modalService.error({
      title: 'Xóa nhóm vai trò',
      confirmIcon: "tdsi-trash-fill",
      content: `<div class="tds-body-2">Bạn có chắc chắn muốn xóa vai trò này "<span class="font-semibold">${role.name}</span>"?</div>`,
      onOk: () => {
        this.onDeleteRole(role.id, modal);
        return false;//prevent close modal until delete success
      },
      onCancel:()=> {},
      okText: "Xác nhận",
      cancelText: "Đóng",
    });

    modal.afterClose.subscribe((isDeleted: boolean | undefined | null) => {
      if (!isDeleted) return;
      this.resetTableParams();
      this.loadRoles();
    });
  }

  private resetTableParams(): void {
    this.pageIndex = 1;
    this.pageSize = 20;
  }

  clearSearch(): void {
    this.searchValue = '';
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    this.resetTableParams();
    this.loadRoles();
  }

  refreshTable(): void {
    this.resetTableParams();
    this.clearSearch();
    this.loadRoles();
  }
}
