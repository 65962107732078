import { Injectable } from '@angular/core';
import { USER_INFO_ENDPOINT } from 'app/core/constants/api-endpoint.const';
import { UserInfo } from 'app/core/models/user.model';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';
import { IUpdateUserInfoReq } from '../models/update-user-info-req.model';
import { TDSHelperString } from 'tds-ui/shared/utility';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getUserInfo$(storeId?: string): Observable<UserInfo> {
    const query = TDSHelperString.hasValueString(storeId) ? `?storeId=${storeId}` : '';
    return this.apiService.get<UserInfo>(`${USER_INFO_ENDPOINT}${query}`);
  }

  updateUserInfo$(updateInfo: IUpdateUserInfoReq): Observable<UserInfo> {
    return this.apiService.put<UserInfo>(USER_INFO_ENDPOINT, updateInfo);
  }
}