export const SIGN_IN_ENDPOINT = '/api/app/user/log-in';
export const SIGN_OUT_ENDPOINT = '/api/app/user/log-out';
export const REFRESH_TOKEN_ENDPOINT = '/api/app/user/refresh-token';
export const USER_INFO_ENDPOINT = '/api/app/user';

export const STORE_ENDPOINT = '/api/app/store';
export const STORE_CATEGORY_ENDPOINT = '/api/app/store-category';
export const STORE_BRANCH_ENDPOINT = '/api/app/store-branch';
export const ACTIVE_STORE_BRANCH_ENDPOINT = '/api/app/store-branch/active';

export const UPLOAD_IMAGE_ENDPOINT = '/api/app/file';

export const ROLE_ENDPOINT = '/api/app/role';
export const USER_PERMISSION_ENDPOINT = '/api/app/role/user-permissions';
export const DEFAULT_PERMISSION_ENDPOINT = '/api/app/role/default-permissions';

export const EMPLOYEE_ENDPOINT = '/api/app/employee';
export const EMPLOYEE_LIST_ENDPOINT = '/api/app/employee/list';
