import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TDSPageHeaderModule } from 'tds-ui/page-header';
import { TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSTabsFilterOption, TDSTabsModule } from "tds-ui/tabs";
import { TDSTagModule } from 'tds-ui/tag';

@Component({
  selector: 'app-settings-tag-selector',
  standalone: true,
  imports: [
    CommonModule,
    TDSPageHeaderModule,
    RouterOutlet,
    TDSTabsModule,
    TDSTagModule],
  templateUrl: './settings-tag-selector.component.html',
  styleUrl: './settings-tag-selector.component.scss'
})
export class SettingsTagSelectorComponent {
  lstSetting$: Observable<TDSSafeAny> = of([
    {
      icon: "tdsi-product-fill",
      name: "Danh mục sản phẩm",
      value: 0
    },
    {
      icon: "tdsi-shop-fill",
      name: "Thuộc tính",
      value: 1
    },
  ]);

  lstSetting: Array<TDSTabsFilterOption> = [
    {
      icon: "tdsi-product-fill",
      label: "Danh mục sản phẩm",
      value: 0
    },
    {
      icon: "tdsi-shop-fill",
      label: "Thuộc tính",
      value: 1
    }
  ]
  selectedSetting: number = 0;
  ROUTING_CATEGORY = "settings/categories/list";
  ROUTING_ATTRIBUTE = "settings/attribute/list";

  constructor(private _router: Router) {
   this.checkUrl();
  }

  checkUrl(){
    const url = this._router.url;
    if(url.includes(this.ROUTING_CATEGORY)) this.selectedSetting = 0;
    if(url.includes(this.ROUTING_ATTRIBUTE)) this.selectedSetting = 1;
  }

  onSettingSelected(event: TDSTabsFilterOption) {
    this.selectedSetting = event.value;
    switch (this.selectedSetting) {
      case 0:
        this._router.navigateByUrl(this.ROUTING_CATEGORY);
        break;
      case 1:
        this._router.navigateByUrl(this.ROUTING_ATTRIBUTE);
        break;
      default:
        break;
    }
  }
}
