import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { ProvinceResponse, DistrictResponse, WardResponse, AddressResponse } from '../models/address.model';
import { PROVINCES, DISTRICTS, WARD } from '../constants/aship-address-endpoint.const';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends BaseApiService {
  protected override readonly apiUrl = environment.aShipUrl;

  constructor(_http: HttpClient) {
    super(_http);
  }

  getProvinces$(): Observable<AddressResponse<ProvinceResponse[]>> {
    return this.get<AddressResponse<ProvinceResponse[]>>(PROVINCES);
  }

  getDistricts$(provinceCode: string): Observable<AddressResponse<DistrictResponse[]>> {
    const url = DISTRICTS + '?provinceCode=' + provinceCode;
    return this.get<AddressResponse<DistrictResponse[]>>(url);
  }

  getWards$(districtCode: string): Observable<AddressResponse<WardResponse[]>> {
    const url = WARD + '?districtCode=' + districtCode;
    return this.get<AddressResponse<WardResponse[]>>(url);
  }
}
