import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSModalModule } from 'tds-ui/modal';
import { TDSPageHeaderModule } from 'tds-ui/page-header';
import { TDSTableModule, TDSTableQueryParams } from 'tds-ui/table';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { EmployeeService } from '../../services/employee.service';
import { IEmployee, IEmployeeList } from '../../models/employee.model';
import { takeUntil } from 'rxjs';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSInputModule } from 'tds-ui/tds-input';
import { EmployeeActionsComponent } from '../employee-actions/employee-actions.component';
import { EmployeeStatusComponent } from '../employee-status/employee-status.component';
import { EEmployeeStatus } from '../../enums/employee-status.enum';
import { EmployeeBranchesDetailComponent } from '../employee-branches-detail/employee-branches-detail.component';

@Component({
  selector: 'app-list-employees',
  standalone: true,
  imports: [
    CommonModule,
    TDSPageHeaderModule,
    TDSInputModule,
    TDSFormFieldModule,
    TDSButtonModule,
    TDSTableModule,
    TDSAvatarModule,
    TDSTagModule,
    TDSToolTipModule,
    TDSModalModule,
    EmployeeActionsComponent,
    EmployeeStatusComponent,
    EmployeeBranchesDetailComponent
  ],
  providers: [
    EmployeeService,
    TDSDestroyService
  ],
  templateUrl: './list-employees.component.html',
  styleUrl: './list-employees.component.scss'
})
export class ListEmployeesComponent implements OnInit {
  storeId: string = '';

  employees: IEmployee[] = [];
  isLoading = false;
  total: number = 0;
  pageIndex = 1;
  pageSize = 20;

  expandSet = new Set<string>();

  constructor(
    private readonly employeeService: EmployeeService,
    private readonly destroyService: TDSDestroyService
  ) { }

  ngOnInit(): void {
    this.employees = this.createMockData();
    this.total = this.employees.length;
  }

  onQueryParamsChange(params: TDSTableQueryParams): void {
    let { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    // this.loadEmployees(this.pageIndex - 1, pageSize);
  }

  loadEmployees(pageIndex: number = 0, pageSize: number = 20): void {
    this.employeeService.getEmployeesInStore$(this.storeId, { skip: pageIndex, take: pageSize })
      .pipe(
        takeUntil(this.destroyService)
      )
      .subscribe({
        next: (res: IEmployeeList) => {
          this.employees = res.employeeList;
          this.total = res.totalCount;
        }
      })
  }

  addNewEmployee(): void {

  }

  onActionCompleted(): void {

  }

  onExpandChange(id: string): void {
    this.expandSet.has(id) ? this.expandSet.delete(id) : this.expandSet.add(id);
  }

  private createMockData(): IEmployee[] {
    return Array.from({ length: 50 }, (_, index) => ({
      id: `emp-${index + 1}`,
      name: `Employee ${index + 1}`,
      phoneNumber: `090${Math.floor(1000000 + Math.random() * 9000000)}`,
      email: `employee${index + 1}@example.com`,
      birthday: new Date(1980 + index % 40, index % 12, index % 28 + 1),
      street: `Street ${index + 1}`,
      ward: `Ward ${index % 10 + 1}`,
      district: `District ${index % 5 + 1}`,
      city: `City ${index % 3 + 1}`,
      isLocked: index % 2 !== 0 || index % 3 !== 0,//extra: backend must add this field to check if the employee is locked
      userStatus: index % 2 === 0 ? EEmployeeStatus.Active : index % 3 === 0 ? EEmployeeStatus.Pending : EEmployeeStatus.Inactive,
      userStatusName: this.getUserStatusName(index % 2 === 0 ? EEmployeeStatus.Active : index % 3 === 0 ? EEmployeeStatus.Pending : EEmployeeStatus.Inactive),
      creator: {
        id: `creator-${index % 5}`,
        name: `Creator ${index % 5}`,
        isOnline: index % 2 === 0,
        avatar: `https://khoinguonsangtao.vn/wp-content/uploads/2022/08/avatar-nu-cute-dep.jpg`,
      },
      creationTime: new Date(Date.now() - Math.random() * 1000000000),
      userInStoreBranches: Array.from({ length: Math.floor(1 + Math.random() * 3) }, (_, subIndex) => ({
        id: `branch-${index + 1}-${subIndex + 1}`,
        storeId: `store-${subIndex + 1}`,
        name: `Store Branch ${subIndex + 1}`,
        roleName: subIndex % 2 === 0 ? 'Manager' : 'Staff',
        updator: {
          id: `updator-${index % 3}`,
          name: `Updator ${index % 3}`,
          isOnline: subIndex % 2 === 0,
          avatar: `https://khoinguonsangtao.vn/wp-content/uploads/2022/08/avatar-nu-cute-dep.jpg`,
        },
        lastModificationTime: new Date(Date.now() - Math.random() * 1000000000),
        userStatus: index % 2 === 0 ? EEmployeeStatus.Active : index % 3 === 0 ? EEmployeeStatus.Pending : EEmployeeStatus.Inactive,
        userStatusName: this.getUserStatusName(index % 2 === 0 ? EEmployeeStatus.Active : index % 3 === 0 ? EEmployeeStatus.Pending : EEmployeeStatus.Inactive),
      })),
    }));
  }

  private getUserStatusName(status: number): string {
    switch (status) {
      case 0:
        return "Đang làm việc";
      case 1:
        return "Ngưng làm việc";
      case 2:
        return "Chờ xác nhận";
      case 99:
        return "Không xác định";
      default:
        return "Không xác định";
    }
  }
}
