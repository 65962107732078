import { Component } from '@angular/core';
import { TDSHeaderModule } from 'tds-ui/header';
import { TDSLayoutModule } from 'tds-ui/layout';
import { TDSMenuModule } from 'tds-ui/menu';
import { DATA_MENU } from '../data/menu.data';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../components/header/header.component';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSSpinnerModule } from "tds-ui/progress-spinner";

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    TDSLayoutModule,
    TDSHeaderModule,
    TDSMenuModule,
    RouterOutlet,
    HeaderComponent,
    TDSAvatarModule,
    TDSSpinnerModule
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  lstData = DATA_MENU;
  isLoading = true;

  constructor(
    private readonly router: Router
  ) {}

  /**
   * Handle role list change
   * @param roleList 
   */
  roleListChange(roleList: string[]) {
    if (!roleList.length) {
      this.router.navigate(['/user/profile']);
    }
  }
}
