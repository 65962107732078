<ng-container>
  <tds-spin [spinning]="isLoading">
    <form class="w-full h-full flex flex-col"
      [formGroup]="branchForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="flex items-center gap-4 w-full px-10 py-5 tds-color-background-neutral-minimalist">
        <tds-avatar [size]="'xl'" icon="tdsi-shop-fill"
          class="tds-color-background-neutral-minimal text-tds-color-neutral-300-light dark:text-tds-color-neutral-300-dark"
        ></tds-avatar>

        <div class="w-full flex-1">
          <div class="flex items-center justify-between pb-2">
            <tds-label [tdsRequired]="true">Tên cửa hàng</tds-label> 
            <p class="tds-caption-1 tds-color-text-tertiary">{{branchForm.value.name?.length || 0}} / 100</p>
          </div>
          <tds-form-field class="w-full">
            <input tdsInput
              [maxlength]="100"
              formControlName="name"
              placeholder="Tối đa 100 ký tự"
            />
            <tds-error>Vui lòng nhập tên cửa hàng</tds-error>
          </tds-form-field>
        </div>
      </div>

      <div class="w-full flex flex-col gap-3 p-4">
        <div class="flex items-center gap-2">
          <tds-label class="w-1/3">Email</tds-label>
          <tds-form-field class="w-2/3 flex items-center gap-2">
            <input tdsInput 
              formControlName="email"
              placeholder="Nhập email"
            />
            @if (branchForm.get('email')?.errors?.['inValidEmail']) {
              <tds-error>Email không đúng định dạng</tds-error>
            }
          </tds-form-field>
        </div>

        <div class="flex items-start gap-2">
          <tds-label class="w-1/3">Số điện thoại</tds-label>
          <div class="w-2/3 flex flex-col gap-2">
            <tds-form-field class="w-full">
              <input tdsInput
                [maxlength]="10"
                formControlName="phoneNumber"
                placeholder="Nhập số điện thoại"
                numbersOnly
                (blur)="onPhoneBlur()"
                (focus)="onPhoneFocus()"              
              />
              @if (branchForm.get('phoneNumber')?.errors && phoneIsTouched) {
                <tds-error>Số điện thoại không hợp lệ</tds-error>
              }
            </tds-form-field>

            @if (subPhones.length < 2) {
              <div class="flex items-center gap-1 cursor-pointer tds-color-text-info font-semibold"
                (click)="onAddSubPhone()"
              >
                <i class="tdsi-plus-fill text-xl leading-none"></i>
                <span class="tds-body-2">Thêm SĐT phụ</span>
              </div>
            }

            <section class="flex flex-col gap-2"
              formArrayName="subPhones"
            >
              @for (subPhone of subPhones.controls; track subPhone.value; let i = $index) {
                <div class="flex gap-2 w-full"
                  [formGroupName]="i"
                >
                  <tds-form-field class="flex-1">
                    <input
                      tdsInput
                      formControlName="subPhone"
                      placeholder="Nhập số điện thoại"
                      [maxlength]="10"
                      numbersOnly
                      (blur)="onPhoneBlur()"
                      (keyup.tab)="onPhoneBlur()"
                      (focus)="onPhoneFocus()"
                    />
                    @if (subPhone.get('subPhone')?.errors && phoneIsTouched) {
                      <tds-error>Số điện thoại không hợp lệ</tds-error>
                    }
                  </tds-form-field>
                  <div class="!mt-0.5">
                    <button tds-button-flat-icon color="error"
                      type="button"
                      size="sm"
                      (click)="onRemoveSubPhone(i)"
                    >
                      <i class="tdsi-close-fill"></i>
                    </button>
                  </div>
                </div>
              }
            </section>
          </div>
        </div>

        <div class="flex items-start gap-2">
          <tds-label class="w-1/3">Địa chỉ</tds-label>
          <div class="w-2/3 flex flex-col gap-2">
            <tds-form-field class="w-full">
              <input tdsInput 
                formControlName="street"
                placeholder="Nhập số nhà, tên đường"
                [maxlength]="1000"
              />
              <tds-error>Vui lòng nhập địa chỉ</tds-error>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="provinces"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="city"
                placeholder="Tỉnh/thành phố"
                (selectChange)="onChangeProvince($event)"
              >
              </tds-select>
              <tds-error>Vui lòng chọn tỉnh/thành phố</tds-error>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="districts"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="district"
                placeholder="Quận/huyện"
                (selectChange)="onChangeDistrict($event)"
              >
              </tds-select>
              <tds-error>Vui lòng chọn quận/huyện</tds-error>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="wards"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="ward"
                placeholder="Phường/xã"
                (selectChange)="onChangeWard($event)"
              >
              </tds-select>
              <tds-error>Vui lòng chọn phường/xã</tds-error>
            </tds-form-field>
          </div>
        </div>

        <div class="flex items-center gap-2">
          <tds-label class="w-1/3">Múi giờ</tds-label>
          <div class="w-2/3 tds-body-2">UTC+7</div>
        </div>
      </div>
      <div *tdsModalFooter
        class="flex items-center justify-end gap-2 p-4"
      >
        <button tds-button
          color="secondary"
          class="min-w-[100px]"
          type="button"
          (tdsBtnClick)="onCancel()"
        >
          Hủy
        </button>
        <button tds-button
          color="primary"
          class="min-w-[100px]"
          type="submit"
          (tdsBtnClick)="onSubmit()"
        >
          Lưu
        </button>
      </div>
    </form>
  </tds-spin>
</ng-container>