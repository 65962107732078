<div class="flex items-center py-2 px-3 justify-between">
    <div class="font-semibold tds-heading-6">
        Thuộc tính
    </div>
    <div>
        <button 
            tds-button-outline-icon 
            color="secondary" 
            class="mr-2"
            tds-tooltip
            tooltipTitle="Sắp xếp theo ngày cập nhật"
            (tdsBtnClick)="changeSort()">
            <i [ngClass]="sortByName | sortDateClass"></i>
        </button>
        <button 
            tds-button 
            tds-popover
            popoverTrigger="click" 
            [popoverContent]="addAttributeTemplate"
            [popoverFooter]="footerTemplate"
            [(popoverVisible)]="isVisibleAddAttribute"
            popoverPlacement="bottomRight"
            color="primary" 
            (click)="openPopover()"
            [tdsPrefixIcon]="'tdsi-plus-circle-fill'">
            Thêm mới
        </button>
    </div>
</div>
<ng-template #addAttributeTemplate>
    <div class="w-72">
        <tds-spin [spinning]="isLoading" class="w-full h-full">
            <form [formGroup]="form" id="form">
                <tds-form-field class="w-full">
                    <tds-label tdsRequired="true">Thuộc tính</tds-label>
                    <input 
                        tdsInput 
                        autocomplete="off"  
                        placeholder="Tối đa {{rule.name.maxLength}} ký tự" 
                        formControlName="name"
                        [maxlength]="rule.name.maxLength"
                        (blur)="trimName()"
                        [required]='true' />
                    @if (this.form.get('name')?.errors?.['required'] || this.form.get('name')?.errors?.['whitespace']) {
                        <tds-error>Vui lòng nhập tên thuộc tính</tds-error>
                    }
                </tds-form-field>
                @if (isDuplicate) {
                    <tds-error>Thuộc tính đã tồn tại</tds-error>
                }
            </form>
        </tds-spin>
    </div>
</ng-template>

<ng-template #footerTemplate>
    <div class="flex justify-end">
        <button tds-button-outline 
            color="secondary" 
            class="mr-2"  
            [disabled]="isLoading"
            (click)="close()">
            Đóng
        </button>
        <button tds-button 
            color="primary" 
            size="sm" 
            [disabled]="isLoading"
            (click)="addAttribute()">
            Thêm thuộc tính
        </button>
    </div>
</ng-template>

