import { Injectable } from '@angular/core';
import { STORE_BRANCH_ENDPOINT, STORE_ENDPOINT } from 'app/core/constants/api-endpoint.const';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';
import { CreateStoreReq, CreateStoreResponse } from '../models/create-store.model';
import { Store } from '../models/store.model';
import { UpdateStoreReq } from '../models/update-store.model';


@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getStoreList$(): Observable<Store[]> {
    return this.apiService.get<Store[]>(`${STORE_BRANCH_ENDPOINT}`);
  }

  createStore$(data: CreateStoreReq): Observable<CreateStoreResponse> {
    return this.apiService.post(STORE_ENDPOINT, data);
  }

  updateStore$(storeId: string, data: UpdateStoreReq): Observable<Store> {
    return this.apiService.put(`${STORE_ENDPOINT}/${storeId}`, data);
  }

}
