<tds-tabset [tabBarExtraContent]="extraTemplate" class="!mt-[-8px]" >
    <tds-tab title="Thông tin gian hàng" clsContent="-mt-px" >
        <tds-spin 
            [spinning]="isLoading" 
            [size]="'md'">
            <div class="w-full h-full flex flex-col pb-4 pt-4">
                @if (shopDetail) {
                    <div class="flex flex-row items-center">
                        <div *ngIf="!shopDetail.shop_logo" class="p-4 mr-4 border border-neutral-2-200 rounded-2xl">
                            <i class="text-5xl leading-[48px] text-neutral-1-500" [ngClass]="eChannel | tdsMapper: getIconStatus"></i>    
                        </div>
                        <div *ngIf="shopDetail.shop_logo"
                            class="mr-4 border border-neutral-2-200 rounded-2xl w-[85px] h-[85px]">
                            <img tds-image class="rounded-2xl" [srcset]="shopDetail.shop_logo" alt="" />
                        </div>
                        <div class="flex flex-col font-semibold">
                            <div class="text-primary-1 text-title-1">#{{ shopDetail.shop_id }}</div>
                            <div
                                class="text-neutral-1-900 text-header-1 max-w-xl line-clamp-3 whitespace-pre-line break-words">
                                {{ shopDetail.shop_name }}
                            </div>
                            <div>
                                @if (shopInfo.message_error === DEAUTHORIZED_MESSAGE_1 || shopInfo.message_error === DEAUTHORIZED_MESSAGE_2) {
                                    <span class="text-orange-400 text-xs">
                                        Gian hàng bị hủy ủy quyền
                                    </span>
                                }
                                @if ((shopInfo.e_Channel === channelType.shopee && shopInfo.message_error === CANCELED_BY_USER) || (shopInfo.e_Channel === channelType.lazada && shopInfo.message_error == DEAUTHORIZED_MESSAGE_LAZADA)) {
                                    <span class="text-orange-400 text-xs">
                                        Gian hàng bị hủy bởi người dùng
                                    </span>
                                }
                                @if (!shopInfo.can_enable) {
                                    <span class="text-orange-400 text-xs">
                                        Gian hàng bị hủy kích hoạt
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full py-4">
                        <div class="flex flex-row pb-2 items-center">
                            <div class="w-1/4 text-neutral-1-900 text-title-1 font-semibold">Chủ gian hàng:</div>
                            @if (shopDetail.contact_person !== '') {
                                <div class="w-3/4 text-body-2">
                                    {{ shopDetail.contact_person }}
                                </div>
                            }
                            @if (shopDetail.contact_person === '') {
                                <div class="w-3/4 text-body-2">
                                    N/A
                                </div>
                            }
                        </div>
                        <div class="flex flex-row items-center">
                            <div class="w-1/4 text-neutral-1-900 text-title-1 font-semibold">Địa chỉ:</div>
                            @if (shopDetail.full_address !== '') {
                                <div class="w-3/4 text-body-2">
                                    {{ shopDetail.full_address }}
                                </div>
                            }
                            @if (shopDetail.full_address === '') {
                                <div class="w-3/4 text-body-2">
                                    N/A
                                </div>
                            }
                        </div>
                    </div>
                }
                
                <div class="flex flex-row-reverse pt-4">
                    @if (!shopInfo.is_active) {
                        <button tds-button 
                            (click)="onClickLinkShop(shopInfo)" 
                            color="success" 
                            class="ml-2 w-[106px]">
                            <div class="flex items-center justify-center w-full">Kết nối lại</div>
                        </button>
                    }
                    @if (shopInfo.is_active) {
                        <button tds-button 
                            (click)="onClickLinkShop(shopInfo)" 
                            color="error"
                            class="w-[106px] ml-2">
                            <div class="flex items-center justify-center w-full">Ngắt kết nối</div>
                        </button>
                    }
                    <button tds-button-outline
                        class="mr-2 min-w-[100px]" 
                        color="secondary"
                        type="button"
                        [disabled]="isLoading"
                        [size]="'md'"
                        (click)="onClose()">
                        Đóng
                    </button>
                </div>
            </div>
        </tds-spin>
    </tds-tab>
    <ng-template #extraTemplate>
        <button tds-button-flat-icon color="secondary" (click)="onClose()">
            <i class="tdsi-close-fill"></i>
          </button>
    </ng-template>
</tds-tabset>