import { AbstractControl, ValidatorFn } from "@angular/forms";
import { TDSHelperString } from 'tds-ui/shared/utility';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value;

    if (!TDSHelperString.hasValueString(value)) {
      return null;
    }

    // Kiểm tra chỉ chứa số
    if (!/^\d+$/.test(value)) {
      return { 'pattern': true };
    }

    // Nếu bắt đầu bằng 0
    if (value.startsWith('0')) {
      // Phải đúng 10 ký tự
      return value.length === 10 ? null : { 'pattern': true };
    } 
    
    // Nếu không bắt đầu bằng 0
    // Phải đúng 9 ký tự
    return value.length === 9 ? null : { 'pattern': true };
  }
}

export function phoneValidatorTenDigits(): ValidatorFn {
  return (control: AbstractControl) => {
    const text = control.value as string;

    if (!TDSHelperString.hasValueString(text)) return null;

    const regex = /^0\d{9}$/;

    if (!regex.test(text)) {
      return { 'pattern': true };
    }

    return null;
  }
}
