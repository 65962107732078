import { Injectable } from '@angular/core';
import { ACTIVE_STORE_BRANCH_ENDPOINT, STORE_BRANCH_ENDPOINT } from 'app/core/constants/api-endpoint.const';
import { StoreBranch, UserStoreBranch } from '../models/store-branch.model';
import { Observable } from 'rxjs';
import { ApiService } from 'app/core/services/api.service';
import { CreateBranchReq } from '../models/create-branch.model';
import { UpdateBranchReq } from '../models/update-branch.model';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getUserStoreBranches$(): Observable<UserStoreBranch[]> {
    return this.apiService.get<UserStoreBranch[]>(`${STORE_BRANCH_ENDPOINT}`);
  }

  createBranch$(model: CreateBranchReq): Observable<StoreBranch> {
    return this.apiService.post(`${STORE_BRANCH_ENDPOINT}`, model);
  }

  updateBranch$(id: string, model: UpdateBranchReq): Observable<StoreBranch> {
    return this.apiService.put(`${STORE_BRANCH_ENDPOINT}/${id}`, model);
  }

  setActiveBranch$(id: string): Observable<StoreBranch> {
    return this.apiService.put(`${ACTIVE_STORE_BRANCH_ENDPOINT}/${id}`);
  }
}
