import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo, UserStoreBranchStatus } from 'app/core/models/user.model';
import { EEmployeeStatus } from 'app/features/employees/enums/employee-status.enum';
import { CreateStoreComponent } from 'app/features/stores/components/create-store/create-store.component';
import { TDSAlertModule } from 'tds-ui/alert';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSHeaderModule } from 'tds-ui/header';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';

@Component({
  selector: 'app-access-denied',
  standalone: true,
  imports: [
    CommonModule,
    TDSHeaderModule,
    TDSAlertModule,
    TDSButtonModule,
    TDSModalModule
  ],
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.scss'
})
export class AccessDeniedComponent implements OnInit {
  userInfo : UserInfo = history.state.userInfo;
  isBlock = false;
  isDelete = false;
  isBlockAndDelete = false;

  blockedStores: string = '';
  deletedStores: string = '';
  constructor(private readonly _router: Router,
    private readonly modalService: TDSModalService,
  ) {

  }

  ngOnInit(): void {
    this.handleData();
  }

  handleData() {
    let userStoreBranches = this.userInfo.userStoreBranchStatuses;
    const statuses = new Set(userStoreBranches.map((branch: UserStoreBranchStatus) => branch.status));

    this.isBlock = statuses.size === 1 && statuses.has(EEmployeeStatus.Inactive);
    this.isDelete = statuses.size === 1 && statuses.has(EEmployeeStatus.Removed);
    this.isBlockAndDelete = statuses.size === 2 && statuses.has(EEmployeeStatus.Inactive) && statuses.has(EEmployeeStatus.Removed);

    this.blockedStores = userStoreBranches
      .filter(branch => branch.status === EEmployeeStatus.Inactive)
      .map(branch => branch.storeBranchName)
      .join(",");

    this.deletedStores = userStoreBranches
      .filter(branch => branch.status === EEmployeeStatus.Removed)
      .map(branch => branch.storeBranchName)
      .join(",");
  }

  addBranch() {
    const modal = this.modalService.create({
      closable: false,
      title: 'Tạo doanh nghiệp mới',
      content: CreateStoreComponent,
      componentParams: {
        userInfo: this.userInfo,
        isFirst: false
      },
      size: 'lg',
      bodyStyle: {
        'padding': '0px'
      },
      footer: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        this._router.navigate(['/dashboard']);
      }
    });
  }

  onClose() {
    this._router.navigate(['/sign-in']);
  }
}
