class TDSPureException extends Error {
  constructor() {
    super(`tuiPure can only be used with functions or getters`);
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { TDSPureException };
