const TDS_DEFAULT_NUMBER_FORMAT = {
  decimalLimit: Infinity,
  decimalSeparator: `,`,
  thousandSeparator: '.',
  zeroPadding: true
};
const TDS_DECIMAL_SYMBOLS = [`,`, `.`];
const MASK_CARET_TRAP = `[]`;
const TDS_DIGIT_REGEXP = /\d/;
const TDS_NON_DIGIT_REGEXP = /\D/;
const TDS_NON_DIGITS_REGEXP = /\D+/g;
const TDS_LEADING_ZEROES_REGEXP = /^0+/;
const TDS_MASK_SYMBOLS_REGEXP = /[ \-_()]/g;
const TDS_LAST_PUNCTUATION_MARK_REGEXP = /[.,\\/#!$%\\^&\\*;:{}=\\-_`~()]$/;
const TDS_LATIN_REGEXP = /[A-z]/;
const TDS_LATIN_AND_NUMBERS_REGEXP = /[A-z|0-9]/;

/**
 * Generated bundle index. Do not edit.
 */

export { MASK_CARET_TRAP, TDS_DECIMAL_SYMBOLS, TDS_DEFAULT_NUMBER_FORMAT, TDS_DIGIT_REGEXP, TDS_LAST_PUNCTUATION_MARK_REGEXP, TDS_LATIN_AND_NUMBERS_REGEXP, TDS_LATIN_REGEXP, TDS_LEADING_ZEROES_REGEXP, TDS_MASK_SYMBOLS_REGEXP, TDS_NON_DIGITS_REGEXP, TDS_NON_DIGIT_REGEXP };
