import { CommonModule } from '@angular/common';
import { Component, OnInit} from '@angular/core';
import { TDSHeaderModule } from 'tds-ui/header';
import { TDSAvatarModule } from "tds-ui/avatar";
import { TDSButtonModule } from 'tds-ui/button';
import { RouterLink, Router   } from '@angular/router';
import { UserInfo } from 'app/core/models/user.model';
import { Store } from '../../models/store.model';
import { StoreService } from '../../services/store.service';
import { TDSDestroyService } from 'tds-ui/core/services';
import { takeUntil, finalize } from 'rxjs';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { TDSMessageService } from 'tds-ui/message';
import { AuthService } from 'app/core/services/auth.service';
import { StoreBranch } from 'app/features/branches/models/store-branch.model';

@Component({
  selector: 'app-pick-store',
  standalone: true,
  imports: [
    CommonModule,
    TDSHeaderModule,
    TDSAvatarModule,
    TDSButtonModule,
    RouterLink,
    TDSSpinnerModule
  ],
  providers: [
    TDSDestroyService,
    StoreService,
    TDSMessageService
  ],
  templateUrl: './pick-store.component.html',
  styleUrl: './pick-store.component.scss'
})
export class PickStoreComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly storeService: StoreService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly msgService: TDSMessageService,
    private readonly authService: AuthService
  ) {}
  userInfo!: UserInfo;
  stores!: Store[];
  isLoading = true;

  ngOnInit(): void {
    this.loadStores();
  }

  loadStores(): void {
    this.storeService.getStoreList$()
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res) => {
          this.stores = res;
        },
        error: (err) => {
          this.msgService.error("Đã xảy ra lỗi, vui lòng thử lại sau");
        }
      });
  }
  /**
   * Go to sign in page and clear token
   */
  goToSignInPage() {
    this.authService.clearStorage();
    this.router.navigate(['/sign-in']);
  }

  onAccessBranch(branch: StoreBranch): void {
    this.authService.setCurrentStoreId(branch.storeId);
    this.authService.setCurrentBranchId(branch.id);
    this.authService.setBranchTenantId(branch.tenantId);
    this.router.navigate(['/dashboard']);
  }
}
