import { Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';
import { IEmployeeList, IEmployeeQueryParams } from '../models/employee.model';
import { EMPLOYEE_LIST_ENDPOINT } from 'app/core/constants/api-endpoint.const';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getEmployeesInStore$(
    storeId: string,
    queryParams: IEmployeeQueryParams
  ): Observable<IEmployeeList> {
    return this.apiService.post<IEmployeeList>(`${EMPLOYEE_LIST_ENDPOINT}/${storeId}`, queryParams);
  }
}
