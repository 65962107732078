import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DistrictResponse, ProvinceResponse, WardResponse } from 'app/core/models/address.model';
import { ErrorResponse } from 'app/core/models/error-response.model';
import { UserInfo } from 'app/core/models/user.model';
import { AddressService } from 'app/core/services/address.service';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { finalize, takeUntil } from 'rxjs';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSBadgeModule } from "tds-ui/badges";
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSInputNumberModule } from 'tds-ui/input-number';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSelectModule } from 'tds-ui/select';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSInputModule } from "tds-ui/tds-input";
import { CreateStoreReq, CreateStoreResponse } from '../../models/create-store.model';
import { StoreCategory } from '../../models/store-category.model';
import { StoreCategoryService } from '../../services/store-category.service';
import { StoreService } from '../../services/store.service';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { NumbersOnlyDirective } from 'app/shared/directives/numbers-only.directive';
import { emailValidator } from 'app/core/utilities/email-validator.util';
import { phoneValidatorTenDigits } from 'app/core/utilities/phone-validator.util';

@Component({
  selector: 'app-create-store',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSAvatarModule,
    TDSFormFieldModule,
    TDSButtonModule,
    TDSInputModule,
    TDSInputNumberModule,
    TDSSelectModule,
    TDSModalModule,
    TDSBadgeModule,
    TDSSpinnerModule,
    NumbersOnlyDirective
  ],
  providers: [
    StoreService,
    TDSDestroyService,
    {
      provide: ErrorStateMatcher, useClass: WiECommerceDirtyErrorStateMatcher
    },
    AddressService,
    TDSMessageService
  ],
  templateUrl: './create-store.component.html',
  styleUrl: './create-store.component.scss'
})
export class CreateStoreComponent implements OnInit {
  @Input() userInfo!: UserInfo;
  @Input() isFirst!: boolean;
  storeForm!: FormGroup;
  isLoading = false;

  storeCategories: StoreCategory[] = [];
  provinces: ProvinceResponse[] = [];
  districts: DistrictResponse[] = [];
  wards: WardResponse[] = [];

  phoneIsTouched = false;

  constructor(
    private readonly storeService: StoreService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly storeCategoryService: StoreCategoryService,
    private readonly addressService: AddressService,
    private readonly msgService: TDSMessageService,
    private readonly modalRef: TDSModalRef<CreateStoreComponent>
  ) {

  }

  ngOnInit(): void {
    this.loadStoreCategories();
    this.loadProvinces();
    this.initForm();

    if (this.userInfo) {
      this.storeForm.get('ownerName')?.setValue(this.userInfo.name);
      this.storeForm.get('email')?.setValue(this.userInfo.email);
      this.storeForm.get('phone')?.setValue(this.userInfo.phoneNumber);
    }
  }

  loadStoreCategories(): void {
    this.isLoading = true;

    this.storeCategoryService.getStoreCategories$()
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res) => {
          this.storeCategories = res;
        },
        error: () => {
          this.msgService.error('Lấy danh sách danh mục thất bại');
        }
      })
  }

  initForm(): void {
    this.storeForm = new FormGroup({
      ownerName: new FormControl('', [Validators.required]),
      storeCategories: new FormControl(null),
      storeName: new FormControl('', [Validators.required]),
      email: new FormControl(null, emailValidator),
      phone: new FormControl(null, phoneValidatorTenDigits()),
      province: new FormControl(null),
      district: new FormControl(null),
      ward: new FormControl(null),
      street: new FormControl(null, Validators.maxLength(1000)),
    })
  };

  private prepareModel(): CreateStoreReq {
    const formValue = this.storeForm.value;
    return {
      OwnerName: formValue.ownerName,
      StoreCategoryIdList: formValue.storeCategories,
      Name: formValue.storeName,
      Email: formValue.email,
      PhoneNumber: formValue.phone,
      Street: formValue.street,
      Ward: formValue.ward,
      District: formValue.district,
      City: formValue.province,
      TimeZone: 'UTC+7'
    };
  }

  private markFormAsDirty(): void {
    Object.keys(this.storeForm.controls).forEach(key => {
      this.storeForm.get(key)?.markAsDirty();
    });
  }

  onSubmit(): void {
    this.markFormAsDirty();
    
    if (!this.storeForm.valid) return;

    this.isLoading = true;
    const payload = this.prepareModel();
    
    this.storeService.createStore$(payload)
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: CreateStoreResponse) => {
          this.msgService.success(this.isFirst ? 'Tạo cửa hàng thành công' : 'Tạo doanh nghiệp thành công');
          this.modalRef.destroy(res);
        },
        error: (err: ErrorResponse) => {
          this.msgService.error(err.error.code ? err.error.message : this.isFirst ? 'Tạo cửa hàng thất bại' : 'Tạo doanh nghiệp thất bại');
        }
      })
  }

  loadProvinces(): void {
    this.addressService.getProvinces$()
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.provinces = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách tỉnh/thành phố thất bại');
        }
      })
  }

  loadDistricts(provinceCode: string): void {
    this.addressService.getDistricts$(provinceCode)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.districts = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách quận/huyện thất bại');
        }
      })
  }

  loadWards(districtCode: string): void {
    this.addressService.getWards$(districtCode)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.wards = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách phường/xã thất bại');
        }
      })
  }

  onChangeProvince(event: ProvinceResponse): void {
    if (!event) return;

    this.storeForm.get('district')?.reset();
    this.storeForm.get('ward')?.reset();

    this.storeForm.get('province')?.setValue(event.Name);
    this.loadDistricts(event.Code);
  }
    
  onChangeDistrict(event: DistrictResponse): void {
    if (!event) return;

    this.storeForm.get('ward')?.reset();

    this.storeForm.get('district')?.setValue(event.Name);
    this.loadWards(event.Code);
  }

  onChangeWard(event: WardResponse): void {
    if (!event) return;

    this.storeForm.get('ward')?.setValue(event.Name);
  }

  onChangeStoreCategories(event: StoreCategory[]): void {
    if (!event) return;

    const lstId = event.map(item => item.id);
    this.storeForm.get('storeCategories')?.reset();
    this.storeForm.get('storeCategories')?.setValue(lstId);
  }

  onCancel(): void {
    this.modalRef.close();
  }

  onEditAvatar(): void {
  }

  onPhoneBlur(): void {
    this.phoneIsTouched = true;
  }

  onPhoneFocus(): void {
    this.phoneIsTouched = false;
  }
}
