import { Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { CreateStoreCategoryReq, StoreCategory } from '../models/store-category.model';
import { Observable } from 'rxjs';
import { STORE_CATEGORY_ENDPOINT } from 'app/core/constants/api-endpoint.const';

@Injectable({
  providedIn: 'root'
})
export class StoreCategoryService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getStoreCategories$(): Observable<StoreCategory[]> {
    return this.apiService.get<StoreCategory[]>(STORE_CATEGORY_ENDPOINT);
  }

  createStoreCategory$(req: CreateStoreCategoryReq): Observable<StoreCategory> {
    return this.apiService.post<StoreCategory>(STORE_CATEGORY_ENDPOINT, req);
  }
}
