<tds-spin [spinning]="isLoading" class="w-full h-full">
    <div class="tds-color-background-neutral-minimalist p-2 tds-body-2 flex">
        <span>Thuộc tính:</span>
        <span class="ml-1 font-semibold">{{attributeValue.attributeName}}</span>
    </div>
    
    <form [formGroup]="form" id="form" (ngSubmit)="onSave()">
        <tds-form-field class="w-full mt-3">
            <tds-label [tdsRequired]="true">Giá trị thuộc tính</tds-label>
            <input tdsInput 
                autocomplete="off"  
                formControlName="name"
                placeholder="Nhập giá trị thuộc tính" 
                (blur)="trimName()"
                [maxlength]="rule.name.maxLength"
                [required]='true' 
            />
            @if (this.form.get('name')?.errors?.['required'] || this.form.get('name')?.errors?.['whitespace'] ) {
                <tds-error>Vui lòng nhập tên giá trị thuộc tính</tds-error>
            }
        </tds-form-field>
        @if (isDuplicate) {
            <tds-error>Giá trị thuộc tính đã tồn tại</tds-error>
        }
        <div class="w-full flex justify-end p-4" *tdsModalFooter>
            <button tds-button-outline
                class="mr-2 min-w-[100px]" 
                color="secondary"
                [size]="'md'"
                [disabled]="isLoading"
                (click)="onCancel()">
                Đóng
            </button>
            <button tds-button 
                class="min-w-[100px]" 
                color="primary"
                [size]="'md'"
                (click)="onSave()"
                [disabled]="isLoading">
                Lưu
            </button>
        </div>
    </form>
</tds-spin>
