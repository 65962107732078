import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IColumnConfig } from 'app/shared/models/column-config.model';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSPopoverModule } from 'tds-ui/popover';
import { TDSCheckBoxModule } from 'tds-ui/tds-checkbox';
import { RoleColumnConfigService } from '../../services/role-column-config.service';

@Component({
  selector: 'app-filter-column-config',
  standalone: true,
  imports: [
    CommonModule,
    TDSButtonModule,
    TDSPopoverModule,
    TDSCheckBoxModule
  ],
  templateUrl: './filter-column-config.component.html',
  styleUrl: './filter-column-config.component.scss'
})
export class FilterColumnConfigComponent {
  isVisible = false;
  colsConfig: IColumnConfig[] = [];

  constructor(
    private readonly roleColumnConfigService: RoleColumnConfigService
  ) {}

  ngOnInit(): void {
    this.roleColumnConfigService.colsConfig$.subscribe(config => {
      const localConfigs = this.roleColumnConfigService.getConfig();
      this.colsConfig = localConfigs ? localConfigs : config;
    });
  }

  onChangeCol(col: IColumnConfig): void {
    const updatedConfig = this.colsConfig.map(c =>
      c.id === col.id ? { ...c, checked: !c.checked } : c
    );
    this.roleColumnConfigService.updateConfig(updatedConfig);
  }

  reset(): void {
    this.roleColumnConfigService.resetConfig();
  }
}
