import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]',
  standalone: true
})
export class NumbersOnlyDirective {
  constructor(private readonly el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent) {
    const initialValue = this.el.nativeElement.value;
    const newValue = initialValue.replace(/\D/g, ''); // Only keep digits
    if (initialValue !== newValue) {
      this.el.nativeElement.value = newValue;
      event.stopPropagation();
    }
  }
  
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const pattern = /\d/;

    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    // Prevent the default paste behavior
    event.preventDefault();
  
    // Get the pasted text from the clipboard
    const clipboardData = event.clipboardData?.getData('text');
    if (!clipboardData) {
      return;
    }
  
    // Filter out non-numeric characters
    const pastedInput = clipboardData.replace(/\D/g, '').slice(0, 10);
  
    // Get the input element
    const input = this.el.nativeElement as HTMLInputElement;

    // Get the current selection range
    const start = input.selectionStart ?? 0;
    const end = input.selectionEnd ?? 0;
  
    // Insert the filtered numeric input at the current cursor position
    const currentValue = input.value;
    const newValue = currentValue.slice(0, start) + pastedInput + currentValue.slice(end);
    input.value = newValue;
  
    // Set the cursor position after the inserted text
    const newCursorPosition = start + pastedInput.length;
    input.setSelectionRange(newCursorPosition, newCursorPosition);
  
    // Trigger input event to update any bindings
    input.dispatchEvent(new Event('input'));
  }
}
