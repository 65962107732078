import { Injectable } from "@angular/core";
import { BaseColumnConfigService } from "app/shared/services/base-column-config.service";
import { ROLE_COLS_CONFIG } from "../constants/role-column-configure.const";
import { IColumnConfig } from "app/shared/models/column-config.model";
import {AuthService} from "../../../core/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class RoleColumnConfigService extends BaseColumnConfigService {
  protected storageKey: string = ROLE_COLS_CONFIG;
  protected override defaultConfigs: IColumnConfig[] = [];

  constructor(
    private  readonly  authService: AuthService
  ) {
    super();
    this.defaultConfigs = this.getConfigsBasedOnRole();
  }

  private getConfigsBasedOnRole(): IColumnConfig[] {
    const isOwner: boolean = this.authService.isOwner(); // Check user is owner or not

    const employeeConfigs: IColumnConfig[] = [
      { id: 0, name: 'Mô tả', checked: true, width: 270 },
      { id: 1, name: 'Nhân viên', checked: true, width: 200 },
      { id: 2, name: 'Loại', checked: true, width: 150 },
      { id: 3, name: 'Tạo bởi', checked: true, width: 200 },
      { id: 4, name: 'Ngày tạo', checked: true, width: 120 },
      { id: 5, name: 'Cập nhật bởi', checked: true, width: 200 },
      { id: 6, name: 'Ngày cập nhật', checked: true, width: 120 },
    ];

    const adminConfigs: IColumnConfig[] = [
      { id: 0, name: 'Mô tả', checked: false, width: 270 },
      { id: 1, name: 'Cửa hàng', checked: true, width: 170 },
      { id: 2, name: 'Doanh nghiệp', checked: true, width: 170 },
      { id: 3, name: 'Nhân viên', checked: true, width: 170 },
      { id: 4, name: 'Loại', checked: true, width: 90 },
      { id: 5, name: 'Tạo bởi', checked: true, width: 170 },
      { id: 6, name: 'Ngày tạo', checked: true, width: 90 },
      { id: 7, name: 'Cập nhật bởi', checked: true, width: 170 },
      { id: 8, name: 'Ngày cập nhật', checked: true, width: 90 },
    ];

    if (isOwner) {
      return adminConfigs;
    } else {
      return employeeConfigs;
    }
  }
}
