import { TDSMenuDTO } from 'tds-ui/menu';

export const DATA_MENU: Array<TDSMenuDTO> = [
  {
    "name": "Tổng quan",
    "icon": "tdsi-bar-chart-fill",
    "link": "/dashboard"
  },
  // {
  //   "name": "Kết nối",
  //   "icon": "tdsi-shop-fill",
  //   "link": "/connection"
  // },
  // {
  //   "name": "Quản lý sản phẩm",
  //   "icon": "tdsi-box-fill",
  //   "link": "product"
  // },
  // {
  //   "name": "Sản phẩm sàn TMĐT",
  //   "icon": "tdsi-import-product-fill",
  //   "link": "ecommerce-product"
  // },
  // {
  //   "name": "Đơn hàng",
  //   "icon": "tdsi-shopping-bag-check-fill",
  //   "link": "order"
  // },
  // {
  //   "name": "Trả hàng/ hoàn tiền",
  //   "icon": "tdsi-cod-fill",
  //   "link": "return-and-refund"
  // },
  // {
  //   "name": "Nhà cung cấp",
  //   "icon": "tdsi-storehouse-fill",
  //   "link": "supplier"
  // },
  // {
  //   "name": "Tối ưu bán hàng",
  //   "icon": "tdsi-cod-fill",
  //   "listChild": [
  //     {
  //       "name": "Phân tích và định giá",
  //       "link": "analysis",
  //     },
  //     {
  //       "name": "Ước tính giá bán, chi phí",
  //       "link": "calculate",
  //     }
  //   ]
  // },
  {
    "name": "Cài đặt",
    "icon": "tdsi-gear-fill",
    "listChild": [
      {
        "name": "Vai trò",
        "link": "roles/list",
      },
      // {
      //   "name": "Nhân viên",
      //   "link": "employees/list",
      // }
    ]
  },
];
