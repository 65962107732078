import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSButtonMenuModule } from 'tds-ui/button-menu';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSHeaderModule } from 'tds-ui/header';
import { TDSInputNumberModule } from 'tds-ui/input-number';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { TDSInputModule } from 'tds-ui/tds-input';
import { TDSCheckboxChange, TDSCheckBoxModule } from 'tds-ui/tds-checkbox';
import { OtpComponent } from './otp/otp.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { SignUpSuccessComponent } from './sign-up-success/sign-up-success.component';
import { WiAccountService } from '../../../core/services/wi-account.service';
import { VerifiedOtp, VerifyOtp } from '../../../core/models/sign-up.model';
import { OtpType, SendOtpErrorResponse, SendOtpReq, SendOtpResponse } from '../../../core/models/otp.model';
import { TDSDestroyService } from 'tds-ui/core/services';
import { finalize, takeUntil, takeWhile } from 'rxjs/operators';
import { TDSSpinnerModule } from "tds-ui/progress-spinner";
import { TDSMessageModule, TDSMessageService } from "tds-ui/message";
import { interval } from 'rxjs';
import { OTP_LIMIT_EXCEEDED, PHONE_NUMBER_ALREADY_EXISTS, RETRY_REQUEST_DELAY } from '../../../core/constants/wi-account-error-code.const';
import { phoneValidator } from '../../../core/utilities/phone-validator.util';
import { WiECommerceDirtyErrorStateMatcher } from '../../../core/utilities/error-state-matcher.util';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { NumbersOnlyDirective } from 'app/shared/directives/numbers-only.directive';

interface SignUpForm {
  phoneNumber: FormControl<string | null>;
  isCheck: FormControl<boolean | null>;
}

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSHeaderModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSButtonModule,
    TDSButtonMenuModule,
    RouterLink,
    RouterLinkActive,
    TDSInputNumberModule,
    TDSModalModule,
    TDSCheckBoxModule,
    TDSSpinnerModule,
    TDSMessageModule,
    NumbersOnlyDirective
  ],
  providers: [
    TDSModalService,
    WiAccountService,
    TDSDestroyService,
    TDSMessageService,
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss'
})
export class SignUpComponent implements OnInit {
  @ViewChild('existPhoneNumber') existPhoneContent!: TemplateRef<void>;
  @ViewChild('limitOtp') limitOtpTmp: TemplateRef<unknown> | undefined;
  signUpForm!: FormGroup<SignUpForm>;
  isSubmitted = false;
  isLoading = false;
  limitTime = "";

  phoneIsTouched = false;

  constructor(
    private readonly modalService: TDSModalService,
    private readonly router: Router,
    private readonly wiAccountService: WiAccountService,
    private readonly destroyService: TDSDestroyService,
    private readonly msgService: TDSMessageService
  ) {}

  ngOnInit(): void {
    this.initializeSignUpForm();
  }

  /**
   * Initializes the sign-up form with validators
   */
  private initializeSignUpForm(): void {
    this.signUpForm = new FormGroup<SignUpForm>({
      phoneNumber: new FormControl(null, [Validators.required, phoneValidator()]),
      isCheck: new FormControl(true, Validators.required)
    });
  }

  /**
   * Getter for phoneNumber form control
   */
  get phoneNumber(): FormControl {
    return this.signUpForm.get("phoneNumber") as FormControl;
  }

  /**
   * Getter for isCheck form control
   */
  get isCheck(): FormControl {
    return this.signUpForm.get("isCheck") as FormControl;
  }
  /**
   * Handles checkbox change event
   * @param event Checkbox change event
   */
  onCheckboxChange(event: TDSCheckboxChange): void {
    this.isCheck.setErrors(event.checked ? null : { required: true });
  }

  /**
   * Sends OTP via Zalo
   * @param phone Phone number to send OTP to
   */
  sendOTPViaZalo(phone: string): void {
    this.isSubmitted = true;
    if (this.signUpForm.invalid) return;

    const data: SendOtpReq = {
      phoneNumber: phone,
      method: "ZaLo",
    };

    this.sendOtpWithType(data);
  }

  /**
   * Sends OTP with specified type
   * @param data OTP request data
   */
  private sendOtpWithType(data: SendOtpReq): void {
    this.isLoading = true;
    this.wiAccountService.sendOtp(data)
      .pipe(
        takeUntil(this.destroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: SendOtpResponse) => this.handleOtpSuccess(res, data.phoneNumber, data.method),
        error: (err: SendOtpErrorResponse) => this.handleOtpError(err, data.phoneNumber)
      });
  }

  /**
   * Handles successful OTP send
   * @param res OTP response
   * @param phone Phone number
   */
  private handleOtpSuccess(res: SendOtpResponse, phone: string, type: OtpType): void {
    const data: VerifyOtp = {
      type: type,
      phone: phone,
      expiredTime: new Date(res.codeVerifyExpiryDate)
    };
    this.inputOtp(data);
  }

  /**
   * Handles OTP send error
   * @param err Error response
   * @param phone Phone number
   */
  private handleOtpError(err: SendOtpErrorResponse, phone: string): void {
    if (err.error.code === PHONE_NUMBER_ALREADY_EXISTS) {
      this.showExistPhoneNumberModal(phone);
    } else if (err.error.code === OTP_LIMIT_EXCEEDED) {
      this.handleOtpLimitExceeded(err.error.data as { latestTimeSend: Date });
    } else if (err.error.code === RETRY_REQUEST_DELAY) {
      this.msgService.error('Vui lòng gửi lại yêu cầu sau 30s');
    } else {
      this.msgService.error(err.error.message);
    }
  }

  /**
   * Handles OTP limit exceeded error
   * @param data Error data containing latest send time
   */
  private handleOtpLimitExceeded(data: { latestTimeSend: Date }): void {
    const adjustedTimeSend = new Date(data.latestTimeSend);
    adjustedTimeSend.setHours(adjustedTimeSend.getHours() + 6);
    this.startCountdown(adjustedTimeSend);
    this.showLimitAccessOtpModal();
  }

  /**
   * Sends OTP via SMS
   * @param phone Phone number to send OTP to
   */
  sendOTPViaSMS(phone: string): void {
    this.isSubmitted = true;
    if (this.signUpForm.invalid) return;

    const data: SendOtpReq = {
      phoneNumber: phone,
      method: "SMS",
    };

    this.sendOtpWithType(data);
  }

  /**
   * Shows modal for existing phone number
   * @param phone Existing phone number
   */
  private showExistPhoneNumberModal(phone: string): void {
    const modal = this.modalService.info({
      title: 'Số điện thoại đã được đăng ký',
      content: this.existPhoneContent,
      onCancel: () => { modal.close() },
      cancelText: "Đóng",
      okText: "Đăng nhập",
      onOk: () => { modal.destroy(true) },
    });

    modal.afterClose.subscribe((isSignIn: boolean | undefined) => {
      if (isSignIn) {
        this.router.navigate(['/sign-in'], { state: { phone } });
      }
    });
  }

  /**
   * Opens input OTP modal
   * @param input OTP verification data
   */
  private inputOtp(input: VerifyOtp): void {
    const modal = this.modalService.create({
      closable: false,
      content: OtpComponent,
      bodyStyle: { "padding": "0px" },
      style: { "width": "520px", "height": "688px" },
      componentParams: { inputData: input },
      footer: null
    });

    modal.afterClose.subscribe((res: VerifiedOtp | undefined) => {
      if (res) {
        this.createPassword(res);
      }
    });
  }

  /**
   * Opens create new password modal
   */
  private createPassword(verifiedOtp: VerifiedOtp): void {
    const modal = this.modalService.create({
      closable: false,
      content: CreatePasswordComponent,
      bodyStyle: { "padding": "0px" },
      style: { 
        "width": "520px",
        "min-height": "836px",
        "max-height": "876px"
      },
      componentParams: { verifiedOtp: verifiedOtp },
      centered: true,
      footer: null
    });

    modal.afterClose.subscribe((isCompleted: boolean) => {
      if (isCompleted) {
        this.openSuccess();
      }
    });
  }

  /**
   * Opens success modal when signup is successful
   */
  private openSuccess(): void {
    const modal = this.modalService.create({
      closable: false,
      content: SignUpSuccessComponent,
      bodyStyle: { "padding": "0px" },
      style: { "width": "520px", "height": "708px" },
      footer: null
    });

    modal.afterClose.subscribe((res: boolean | undefined) => {
      if (res) {
        this.router.navigate(['/sign-in']);
      }
    });
  }

  /**
   * Shows modal for limited OTP access
   */
  private showLimitAccessOtpModal(): void {
    const modal =this.modalService.error({
      title: "Thông báo",
      content: this.limitOtpTmp,
      okText: null,
      cancelText: 'Đóng',
      onCancel: () => { modal.close() },
    });
  }

  /**
   * Starts countdown for OTP limit
   * @param inputExpiredTime Expiry time for the countdown
   */
  private startCountdown(inputExpiredTime: Date): void {
    const expiredTime = new Date(inputExpiredTime).getTime();
    const now = new Date().getTime();
    let countDown = Math.floor((expiredTime - now) / 1000);

    this.limitTime = this.formatCountdown(countDown);

    const subscription = interval(1000)
      .pipe(
        takeWhile(() => countDown > 0),
        takeUntil(this.destroyService)
      )
      .subscribe(() => {
        countDown--;
        this.limitTime = this.formatCountdown(countDown);
        if (countDown === 0) {
          subscription.unsubscribe();
        }
      });
  }

  /**
   * Formats countdown time to string
   * @param countDown Countdown time in seconds
   * @returns Formatted countdown string
   */
  private formatCountdown(countDown: number): string {
    const hours = Math.floor(countDown / 3600);
    const minutes = Math.floor((countDown % 3600) / 60);
    const seconds = countDown % 60;
    return [hours, minutes, seconds].map(unit => unit.toString().padStart(2, '0')).join(':');
  }

  onPhoneBlur(): void {
    this.phoneIsTouched = true;
  }

  onPhoneFocus(): void {
    this.phoneIsTouched = false;
  }
}