import { Routes } from '@angular/router';
import { SignInComponent } from './features/auth/sign-in/sign-in.component';
import { SignUpComponent } from './features/auth/sign-up/sign-up.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { DashboardComponent } from './features/dashboard/components/dashboard/dashboard.component';
import { authGuard } from './core/guards/auth.guard';
import { PickStoreComponent } from './features/stores/components/pick-store/pick-store.component';
import { ProfileComponent } from './features/users/components/profile/profile.component';
import {ListRoleComponent} from "./features/roles/components/list-role/list-role.component";
import { ListEmployeesComponent } from './features/employees/components/list-employees/list-employees.component';
import { AccessDenyComponent } from './shared/components/access-deny/access-deny.component';

export const routes: Routes = [
  {
    path: 'sign-in',
    title: 'Sign In',
    component: SignInComponent,
  },
  {
    path: 'sign-up',
    title: 'Sign Up',
    component: SignUpComponent
  },
  {
    path: '',
    title: 'Layout',
    component: LayoutComponent,
    canActivate: [authGuard], // Require authentication to access layout
    children: [
      {
        path: 'dashboard',
        title: 'Dashboard',
        component: DashboardComponent,
      },
      {
        path: 'user/profile',
        title: 'User Profile',
        component: ProfileComponent,
      },
      {
        path: 'roles/list',
        title: 'Roles',
        component: ListRoleComponent,
      },
      {
        path: 'employees/list',
        title: 'Employees',
        component: ListEmployeesComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'pick-store',
    title: 'Pick Store',
    component: PickStoreComponent,
    canActivate: [authGuard], // Require authentication to access pick store
  },
  {
    path: '403',
    title: '403 not found',
    component: AccessDenyComponent
  },
  {
    path: '404-not-found',
    title: '404 not found',
    component: NotFoundComponent
  },
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  { path: '**', component: NotFoundComponent }
];