import { tdsClamp } from 'tds-ui/cdk/utils/math';
import { tdsAssert } from 'tds-ui/cdk/classes';
function tdsCanScroll(element, rootElement, vertical, scrollEnd) {
  return vertical ? canScrollVertical(element, rootElement, scrollEnd) : canScrollHorizontal(element, rootElement, scrollEnd);
}
function canScrollVertical(element, rootElement, scrollEnd) {
  let currentElement = element;
  while (currentElement !== rootElement.parentElement) {
    if (Math.floor(currentElement.scrollTop) > 0 && !scrollEnd || Math.ceil(currentElement.scrollTop + currentElement.clientHeight) < currentElement.scrollHeight && scrollEnd) {
      return true;
    }
    if (currentElement.parentElement) {
      currentElement = currentElement.parentElement;
    } else {
      return false;
    }
  }
  return false;
}
function canScrollHorizontal(element, rootElement, scrollEnd) {
  let currentElement = element;
  while (currentElement !== rootElement.parentElement) {
    if (Math.floor(currentElement.scrollLeft) > 0 && !scrollEnd || Math.ceil(currentElement.scrollLeft + currentElement.clientWidth) < currentElement.scrollWidth && scrollEnd) {
      return true;
    }
    if (currentElement.parentElement) {
      currentElement = currentElement.parentElement;
    } else {
      return false;
    }
  }
  return false;
}
function tdsContainsOrAfter(current, node) {
  return current.contains(node) || !!(node.compareDocumentPosition(current) & Node.DOCUMENT_POSITION_PRECEDING);
}
function tdsIsInput(element) {
  return element.matches(`input`);
}
function tdsIsTextarea(element) {
  return element.matches(`textarea`);
}
function tdsIsTextfield(element) {
  return tdsIsInput(element) || tdsIsTextarea(element);
}
function tdsIsElement(node) {
  return !!node && `nodeType` in node && node.nodeType === Node.ELEMENT_NODE;
}
function tdsIsHTMLElement(node) {
  // TODO: iframe warning
  return node instanceof HTMLElement;
}
function tdsIsTextNode(node) {
  return node.nodeType === Node.TEXT_NODE;
}

/**
 * Gets actual target from open Shadow DOM if event happened within it
 */
function tdsGetActualTarget(event) {
  if (`composedPath` in event) {
    return event.composedPath()[0];
  }
  return event.target;
}
const DEFAULT_FORMAT = `text/plain`;
/**
 * Gets text from data of clipboardEvent, it also works in IE and Edge browsers
 */
function tdsGetClipboardDataText(event, format = DEFAULT_FORMAT) {
  return `clipboardData` in event && event.clipboardData !== null ? event.clipboardData.getData(format) || event.clipboardData.getData(DEFAULT_FORMAT) : event.target.ownerDocument.defaultView.clipboardData.getData(`text`);
}
function tdsGetDocumentOrShadowRoot(node) {
  return `getRootNode` in node && node.isConnected ? node.getRootNode() : node.ownerDocument;
}
function tdsIsPresent(value) {
  return value !== null && value !== undefined;
}
/**
 * Returns array of Elements covering edges of given element or null if at least one edge middle point is visible
 *
 * CAUTION: Empty array means element if offscreen i.e. covered by no elements, rather than not covered
 */
function tdsGetElementObscures(element) {
  const {
    ownerDocument
  } = element;
  if (!ownerDocument?.defaultView) {
    return null;
  }
  const {
    innerWidth,
    innerHeight
  } = ownerDocument.defaultView;
  const documentRef = tdsGetDocumentOrShadowRoot(element);
  const rect = element.getBoundingClientRect();
  const left = tdsClamp(Math.round(rect.left) + 2, 0, innerWidth);
  const top = tdsClamp(Math.round(rect.top) + 2, 0, innerHeight);
  const right = tdsClamp(Math.round(rect.right) - 2, 0, innerWidth);
  const bottom = tdsClamp(Math.round(rect.bottom) - 2, 0, innerHeight);
  const horizontalMiddle = tdsClamp(Math.round(rect.left + rect.width / 2), 0, innerWidth);
  const verticalMiddle = tdsClamp(Math.round(rect.top + rect.height / 2), 0, innerHeight);
  const elements = [documentRef.elementFromPoint(horizontalMiddle, top), documentRef.elementFromPoint(horizontalMiddle, bottom), documentRef.elementFromPoint(left, verticalMiddle), documentRef.elementFromPoint(right, verticalMiddle)];
  const nonNull = elements.filter(tdsIsPresent);
  if (!nonNull.length) {
    return nonNull;
  }
  const filtered = nonNull.filter(el => !element.contains(el));
  return filtered.length === 4 ? filtered : null;
}

/**
 * Calculates offset for an element relative to it's parent several levels above
 *
 * @param host parent element
 * @param element
 * @return object with offsetTop and offsetLeft number properties
 */
function tdsGetElementOffset(host, element) {
  tdsAssert.assert(host.contains(element), `Host must contain element`);
  let {
    offsetTop,
    offsetLeft,
    offsetParent
  } = element;
  while (tdsIsHTMLElement(offsetParent) && offsetParent !== host) {
    offsetTop += offsetParent.offsetTop;
    offsetLeft += offsetParent.offsetLeft;
    offsetParent = offsetParent.offsetParent;
  }
  return {
    offsetTop,
    offsetLeft
  };
}

/**
 * Finds the nearest parent with scroll in it
 *
 * @param element initial element
 * @param vertical flag for orientation of scroll
 */
function tdsGetScrollParent(element, vertical = true) {
  if (element === null) {
    return null;
  }
  if (vertical && element.scrollHeight > element.clientHeight) {
    return element;
  }
  if (!vertical && element.scrollWidth > element.clientWidth) {
    return element;
  }
  return tdsGetScrollParent(element.parentElement, vertical);
}

/**
 * @description:
 * cross browser way to get selected text
 *
 * History:
 * BUG - window.getSelection() fails when text selected in a form field
 * https://bugzilla.mozilla.org/show_bug.cgi?id=85686
 */
function tdsGetSelectedText({
  getSelection,
  document
}) {
  return document.activeElement && tdsIsTextfield(document.activeElement) ? document.activeElement.value.slice(document.activeElement.selectionStart || 0, document.activeElement.selectionEnd || 0) : getSelection()?.toString() || null;
}
function tdsIsCurrentTarget({
  target,
  currentTarget
}) {
  return target === currentTarget;
}
function tdsIsElementEditable(element) {
  return tdsIsTextfield(element) && !element.readOnly || element.isContentEditable;
}

/**
 * Checks if an app is running inside <iframe /> tag
 */
function tdsIsInsideIframe(windowRef) {
  return windowRef.parent !== windowRef;
}

/**
 * Checks if node is inside a specific selector
 *
 * @param node
 * @param selector
 * @return true if node is inside a particular selector
 */
function tdsIsNodeIn(node, selector) {
  return tdsIsTextNode(node) ? !!node.parentElement?.closest(selector) : tdsIsElement(node) && !!node.closest(selector);
}
function tdsPointToClientRect(x = 0, y = 0) {
  const rect = {
    x,
    y,
    left: x,
    right: x,
    top: y,
    bottom: y,
    width: 0,
    height: 0
  };
  return {
    ...rect,
    toJSON() {
      return rect;
    }
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { tdsCanScroll, tdsContainsOrAfter, tdsGetActualTarget, tdsGetClipboardDataText, tdsGetDocumentOrShadowRoot, tdsGetElementObscures, tdsGetElementOffset, tdsGetScrollParent, tdsGetSelectedText, tdsIsCurrentTarget, tdsIsElement, tdsIsElementEditable, tdsIsHTMLElement, tdsIsInput, tdsIsInsideIframe, tdsIsNodeIn, tdsIsTextNode, tdsIsTextarea, tdsIsTextfield, tdsPointToClientRect };
