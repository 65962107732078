function tdsIsEdge(userAgent) {
  return userAgent.toLowerCase().includes(`edge`);
}
const EDGE = `edge/`;
function tdsIsEdgeOlderThan(version, userAgent) {
  const currentVersion = parseInt(userAgent.slice(userAgent.toLowerCase().indexOf(EDGE) + EDGE.length), 10);
  return currentVersion < version;
}
function tdsIsFirefox(userAgent) {
  return userAgent.toLowerCase().includes(`firefox`);
}
function tdsIsSafari(element) {
  const documentRef = element.ownerDocument;
  const windowRef = documentRef?.defaultView;
  return !!windowRef && `safari` in windowRef;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tdsIsEdge, tdsIsEdgeOlderThan, tdsIsFirefox, tdsIsSafari };
