import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class BaseApiService {
  protected abstract readonly apiUrl: string;

  constructor(
    protected readonly _http: HttpClient
  ) { }

  get<T>(endpoint: string, headers?: HttpHeaders): Observable<T> {
    return this._http.get<T>(`${this.apiUrl}${endpoint}`, { headers });
  }

  post<T>(endpoint: string, data: any, headers?: HttpHeaders): Observable<T> {
    return this._http.post<T>(`${this.apiUrl}${endpoint}`, data, { headers });
  }

  put<T>(endpoint: string, data?: any, headers?: HttpHeaders): Observable<T> {
    return this._http.put<T>(`${this.apiUrl}${endpoint}`, data, { headers });
  }

  delete<T>(endpoint: string, headers?: HttpHeaders): Observable<T> {
    return this._http.delete<T>(`${this.apiUrl}${endpoint}`, { headers });
  }
}