import { AbstractControl, ValidationErrors } from "@angular/forms";

export function noWhitespaceOrVietnamese(control: AbstractControl): ValidationErrors | null {
  const hasWhitespace = /\s/.test(control.value);
  const hasVietnamese = /[àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ]/i.test(control.value);
  
  if (hasWhitespace) {
    return { hasWhitespace: true };
  }
  
  if (hasVietnamese) {
    return { hasVietnamese: true };
  }
  
  return null;
}