import { tdsAssert } from 'tds-ui/cdk/classes';
import { CHAR_HYPHEN, CHAR_NO_BREAK_SPACE, CHAR_EN_DASH } from 'tds-ui/cdk/contants';
import { tdsIsSafari } from 'tds-ui/cdk/utils/browser';
import { tdsGetDocumentOrShadowRoot } from 'tds-ui/cdk/utils/dom';
import { tdsIsNativeFocused } from 'tds-ui/cdk/utils/focus';
import { MASK_CARET_TRAP, TDS_DIGIT_REGEXP, TDS_NON_DIGITS_REGEXP, TDS_LEADING_ZEROES_REGEXP, TDS_DECIMAL_SYMBOLS } from 'tds-ui/core/constants';
import { tdsOtherDecimalSymbol } from 'tds-ui/core/format';

/**
 * Used to finish a number with zeros to a given precision
 */
function tdsCreateAutoCorrectedNumberPipe(decimalLimit = 0, decimalSymbol = `,`, thousandSymbol = CHAR_NO_BREAK_SPACE, nativeInput, allowNegative, isIOS = false) {
  tdsAssert.assert(Number.isInteger(decimalLimit));
  tdsAssert.assert(decimalLimit >= 0);
  // Guess for which browser I need this :)
  let previousCaret = -1;
  const unlucky = !!nativeInput && tdsIsSafari(nativeInput) || isIOS;
  if (nativeInput && unlucky) {
    nativeInput.addEventListener(`beforeinput`, () => {
      previousCaret = nativeInput.selectionStart || 0;
    });
  }
  return (conformedValue, config) => {
    // Removing everything by selecting and pressing '-'
    if (!conformedValue && config.rawValue === CHAR_HYPHEN && allowNegative) {
      return CHAR_HYPHEN;
    }
    // remove these hacks after text mask library has changed
    if (nativeInput && unlucky && tdsIsNativeFocused(nativeInput)) {
      const caret = calculateSafariCaret(config.previousConformedValue, conformedValue, previousCaret);
      setTimeout(() => {
        nativeInput.setSelectionRange(caret, caret);
      });
    }
    if (nativeInput && nativeInput.ownerDocument !== tdsGetDocumentOrShadowRoot(nativeInput) && tdsIsNativeFocused(nativeInput) && config.currentCaretPosition) {
      const realCaretPosition = config.currentCaretPosition + calculateCaretGap(config.previousConformedValue, conformedValue, thousandSymbol);
      setTimeout(() => {
        nativeInput.setSelectionRange(realCaretPosition, realCaretPosition);
      });
    }
    if (conformedValue === `` || !decimalLimit) {
      return {
        value: conformedValue
      };
    }
    const withDecimalSymbol = addDecimalSymbolIfNeeded(conformedValue, decimalSymbol);
    const decimalPart = withDecimalSymbol.split(decimalSymbol)[1];
    const zeroPaddingSize = decimalLimit - decimalPart.length;
    return {
      value: withDecimalSymbol + `0`.repeat(zeroPaddingSize)
    };
  };
}
function addDecimalSymbolIfNeeded(value, decimalSymbol = `,`) {
  return !value.includes(decimalSymbol) ? value + decimalSymbol : value;
}
function calculateSafariCaret(previousValue = ``, current, previousCaret, decimalSymbol = `,`) {
  const tailRegex = new RegExp(`${decimalSymbol}.+`);
  const previousWithoutTail = previousValue.replace(tailRegex, ``);
  const currentWithoutTail = current.replace(tailRegex, ``);
  const pasteOrCutOperation = Math.abs(previousWithoutTail.length - currentWithoutTail.length) > 2;
  if (pasteOrCutOperation) {
    return current.length;
  }
  if (previousValue.length === current.length) {
    if (previousValue.indexOf(decimalSymbol) <= previousCaret) {
      return calculateChangedTailIndex(previousValue, current);
    }
    return previousWithoutTail === currentWithoutTail ? previousCaret - 1 : previousCaret + 1;
  }
  if (previousValue.length === 0) {
    return 1;
  }
  const changeLength = current.length - previousValue.length;
  return previousCaret + changeLength;
}
function calculateChangedTailIndex(previous, current) {
  for (let i = 0; i < current.length; i++) {
    if (previous[i] !== current[i]) {
      return current[i] === `0` ? i : i + 1;
    }
  }
  return current.length;
}
function calculateCaretGap(previousValue = ``, current, thousandSymbol) {
  const pasteOrCutOperation = Math.abs(previousValue.length - current.length) > 2;
  if (pasteOrCutOperation) {
    return 0;
  }
  const wereSpaces = previousValue.split(thousandSymbol).length;
  const nowSpaces = current.split(thousandSymbol).length;
  return nowSpaces - wereSpaces;
}
const ASSERTION = `Correction function must return single char or null`;
function tdsCreateCorrectionMask(allowed, correctionHandler) {
  return rawValue => {
    const mask = rawValue.split(``).reduce((result, char, index) => {
      const corrected = correctionHandler(char, index);
      tdsAssert.assert(corrected === null || corrected.length === 1, ASSERTION);
      if (!allowed.test(char) && !corrected) {
        return result;
      }
      if (allowed.test(char)) {
        return [...result, allowed];
      }
      if (corrected) {
        return [...result, corrected, MASK_CARET_TRAP];
      }
      return result;
    }, []);
    const lastIndex = mask.lastIndexOf(MASK_CARET_TRAP);
    const filtered = mask.filter((item, index) => item !== MASK_CARET_TRAP || index === lastIndex);
    return filtered.some(item => item !== allowed) ? [...filtered, allowed] : filtered;
  };
}
const EMPTYSTRING = ``;
const NON_ZERO_DIGIT = /[1-9]/;
/**
 * Adaptation for {@link https://github.com/text-mask/text-mask/tree/master/addons#createnumbermask `createNumberMask`}
 */
function tdsCreateNumberMask({
  allowDecimal = false,
  decimalSymbol = `,`,
  thousandSymbol = CHAR_NO_BREAK_SPACE,
  autoCorrectDecimalSymbol = true,
  decimalLimit = 2,
  requireDecimal = false,
  allowNegative = false,
  integerLimit = 0
} = {}) {
  tdsAssert.assert(Number.isInteger(decimalLimit));
  tdsAssert.assert(decimalLimit >= 0);
  tdsAssert.assert(Number.isInteger(integerLimit));
  tdsAssert.assert(integerLimit >= 0);
  return (rawValue, {
    previousConformedValue
  }) => {
    if (previousConformedValue && requireDecimal) {
      const conformedWithoutSeparator = rawValue.split(thousandSymbol).join(``);
      const previousConformedValueWithoutDecimalSymbolAndSeparator = previousConformedValue.split(thousandSymbol).join(``).split(decimalSymbol).join(``);
      // Forbid removal of decimal separator if decimal part is required
      if (conformedWithoutSeparator === previousConformedValueWithoutDecimalSymbolAndSeparator) {
        rawValue = previousConformedValue;
      }
    }
    const isNegative = (rawValue[0] === CHAR_HYPHEN || rawValue[0] === CHAR_EN_DASH) && allowNegative;
    if (isDecimalSymbol(rawValue, decimalSymbol, autoCorrectDecimalSymbol) && allowDecimal) {
      return [`0`, decimalSymbol, TDS_DIGIT_REGEXP];
    }
    if (isNegative) {
      rawValue = rawValue.slice(1);
    }
    const decimalIndex = getDecimalSymbolIndex(rawValue, decimalSymbol, autoCorrectDecimalSymbol);
    const hasDecimal = decimalIndex !== -1;
    const integer = hasDecimal ? rawValue.slice(0, decimalIndex) : rawValue;
    const thousandSeparators = integer.match(new RegExp(thousandSymbol, `g`)) || [];
    const integerCapped = integerLimit ? integer.slice(0, integerLimit + thousandSeparators.length) : integer;
    const integerCappedClean = integerCapped.replace(TDS_NON_DIGITS_REGEXP, ``);
    const [leadingZerosMatch] = integerCappedClean.match(TDS_LEADING_ZEROES_REGEXP) || [``];
    const leadingZerosAmount = leadingZerosMatch.length;
    const integerCappedZerosClean = integerCappedClean.replace(/^0+(?!\.|$)/, ``).trim();
    const withSeparator = addThousandsSeparator(integerCappedZerosClean, thousandSymbol);
    const mask = convertToMask(withSeparator);
    if (hasDecimal && allowDecimal || requireDecimal) {
      const fraction = hasDecimal ? convertToMask(rawValue.slice(decimalIndex + 1).replace(TDS_NON_DIGITS_REGEXP, ``)) : [];
      const fractionCapped = decimalLimit ? fraction.slice(0, decimalLimit) : fraction;
      if (rawValue[decimalIndex] !== tdsOtherDecimalSymbol(decimalSymbol)) {
        mask.push(MASK_CARET_TRAP);
      }
      mask.push(decimalSymbol, MASK_CARET_TRAP, ...fractionCapped);
      for (let i = 0; i < decimalLimit - fractionCapped.length; i++) {
        mask.push(TDS_DIGIT_REGEXP);
      }
    }
    const isOnlyZeroDigit = mask.length === 1 && integerCappedZerosClean === `0`;
    if (isNegative) {
      if (mask.length === 0) {
        mask.push(TDS_DIGIT_REGEXP);
      }
      mask.unshift(CHAR_HYPHEN);
    }
    return preventLeadingZeroes(mask, isOnlyZeroDigit, leadingZerosAmount);
  };
}
function preventLeadingZeroes(mask, isOnlyZeroDigit = false, leadingZerosAmount = 0) {
  if (isOnlyZeroDigit || leadingZerosAmount === 0) {
    return mask;
  }
  const firstDigitIndex = mask.indexOf(TDS_DIGIT_REGEXP);
  if (firstDigitIndex === -1) {
    return mask;
  }
  const secondMaskDigit = mask[firstDigitIndex + 1];
  const isCaretTrap = secondMaskDigit === MASK_CARET_TRAP;
  if (isCaretTrap && leadingZerosAmount === 1) {
    return mask;
  } else if (isCaretTrap) {
    mask.unshift(NON_ZERO_DIGIT);
    return mask;
  }
  mask[firstDigitIndex] = NON_ZERO_DIGIT;
  return mask;
}
function getDecimalSymbolIndex(str, decimalSymbol, autoCorrectDecimalSymbol) {
  if (!autoCorrectDecimalSymbol) {
    return str.lastIndexOf(decimalSymbol);
  }
  return Math.max(str.lastIndexOf(decimalSymbol), str.lastIndexOf(tdsOtherDecimalSymbol(decimalSymbol)));
}
function isDecimalSymbol(str, decimalSymbol, autoCorrectDecimalSymbol) {
  if (autoCorrectDecimalSymbol) {
    return /^[,.]$/.test(str);
  }
  return str === decimalSymbol;
}
function convertToMask(strNumber) {
  return strNumber.split(``).map(char => TDS_DIGIT_REGEXP.test(char) ? TDS_DIGIT_REGEXP : char);
}
function addThousandsSeparator(strNumber, thousandSymbol) {
  return strNumber.length > 3 ?
  // TODO: investigate to disallow potentially catastrophic exponential-time regular expressions.
  // eslint-disable-next-line unicorn/no-unsafe-regex
  strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSymbol) : strNumber;
}
function tdsEnableAutoCorrectDecimalSymbol({
  thousandSeparator
}) {
  return !TDS_DECIMAL_SYMBOLS.includes(thousandSeparator);
}
function tdsMaskedMoneyValueIsEmpty(value) {
  switch (value) {
    case ``:
    case CHAR_HYPHEN:
    case `,`:
    case `${CHAR_HYPHEN},`:
      return true;
    default:
      return false;
  }
}
function tdsMaskedNumberStringToNumber(value, decimalsSymbol, thousandSymbol) {
  return parseFloat(value.split(thousandSymbol).join(``).split(decimalsSymbol).join(`.`));
}

/**
 * Generated bundle index. Do not edit.
 */

export { tdsCreateAutoCorrectedNumberPipe, tdsCreateCorrectionMask, tdsCreateNumberMask, tdsEnableAutoCorrectDecimalSymbol, tdsMaskedMoneyValueIsEmpty, tdsMaskedNumberStringToNumber };
