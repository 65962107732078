import { CHAR_HYPHEN } from 'tds-ui/cdk/contants';
import { TDS_DEFAULT_NUMBER_FORMAT } from 'tds-ui/core/constants';
import { isNumberFinite } from 'tds-ui/core/util';

/**
 * Convert number to string with replacing exponent part on decimals
 *
 * @param value the number
 * @return string representation of a number
 */
function tdsNumberToStringWithoutExp(value) {
  const valueAsString = String(value);
  const [numberPart, expPart] = valueAsString.split(`e-`);
  let valueWithoutExp = valueAsString;
  if (expPart) {
    const [, fractionalPart] = numberPart.split(`.`);
    const decimalDigits = Number(expPart) + (fractionalPart?.length || 0);
    valueWithoutExp = value.toFixed(decimalDigits);
  }
  return valueWithoutExp;
}

/**
 * Return fractional part of number
 *
 * @param value the number
 * @param precision number of digits of decimal part, null to keep untouched
 * @return the fractional part of number
 */
function tdsGetFractionPartPadded(value, precision) {
  const [, fractionPartPadded = ``] = tdsNumberToStringWithoutExp(value).split(`.`);
  return isNumberFinite(precision) ? fractionPartPadded.slice(0, Math.max(0, precision)) : fractionPartPadded;
}

/**
 * Formats number adding a thousand separators and correct decimal separator
 * padding decimal part with zeroes to given length
 *
 * @param value the input number
 * @param settings See {@link tdsNumberFormatSettings}
 * @return the formatted string
 */
function tdsFormatNumber(value, settings = {}) {
  const {
    decimalLimit,
    decimalSeparator,
    thousandSeparator,
    zeroPadding
  } = {
    ...TDS_DEFAULT_NUMBER_FORMAT,
    ...settings
  };
  const integerPartString = String(Math.floor(Math.abs(value)));
  let fractionPartPadded = tdsGetFractionPartPadded(value, decimalLimit);
  if (Number.isFinite(decimalLimit)) {
    if (zeroPadding) {
      const zeroPaddingSize = Math.max(decimalLimit - fractionPartPadded.length, 0);
      const zeroPartString = `0`.repeat(zeroPaddingSize);
      fractionPartPadded = `${fractionPartPadded}${zeroPartString}`;
    } else {
      fractionPartPadded = fractionPartPadded.replace(/0*$/, ``);
    }
  }
  const remainder = integerPartString.length % 3;
  const sign = value < 0 ? CHAR_HYPHEN : ``;
  let result = sign + integerPartString.charAt(0);
  for (let i = 1; i < integerPartString.length; i++) {
    if (i % 3 === remainder && integerPartString.length > 3) {
      result += thousandSeparator;
    }
    result += integerPartString.charAt(i);
  }
  return fractionPartPadded ? result + decimalSeparator + fractionPartPadded : result;
}
function tdsOtherDecimalSymbol(symbol) {
  return symbol === `.` ? `,` : `.`;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tdsFormatNumber, tdsGetFractionPartPadded, tdsNumberToStringWithoutExp, tdsOtherDecimalSymbol };
