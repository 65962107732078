<div class="h-full w-full bg-white">
  <tds-header 
    tdsTheme="dark"
    urlLogoText="/assets/images/WiOnLogo.svg"
    urlLogo="/assets/images/WiOnLogo.svg"
    class="!pl-[60px] !py-2"
  ></tds-header>

  <tds-spin [spinning]="isLoading" class="w-full h-full">
    <div class="w-full h-full pt-24">
      <div class="flex m-auto h-[668px] w-[520px] rounded-xl border border-solid tds-color-border-secondary shadow-xl">
        <form
          #formDirective="ngForm"
          class="h-full w-full" 
          [formGroup]="signUpForm"
          (ngSubmit)="sendOTPViaZalo(phoneNumber.value)"
        >
          <div class="px-[70px] py-16 w-full h-full flex flex-col gap-16">
            <section class="flex flex-col justify-center items-center gap-4">
              <h3 class="text-heading-3 font-semibold font-tds-font-family-heading">Đăng ký</h3>
              <div class="text-body-1 font-tds-font-family-body items-center">
                <span class="tds-color-text-tertiary mr-1">Chào mừng bạn đến với</span>
                <span class="tds-color-text-brand-primary font-semibold">WiOn E-commerce</span>
              </div>
            </section>

            <section class="flex flex-col">
              <div>
                <tds-label [tdsRequired]="true">Số điện thoại</tds-label>
                <tds-form-field class="w-full mt-2">
                  <span tdsPrefix class="tds-body-2 font-semibold">+84</span>
                  <input tdsInput
                    type="text"
                    size="xl"
                    autocomplete="off"
                    placeholder="Nhập số điện thoại"
                    formControlName="phoneNumber"
                    [required]='true'
                    maxlength="10"
                    numbersOnly
                    (blur)="onPhoneBlur()"
                    (focus)="onPhoneFocus()"
                  />
                  @if(phoneNumber.errors?.['required'] && (phoneIsTouched || phoneNumber.dirty || phoneNumber.touched)) {
                    <tds-error>Vui lòng nhập số điện thoại</tds-error>
                  }

                  @if(phoneNumber.errors?.['pattern'] && phoneIsTouched) {
                    <tds-error>Số điện thoại không hợp lệ</tds-error>
                  }
                </tds-form-field>
              </div>

              @if(isShowWarning) {
                <tds-alert
                  class="mt-2"
                  type="info"
                  [message]="contentMessage"
                  showIcon
                >
                </tds-alert>
                <ng-template #contentMessage>
                  <div class="text-body-2 font-regular">Vui lòng gửi lại yêu cầu sau <span class="font-semibold">{{resendTime}}s</span></div>
                </ng-template>
              }

              <div class="flex flex-col gap-2 w-full mt-6">
                <button tds-button color="primary" type="submit" size="xl">
                  Gửi mã OTP qua Zalo
                </button>
                <button tds-button-outline color="secondary" class="pt-2" type="button" size="xl"
                  (tdsBtnClick)="sendOTPViaSMS(phoneNumber.value)">
                  Gửi mã OTP qua SMS
                </button>
              </div>

              <div class="w-full mt-6">
                <div class="flex flex-row items-start gap-3">
                  <tds-checkbox formControlName="isCheck" (tdsChange)="onCheckboxChange($event)"></tds-checkbox>
                  <div class="tds-body-2">
                    Tôi đã đọc và đồng ý với <a tdsLink class="tds-color-text-info">Chính sách bảo mật</a> và 
                    <a tdsLink class="tds-color-text-info">Điều khoản sử dụng</a> của dịch vụ.
                  </div>
                </div>
                @if(isCheck.errors?.['required'] && isSubmitted) {
                  <tds-error class="mt-2">Vui lòng chọn điều khoản và chính sách bảo mật</tds-error>
                }
              </div>
            </section>

            <section class="flex justify-center tds-body-2 gap-2">
              <span class="tds-color-text-tertiary mr-1">Bạn đã có tài khoản?</span>
              <a tds-link class="font-semibold cursor-pointer" routerLink="/sign-in">
                Đăng nhập
              </a>
            </section>
          </div>
        </form>
      </div>
    </div>
  </tds-spin>
</div>

<ng-template #existPhoneNumber>
  <div class="tds-body-2 w-full">
    Số điện thoại <span class="font-semibold">{{phoneNumber.value}}</span> đã được đăng ký trên hệ thống của 
    <span class="font-semibold">WiAccount</span>.
    <span class="ml-1">Bạn vui lòng đăng nhập để sử dụng dịch vụ.</span>
  </div>
</ng-template>

<!-- Limit access exceeded otp template -->
<ng-template #limitOtp>
  <div class="w-full flex flex-col justify-center text-center tds-body-2">
    <div>
      Bạn đã hết 3 lượt gửi mã.
    </div>
    <div>
      Vui lòng thử lại sau <span class="font-semibold ml-0.5">{{sixHoursFromLatestTimeSend | date: 'HH:mm'}}</span>
    </div>
  </div>
</ng-template>