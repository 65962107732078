import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private reloadHeader = new BehaviorSubject<boolean>(false);
  reloadHeader$ = this.reloadHeader.asObservable();

  triggerReloadHeader(): void {
    this.reloadHeader.next(true);
  }

  resetReloadHeader(): void {
    this.reloadHeader.next(false);
  }
}
