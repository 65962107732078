import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TDSCheckboxChange, TDSCheckBoxModule } from 'tds-ui/tds-checkbox';
import { EPermissionAction } from '../../enums/permission-action.enum';
import { IMyPermission, IPermission } from '../../models/permission.model';

export interface INestedFormGroup {
  action: FormControl<EPermissionAction | null>;
  name: FormControl<string | null>;
  displayName: FormControl<string | null>;
  isActive: FormControl<boolean | null>;
  isIndeterminate?: FormControl<boolean | null>;
  permissionDetailList: FormArray<FormGroup<INestedFormGroup>>;
}

@Component({
  selector: 'app-setting-permission',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSCheckBoxModule,
  ],
  templateUrl: './setting-permission.component.html',
  styleUrl: './setting-permission.component.scss'
})
export class SettingPermissionComponent {
  @Input() permission!: IPermission;
  @Output() onChangeActivePermission = new EventEmitter<void>();

  form!: FormGroup;
  isCollapsed = false;

  ngOnInit(): void {
    this.createForm();
    
    if (this.permissionDetailList.length) {
      this.checkActivePermission();
    }
  }

  checkActivePermission(): void {
    const permissionDetails = this.permissionDetailList.value as IMyPermission[];

    const isActive = permissionDetails.every(item => item.isActive);
    this.setActive(isActive);

    const indeterminate = !permissionDetails.every(item => item.isActive)
      && !permissionDetails.every(item => !item.isActive);
    this.setIndeterminate(indeterminate);
  }

  createForm(): void {
    this.form = new FormGroup({
      permissionType: new FormControl(this.permission.permissionType, [Validators.required]),
      permissionName: new FormControl(this.permission.permissionName, [Validators.required, Validators.maxLength(64)]),
      displayName: new FormControl(this.permission.displayName, [Validators.required, Validators.maxLength(64)]),
      isActive: new FormControl(this.permission.isActive),
      isIndeterminate: new FormControl(false),
      permissionDetailList: new FormArray(
        this.permission.permissionDetailList
          ?.map(item => this.createPermissionDetailForm(item)) ?? []
      )
    });
  }

  get displayName(): FormControl {
    return this.form.get('displayName') as FormControl;
  }

  get isActive(): FormControl {
    return this.form.get('isActive') as FormControl;
  }

  get isIndeterminate(): FormControl {
    return this.form.get('isIndeterminate') as FormControl;
  }

  setActive(val: boolean) {
    this.isActive.setValue(val);
  }

  setIndeterminate(val: boolean) {
    this.isIndeterminate.setValue(val);
  }

  createPermissionDetailForm(permissionDetail: IMyPermission): FormGroup {
    const formGroup = new FormGroup<INestedFormGroup>({
      action: new FormControl(permissionDetail.action, [Validators.required]),
      name: new FormControl(permissionDetail.name, [Validators.required, Validators.maxLength(64)]),
      displayName: new FormControl(permissionDetail.displayName, [Validators.required, Validators.maxLength(64)]),
      isActive: new FormControl(permissionDetail.isActive, [Validators.required]),
      isIndeterminate: new FormControl(false),
      permissionDetailList: new FormArray<FormGroup<INestedFormGroup>>([])
    });

    if (permissionDetail.permissionDetailList && permissionDetail.permissionDetailList.length > 0) {
      const nestedFormArray = formGroup.get('permissionDetailList') as FormArray;
      permissionDetail.permissionDetailList.forEach(item => {
        nestedFormArray.push(this.createPermissionDetailForm(item)); // Recursive call
      });
    }

    return formGroup;
  }

  get permissionDetailList(): FormArray {
    return this.form.get('permissionDetailList') as FormArray;
  }

  permissionDetailListAtIndex(index: number): FormArray {
    return this.permissionDetailList.controls[index].get('permissionDetailList') as FormArray;
  }

  changeIsActivePermission(formArray: FormArray<FormGroup<INestedFormGroup>>, isActive: boolean): void {
    formArray.controls.forEach(item => {
      item.get('isActive')?.setValue(isActive);
      item.get('isIndeterminate')?.setValue(false);
      if (item.get('permissionDetailList')?.value.length! > 0) {
        this.changeIsActivePermission(item.get('permissionDetailList') as FormArray, isActive);
      }
    });
  }

  onChangeActive(event: TDSCheckboxChange): void {
    this.setActive(event.checked);
    this.setIndeterminate(false);
    if (this.permissionDetailList.value.length > 0) {
      this.changeIsActivePermission(this.permissionDetailList, event.checked);
    }
    this.onChangeActivePermission.emit();
  }

  onChangeActiveItem(event: TDSCheckboxChange, control: AbstractControl, parentIndex?: number): void {
    (control as FormGroup).get('isActive')?.setValue(event.checked);

    if (parentIndex !== undefined) {
      const parent = (this.form.get('permissionDetailList') as FormArray).controls[parentIndex];

      if (parent.get('permissionDetailList')?.value.length > 0) {
        const permissionDetailList = parent.get('permissionDetailList')?.value as IMyPermission[];
        const indeterminate = !permissionDetailList?.every(item => item.isActive)
          && !permissionDetailList?.every(item => !item.isActive);
        parent.get('isIndeterminate')?.setValue(indeterminate);

        const allActive = permissionDetailList?.every(item => item.isActive);
        parent.get('isActive')?.setValue(allActive);
      }
    }

    const permissionDetail = control.get('permissionDetailList') as FormArray;
    if (permissionDetail.value.length > 0) {
      this.changeIsActivePermission(permissionDetail, event.checked);
    }

    this.checkActivePermission();
    this.onChangeActivePermission.emit();
  }

  collapsePermission(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  checkAllPermissionsActive(formArray: FormArray): boolean {
    this.checkActivePermission();
    return formArray.controls.every(control => {
      if (control.get('permissionDetailList') && control.get('permissionDetailList')?.value.length > 0) {
        return this.checkAllPermissionsActive(control.get('permissionDetailList') as FormArray);
      }
      return control.get('isActive')?.value;
    });
  }
  
  checkAtLeastOnePermissionActive(formArray: FormArray): boolean {
    return formArray.controls.some(control => {
      if (control.get('permissionDetailList') && control.get('permissionDetailList')?.value.length > 0) {
        return this.checkAtLeastOnePermissionActive(control.get('permissionDetailList') as FormArray);
      }
      return control.get('isActive')?.value;
    });
  }
}
