<div class="w-full h-full flex flex-col gap-6 px-[70px] pt-[64px]">
  <section class="w-full flex justify-center items-center">
    <div class="w-[140px] h-[140px]">
      <svg width="140" height="141" viewBox="0 0 140 141" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="41.4817" y="36.7896" width="64.8148" height="64.8148" rx="32.4074" fill="#28A745"/>
        <path d="M69.1821 83.4914C68.1274 84.5461 66.4175 84.5461 65.3628 83.4914L51.8789 70.0076L55.7273 66.1592L67.2724 77.7043L92.0506 52.9261L95.899 56.7745L69.1821 83.4914Z" fill="white"/>
        <g opacity="0.08">
        <rect y="0.493347" width="140" height="140" rx="70" fill="#28A745"/>
        </g>
        <g opacity="0.15">
        <rect x="16.8518" y="17.3453" width="106.296" height="106.296" rx="53.1481" fill="#28A745"/>
        </g>
        <rect x="32.407" y="32.9007" width="75.1852" height="75.1852" rx="37.5926" fill="#28A745"/>
        <path d="M65.6424 83.583C64.6664 84.559 63.0839 84.559 62.1079 83.583L49.6292 71.1043L53.1906 67.5428L63.8751 78.2273L86.8061 55.2963L90.3676 58.8578L65.6424 83.583Z" fill="white"/>
        <path d="M19 26.9998C21.7614 26.9998 24 24.7612 24 21.9998C24 19.2384 21.7614 16.9998 19 16.9998C16.2386 16.9998 14 19.2384 14 21.9998C14 24.7612 16.2386 26.9998 19 26.9998Z" fill="#28A745"/>
        <path d="M130.837 109C132.956 109 134.673 107.209 134.673 105C134.673 102.791 132.956 101 130.837 101C128.718 101 127 102.791 127 105C127 107.209 128.718 109 130.837 109Z" fill="#F0A328"/>
        <path d="M32 134.51C35.3137 134.51 38 131.71 38 128.255C38 124.8 35.3137 122 32 122C28.6863 122 26 124.8 26 128.255C26 131.71 28.6863 134.51 32 134.51Z" fill="#2A92FF"/>
        <path d="M123 27.2551C124.657 27.2551 126 25.8548 126 24.1275C126 22.4001 124.657 20.9998 123 20.9998C121.343 20.9998 120 22.4001 120 24.1275C120 25.8548 121.343 27.2551 123 27.2551Z" fill="#EB3B5B"/>
      </svg>
    </div>
  </section>
  <section class="w-full flex flex-col gap-20">
    <div class="flex flex-col gap-5 w-full">
      <div class="w-full flex flex-col gap-4 items-center justify-center">
        <div class="tds-heading-4 tds-color-text-primary font-semibold">
          Đăng ký thành công
        </div>
        <div class="tds-body-2 flex flex-col text-center">
          <span>Tài khoản của bạn đã được đăng ký thành công. Bạn có</span>
          <span>thể sử dụng tài khoản này để đăng nhập vào tất cả các</span>
          <span>ứng dụng của <span class="font-semibold">WiAccount</span> như:</span>
        </div>
      </div>
      <div class="w-full flex gap-4 items-center">
        <svg width="109" height="60" viewBox="0 0 109 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.0793 24.0762H54.5404V28.5241L57.6816 25.3961V20.9218H53.181L50.0793 24.0762Z" fill="#00AC52" />
            <path
              d="M8.93994 31.6786L14.8528 47.7543C16.3443 51.8194 22.0988 51.8194 23.5902 47.7543L29.5031 31.6918H25.0684L19.9474 45.5765C19.6967 46.2497 18.7464 46.2497 18.4956 45.5765L13.401 31.6786H8.93994Z"
              fill="black" />
            <path
              d="M25.0684 31.6786L30.9813 47.7543C32.4727 51.8194 38.2272 51.8194 39.7186 47.7543L45.6315 31.6918H41.1968L36.0758 45.5765C35.8251 46.2497 34.8748 46.2497 34.624 45.5765L29.5294 31.6786H25.0684Z"
              fill="black" />
            <path d="M54.5404 31.6786H50.0794V50.5655H54.5404V31.6786Z" fill="black" />
            <path
              d="M73.9421 32.5761C72.4507 31.7314 70.7481 31.309 68.8739 31.309C66.9998 31.309 65.2972 31.7314 63.8057 32.5761C62.3143 33.4208 61.1397 34.5954 60.2818 36.0736C59.4239 37.5519 58.9883 39.2413 58.9883 41.1154C58.9883 42.9896 59.4239 44.6922 60.2818 46.1572C61.1397 47.6355 62.3275 48.8101 63.8057 49.6548C65.284 50.4995 66.9866 50.9219 68.8739 50.9219C70.7481 50.9219 72.4507 50.4995 73.9421 49.6548C75.4335 48.8101 76.6082 47.6355 77.4397 46.1572C78.2712 44.679 78.6935 42.9896 78.6935 41.1154C78.6935 39.2413 78.2712 37.5387 77.4397 36.0736C76.595 34.5954 75.4203 33.4208 73.9421 32.5761ZM71.6588 46.2496C70.8669 46.7644 69.9298 47.0283 68.8739 47.0283C67.8445 47.0283 66.9074 46.7644 66.0891 46.2496C65.2708 45.7349 64.6108 45.0222 64.1621 44.1511C63.7001 43.2668 63.4758 42.2505 63.4758 41.1286C63.4758 40.0068 63.7133 38.9773 64.1621 38.1062C64.624 37.2219 65.2708 36.5224 66.0891 36.0077C66.9074 35.4929 67.8445 35.2289 68.8739 35.2289C69.9298 35.2289 70.8669 35.4929 71.6588 36.0077C72.4639 36.5224 73.0974 37.2087 73.5594 38.0534C74.0213 38.8981 74.2457 39.8748 74.2457 40.9571V41.3134C74.2457 42.3693 74.0081 43.3328 73.5594 44.2039C73.0974 45.0354 72.4639 45.7349 71.6588 46.2496Z"
              fill="black" />
            <path
              d="M98.8872 34.7669C98.3988 33.6319 97.6465 32.7608 96.6566 32.1801C95.6799 31.5993 94.4657 31.309 93.0535 31.309C91.9976 31.309 90.9813 31.5333 90.031 31.9689C89.0807 32.4044 88.2492 32.9852 87.5497 33.6715C87.4045 33.8299 87.2725 33.9882 87.1406 34.1334L87.1142 31.6521H82.8247V50.5654H87.2198V39.0036C87.2198 38.5021 87.3253 38.027 87.5365 37.5782C87.7477 37.1295 88.0645 36.7071 88.4604 36.3508C88.8564 35.9944 89.3315 35.704 89.8594 35.5193C90.4006 35.3213 90.9945 35.2289 91.628 35.2289H91.7996C92.5255 35.2289 93.1458 35.3609 93.6474 35.6248C94.1357 35.8756 94.5185 36.3244 94.7824 36.9315C95.0596 37.565 95.1916 38.3701 95.1916 39.3336V50.5918H99.5867V39.0564C99.6131 37.3274 99.3755 35.8888 98.8872 34.7669Z"
              fill="black" />
            <path
              d="M62.1519 20.9317V9.40457H66.8867C67.5593 9.40457 68.1528 9.56284 68.6672 9.87937C69.1947 10.1959 69.6036 10.618 69.9201 11.1587C70.2367 11.6994 70.3949 12.3193 70.3949 12.992C70.3949 13.6778 70.2367 14.3109 69.9201 14.8648C69.6036 15.4187 69.1816 15.8671 68.6672 16.1969C68.1528 16.5266 67.5461 16.6849 66.8867 16.6849H64.183V20.9317H62.1519ZM64.1566 14.8384H66.7416C67.0318 14.8384 67.3087 14.7593 67.5461 14.601C67.7835 14.4427 67.9814 14.2317 68.1264 13.9547C68.2715 13.6778 68.3375 13.3744 68.3375 13.0183C68.3375 12.6754 68.2715 12.3853 68.1264 12.1215C67.9814 11.8577 67.7967 11.6599 67.5461 11.5016C67.3087 11.3433 67.0318 11.2774 66.7416 11.2774H64.1566V14.8384Z"
              fill="#00AC52" />
            <path
              d="M77.8074 21.0899C76.9765 21.0899 76.1983 20.9448 75.4993 20.6415C74.7871 20.3513 74.1804 19.9293 73.6661 19.4017C73.1517 18.8742 72.756 18.2543 72.4659 17.5289C72.1757 16.8035 72.0438 16.0122 72.0438 15.1417C72.0438 14.2844 72.1889 13.4931 72.4659 12.7677C72.756 12.0423 73.1517 11.4092 73.6661 10.8817C74.1804 10.3409 74.7871 9.93206 75.4993 9.62872C76.2115 9.33856 76.9765 9.19348 77.8074 9.19348C78.6383 9.19348 79.4032 9.33856 80.1023 9.64191C80.8013 9.93206 81.408 10.3541 81.9223 10.8949C82.4499 11.4356 82.8456 12.0687 83.1357 12.7941C83.4259 13.5195 83.5578 14.3108 83.5578 15.1549C83.5578 16.0122 83.4127 16.8035 83.1357 17.5289C82.8456 18.2543 82.4499 18.8873 81.9223 19.4149C81.3948 19.9425 80.7881 20.3645 80.1023 20.6547C79.4164 20.9448 78.6515 21.0899 77.8074 21.0899ZM77.8074 19.1511C78.3481 19.1511 78.8361 19.0588 79.2845 18.861C79.733 18.6631 80.1286 18.3862 80.4584 18.0301C80.7881 17.674 81.0387 17.2519 81.2365 16.7639C81.4343 16.2759 81.5135 15.7352 81.5135 15.1549C81.5135 14.5878 81.4212 14.0602 81.2365 13.5722C81.0519 13.0842 80.7881 12.6622 80.4584 12.2929C80.1286 11.9236 79.7462 11.6466 79.2977 11.4488C78.8493 11.251 78.3481 11.1586 77.8206 11.1586C77.2798 11.1586 76.7918 11.2641 76.3434 11.4488C75.895 11.6466 75.4993 11.9236 75.1696 12.2797C74.8399 12.6358 74.5761 13.0578 74.3915 13.5458C74.2068 14.0338 74.1145 14.5746 74.1145 15.1549C74.1145 15.933 74.2728 16.6188 74.6025 17.2255C74.9322 17.8322 75.3674 18.307 75.9214 18.6368C76.4489 18.9797 77.082 19.1511 77.8074 19.1511Z"
              fill="#00AC52" />
            <path
              d="M89.664 21.0899C88.6353 21.0899 87.7516 20.8921 86.9999 20.5096C86.2613 20.1271 85.6282 19.56 85.1006 18.8346L86.4063 17.3706C86.9471 18.1092 87.4747 18.6104 88.0154 18.9005C88.543 19.1907 89.1496 19.3226 89.8223 19.3226C90.2048 19.3226 90.5609 19.2566 90.8774 19.1379C91.1939 19.0192 91.4445 18.8478 91.6292 18.6236C91.8138 18.3994 91.9061 18.1488 91.9061 17.8718C91.9061 17.674 91.8666 17.4893 91.7874 17.3311C91.7083 17.1596 91.6028 17.0277 91.4445 16.8958C91.2863 16.7639 91.1148 16.6452 90.8906 16.5397C90.6664 16.4342 90.429 16.3287 90.152 16.2496C89.8882 16.1704 89.5717 16.0913 89.242 16.0253C88.6485 15.8935 88.1341 15.722 87.6857 15.5242C87.2373 15.3263 86.868 15.0757 86.5646 14.7856C86.2613 14.4954 86.0371 14.1657 85.892 13.7964C85.7469 13.4271 85.6678 13.0051 85.6678 12.5171C85.6678 11.8708 85.8524 11.2905 86.2217 10.8025C86.591 10.3145 87.079 9.90568 87.6989 9.62872C88.3187 9.33856 88.9914 9.19348 89.7432 9.19348C90.7191 9.19348 91.5368 9.36494 92.2095 9.70785C92.8689 10.0508 93.3965 10.5388 93.7922 11.1586L92.4601 12.4775C92.1172 11.9895 91.7215 11.6071 91.2731 11.3565C90.8246 11.1059 90.3235 10.974 89.7563 10.974C89.3475 10.974 88.9914 11.0267 88.6748 11.1454C88.3583 11.2641 88.1209 11.4224 87.9495 11.6466C87.778 11.8708 87.6989 12.1214 87.6989 12.4248C87.6989 12.649 87.7516 12.86 87.8439 13.0446C87.9363 13.2293 88.0945 13.3876 88.2924 13.5195C88.4902 13.6513 88.7276 13.77 89.0178 13.8756C89.3079 13.9679 89.6376 14.0602 90.0069 14.1393C90.6136 14.258 91.1544 14.4163 91.6424 14.6273C92.1303 14.8252 92.5392 15.0626 92.8821 15.3395C93.225 15.6165 93.4888 15.933 93.6603 16.2891C93.8449 16.6452 93.9372 17.0541 93.9372 17.5025C93.9372 18.2411 93.7526 18.8742 93.3965 19.4149C93.0404 19.9556 92.5392 20.3645 91.9061 20.6547C91.2599 20.9448 90.5213 21.0899 89.664 21.0899Z"
              fill="#00AC52" />
          </svg>

          <svg width="121" height="60" viewBox="0 0 121 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.6082 24.0743H55.0692V28.5221L58.2104 25.3941V20.9199H53.7098L50.6082 24.0743Z" fill="#00AC52" />
            <path
              d="M9.46875 31.6766L15.3816 47.7523C16.8731 51.8174 22.6276 51.8174 24.119 47.7523L30.0319 31.6898H25.5972L20.4762 45.5745C20.2255 46.2477 19.2752 46.2477 19.0244 45.5745L13.9298 31.6766H9.46875Z"
              fill="black" />
            <path
              d="M25.5972 31.6766L31.5101 47.7523C33.0015 51.8174 38.756 51.8174 40.2474 47.7523L46.1603 31.6898H41.7256L36.6047 45.5745C36.3539 46.2477 35.4036 46.2477 35.1528 45.5745L30.0582 31.6766H25.5972Z"
              fill="black" />
            <path d="M55.0693 31.6766H50.6082V50.5635H55.0693V31.6766Z" fill="black" />
            <path
              d="M74.4709 32.5741C72.9795 31.7294 71.2769 31.307 69.4027 31.307C67.5286 31.307 65.826 31.7294 64.3345 32.5741C62.8431 33.4188 61.6685 34.5934 60.8106 36.0716C59.9527 37.5499 59.5171 39.2393 59.5171 41.1134C59.5171 42.9876 59.9527 44.6902 60.8106 46.1552C61.6685 47.6334 62.8563 48.8081 64.3345 49.6528C65.8128 50.4975 67.5154 50.9199 69.4027 50.9199C71.2769 50.9199 72.9795 50.4975 74.4709 49.6528C75.9623 48.8081 77.137 47.6334 77.9685 46.1552C78.8 44.677 79.2224 42.9876 79.2224 41.1134C79.2224 39.2393 78.8 37.5367 77.9685 36.0716C77.1238 34.5934 75.9492 33.4188 74.4709 32.5741ZM72.1876 46.2476C71.3957 46.7624 70.4586 47.0263 69.4027 47.0263C68.3733 47.0263 67.4362 46.7624 66.6179 46.2476C65.7996 45.7329 65.1396 45.0202 64.6909 44.1491C64.229 43.2648 64.0046 42.2485 64.0046 41.1266C64.0046 40.0048 64.2422 38.9753 64.6909 38.1042C65.1528 37.2199 65.7996 36.5204 66.6179 36.0056C67.4362 35.4909 68.3733 35.2269 69.4027 35.2269C70.4586 35.2269 71.3957 35.4909 72.1876 36.0056C72.9927 36.5204 73.6262 37.2067 74.0882 38.0514C74.5501 38.8961 74.7745 39.8728 74.7745 40.955V41.3114C74.7745 42.3673 74.5369 43.3308 74.0882 44.2019C73.6262 45.0334 72.9927 45.7329 72.1876 46.2476Z"
              fill="black" />
            <path
              d="M99.416 34.7649C98.9276 33.6299 98.1753 32.7588 97.1854 32.178C96.2087 31.5973 94.9945 31.3069 93.5823 31.3069C92.5264 31.3069 91.5101 31.5313 90.5598 31.9669C89.6095 32.4024 88.778 32.9831 88.0785 33.6695C87.9333 33.8278 87.8014 33.9862 87.6694 34.1314L87.643 31.6501H83.3535V50.5634H87.7486V39.0016C87.7486 38.5001 87.8541 38.0249 88.0653 37.5762C88.2765 37.1274 88.5933 36.7051 88.9892 36.3487C89.3852 35.9924 89.8603 35.702 90.3882 35.5172C90.9294 35.3193 91.5233 35.2269 92.1568 35.2269H92.3284C93.0543 35.2269 93.6746 35.3589 94.1762 35.6228C94.6645 35.8736 95.0473 36.3223 95.3113 36.9295C95.5884 37.563 95.7204 38.3681 95.7204 39.3316V50.5898H100.115V39.0544C100.142 37.3254 99.9043 35.8868 99.416 34.7649Z"
              fill="black" />
            <path d="M109.835 20.9233V8.71332H111.766V20.9233H109.835Z" fill="#00AC52" />
            <path
              d="M105.811 20.9233V19.9498C105.745 20.0048 105.679 20.0653 105.613 20.1313C105.261 20.4283 104.882 20.6648 104.475 20.8408C104.079 21.0058 103.661 21.0883 103.221 21.0883C102.671 21.0883 102.17 20.9783 101.719 20.7583C101.279 20.5383 100.927 20.2358 100.663 19.8508C100.41 19.4548 100.284 18.9928 100.284 18.4648C100.284 18.0248 100.366 17.6343 100.531 17.2933C100.696 16.9523 100.933 16.6718 101.241 16.4518C101.56 16.2208 101.961 16.0503 102.445 15.9403C102.94 15.8193 103.501 15.7588 104.128 15.7588H105.811V15.3298C105.811 14.8348 105.646 14.4498 105.316 14.1748C104.997 13.8888 104.574 13.7458 104.046 13.7458C103.562 13.7458 103.127 13.8448 102.742 14.0428C102.357 14.2298 102.005 14.5213 101.686 14.9173L100.482 13.7458C100.999 13.1958 101.571 12.7778 102.198 12.4918C102.825 12.1948 103.507 12.0463 104.244 12.0463C104.937 12.0463 105.536 12.1563 106.042 12.3763C106.548 12.5963 106.939 12.9263 107.214 13.3663C107.489 13.7953 107.626 14.3288 107.626 14.9668V20.9233H105.811ZM105.811 17.1283H104.029C103.732 17.1283 103.463 17.1558 103.221 17.2108C102.979 17.2548 102.775 17.3373 102.61 17.4583C102.456 17.5683 102.335 17.7003 102.247 17.8543C102.159 18.0083 102.115 18.1898 102.115 18.3988C102.115 18.6298 102.176 18.8388 102.297 19.0258C102.418 19.2018 102.583 19.3393 102.792 19.4383C103.012 19.5263 103.254 19.5703 103.518 19.5703C103.881 19.5703 104.227 19.5043 104.557 19.3723C104.887 19.2403 105.184 19.0588 105.448 18.8278C105.58 18.7178 105.701 18.5913 105.811 18.4483V17.1283Z"
              fill="#00AC52" />
            <path
              d="M95.355 18.2503V13.9933H93.5895V12.2113H95.355V10.0003H97.302V12.2113H99.216V13.9933H97.302V18.1348C97.302 18.3768 97.335 18.5748 97.401 18.7288C97.467 18.8718 97.577 18.9818 97.731 19.0588C97.896 19.1248 98.0885 19.1578 98.3085 19.1578H98.391C98.424 19.1578 98.457 19.1578 98.49 19.1578H98.721C98.743 19.1578 98.765 19.1578 98.787 19.1578H98.82C98.831 19.1578 98.842 19.1578 98.853 19.1578H98.952C98.963 19.1578 98.9795 19.1578 99.0015 19.1578H99.1665C99.1665 19.1578 99.172 19.1578 99.183 19.1578H99.216L99.9255 20.9233H97.995C97.467 20.9233 97.005 20.8078 96.609 20.5768C96.213 20.3458 95.905 20.0323 95.685 19.6363C95.465 19.2293 95.355 18.7673 95.355 18.2503Z"
              fill="#00AC52" />
            <path
              d="M84.3748 20.9233V12.2113H86.2558L86.2723 13.4818C86.3603 13.3718 86.4593 13.2563 86.5693 13.1353C86.8883 12.8163 87.2678 12.5578 87.7078 12.3598C88.1478 12.1508 88.6098 12.0463 89.0938 12.0463C89.7428 12.0463 90.2928 12.1783 90.7438 12.4423C91.1948 12.7063 91.5303 13.1023 91.7503 13.6303C91.9813 14.1473 92.0968 14.8018 92.0968 15.5938V20.9233H90.1663V15.7258C90.1663 15.2638 90.1003 14.8843 89.9683 14.5873C89.8363 14.2793 89.6493 14.0648 89.4073 13.9438C89.1653 13.8118 88.8683 13.7458 88.5163 13.7458C88.4943 13.7458 88.4668 13.7458 88.4338 13.7458C88.1258 13.7458 87.8398 13.7953 87.5758 13.8943C87.3228 13.9823 87.0973 14.1143 86.8993 14.2903C86.7123 14.4663 86.5638 14.6643 86.4538 14.8843C86.3548 15.1043 86.3053 15.3353 86.3053 15.5773V20.9233H84.3748Z"
              fill="#00AC52" />
            <path
              d="M78.8302 21.0882C77.9172 21.0882 77.1142 20.9012 76.4212 20.5272C75.7282 20.1422 75.1837 19.6142 74.7877 18.9432C74.4027 18.2722 74.2102 17.5077 74.2102 16.6497C74.2102 15.9677 74.3202 15.3462 74.5402 14.7852C74.7602 14.2132 75.0627 13.7237 75.4477 13.3167C75.8437 12.9097 76.3112 12.5962 76.8502 12.3762C77.3892 12.1452 77.9777 12.0297 78.6157 12.0297C79.2097 12.0297 79.7487 12.1397 80.2327 12.3597C80.7167 12.5797 81.1402 12.8767 81.5032 13.2507C81.8662 13.6247 82.1412 14.0647 82.3282 14.5707C82.5152 15.0767 82.6087 15.6212 82.6087 16.2042C82.6087 16.2372 82.6087 16.2702 82.6087 16.3032L82.5922 16.9962H76.1077C76.1627 17.3702 76.2727 17.7057 76.4377 18.0027C76.6797 18.4427 77.0262 18.7837 77.4772 19.0257C77.9282 19.2567 78.4452 19.3722 79.0282 19.3722C79.4572 19.3722 79.8477 19.3062 80.1997 19.1742C80.5517 19.0312 80.9202 18.7947 81.3052 18.4647L82.2457 19.7847C81.9487 20.0487 81.6132 20.2797 81.2392 20.4777C80.8652 20.6757 80.4692 20.8242 80.0512 20.9232C79.6442 21.0332 79.2372 21.0882 78.8302 21.0882ZM80.7442 15.6927V15.6102C80.7222 15.2472 80.6122 14.9282 80.4142 14.6532C80.2162 14.3672 79.9632 14.1417 79.6552 13.9767C79.3472 13.8117 79.0062 13.7292 78.6322 13.7292C78.0822 13.7292 77.6147 13.8392 77.2297 14.0592C76.8557 14.2792 76.5697 14.5927 76.3717 14.9997C76.2727 15.2087 76.1957 15.4397 76.1407 15.6927H80.7442Z"
              fill="#00AC52" />
            <path
              d="M62.6794 20.9232V9.37323H67.2829C68.1079 9.37323 68.8559 9.51623 69.5269 9.80223C70.2089 10.0772 70.7919 10.4732 71.2759 10.9902C71.7709 11.4962 72.1504 12.1012 72.4144 12.8052C72.6784 13.5092 72.8104 14.2902 72.8104 15.1482C72.8104 15.9952 72.6784 16.7762 72.4144 17.4912C72.1504 18.1952 71.7709 18.8057 71.2759 19.3227C70.7919 19.8287 70.2089 20.2247 69.5269 20.5107C68.8559 20.7857 68.1079 20.9232 67.2829 20.9232H62.6794ZM64.7254 19.0587H67.2004C67.7504 19.0587 68.2399 18.9707 68.6689 18.7947C69.1089 18.6077 69.4829 18.3437 69.7909 18.0027C70.0989 17.6507 70.3354 17.2382 70.5004 16.7652C70.6654 16.2812 70.7479 15.7422 70.7479 15.1482C70.7479 14.5542 70.6654 14.0207 70.5004 13.5477C70.3354 13.0637 70.0934 12.6512 69.7744 12.3102C69.4664 11.9582 69.0979 11.6942 68.6689 11.5182C68.2399 11.3312 67.7504 11.2377 67.2004 11.2377H64.7254V19.0587Z"
              fill="#00AC52" />
          </svg>

          <svg width="118" height="60" viewBox="0 0 118 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.7852 24.081H55.2462V28.5289L58.3874 25.4008V20.9266H53.8868L50.7852 24.081Z" fill="#00AC52" />
            <path
              d="M9.64575 31.6833L15.5586 47.759C17.0501 51.8241 22.8046 51.8241 24.296 47.759L30.2089 31.6965H25.7742L20.6532 45.5812C20.4025 46.2544 19.4522 46.2544 19.2014 45.5812L14.1068 31.6833H9.64575Z"
              fill="black" />
            <path
              d="M25.7742 31.6833L31.6871 47.759C33.1785 51.8241 38.933 51.8241 40.4244 47.759L46.3373 31.6965H41.9026L36.7817 45.5812C36.5309 46.2544 35.5806 46.2544 35.3298 45.5812L30.2352 31.6833H25.7742Z"
              fill="black" />
            <path d="M55.2463 31.6833H50.7852V50.5702H55.2463V31.6833Z" fill="black" />
            <path
              d="M74.6479 32.5808C73.1565 31.7361 71.4539 31.3137 69.5797 31.3137C67.7056 31.3137 66.003 31.7361 64.5115 32.5808C63.0201 33.4255 61.8455 34.6001 60.9876 36.0783C60.1297 37.5566 59.6941 39.246 59.6941 41.1201C59.6941 42.9943 60.1297 44.6969 60.9876 46.1619C61.8455 47.6402 63.0333 48.8148 64.5115 49.6595C65.9898 50.5042 67.6924 50.9266 69.5797 50.9266C71.4539 50.9266 73.1565 50.5042 74.6479 49.6595C76.1394 48.8148 77.314 47.6402 78.1455 46.1619C78.977 44.6837 79.3994 42.9943 79.3994 41.1201C79.3994 39.246 78.977 37.5434 78.1455 36.0783C77.3008 34.6001 76.1262 33.4255 74.6479 32.5808ZM72.3646 46.2543C71.5727 46.7691 70.6356 47.033 69.5797 47.033C68.5503 47.033 67.6132 46.7691 66.7949 46.2543C65.9766 45.7396 65.3167 45.0269 64.8679 44.1558C64.406 43.2715 64.1816 42.2552 64.1816 41.1333C64.1816 40.0115 64.4192 38.982 64.8679 38.1109C65.3298 37.2266 65.9766 36.5271 66.7949 36.0124C67.6132 35.4976 68.5503 35.2336 69.5797 35.2336C70.6356 35.2336 71.5727 35.4976 72.3646 36.0124C73.1697 36.5271 73.8032 37.2134 74.2652 38.0581C74.7271 38.9028 74.9515 39.8795 74.9515 40.9618V41.3181C74.9515 42.374 74.7139 43.3375 74.2652 44.2086C73.8032 45.0401 73.1697 45.7396 72.3646 46.2543Z"
              fill="black" />
            <path
              d="M99.593 34.7716C99.1046 33.6366 98.3523 32.7655 97.3624 32.1848C96.3857 31.604 95.1715 31.3137 93.7593 31.3137C92.7034 31.3137 91.6871 31.538 90.7368 31.9736C89.7865 32.4091 88.955 32.9899 88.2555 33.6762C88.1103 33.8346 87.9784 33.9929 87.8464 34.1381L87.82 31.6568H83.5305V50.5701H87.9256V39.0083C87.9256 38.5068 88.0312 38.0317 88.2423 37.5829C88.4535 37.1342 88.7703 36.7118 89.1662 36.3555C89.5622 35.9991 90.0373 35.7087 90.5652 35.524C91.1064 35.326 91.7003 35.2336 92.3338 35.2336H92.5054C93.2313 35.2336 93.8516 35.3656 94.3532 35.6295C94.8415 35.8803 95.2243 36.3291 95.4883 36.9362C95.7654 37.5697 95.8974 38.3748 95.8974 39.3383V50.5965H100.292V39.0611C100.319 37.3321 100.081 35.8935 99.593 34.7716Z"
              fill="black" />
            <path d="M106.679 20.9233V8.71332H108.61V20.9233H106.679Z" fill="#00AC52" />
            <path
              d="M102.655 20.9233V19.9498C102.589 20.0048 102.523 20.0653 102.457 20.1313C102.105 20.4283 101.726 20.6648 101.319 20.8408C100.923 21.0058 100.505 21.0883 100.065 21.0883C99.5147 21.0883 99.0142 20.9783 98.5632 20.7583C98.1232 20.5383 97.7712 20.2358 97.5072 19.8508C97.2542 19.4548 97.1277 18.9928 97.1277 18.4648C97.1277 18.0248 97.2102 17.6343 97.3752 17.2933C97.5402 16.9523 97.7767 16.6718 98.0847 16.4518C98.4037 16.2208 98.8052 16.0503 99.2892 15.9403C99.7842 15.8193 100.345 15.7588 100.972 15.7588H102.655V15.3298C102.655 14.8348 102.49 14.4498 102.16 14.1748C101.841 13.8888 101.418 13.7458 100.89 13.7458C100.406 13.7458 99.9712 13.8448 99.5862 14.0428C99.2012 14.2298 98.8492 14.5213 98.5302 14.9173L97.3257 13.7458C97.8427 13.1958 98.4147 12.7778 99.0417 12.4918C99.6687 12.1948 100.351 12.0463 101.088 12.0463C101.781 12.0463 102.38 12.1563 102.886 12.3763C103.392 12.5963 103.783 12.9263 104.058 13.3663C104.333 13.7953 104.47 14.3288 104.47 14.9668V20.9233H102.655ZM102.655 17.1283H100.873C100.576 17.1283 100.307 17.1558 100.065 17.2108C99.8227 17.2548 99.6192 17.3373 99.4542 17.4583C99.3002 17.5683 99.1792 17.7003 99.0912 17.8543C99.0032 18.0083 98.9592 18.1898 98.9592 18.3988C98.9592 18.6298 99.0197 18.8388 99.1407 19.0258C99.2617 19.2018 99.4267 19.3393 99.6357 19.4383C99.8557 19.5263 100.098 19.5703 100.362 19.5703C100.725 19.5703 101.071 19.5043 101.401 19.3723C101.731 19.2403 102.028 19.0588 102.292 18.8278C102.424 18.7178 102.545 18.5913 102.655 18.4483V17.1283Z"
              fill="#00AC52" />
            <path d="M93.0995 20.9233V12.2113H95.03V20.9233H93.0995ZM93.0995 10.7758V8.71332H95.03V10.7758H93.0995Z"
              fill="#00AC52" />
            <path
              d="M87.7686 21.0883C86.9326 21.0883 86.1901 20.8903 85.5411 20.4943C84.8921 20.0983 84.3751 19.5593 83.9901 18.8773C83.6161 18.1953 83.4291 17.4253 83.4291 16.5673C83.4291 15.7093 83.6161 14.9393 83.9901 14.2573C84.3751 13.5753 84.8921 13.0363 85.5411 12.6403C86.1901 12.2443 86.9326 12.0463 87.7686 12.0463C88.5386 12.0463 89.2371 12.1948 89.8641 12.4918C90.5021 12.7888 90.9971 13.1903 91.3491 13.6963L90.2766 14.9833C90.1116 14.7523 89.9026 14.5433 89.6496 14.3563C89.3966 14.1693 89.1216 14.0263 88.8246 13.9273C88.5276 13.8173 88.2306 13.7623 87.9336 13.7623C87.4276 13.7623 86.9766 13.8833 86.5806 14.1253C86.1846 14.3673 85.8711 14.7028 85.6401 15.1318C85.4201 15.5498 85.3101 16.0283 85.3101 16.5673C85.3101 17.0953 85.4256 17.5683 85.6566 17.9863C85.8876 18.4043 86.2011 18.7398 86.5971 18.9928C87.0041 19.2458 87.4551 19.3723 87.9501 19.3723C88.2581 19.3723 88.5496 19.3283 88.8246 19.2403C89.0996 19.1413 89.3581 19.0038 89.6001 18.8278C89.8531 18.6518 90.0786 18.4373 90.2766 18.1843L91.3491 19.4878C90.9751 19.9608 90.4636 20.3458 89.8146 20.6428C89.1656 20.9398 88.4836 21.0883 87.7686 21.0883Z"
              fill="#00AC52" />
            <path
              d="M77.6706 21.0883C76.8016 21.0883 76.0206 20.8958 75.3276 20.5108C74.6456 20.1148 74.1066 19.5758 73.7106 18.8938C73.3146 18.2118 73.1166 17.4363 73.1166 16.5673C73.1166 15.6983 73.3146 14.9228 73.7106 14.2408C74.1066 13.5588 74.6456 13.0253 75.3276 12.6403C76.0206 12.2443 76.8016 12.0463 77.6706 12.0463C78.5396 12.0463 79.3151 12.2443 79.9971 12.6403C80.6901 13.0253 81.2291 13.5588 81.6141 14.2408C81.9991 14.9228 82.1916 15.6983 82.1916 16.5673C82.1916 17.4363 81.9991 18.2118 81.6141 18.8938C81.2291 19.5758 80.6901 20.1148 79.9971 20.5108C79.3151 20.8958 78.5396 21.0883 77.6706 21.0883ZM77.6706 19.3888C78.1766 19.3888 78.6221 19.2678 79.0071 19.0258C79.3921 18.7728 79.6946 18.4428 79.9146 18.0358C80.1346 17.6178 80.2446 17.1558 80.2446 16.6498C80.2446 16.6168 80.2446 16.5893 80.2446 16.5673C80.2446 16.5453 80.2446 16.5178 80.2446 16.4848C80.2446 15.9678 80.1346 15.5058 79.9146 15.0988C79.6946 14.6918 79.3921 14.3673 79.0071 14.1253C78.6221 13.8723 78.1766 13.7458 77.6706 13.7458C77.1756 13.7458 76.7301 13.8723 76.3341 14.1253C75.9381 14.3673 75.6301 14.7028 75.4101 15.1318C75.1901 15.5498 75.0801 16.0283 75.0801 16.5673C75.0801 17.1063 75.1901 17.5903 75.4101 18.0193C75.6301 18.4373 75.9381 18.7728 76.3341 19.0258C76.7301 19.2678 77.1756 19.3888 77.6706 19.3888Z"
              fill="#00AC52" />
            <path
              d="M67.4394 21.0883C66.4054 21.0883 65.5144 20.8958 64.7664 20.5108C64.0294 20.1258 63.3969 19.5648 62.8689 18.8278L64.1724 17.3593C64.7114 18.0963 65.2449 18.6078 65.7729 18.8938C66.3119 19.1798 66.9169 19.3228 67.5879 19.3228C67.9729 19.3228 68.3249 19.2623 68.6439 19.1413C68.9629 19.0203 69.2104 18.8498 69.3864 18.6298C69.5734 18.4098 69.6669 18.1568 69.6669 17.8708C69.6669 17.6728 69.6284 17.4913 69.5514 17.3263C69.4744 17.1613 69.3589 17.0183 69.2049 16.8973C69.0619 16.7653 68.8804 16.6443 68.6604 16.5343C68.4404 16.4243 68.1929 16.3308 67.9179 16.2538C67.6539 16.1658 67.3514 16.0888 67.0104 16.0228C66.4164 15.8908 65.8939 15.7258 65.4429 15.5278C65.0029 15.3188 64.6289 15.0713 64.3209 14.7853C64.0239 14.4883 63.7984 14.1528 63.6444 13.7788C63.5014 13.4048 63.4299 12.9758 63.4299 12.4918C63.4299 11.8428 63.6114 11.2708 63.9744 10.7758C64.3484 10.2698 64.8434 9.87375 65.4594 9.58775C66.0864 9.30175 66.7739 9.15875 67.5219 9.15875C68.5009 9.15875 69.3204 9.33475 69.9804 9.68675C70.6514 10.0278 71.1849 10.5118 71.5809 11.1388L70.2444 12.4588C69.9034 11.9638 69.5074 11.5898 69.0564 11.3368C68.6054 11.0838 68.0994 10.9573 67.5384 10.9573C67.1314 10.9573 66.7684 11.0178 66.4494 11.1388C66.1414 11.2488 65.8994 11.4138 65.7234 11.6338C65.5584 11.8538 65.4759 12.1123 65.4759 12.4093C65.4759 12.6403 65.5254 12.8493 65.6244 13.0363C65.7234 13.2123 65.8719 13.3718 66.0699 13.5148C66.2679 13.6468 66.5099 13.7623 66.7959 13.8613C67.0819 13.9603 67.4119 14.0483 67.7859 14.1253C68.3909 14.2463 68.9354 14.4113 69.4194 14.6203C69.9034 14.8183 70.3159 15.0548 70.6569 15.3298C70.9979 15.6048 71.2564 15.9238 71.4324 16.2868C71.6194 16.6388 71.7129 17.0403 71.7129 17.4913C71.7129 18.2283 71.5314 18.8663 71.1684 19.4053C70.8164 19.9443 70.3214 20.3623 69.6834 20.6593C69.0454 20.9453 68.2974 21.0883 67.4394 21.0883Z"
              fill="#00AC52" />
          </svg>
      </div>
    </div>
    <div class="w-full flex flex-col gap-2 items-center justify-center">
      <div class="tds-body-2 tds-color-text-tertiary">
        Hãy đăng nhập để bắt đầu sử dụng WiOn E-commerce
      </div>
      <button
        tds-button
        color="primary"
        class="w-full"
        size="xl"
        (tdsBtnClick)="onSignIn()"
      >
        Đăng nhập
      </button>
    </div>
  </section>
</div>