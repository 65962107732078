<div class="w-full h-full flex flex-col">
  <div class="flex flex-col tds-page-header-color-background">
    <tds-page-header tdsTitle="Danh sách vai trò">
      <tds-page-header-extra>
        <button tds-button
          [tdsPrefixIcon]="'tdsi-plus-circle-fill'"
          color="primary"
          (tdsBtnClick)="addNewRole()"
        >
          Thêm vai trò
        </button>
      </tds-page-header-extra>
    </tds-page-header>
  </div>

  <div class="w-full flex-auto">
    <div class="w-full h-full p-2">
      <div class="flex flex-col w-full h-full bg-white rounded-md">
        <div class="flex flex-row justify-end">
          <tds-form-field class="w-80 py-1.5 pr-2.5">
            <input
              tdsInput
              #searchInput
              placeholder="Tìm theo tên vai trò, thành viên"
              class="placeholder-neutral-1-400"
              [(ngModel)]="searchValue"
            />
            <span tdsPrefix><i class="text-neutral-1-500 tdsi-search-fill text-base"></i></span>
          </tds-form-field>
        </div>
        <div class="flex-auto w-full tds-body-2">
          <tds-table
            #roleTable
            [widthConfig]="['208px', '200px', '150px', '240px', '200px', '150px', '200px', '150px', '150px']"
            showPagination
            showSizeChanger
            [scroll]="{'x':'auto','y': 'auto'}"
            [listData]="roles"
            [(pageIndex)]="pageIndex"
            [(pageSize)]="pageSize"
            [total]="total"
            [loading]="isLoading"
            [frontPagination]="false"
            [pageSizeOptions]="[10, 20, 50, 100, 200]"
            (queryParams)="onQueryParamsChange($event)"
            (clickRefresh)="refreshTable()"
          >
            <thead>
              <tr>
                <th scope="col">Tên vai trò</th>
                <th scope="col">Thành viên</th>
                <th scope="col">Loại</th>
                <th scope="col">Mô tả</th>
                <th scope="col">Tạo bởi</th>
                <th scope="col">Ngày tạo</th>
                <th scope="col">Cập nhật bởi</th>
                <th scope="col">Ngày cập nhật</th>
                <th scope="col">Thao tác</th>
              </tr>
            </thead>
            <tbody>
              @for(role of roles; track role.id) {
                <tr>
                  <td>
                    <div class="tds-color-text-primary">{{role.name}}</div>
                  </td>
                  <td>
                    @if (role.userInRoleList && role.userInRoleList.length) {
                      @if (role.userInRoleList.length === 1) {
                        <div class="flex items-center gap-2">
                          <tds-avatar
                            class="tds-color-background-info-minimal tds-color-text-info tds-body-1 font-semibold"
                            [tdsSrc]="role.userInRoleList[0].avatar">
                          </tds-avatar>
                          <span class="tds-body-2 tds-color-text-primary">{{role.userInRoleList[0].name}}</span>
                        </div>
                      } @else {
                        <tds-avatar-group>
                          @for(user of role.userInRoleList; let idx = $index; track user.id) {
                            @if(idx < 3) {
                              <tds-avatar class="tds-color-background-info-minimal tds-color-text-info tds-body-1 font-semibold cursor-pointer"
                                tds-tooltip
                                [tooltipTitle]="role.userInRoleList[idx].name"
                                [tdsSrc]="role.userInRoleList[idx].avatar">
                              </tds-avatar>
                            }
                          }
                          @if(role.userInRoleList.length > 3){
                            <tds-avatar 
                              class="tds-color-background-info-minimal tds-color-text-info font-semibold tds-body-1 cursor-pointer" 
                              tds-dropdown
                              [text]="'+' + (role.userInRoleList.length - 3)"
                              trigger="click"
                              [tdsDropdownMenu]="usersInRole"
                            >
                            </tds-avatar>
                          }

                          <tds-dropdown-menu #usersInRole="tdsDropdownMenu">
                            @for(user of role.userInRoleList; track user.id; let idx = $index) {
                              @if(idx >= 3) {
                                <div class="max-w-64 flex items-center gap-2 px-3 py-2">
                                  <tds-avatar
                                    class="tds-color-background-info-minimal tds-color-text-info tds-body-1 font-semibold"
                                    [tdsSrc]="user.avatar">
                                  </tds-avatar>
                                  <span class="tds-body-2 tds-color-text-primary flex-1">{{user.name}}</span>
                                </div>
                              }
                            }
                          </tds-dropdown-menu>
                        </tds-avatar-group>
                      }
                    } @else {
                      <div>---</div>
                    }
                  </td>
                  <td> 
                    <tds-tag [status]="role.isDefault ? 'info' : 'success'" type="outline">{{role.isDefault ? 'Mặc định' : 'Tùy chỉnh'}}</tds-tag>
                  </td>
                  <td>
                    @if(role.description) {
                      <p class="tds-body-2 tds-color-text-primary">{{role.description}}</p>
                    } @else {
                      <p>---</p>
                    }
                  </td>
                  <td>
                    <div class="flex items-center gap-2">
                      @if(role.creator !== null) {
                        <tds-avatar class="bg-base-orange-500 dark:bg-d-base-orange-500 text-white" size="md"
                          [tdsSrc]="role.creator.avatar">
                        </tds-avatar>
                        <div>{{role.creator.name}}</div>
                      } @else {
                        <div>---</div>
                      }
                    </div>
                  </td>
                  <td>
                    <div>{{role.creationTime | date: 'dd/MM/yyyy'}}</div>
                    <div>{{role.creationTime | date: 'HH:mm:ss'}}</div>
                  </td>
                  <td>
                    <div class="flex items-center gap-2">
                      @if(role.updator !== null) {
                        <tds-avatar class="bg-base-orange-500 dark:bg-d-base-orange-500 text-white" size="md"
                          [tdsSrc]="role.updator!.avatar">
                        </tds-avatar>
                        <div>{{role.updator!.name}}</div>  
                      } @else {
                        <div>---</div>
                      }
                    </div>
                  </td>
                  <td>
                    @if(role.lastModificationTime) {
                      <div>{{role.lastModificationTime | date: 'dd/MM/yyyy'}}</div>
                      <div>{{role.lastModificationTime | date: 'HH:mm:ss'}}</div>
                    } @else {
                      <div>---</div>
                    }
                  </td>
                  <td>
                    @if(!role.isDefault) {
                      <div class="flex items-center gap-2">
                      <button tds-button-flat-icon
                        size="sm"
                        color="info"
                        (tdsBtnClick)="editRole(role)"
                      >
                        <i class="tdsi-edit-line"></i>
                      </button>
                      <button tds-button-flat-icon
                        size="sm"
                        color="error"
                        (tdsBtnClick)="deleteRole(role)"
                      >
                        <i class="tdsi-trash-line"></i>
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </tds-table>
        </div>

      </div>
    </div>
  </div>
</div>